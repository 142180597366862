import React from "react";
import { Link } from "react-router-dom";
export const subscriptionAssetColumn = [

  {
    title: "Asset Id",
    dataIndex: "assetId",
    key: "assetId",
    render: (text, record) =>
      <Link to="#">
        {text}
      </Link>
  },
  {
    title: "Engine Name",
    dataIndex: "engineType",
    key: "engineType",
  },
  {
    title: "Platform Name",
    dataIndex: "platform",
    key: "platform",
  },
  {
    title: "Size",
    dataIndex: "size",
    key: "size",
  },
];

export const productAssetColumn = [
  {
    title: "Asset Id",
    dataIndex: "assetId",
    key: "assetId",
  },
  {
    title: "Engine Name",
    dataIndex: "engineType",
    key: "engineType",
  },
  {
    title: "Platform Name",
    dataIndex: "platform",
    key: "platform",
  },
  {
    title: "Size",
    dataIndex: "size",
    key: "size",
  },
];
