import React, { useEffect, useState } from "react";
import { Table, Breadcrumb, message, Skeleton, DatePicker, Tooltip, Tag, Pagination } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CancelButton } from "../../buttons/CancelButton";
import "./css/EnvDetailsTable.css";
import { PageHeader } from "@ant-design/pro-layout";
import { getDeployedAssetVersions } from "@services/EnvService";
import { convertDateToNestorTime } from "@utilities/datehelper";
import { getTargetPlatforms ,getAssetTypes } from "@services/systemService";
import {FileOutlined ,GlobalOutlined ,AndroidOutlined , AppleOutlined  } from '@ant-design/icons';

const pageSize = 20;

function EnvironmentDetailsTable() {
  const location = useLocation();
  const navigate = useNavigate();
  const { env, envId } = location.state || {};
  const [isLoading, setLoading] = useState(true);
  const [filters, setFilters] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalVersions, setTotalVersions] = useState();
  const [targetPlatform, setTargetPlatform] = useState();
  const [getDAV, setGetDAV] = useState();

  const [showTotal, setShowTotal] = useState();
  const [assetTypeMap, setAssetTypeMap] = useState({}); //assetType states

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const setDateFilter = (dates) => {
    if (dates !== null) {
      setStartDate(new Date(dates[0]?.$d).toISOString());
      setEndDate(new Date(dates[1]?.$d).toISOString());
    } else {
      setEndDate("");
      setStartDate("");
    }
  };

  const getTotalDeployedAssetVersions = (envId) => {
    const query = `pageNumber=${pageNumber}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}&targetPlatform=${targetPlatform}`;
    getDeployedAssetVersions(envId, query)
      .then((res) => {
        if (res.status === 200 && res.data && Array.isArray(res.data.results)) {
          const formattedData = res.data.results.map((item, index) => ({
            key: index + 1,
            assetId: item.assetId,
            versionId: item.versionId,
            versionName: item.versionName,
            createdAt: item.createdAt,
            targetPlatform: item.targetPlatform,
            state: item.state,
            size: item.size,
            assetType: item.assetType,
            item,
          }));
          setGetDAV(formattedData);
          setTotalVersions(res?.data?.totalResults);
          setLoading(false);
        } else {
          console.error("Unexpected API response format:", res);
        }
      })
      .catch((err) => {
        message.error("Unable to load total asset versions.");
        setLoading(false);
      });
  };


  useEffect(() => {
    const fetchAssetTypes = async () => {
      try {
        const response = await getAssetTypes();
        const assetTypeMapping = response.data.reduce((acc, asset) => {
          acc[asset.typeId] = asset.typeName; 
          return acc;
        }, {});
        setAssetTypeMap(assetTypeMapping);
      } catch (error) {
        console.error("Failed to fetch asset types", error);
      }
    };

    fetchAssetTypes();
  }, []);


  const getTargetPlatformFilters = () => {
    getTargetPlatforms()
      .then((res) => {

        if (res.status === 200 && res.data && res.data.dataList) {
          const formattedFilters = res.data.dataList.map((item) => ({
            text: item.platformName,
            value: item.platformName.toLowerCase(),
          }));
          setFilters(formattedFilters);
        } else {
          console.error("Unexpected API response format:", res);
        }
      })
      .catch((err) => {
        message.error("Unable to load target platforms.");
      });
  };

  useEffect(() => {
    getTargetPlatformFilters();
  }, []);

  useEffect(() => {
    getTotalDeployedAssetVersions(envId);
  }, [envId, startDate, endDate, pageNumber, targetPlatform]);

  const getVersionForDetailPage = (assetVersion) => {
    const concatenatedId = assetVersion.concatenatedId.replace(`###${assetVersion.envId}`, "");
    return {
      ...assetVersion,
      concatenatedId,
    };
  };

  const paginationConfg = {
    total: parseInt(totalVersions),
    responsive: true,
    pageSize: pageSize,
    current: pageNumber,
    onChange: setPageNumber,
    showSizeChanger: false,
    showTotal: (total, range) => setShowTotal(`${range[0]}-${range[1]} of ${total} Versions`),
  };

  const TotalComponent = () => {
    return (
      <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "end", marginBlock: "10px" }}>
        {showTotal}
        <Pagination
          {...paginationConfg}
          style={{
            textAlign: "left",
          }}
        />
      </div>
    );
  };

  const columns = [
    {
      title: "Asset ID",
      dataIndex: "assetId",
      key: "assetId",
      className: "center-align",
      onHeaderCell: () => ({
        style: { textAlign: "center" },
      }),
      render: (assetId) => {
        if (assetId.length > 10) {
          return (
            <Tooltip title={assetId}>
              <span>{`${assetId.slice(0, 9)}...`}</span>
            </Tooltip>
          );
        }
        return assetId;
      },
    },
    {
      title: "Version Id",
      dataIndex: "versionId",
      key: "versionId",
      className: "center-align",
      onHeaderCell: () => ({
        style: { textAlign: "center" },
      }),
      render: (versionId, record) => (
        <Link
          to={`/assetscontainer/${record.assetId}/assetversion/${record.versionId}`}
          state={{
            assetId: record.assetId,
            assetVersion: getVersionForDetailPage(record.item),
            platform: { platformName: record.targetPlatform },
          }}
          className="custom-link"
        >
          {versionId}
        </Link>
      ),
    },
    {
      title: "Version Name",
      dataIndex: "versionName",
      key: "versionName",
      className: "center-align",
      onHeaderCell: () => ({
        style: { textAlign: "center" },
      }),
      render: (versionName) => {
        if (versionName.length > 10) {
          return (
            <Tooltip title={versionName}>
              <span>{`${versionName.slice(0, 9)}...`}</span>
            </Tooltip>
          );
        }
        return versionName;
      },
    },
    {
      title:"Asset Type",
      dataIndex:"assetType",
      key:"assetType",
      className: "center-align",
      onHeaderCell: () => ({
        style: { textAlign: "center" },
      }),
      render: (assetType) => assetTypeMap[assetType] || assetType,
    },
    {
      title: "Target Platform",
      dataIndex: "targetPlatform",
      key: "targetPlatform",
      filterMode: "tree",
      filters: filters,
      filterSearch: true,
      onFilter: (value, record) => record.targetPlatform && record.targetPlatform.includes(value),
      className: "center-align",
      onHeaderCell: () => ({
        style: { textAlign: "center" },
      }),
      render: (targetPlatform) => {
        let IconComponent;
        let tooltipTitle;
    
        switch (targetPlatform) {
          case 'generic':
            IconComponent = FileOutlined;
            tooltipTitle = "Generic";
            break;
          case 'web':
            IconComponent = GlobalOutlined;
            tooltipTitle = "Web";
            break;
          case 'android':
            IconComponent = AndroidOutlined;
            tooltipTitle = "Android";
            break;
          case 'ios':
            IconComponent = AppleOutlined;
            tooltipTitle = "IOS";
            break;
          default:
            IconComponent = FileOutlined; 
            tooltipTitle = "Generic";
        }
    
        return (
          <Tooltip title={tooltipTitle}>
            <IconComponent style={{ fontSize: '24px' }} />
          </Tooltip>
        );
      },
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      className: "center-align",
      onHeaderCell: () => ({
        style: { textAlign: "center" },
      }),

      render: (state) => {
        let color;
        let text;
        switch (state) {
          case "LIVE":
            color = "#87d068";
            text = "LIVE";
            break;
          case "DEFAULT":
            color = "#108ee9";
            text = "DEFAULT";
            break;
          case "OFFLINE":
            color = "#C0C0C0";
            text = "OFFLINE";
            break;
          default:
            color = "black";
            text = state;
        }
        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      className: "center-align",
      onHeaderCell: () => ({
        style: { textAlign: "center" },
      }),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      filterDropdown: <DatePicker.RangePicker style={{ margin: 15 }} onChange={setDateFilter} />,
      // sorter: (a, b) => a.date - b.date,
      render: (createdAt) => {
        return convertDateToNestorTime(createdAt);
      },
      className: "center-align",
      onHeaderCell: () => ({
        style: { textAlign: "center" },
      }),
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    setTargetPlatform(filters.targetPlatform || []);
  };
  const goBack = () => {
    navigate(-1);
  };
  return (
    <Skeleton loading={isLoading}>
      <div style={{ width: "100%" }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/dashboard">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/environments">Environments</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/environments/${envId}`} state={envId}>
              {env?.envName}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Versions</Breadcrumb.Item>
        </Breadcrumb>

        <PageHeader
          title={["Deployed Versions - " + envId]}
          style={{ margin: 0, marginTop: 0, width: "100%" }}
          extra={[<CancelButton key="cancelButton" onClick={goBack} />]}
        />
        <TotalComponent />
        <Table
          sticky={true}
          columns={columns}
          dataSource={getDAV}
          onChange={onChange}
          pagination={paginationConfg}
          showSorterTooltip={{
            target: "sorter-icon",
          }}
        />
      </div>
    </Skeleton>
  );
}

export default EnvironmentDetailsTable;
