import React from "react";
import { Form, Input } from "antd";
const ConfirmPassword = (props) => {
  return (
    <Form.Item
      label={props.label || "Confirm Password"}
      name={props.name || "confirmPass"}
      style={{ marginTop: "15px !important" }}
      rules={[
        { required: true, message: "Please enter password" },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue(props.passwordName || "password") === value) {
              return Promise.resolve();
            }
            return Promise.reject(new Error("Passwords do not match"));
          },
        }),
      ]}
    >
      <Input type="password" style={{ height: 40, borderRadius: "10%" }} />
    </Form.Item>
  );
};

export default ConfirmPassword;
