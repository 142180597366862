import React, { useEffect, useState } from "react";
import { getOrganization } from "@services/organizationService";
import { PageHeaderDetailForm } from "@dashboardpages/PageHeader";
import { Breadcrumb, Col, Descriptions, Row, Skeleton, Typography } from "antd";
import { EmptyView } from "@vyomr/nestor_components";
import { useNavigate, Link } from "react-router-dom";
import GenericDetailsPage from "../../GenericComponenets/GenericDetailsPage";
import useAuth from "@hooks/useAuth";
import "../css/form.css";
import { AppObject } from "@context/AppObject";

const { Title } = Typography;

const OrganizationDetails = () => {
  const [orgData, setOrgData] = useState();
  const [isLoadingOrgData, setIsLoadingOrgData] = useState(false);
  const { hasAccess, ACTIONS } = useAuth();
  let navigate = useNavigate();

  const fetchOrgData = () => {
    setIsLoadingOrgData(true);
    getOrganization()
      .then((res) => {
        setIsLoadingOrgData(false);
        let orgData = res?.data?.organization;
        setOrgData(orgData);

        if (res?.status === 404) {
          setIsLoadingOrgData(false);
          setOrgData();
        }
        return res;
      })
      .catch((err) => {
        setIsLoadingOrgData(false);
      });
  };

  const trimOrgData = () => {
    const data = {
      Name: orgData.organizationName,
      Website: orgData.website,
      "Created By": orgData.createdBy,
    };
    delete data._id;
    delete data.__v;

    return data;
  };

  const getOrgDetailsComponent = () => {
    const trimmedData = trimOrgData();
    return <GenericDetailsPage items={trimmedData} objectId={AppObject.ORGANIZATION} />;
  };

  useEffect(() => {
    fetchOrgData();
  }, []);

  return (
    <React.Fragment>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{"Organization Details"}</Breadcrumb.Item>
      </Breadcrumb>

      <div style={{ paddingTop: 15 }}>
        <Skeleton loading={isLoadingOrgData}>
          {orgData ? (
            <React.Fragment>
              <PageHeaderDetailForm
                objectId={AppObject.ORGANIZATION}
                title="Organization Details"
                onEdit={() => navigate("update")}
              />
              <div
                style={{
                  background: "#fff",
                  width:"100%",
                 display:"flex",
                  padding: 10,
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderBottomLeftRadius: 10,
                  borderBottomRightRadius: 10,
                }}
              >
              
                <Row>
                  <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                    <div id="card-info">
                      <Title level={5} className="versionDetails-row" style={{ textAlign: "right" }}>
                        <span className="versionDetails-col" style={{ marginRight: 10,minWidth:"150px" }}>
                          <strong>Organization Name :</strong>{" "}
                        </span>
                        {orgData?.organizationName}
                      </Title>
                      <Title level={5} className="versionDetails-row" style={{ textAlign: "right" }}>
                        <span className="versionDetails-col" style={{ marginRight: 10, minWidth:"150px"}}>
                          <strong>Website :</strong>{" "}
                        </span>
                        {orgData?.website}
                      </Title>
                      <Title level={5} className="versionDetails-row" style={{ textAlign: "right" }}>
                        <span className="versionDetails-col" style={{ marginRight: 10, minWidth:"150px" }}>
                          <strong>Created At :</strong>{" "}
                        </span>
                        {new Date(orgData?.createdAt).toUTCString()}
                      </Title>
                      <Title level={5} className="versionDetails-row" style={{ textAlign: "right" }}>
                        <span className="versionDetails-col" style={{ marginRight: 10,minWidth:"150px"  }}>
                          <strong>Created By :</strong>{" "}
                        </span>
                        {orgData?.createdBy}
                      </Title>
                    </div>
                  </Col>
                </Row>
              </div>
            </React.Fragment>
          ) : (
            <EmptyView
              onButtonClick={() => navigate("add", { replace: true })}
              disabled={!hasAccess(AppObject.ORGANIZATION, ACTIONS.POST)}
              msg="No Organization Data available. Please create new one."
              buttonText="Add Organization"
            />
          )}
        </Skeleton>
      </div>
    </React.Fragment>
  );
};

export default OrganizationDetails;
