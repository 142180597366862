/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { Card, Breadcrumb, Col, Steps } from "antd";
import GenericFormContainer from "../GenericFormContainer";
import { Button, message, Form, Input, Select, Switch, Upload, Skeleton, Modal } from "antd";
import "./css/CreateGameAsset.css";
import { useNavigate } from "react-router-dom";
import { RefreshButton } from "@dashboard/buttons/RefreshButton";
import { InboxOutlined } from "@ant-design/icons";
import {
  createAssetContainers,
  createAssetVersion,
  addAssetVersionToEnv,
  publishAssetVersion,
} from "@services/assetContainer";
import CardList from "../../cards/CardList";
import { getAllEnv } from "@services/EnvService";
import SkipModal from "@dashboardmodals/Skip";
import { getAssetState, getAssetTypes, getTargetPlatforms } from "@services/systemService";
const { Dragger } = Upload;
const { Option } = Select;

const CreateAssetContainer = (props) => {
  const [form] = Form.useForm();
  const [formField, setForm] = useState();
  const [assetTypes, setAssetTypes] = useState([]);

  const [isFormValid, setIsFormValid] = useState(false);
  const [isAssetTypeLoading, setAssetTypeLoaded] = useState(true);

  const [isLoading, setLoading] = useState(false);
  const [successModalVisiblilty, setSuccessModalVisiblilty] = useState(false);
  const [failureModalVisiblilty, setFailureModalVisiblilty] = useState(false);
  const navigate = useNavigate();
  const [cacheAge, setCacheAge] = useState();
  const [cacheError, setCacheError] = useState("");
  const [cacheEnabled, setCacheEnabled] = useState(false);
  useEffect(() => {
    fetchFunc(getAssetTypes, setAssetTypes, setAssetTypeLoaded, "Asset Types");
    form.setFieldsValue({ active: true });
  }, []);

  const uploadFileProps = (fieldName) => {
    return {
      onRemove: (file) => {
        setForm({ ...formField, [fieldName]: null });
      },
      beforeUpload: (file) => {
        setForm({ ...formField, [fieldName]: file });
        return false;
      },
      maxCount: 1,
      listType: "picture",
    };
  };

  const checkFormValidity = () => {
    const hasError = form.getFieldsError().some(({ errors }) => errors.length > 0);
    const {
      assetId,
      assetName,
      assetType,
      active,
      secured = true,
      localCacheControl,
      localCacheMaxAge,
    } = form.getFieldsValue();
    const isFormCompleted = form.isFieldTouched("assetId" && "assetName" && "assetType");
    const isValid = !hasError && isFormCompleted;
    setForm({
      ...formField,
      assetId,
      assetName,
      assetType,
      active,
      secured,
      localCacheControl,
      localCacheMaxAge,
    });
    setIsFormValid(isValid);
  };

  const fetchFunc = (getFunc, setFunc, setLoadOff, type) => {
    getFunc()
      .then((res) => {
        if (res.status == 200 && res?.data?.length > 0) {
          setFunc(res?.data);
        } else {
          message.error(`Unable to find game ${type}. Please try again later"`);
        }
        setLoadOff(false);
      })
      .catch((err) => {
        message.error(`Unable to find game ${type}. Please try again later"`);
        setLoadOff(false);
      });
  };

  const handlePrivateMaxAge = (e) => {
    const inputValue = e.target.value;
    setCacheAge(inputValue);

    if (inputValue === "" || isNaN(inputValue)) {
      setCacheError("Please enter a valid number");
    } else if (Number(inputValue) > 525600) {
      setCacheError("Value cannot exceed 525600");
    } else {
      setCacheError("");
    }
  };
  const onActiveChange = (isChecked) => {
    form.setFieldsValue({ active: isChecked });
  };

  const onSecuredChange = (isChecked) => {
    form.setFieldsValue({ secured: isChecked });
  };

  const createAsset = () => {
    setLoading(true);
    const formData = new FormData();
    for (let key in formField) {
      if(formField[key]){
      formData.append(key, formField[key]);
      }
    }

    createAssetContainers(formData)
      .then((res) => {
        if (res?.status == 201) {
          props?.setAssetContainer(res.data);
          setSuccessModalVisiblilty(true);
        } else {
          // message.error("Sorry! We are unable to create your asset right now.")
          setFailureModalVisiblilty(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setFailureModalVisiblilty(true);
      });
  };

  const onSubmit = () => {
    createAsset();
  };

  const navigateToAssetList = () => {
    setFailureModalVisiblilty(false);
    setSuccessModalVisiblilty(false);
    navigate("/assetscontainer");
  };

  const createMoreAsset = () => {
    setSuccessModalVisiblilty(false);
    props.setCurrent(1);
  };
  const tryAgain = () => {
    setFailureModalVisiblilty(false);
  };

  const resetFunc = () => {
    form.resetFields();
    checkFormValidity();
  };

  return (
    <React.Fragment>
      <GenericFormContainer
        formData={form}
        resetFunc={resetFunc}
        path="assetscontainer"
        formName={"Create Asset"}
        navigate={() => navigate("/assetscontainer")}
      >
        <Form
          form={form}
          labelCol={{ span: 5 }}
          onFieldsChange={checkFormValidity}
          wrapperCol={{ span: 14 }}
          className={"form"}
        >
          <Form.Item label="Asset Id" name="assetId" rules={[{ required: true, message: "Please enter assetId" }]}>
            <Input className="inputAssets" />
          </Form.Item>
          <Form.Item
            label="Asset Name"
            name="assetName"
            rules={[{ required: true, message: "Please enter Asset Name" }]}
          >
            <Input className="inputAssets" />
          </Form.Item>

          <Form.Item
            label="Asset Type"
            name="assetType"
            style={{ textAlign: "left" }}
            rules={[{ required: true, message: "Please select Asset Type" }]}
          >
            <Select placeholder="Select Asset Type">
              {assetTypes?.map((assetType) => (
                <Option value={assetType?.typeId}>{assetType?.typeName}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Thumbnail" name="thumbnail">
            <Dragger {...uploadFileProps("thumbnail")} style={{ width: "100" }} accept="image/png, image/jpg">
              <UploadMsg subMsg="Only jpeg or png files are supported." />
            </Dragger>
          </Form.Item>
          <Form.Item label="Active" name="active" rules={[{ required: false }]} style={{ alignItems: "flex-start" }}>
            <Switch style={{ alignSelf: "flex-start" }} defaultChecked={true} onChange={onActiveChange} />
          </Form.Item>
          <Form.Item label="Secured" name="secured" rules={[{ required: false }]} style={{ alignItems: "flex-start" }}>
            <Switch style={{ alignSelf: "flex-start" }} defaultChecked={true} onChange={onSecuredChange} />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea name="description" style={{ height: 100 }} />
          </Form.Item>
          <Form.Item label="Local Cache" name="localCacheControl">
            <Switch
              style={{ alignSelf: "start" }}
              name="localCacheControl"
              value={cacheEnabled}
              defaultChecked={false}
              onChange={setCacheEnabled}
            />
          </Form.Item>
          {cacheEnabled && (
            <Form.Item
              help={cacheError ? cacheError : "Default 30 minutes and Max 525,600 minutes (365 days)"}
              label="Local Cache Max Age"
              name="localCacheMaxAge"
              required
              style={{ marginBottom: 35 }}
              validateStatus={cacheError === "" ? "success" : "error"}
              // help={}
            >
              <Input
                type="number"
                style={{ height: 40, borderRadius: "100px", width: "100%" }}
                name="localCacheMaxAge"
                onChange={handlePrivateMaxAge}
              />
            </Form.Item>
          )}
          <Form.Item>
            <Button
              loading={isLoading}
              onClick={onSubmit}
              type="primary"
              htmlType="submit"
              className={"button"}
              disabled={!isFormValid}
            >
              Create Asset
            </Button>
          </Form.Item>
        </Form>
      </GenericFormContainer>
      <SuccessModel
        visible={successModalVisiblilty}
        onRightClick={navigateToAssetList}
        onLeftClick={createMoreAsset}
        leftTitle="Upload Version Now"
        rightTitle={"Later"}
        msg={"Do you want to upload asset version for this?"}
        note={"The asset could require some time to appear."}
        title={"Your Virtual Asset has been created."}
      />
      <FailureModel
        visible={failureModalVisiblilty}
        onClose={() => {
          setFailureModalVisiblilty(false);
        }}
        onRightClick={navigateToAssetList}
        onLeftClick={tryAgain}
      />
    </React.Fragment>
  );
};

const UploadMsg = (props) => {
  return (
    <div>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">{props?.subMsg || ""}</p>
    </div>
  );
};

const SuccessModel = ({ visible, msg, onLeftClick, onRightClick, title, leftTitle, rightTitle, onClose, note }) => {
  return (
    <Modal
      open={visible}
      title={title}
      onCancel={onClose}
      footer={[
        <Button type="primary" onClick={onLeftClick}>
          {leftTitle}
        </Button>,
        <Button type="primary" onClick={onRightClick}>
          {rightTitle}
        </Button>,
      ]}
    >
      {note ? (
        <span>
          <span style={{ color: "red" }}>*</span>
          {note}
          <span style={{ color: "red" }}>*</span>
        </span>
      ) : null}
      <div style={{ marginTop: "5px" }}>{msg}</div>
    </Modal>
  );
};
// const SkipModal = ({ visible, onLeftClick, onRightClick }) => {
//   return (
//     <Modal
//       closable={false}
//       open={visible}
//       footer={[
//         <Button type="primary" onClick={onLeftClick}>
//           Skip
//         </Button>,
//         <Button type="primary" onClick={onRightClick}>
//           No
//         </Button>,
//       ]}
//     >
//       Do you really want to skip this step?
//     </Modal>
//   );
// };
const FailureModel = ({ visible, onLeftClick, onRightClick, onClose }) => {
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={[
        <Button type="primary" onClick={onLeftClick}>
          Try Again
        </Button>,
        <Button type="primary" onClick={onRightClick}>
          Close
        </Button>,
      ]}
    >
      <p>Sorry! We are unable to create your asset right now.</p>
    </Modal>
  );
};

const AddVersion = (props) => {
  const [form] = Form.useForm();
  const [formField, setFormField] = useState();
  const [gamePlatforms, setGamePlatform] = useState([]);

  const [isFormValid, setIsFormValid] = useState(false);
  const [isEngineLoading, setEngineLoaded] = useState(true);

  const [isLoading, setLoading] = useState(false);
  const [successModalVisiblilty, setSuccessModalVisiblilty] = useState(false);
  const [failureModalVisiblilty, setFailureModalVisiblilty] = useState(false);
  const [skipModal, setSkipModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchFunc(getTargetPlatforms, setGamePlatform, setEngineLoaded, "platform");
    form.setFieldsValue({ active: true });
  }, []);

  const uploadFileProps = (fieldName) => {
    return {
      onRemove: (file) => {
        setFormField({ ...formField, [fieldName]: null });
        setIsFormValid(false);
      },
      beforeUpload: (file) => {
        setFormField({ ...formField, [fieldName]: file });
        return false;
      },
      maxCount: 1,
      listType: "picture",
    };
  };

  const checkFormValidity = () => {
    const hasError = form.getFieldsError().some(({ errors }) => errors.length > 0);
    const { versionName, targetPlatform, assetFile } = form.getFieldsValue();
    const isFormCompleted = assetFile && versionName?.trim() && targetPlatform;
    const isValid = !hasError && isFormCompleted;
    setFormField({ ...formField, versionName, targetPlatform });
    setIsFormValid(isValid);
  };

  const fetchFunc = (getFunc, setFunc, setLoadOff, type) => {
    getFunc()
      .then((res) => {
        if (res.status == 200 && res?.data?.dataList?.length > 0) {
          setFunc(res?.data?.dataList);
        } else {
          message.error(`Enable to find game ${type}. Please try again later"`);
        }
        setLoadOff(false);
      })
      .catch((err) => {
        message.error(`Enable to find game ${type}. Please try again later"`);
        setLoadOff(false);
      });
  };

  const AddAssetVersion = () => {
    setLoading(true);
    const formData = new FormData();
    for (let key in formField) {
      formData.append(key, formField[key]);
    }
    formData.append("assetContainerId", props?.assetContainer?._id);

    createAssetVersion(formData)
      .then((res) => {
        if (res?.status == 201) {
          props?.setAssetVersion(res.data);
          setSuccessModalVisiblilty(true);
        } else {
          // message.error("Sorry! We are unable to create your asset right now.")
          setFailureModalVisiblilty(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setFailureModalVisiblilty(true);
      });
  };

  const onSubmit = () => {
    AddAssetVersion();
  };

  const navigateToAssetList = () => {
    setFailureModalVisiblilty(false);
    setSuccessModalVisiblilty(false);
    navigate("/assetscontainer");
  };

  const createMoreAsset = () => {
    props.setCurrent(2);
  };
  const tryAgain = () => {
    setFailureModalVisiblilty(false);
  };

  return (
    <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
      <Card className="asset-container" headStyle={{ borderBottom: "0px" }} bordered={false}>
        <SkipModal onLeftClick={navigateToAssetList} visible={skipModal} onRightClick={() => setSkipModal(false)} />
        <PageHeader
          className="site-page-header"
          title={"Asset Versions - " + props?.assetContainer?.assetId}
          extra={[
            <Button size={"large"} onClick={() => setSkipModal(true)}>
              Skip Step
            </Button>,
            <RefreshButton key="1" onClick={() => form.resetFields()} />,
          ]}
        />
        <div className={"main-container"}>
          <Skeleton loading={isEngineLoading} active>
            <Form
              form={form}
              labelCol={{ span: 5 }}
              onFieldsChange={() => {
                checkFormValidity();
              }}
              wrapperCol={{ span: 14 }}
              className={"form"}
            >
              <Form.Item
                label="Version Name"
                name="versionName"
                rules={[{ required: true, message: "Please enter Asset Name" }]}
              >
                <Input className="inputAssets" />
              </Form.Item>
              <Form.Item
                label="Target Platform"
                style={{ textAlign: "left" }}
                name="targetPlatform"
                rules={[{ required: true, message: "Please select Platform" }]}
              >
                <Select placeholder="Platform">
                  {gamePlatforms?.map((platform) => (
                    <Option value={platform?._id}>{platform?.platformName}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label="Asset File"
                name="assetFile"
                rules={[{ required: true, message: "Please select asset file" }]}
              >
                <Dragger {...uploadFileProps("assetFile")} style={{ width: "100" }}>
                  <UploadMsg subMsg="Upload Asset File." />
                </Dragger>
              </Form.Item>

              {/* <Form.Item label="Thumbnail" name="thumbnail">
                <Dragger {...uploadFileProps("thumbnail")} style={{ width: "100" }}>
                  <UploadMsg subMsg="Only jpeg or png files are supported." />
                </Dragger>
              </Form.Item> */}
              <Form.Item>
                <Button
                  loading={isLoading}
                  onClick={onSubmit}
                  type="primary"
                  htmlType="submit"
                  className={"button"}
                  disabled={!isFormValid}
                >
                  Add Asset Version
                </Button>
              </Form.Item>
            </Form>
            <SuccessModel
              visible={successModalVisiblilty}
              onRightClick={navigateToAssetList}
              onLeftClick={createMoreAsset}
              leftTitle="Deploy for environments"
              rightTitle="Not Now"
              title="Asset Version Added Successfully"
              msg={"Do you want to release  this new version for environments?"}
            />
            <FailureModel
              visible={failureModalVisiblilty}
              onClose={() => setFailureModalVisiblilty(false)}
              onRightClick={navigateToAssetList}
              onLeftClick={tryAgain}
            />
          </Skeleton>
        </div>
      </Card>
    </Col>
  );
};

const ReleaseAssets = (props) => {
  const [visible, setVisible] = useState(false);
  const [skipModal, setSkipModal] = useState(false);
  const [envDataFields, setEnvDataFields] = useState([]);
  const [envItem, setEnvItem] = useState();
  const [selectedState, setSelectedState] = useState();
  const [assetStates, setAssetsStates] = useState([]);
  const [successModalVisiblilty, setSuccessModalVisiblilty] = useState(false);
  const [failureModalVisiblilty, setFailureModalVisiblilty] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    getAllEnv().then((res) => {
      changeFields(res?.data?.environments);
    });
    getAssetState("INTENT-STATE").then((res) => {
      const statesList = res?.data?.states;
      const index = statesList.indexOf("DEFAULT");
      statesList.splice(index, 1);
      setAssetsStates(statesList);
    });
  };

  const navigateTo = (path) => {
    setFailureModalVisiblilty(false);
    setSuccessModalVisiblilty(false);
    navigate(path);
  };

  let structure = [];
  const changeFields = (Data) => {
    // eslint-disable-next-line array-callback-return
    Data?.map((item, index) => {
      if (!(structure.length > Data.length)) {
        structure.push({ title: item.envName, url: item.envImageURL, _id: item._id, key: index, data: item });
      }
    });

    setEnvDataFields([...structure]);
  };

  const getDeploymentFunc = (envItem) => {
    if (envItem.badge) {
      return publishAssetVersion;
    }
    return addAssetVersionToEnv;
  };

  const deployAsset = (envItem, intent) => {
    try {
      const { assetContainerId, version, targetPlatform } = props?.assetVersion;
      const releaseAssetBody = {
        assetContainerId,
        version,
        targetPlatform,
        envId: envItem.data.envId,
        intent,
      };

      const deployFunc = getDeploymentFunc(envItem);
      deployFunc(releaseAssetBody)
        .then((res) => {
          if (res.status === 201) {
            const envIndex = envItem.key;
            const envData = envDataFields[envIndex];
            envData.badge = selectedState;
            if (selectedState === "OFFLINE") {
              envData.badgeColor = "gray";
            }
            setEnvDataFields([...envDataFields]);
            setVisible(false);
            setSuccessModalVisiblilty(true);
          } else {
            setFailureModalVisiblilty(true);
          }
        })
        .catch((err) => {
          setFailureModalVisiblilty(true);
        });
    } catch (err) {
      setFailureModalVisiblilty(true);
    }
  };

  return (
    <Card
      className="asset-container"
      headStyle={{ borderBottom: "0px" }}
      style={{ paddingBottom: 30 }}
      bordered={false}
    >
      <SkipModal
        onLeftClick={() => navigateTo("/assetscontainer")}
        visible={skipModal}
        msg="Are you sure, you are done with this step?"
        onRightClick={() => setSkipModal(false)}
        leftButtonText="Yes"
      />
      <PageHeader
        className="site-page-header"
        title="Release Assets"
        extra={[
          <Button size={"large"} onClick={() => setSkipModal(true)}>
            Done
          </Button>,
        ]}
      />
      <Modal
        open={visible}
        title={"Choose State"}
        okText={"Deploy"}
        bodyStyle={{ height: 75, marginTop: 40 }}
        onOk={() => {
          deployAsset(envItem, selectedState);
        }}
        onCancel={() => setVisible(false)}
      >
        <Form>
          <Form.Item
            name="state"
            label="State"
            // className="ant-form-item-section"
            rules={[
              {
                required: true,
                message: "Missing State",
              },
            ]}
          >
            <Select
              placeholder="Select State"
              style={{
                width: "100%",
                textAlign: "left",
              }}
              onChange={(val) => setSelectedState(val)}
            >
              {assetStates?.map((state) => (
                <Select.Option value={state}>{state}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <Card title={"Environments"} style={{ paddingBottom: 30, textAlign: "left" }}>
        <Col
          className="environmentList"
          style={{ margin: "auto", maxWidth: "100%" }}
          lg={{ span: 18 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <CardList
            style={{ textAlign: "Center" }}
            msg={"No Environment Data available. Please create new one."}
            onButtonText="Add Environment"
            items={envDataFields}
            onClick={(item, index) => {
              setEnvItem(item);
              setVisible(true);
            }}
            onButtonClick={() => {
              navigate("/environments");
            }}
          />
        </Col>
      </Card>
      <SuccessModel
        visible={successModalVisiblilty}
        onRightClick={() => navigateTo("/assetscontainer/" + props?.assetContainer?.assetId)}
        onLeftClick={() => navigateTo("/assetscontainer")}
        leftTitle="Virtual Assets List"
        rightTitle="Virtual Asset Details"
        title="Asset Version Added Successfully"
        msg={"You have successfully deployed this version for environments."}
        onClose={() => setSuccessModalVisiblilty(false)}
      />
      <FailureModel
        visible={failureModalVisiblilty}
        onRightClick={() => navigateTo("/assetscontainer")}
        onLeftClick={() => setFailureModalVisiblilty(false)}
      />
    </Card>
  );
};

const AssetCreationSteps = (props) => {
  const [current, setCurrent] = useState(0);
  const [assetContainer, setAssetContainer] = useState();
  const [assetVersion, setAssetVersion] = useState();
  const steps = [
    {
      title: "Create Asset",
      content: <CreateAssetContainer setCurrent={(val) => setCurrent(val)} setAssetContainer={setAssetContainer} />,
    },
    {
      title: "Add Asset Version",
      content: (
        <AddVersion
          setCurrent={(val) => setCurrent(val)}
          assetContainer={assetContainer}
          setAssetVersion={setAssetVersion}
        />
      ),
    },
    {
      title: "Release Asset",
      content: <ReleaseAssets assetVersion={assetVersion} assetContainer={assetContainer} />,
    },
  ];
  const contentStyle = {
    lineHeight: "260px",
    textAlign: "center",
    marginTop: 16,
  };
  return (
    <React.Fragment>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a href="/">Home</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <a href="/assetscontainer">Assets</a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Create</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          background: "white",
          padding: 10,
          marginBottom: 5,
          borderRadius: 12,
          boxShadow: "0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%)",
          marginTop: 15,
        }}
      >
        <Steps current={current} items={steps} />
      </div>
      <div style={contentStyle}>{steps[current].content}</div>
    </React.Fragment>
  );
};

export default AssetCreationSteps;
