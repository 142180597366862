import React, { useEffect, useState } from "react";
import {
  Spin,
  Breadcrumb,
  Image,
  Button,
  Radio,
  Tooltip,
  Row,
  Col,
  Card,
  message,
  Empty,
  Skeleton,
  Select,
  Typography,
  Tag,
} from "antd";
import Title from "antd/lib/typography/Title";
import { PageHeader } from "@ant-design/pro-layout";
import Style from "./css/GameAssetDetails.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CancelButton } from "@dashboard/buttons/CancelButton";
import { DeleteButton } from "@dashboard/buttons/DeleteButton";
import { CopyOutlined, CopyFilled, CloudDownloadOutlined } from "@ant-design/icons";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import "./css/assetcontainer.css";
import { getAllEnv } from "@services/EnvService";
import {
  getAssetsVersionDownloadUrl,
  deleteAssetVersion,
  getEnvironmentsAssetsByQuery,
} from "@services/assetContainer";
import { getAssetTypes } from "@services/systemService";
import Modal from "@dashboardmodals/Success";
import ConfirmWarningModal from "@dashboardmodals/ConfirmWarningModal";
import AssetVersionPreviewComponent from "@components/preview/AssetVersionPreviewComponent";
// import ModalPreview from "../../../modal/Modal";
import { IoIosGitCompare } from "react-icons/io";
const envconfig = require("@localconfig/envconfig");
const { Text } = Typography;
const AssetContainerVersionDetail = ({ history, props }) => {
  const [isLoading, setLoading] = useState(true);
  const [isCopied, setCopied] = useState(false);
  const [isAssetFileLoading, setAssetFileLoading] = useState(false);
  const [versionDeleteAble, setVersionDeleteAble] = useState(false);
  const [assetVersion, setAssetVersion] = useState();
  const [platform, setPlatform] = useState();
  const [assetType, setAssetType] = useState();
  const location = useLocation();
  const [assetContainer, setAssetContainer] = useState();
  const [fetchAllEnv, setFetchingAllEnv] = useState(true);
  const [isFetchingUrl, setFetchingUrl] = useState(false);
  const [allEnv, setAllEnv] = useState();
  const [selectedEnv, setSelectedEnv] = useState();
  const [urlInfo, setUrlInfo] = useState();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteAbleModal, setDeleteAbleModal] = useState(false);
  const [unDeleteAbleModal, setUnDeleteAbleModal] = useState(false);
  const [modalMsg, setModalMsg] = useState(false);
  const [assetVersionState, setAssetVersionState] = useState();
  const { assetId, versionId } = useParams();

  const navigate = useNavigate();
  const { totalAssetVersion } = location.state || {};

  const handleCompareButtonClick = () => {
    const compareURL = `/assetscontainer/${assetId}/assetversion/${versionId}/versioncompare`;
    navigate(compareURL, { state: { assetTypeId: assetType.typeName, assetVersion, assetContainer } });
  };

  useEffect(() => {
    // console.log("location", location.state);
    // console.log("assetId : ", assetId, versionId);
    setAssetContainer(location?.state?.assetContainer);
    setAssetVersion(location?.state?.assetVersion);
    setPlatform(location?.state?.platform);
    fetchEnvData();
    fetchAssetType();
  }, []);

  const fetchAssetType = () => {
    getAssetTypes()
      .then((res) => {
        if (res.status === 200) {
          const assetTypes = res.data;

          const assetType = assetTypes.find((assetType) => {
            return assetType.typeId === location?.state?.assetVersion?.assetType;
          });
          setAssetType(assetType);
        } else {
          message.error("Unable to load assetType");
        }
      })
      .catch((error) => {
        message.error("Unable to load assetType");
      });
  };

  const fetchEnvData = () => {
    getAllEnv()
      .then((res) => {
        if (res?.status === 200 && res?.data?.environments?.length) {
          setAllEnv(res.data?.environments);
          setSelectedEnv(res.data?.environments[0]);
          fetchDownloadUrls(res.data?.environments[0]);
        } else {
          message.error("Unable to load Environments");
        }
      })
      .catch((error) => {
        message.error("Unable to load Environments");
      })
      .finally(() => {
        setFetchingAllEnv(false);
      });
  };

  const fetchDownloadUrls = (environment) => {
    setFetchingUrl(true);
    getEnvironmentsAssetsByQuery(`assetId=${assetId}&versionId=${assetVersion?.versionId}&envId=${environment?.envId}`)
      .then((res) => {
        if (res?.status === 200  && res.data.assetVersions.length > 0) {
          setAssetVersionState(res?.data?.assetVersions[0]?.state);
          // console.log(res.data.assetVersions[0]);
        }
      })
      .catch((err) => {
        setAssetVersionState();
      });
    // console.log(environment);
    getAssetsVersionDownloadUrl(assetId, environment?.envId, versionId)
      .then((res) => {
        if (res.status === 200) {
          setUrlInfo(res.data);
        } else {
          message.error("Unable to load urls");
          setUrlInfo();
        }
      })
      .catch((err) => {
        setUrlInfo();
      })
      .finally(() => {
        setFetchingUrl(false);
        setLoading(false);
      });
  };

  const isAssetVersionDeleteAble = () => {
    setDeleteLoading(true);
    // console.log(`assetId=${assetId}&versionId=${assetVersion?.versionId}&state=LIVE,DEFAULT`)
    getEnvironmentsAssetsByQuery(`assetId=${assetId}&versionId=${assetVersion?.versionId}&state=LIVE&state=DEFAULT`)
      .then((res) => {
        if (res?.status === 200) {
          const deleteAbleFLag = res.data?.assetVersions?.length === 0;
          // setVersionDeleteAble(deleteAbleFLag);
          if (deleteAbleFLag) {
            setDeleteAbleModal(true);
          } else {
            setUnDeleteAbleModal(true);
          }
        } else {
          setVersionDeleteAble(false);
        }
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 6000);
  };
  const goBack = () => {
    navigate(-1);
  };
  // console.log(urlInfo);
  const compare = () => {
    navigate("/versioncompare/");
  };
  const onEnvChange = (value) => {
    let envIdFiltered = allEnv.filter((item) => {
      return item.envId === value;
    });
    setSelectedEnv(envIdFiltered[0]);
  };

  const deleteVersion = () => {
    deleteAssetVersion(assetVersion?.concatenatedId)
      .then((res) => {
        if (res.status === 204) {
          //success
          goBack();
        }
      })
      .catch((error) => {
        //failure
        setModalMsg("Unable to delete Asset Version at the moment, Please try after some time.");
      })
      .finally(() => {
        setDeleteAbleModal(false);
        setDeleteLoading(false);
      });
  };

  const getPreviewUrl = () => {
    if (assetVersion?.concatenatedId) {
      return `${envconfig.GREEN_BACKEND_API_URL}/assetversionfiles/${encodeURIComponent(assetVersion?.concatenatedId)}`;
    }
  };

  useEffect(() => {
    fetchDownloadUrls(selectedEnv);
  }, [selectedEnv]);
  const isImageAsset = assetType?.typeName === "Image";
  return (
    <React.Fragment>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/assetscontainer">Virtual Assets</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/assetscontainer/${assetId}`}>{assetId}</Link>
        </Breadcrumb.Item>
        {assetVersion?.versionId}
      </Breadcrumb>

      <div style={{ paddingTop: "15px" }}>
        <Row
          gutter={[
            { xs: 8, sm: 16, md: 24, lg: 24 },
            { xs: 8, sm: 16, md: 24, lg: 24 },
          ]}
        >
          <Col lg={{ span: 24 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Card hoverable={false} className={["asset-container", "asset-container-row"]} bordered={false}>
              <PageHeader
                className="site-page-header"
                title={["Version Details"]}
                extra={[
                  <Tooltip title="Compare" key="compareTooltip">
                    <Button
                      key="compareButton"
                      onClick={handleCompareButtonClick}
                      shape="circle"
                      style={{ height: "40px", width: "40px", textAlign: "center", padding: "9px" }} //compare button
                      icon={<IoIosGitCompare className="compare-btn" style={{ fontSize: "20px" }} />}
                      disabled={!isImageAsset || totalAssetVersion <= 1}
                    />
                  </Tooltip>,
                  <DeleteButton loading={deleteLoading} onClick={isAssetVersionDeleteAble} key="deleteButton" />,
                  <CancelButton key="cancelButton" onClick={goBack} />,
                ]}
              />

              <Row style={{ paddingTop: "40px" }}>
                <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ height: "auto" }}>
                  {/* preview ---------->*/}
                  <div className="cent-div">
                    <AssetVersionPreviewComponent file={getPreviewUrl()} type={assetType?.typeName} />
                  </div>
                  {/* <ModalPreview/> */}
                </Col>
                <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                  <div className="details">
                    <div>
                      <Row gutter={10}>
                        <Col span={12}>
                          <Title level={5} style={{ textAlign: "right" }}>
                            Version Id:
                          </Title>
                        </Col>
                        <Col span={12}>
                          <Text style={{ textAlign: "left", display: "block", margin: "1px 0px" }}>
                            {assetVersion?.versionId}
                          </Text>
                        </Col>
                      </Row>

                      <Row gutter={10} className="nestor_details_content">
                        <Col span={12}>
                          <Title level={5} style={{ textAlign: "right" }}>
                            Version Name:
                          </Title>
                        </Col>
                        <Col span={12}>
                          <Text style={{ textAlign: "left", display: "block", margin: "1px 0px" }}>
                            {assetVersion?.versionName}
                          </Text>
                        </Col>
                      </Row>

                      <Row gutter={10} className="nestor_details_content">
                        <Col span={12}>
                          <Title level={5} style={{ textAlign: "right" }}>
                            Type Name:
                          </Title>
                        </Col>
                        <Col span={12}>
                          <Text style={{ textAlign: "left", display: "block", margin: "1px 0px" }}>
                            {assetType?.typeName}
                          </Text>
                        </Col>
                      </Row>

                      <Row gutter={10} className="nestor_details_content">
                        <Col span={12}>
                          <Title level={5} style={{ textAlign: "right" }}>
                            Target Platform:
                          </Title>
                        </Col>
                        <Col span={12}>
                          <Text style={{ textAlign: "left", display: "block", margin: "1px 0px" }}>
                            {platform?.platformName}
                          </Text>
                        </Col>
                      </Row>
                      <Row gutter={10} className="nestor_details_content">
                        <Col span={12}>
                          <Title level={5} style={{ textAlign: "right" }}>
                            Size:
                          </Title>
                        </Col>
                        <Col span={12}>
                          <Text style={{ textAlign: "left", display: "block", margin: "1px 0px" }}>
                            {assetVersion?.size}
                          </Text>
                        </Col>
                      </Row>
                      <Row gutter={10} className="nestor_details_content">
                        <Col span={12}>
                          <Title level={5} style={{ textAlign: "right" }}>
                            Asset File:
                          </Title>
                        </Col>
                        <Col span={12}>
                          <Text style={{ textAlign: "left", display: "block", margin: "1px 0px" }}>
                            <Spin spinning={isAssetFileLoading}>
                              <a href={getPreviewUrl()} style={{ padding: 0, fontWeight: 500, color: "#00b96b" }}>
                                <CloudDownloadOutlined style={{ fontSize: "18px", paddingLeft: "5px" }} />
                              </a>
                            </Spin>
                          </Text>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  {/*
                   <div id="card-info">
                    <Title level={5} className="versionDetails-row" style={{ textAlign: "right" }}>
                      <span className="versionDetails-col" style={{ marginRight: 5 }}>
                        <strong>Version ID :</strong>{" "}
                      </span>
                      {assetVersion?.versionId}
                    </Title>
                    <Title level={5} className="versionDetails-row" style={{ textAlign: "right" }}>
                      <span className="versionDetails-col" style={{ marginRight: 5 }}>
                        <strong>Version Name :</strong>{" "}
                      </span>
                      {assetVersion?.versionName}
                    </Title>
                    <Title level={5} className="versionDetails-row" style={{ textAlign: "right" }}>
                      <span className="versionDetails-col" style={{ marginRight: 5 }}>
                        <strong>Asset Type :</strong>{" "}
                      </span>
                      {assetType?.typeName}
                    </Title>
                    <Title level={5} className="versionDetails-row" style={{ textAlign: "right" }}>
                      <span className="versionDetails-col" style={{ marginRight: 5 }}>
                        <strong>Target Platform :</strong>{" "}
                      </span>
                      {platform?.platformName}
                    </Title>
                    <Title level={5} className="versionDetails-row" style={{ textAlign: "right" }}>
                      <span className="versionDetails-col" style={{ marginRight: 5 }}>
                        <strong>Size : </strong>{" "}
                      </span>
                      {assetVersion?.size}
                    </Title>
                    <Title
                      level={5}
                      className="versionDetails-row"
                      style={{ textAlign: "right", justifyContent: "center", alignItems: "flex-end" }}
                    >
                      <span className="versionDetails-col">
                        <strong style={{ verticalAlign: "middle" }}>Asset File :</strong>
                      </span>
                      <div>
                        <Spin spinning={isAssetFileLoading}>
                          <a href={getPreviewUrl()} style={{ padding: 0, fontWeight: 500, color: "#00b96b" }}>
                            <CloudDownloadOutlined style={{ fontSize: "18px", paddingLeft: "5px" }} />
                          </a>
                        </Spin>
                      </div>
                    </Title>
                  </div> */}
                  <div
                    style={{
                      border: "1px solid #eee",
                      borderRadius: "10px",
                      padding: "0 20px 20px",
                      marginTop: "15px",
                      marginLeft: "-20px",
                    }}
                  >
                    <Title
                      className="versionDetails-row"
                      level={5}
                      style={{ borderBottom: "1px solid #eee", paddingBottom: "20px" }}
                    >
                      <span className="versionDetails-col">
                        <strong>Environment : </strong>
                      </span>
                      <Select
                        placeholder="Environment"
                        onChange={onEnvChange}
                        value={selectedEnv?.envId}
                        defaultValue={selectedEnv?.envId}
                        // animation=
                        style={{ width: 200 }}
                      >
                        {allEnv?.map((env, index) => {
                          return (
                            <Select.Option key={index} value={env?.envId}>
                              {env?.envId}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Title>
                    <Skeleton active loading={isLoading}>
                      {urlInfo ? (
                        <div>
                          <Title level={5} className="versionDetails-row">
                            <span className="versionDetails-col" style={{width:"17%"}}>
                              <strong>State:</strong>{" "}
                            </span>
                            {assetVersionState}
                          </Title>
                          {urlInfo?.downloadUrl ? (
                            <DownloadUrlClipBoard
                              handleCopy={handleCopy}
                              downloadUrl={urlInfo?.downloadUrl}
                              isCopied={isCopied}
                            />
                          ) : null}
                        </div>
                      ) : (
                        <Col span={24}>
                          <Empty description={<span>Not deployed yet!</span>} />
                        </Col>
                      )}
                    </Skeleton>
                  </div>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        <ConfirmWarningModal
          visible={deleteAbleModal}
          onRightClick={() => {
            setDeleteAbleModal(false);
            setDeleteLoading(true);
            deleteVersion();
          }}
          onCancel={() => setDeleteAbleModal(false)}
          onLeftClick={() => {
            setDeleteAbleModal(false);
          }}
          leftButtonText="Cancel"
          rightButtonText="Delete"
          msg="Are you sure to delete this version?"
          warningMsg="Once you delete, you can't retrieve this version again."
        />
        {/* <Modal
          visible={deleteAbleModal}
          onRightClick={() => setDeleteAbleModal(false)}
          onClose={() => setDeleteAbleModal(false)}
          onLeftClick={() => {
            setDeleteAbleModal(false);
            setDeleteLoading(true);
            deleteVersion()
          }}
          leftTitle="Delete"
          rightTitle={"Cancel"}
          msg={"Are you sure. You want to delete this version?"}
        /> */}
        <Modal
          visible={unDeleteAbleModal}
          onRightClick={() => setUnDeleteAbleModal(false)}
          onClose={() => setUnDeleteAbleModal(false)}
          // onLeftClick={()=>{console.log('delete under process')}}
          // leftTitle="Yes"
          rightTitle={"Ok"}
          msg={"Please set this asset version 'Offline' in all Environments and then try to delete again."}
        />
        <Modal
          visible={!!modalMsg}
          onRightClick={() => setModalMsg(false)}
          onClose={() => setModalMsg(false)}
          // onLeftClick={()=>{console.log('delete under process')}}
          // leftTitle="Yes"
          rightTitle={"Ok"}
          msg={modalMsg}
        />
      </div>
    </React.Fragment>
  );
};

const DownloadUrlClipBoard = (props) => {
  return (
    <React.Fragment>
      <Title level={5} className="versionDetails-row">
        <span className="versionDetails-col">
          <strong>URL:</strong>{" "}
        </span>
       <div style={{display:"flex", justifyContent:"space-between", alignItems:"center", gap:"20px"}}>
       <div style={{width:"100%"}}>{props?.downloadUrl}</div>
        <div>
          <CopyToClipboard onCopy={props.handleCopy} text={props?.downloadUrl}>
            <div className={Style["modal-container"]}>
              <div className={Style["clip-board-container"]}>
                {props.isCopied ? (
                  <Tooltip title="Copied">
                    <CopyFilled className={Style["clip-board"]} />
                  </Tooltip>
                ) : (
                  <Tooltip title="Copy Url">
                    <CopyOutlined className={Style["clip-board"]} />
                  </Tooltip>
                )}
              </div>
            </div>
          </CopyToClipboard>
        </div>
       </div>
      </Title>
    </React.Fragment>
  );
};

export default AssetContainerVersionDetail;
