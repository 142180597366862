import { DeploymentUnitOutlined } from "@ant-design/icons";
import { Button } from "antd";

export const DeployButton = (props) => {
  return (
    <Button
      key={props?.key || 1}
      style={{
        width: 40,
        height: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      {...props}
     
    >
      <DeploymentUnitOutlined />
    </Button>
  );
};

