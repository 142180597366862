/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import { message, Skeleton, Pagination, Input } from "antd";
import Style from "./css/assetcontainer.css";
import CardList from "@dashboardcards/CardList";
import { useNavigate } from "react-router-dom";
import { getAssetAssetContainers, search } from "@services/assetContainer";
import { AppObject } from "@context/AppObject";
import { PageHeaderListForm } from "@dashboardpages/PageHeader";
import BreadCrumbsPath from "@dashboardpages/Breadcrumbs";
import { BreadCrumbs } from "@vyomr/nestor_components";
import useAuth from "@hooks/useAuth";

const AssetContainer = (props) => {
  const [gameAssets, setGameAssets] = useState([]);
  const [searchResponse, setSearchResponse] = useState();
  const [isLoadingAsset, setLoadingAsset] = useState(true);
  const [listItem, setListItem] = useState();
  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const { hasAccess, ACTIONS } = useAuth();

  useEffect(() => {
    onSearch(searchText)
  }, [currentPage])

  const onSearch = (text) => {
    if (text?.length > 0 && text?.length < 3) {
      return message.info("Search text should be '3' character long")
    }
    setSearchText(text);
    setLoadingAsset(true);
    search(text, currentPage).then(res => {
      const searchResponse = res.data?.results;
      if(text){
        searchResponse.searched=true;
      }
      if (res.status === 200 && searchResponse?.hits?.length) {
        setGameAssets(searchResponse?.hits)
        setSearchResponse(searchResponse)
        prepareListItem(searchResponse?.hits);
      }
      else {
        setGameAssets(searchResponse?.hits);
        setSearchResponse(searchResponse);
        prepareListItem(searchResponse?.hits)
        setListItem([])
        console.error(`Unable to find '${text}'`)
      }
    }).catch(err => {
      console.error(`Unable to find '${text}'`)
    }).finally(() => {
      setLoadingAsset(false);
    })
  }

  const getFilterGameAssetList = (searchAssetList) => {
    const searchFilteredList = [];
    searchAssetList?.forEach(assetItem => {
      const assetObj = gameAssets?.find(gameAsset => gameAsset._id === assetItem?._id);
      if (assetObj) {
        searchFilteredList.push(assetObj);
      }
    })
    return searchFilteredList;
  }

  const prepareListItem = (gameAssetList) => {
    if (gameAssetList?.length) {
      const gameAssetListItems = gameAssetList.map((gameAssets) => {
        //MONGODB
        // const source = gameAssets   
        // ELASTIC-SEARCH
        const source = gameAssets?._source;
        return {
          title: source?.assetName,
          description: source?.assetId,
          url: source?.thumbnail,
          data: { _id: gameAssets?._id, ...source },
        };
      });
      setListItem(gameAssetListItems);
    }
  };

  const fetchAssets = () => {
    getAssetAssetContainers()
      .then((res) => {
        if (res?.status == 200 && res?.data?.assetContainers?.length) {
          setGameAssets(res.data.assetContainers);
          prepareListItem(res.data.assetContainers);
        }
        setLoadingAsset(false);
      })
      .catch((err) => {
        setLoadingAsset(false);
        err?.response?.status != 404 && message.error("Unable to load private assets.");
      });
  };

  const addNewAssetContainer = (item, index) => {
    navigate("create");
  };

  const onListItemClick = (item, index) => {
    navigate(item?.data?.assetId);
  }

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  }
  const { Search } = Input
  return (
    <React.Fragment>
      <BreadCrumbs items={BreadCrumbsPath.AssetContainerlist} />
      <PageHeaderListForm
        objectId={AppObject.ASSET_DETAIL}
        title="Virtual Assets"
        onAdd={() => { navigate("/assetscontainer/create"); }}
        onSearch={onSearch}
        searchPlaceHolderText="Search Assets"

      />
      {searchText && <p style={{ marginLeft: 15, color: "gray" }}>{`Search Results for "${searchText}" - Total ${searchResponse?.total} `}</p>}
      <div className={Style["main-container"]}>
        <Skeleton loading={isLoadingAsset} active>
          <CardList hideEmptyView={searchResponse?.searched} msg={"No Asset Data available. Please create new one."} buttonText="Add Virtual Asset" disabled={!hasAccess(AppObject.ASSET_DETAIL, ACTIONS.POST)} items={listItem} onClick={onListItemClick} onButtonClick={addNewAssetContainer} />
        </Skeleton>
        <div style={{
          marginTop: 15,
          display: "flex",
          justifyContent: "flex-end"
        }}>
          {(searchResponse?.total > searchResponse?.pageSize) && <Pagination onChange={onPageChange} defaultCurrent={1} total={searchResponse?.total} pageSize={searchResponse?.pageSize} showSizeChanger={false}/>}
        </div>
      </div>
    </React.Fragment>
  );
};

export default AssetContainer;
