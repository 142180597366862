import "../css/form.css";

import { PageHeader } from "@ant-design/pro-layout";
import { Form, Modal, Button, Breadcrumb } from "antd";
import { RefreshButton } from "@dashboard/buttons/RefreshButton";
import { CancelButton } from "@dashboard/buttons/CancelButton";
import React, { useState } from "react";
import { CreateOrganization } from "@services/organizationService";
import FormInputs from "./formInputs";
import { useNavigate, Link } from "react-router-dom";

const ModalShow = (props) => {
  return (
    <Modal
      title={"Organization"}
      open={props.visible}
      footer={
        <Button type="primary" onClick={props.onOk}>
          Close
        </Button>
      }
      afterClose={props.afterClose}
      onCancel={props.onOk}
    >
      <p>{props.msg}</p>
    </Modal>
  );
};

const AddOrganization = () => {
  let navigate = useNavigate();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState();
  const [defaultVal, setDefaultVal] = useState();

  const [loading, setLoading] = useState(false);
  const [modalVisible, setIsModalVisible] = useState(false);
  const [msg, setMsg] = useState("");


  const formInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const updatOrg = () => {
    setLoading(true);
    // const data = new FormData();
    const data = {}
    for (const key in formData) {
      // data.append(key, formData[key]);
      data[key]=formData[key];
    }
    CreateOrganization(data)
      .then((res) => {
        setIsModalVisible(true);
        if (res.status === 201) {
          setMsg("Organization created successfully");
        } else {
          setMsg("Error while Creating organization");
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const resetIt = () => {
    let valuesOfForm = defaultVal;
    for (let key in Object.keys(defaultVal)) {
      if (valuesOfForm[key] === undefined) {
        form.resetFields();
        form.setFieldsValue({ ...defaultVal });
      }
      form.setFieldsValue({ ...defaultVal });
    }
  };


  let props = {
    formInputChange,
    formData: formData,
    form,
    updatOrg,
    loading,
  };
  return (
    <React.Fragment>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/organization">Organization</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Add</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ paddingTop: 15 }}>
        <ModalShow visible={modalVisible} msg={msg} onOk={() => {
          setIsModalVisible(false);
        }}
        afterClose={() => {
          navigate("/organization");
        }}
         />
        <PageHeader
          style={{ alignSelf: "flex-start", background: "#fff" }}
          title="Add Organization"
          extra={[
            <RefreshButton key={"1"} onClick={() => resetIt()} />,
            <CancelButton onClick={() => navigate("/organization")} key={"2"} />,
          ]}
        />
        <div className={"main-container"}>
          <FormInputs {...props} buttonText={"Create"} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddOrganization;
