import { Breadcrumb, Card, Col, Row } from "antd";
import { Link, useNavigate } from "react-router-dom";
import "./css/SystemData.css";
const SystemData = () => {
  const navigate = useNavigate();
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>System Data</Breadcrumb.Item>
      </Breadcrumb>
      <Row gutter={32} style={{ marginTop: 20 }}>
        <Col span={4} key={1}>
          <Card className="card-data" onClick={() => navigate("/gameengines")}>
            <Card.Meta title={"Game Engines"} />
          </Card>
        </Col>
        <Col span={4} key={2}>
          <Card className="card-data" onClick={() => navigate("/gameplatforms")}>
            <Card.Meta title={"Game Platforms"} />
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default SystemData;
