import React, { useEffect, useState } from "react";
import { Button, message, Modal, Row, Card, Col, Tooltip, Tag } from "antd";
import { Breadcrumb, Skeleton } from "antd";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import {
  CopyOutlined,
  CopyFilled,
  SafetyCertificateOutlined,
  CheckCircleFilled,
  StopOutlined,
  DeploymentUnitOutlined,
  EditOutlined,
} from "@ant-design/icons";
import Title from "antd/lib/typography/Title";
import "./css/AssetCollectionDetail.css";
import {
  getAssetCollectionById,
  getAllAssetItems,
  deployAssetCollection,
  deleteAssetCollectionApi,
  retrieveAssetCollectionApi,
  purgeAssetCollectionApi,
} from "@services/assetCollection";
import { getTargetPlatforms, getAssetTypes } from "@services/systemService";
import { FileImageOutlined, VideoCameraOutlined, SoundOutlined, PlusOutlined } from "@ant-design/icons";
import { BsFiletypePdf, BsFiletypeXml, BsFiletypeJson } from "react-icons/bs";
import { FaUnity } from "react-icons/fa6";
import { SiUnrealengine } from "react-icons/si";
import { CiFileOn } from "react-icons/ci";
import { getAllEnv } from "@services/EnvService";
import AssetTimeline from "../../GenericComponenets/AssetTimeline";
import { convertDateToNestorTime } from "../../../../utilities/datehelper";
import DefaultURLs from "../../GenericComponenets/DefaultURL";
import { EditButton } from "../../buttons/EditButton";
import { DeployButton } from "../../buttons/DeployButton";
import CollectionDeploymentTable from "../../GenericComponenets/CollectionDeploymentTable";
import useAuth from "@hooks/useAuth";
import { AppObject } from "@context/AppObject";
import { DeleteButton } from "../../buttons/DeleteButton";
import { RetrieveButton } from "../../buttons/RetrieveButton";
import { PurgeButton } from "../../buttons/PurgeButton";
import { ExclamationCircleOutlined } from "@ant-design/icons"; // Import an error icon
import { VscRequestChanges } from "react-icons/vsc";

function AssetCollectionDetail() {
  const location = useLocation();
  const { collectionId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [assetCollection, setAssetCollection] = useState(null);
  const [assetTypeMap, setAssetTypeMap] = useState({});
  const [allEnv, setAllEnv] = useState([]);
  const [fetchingAllEnv, setFetchingAllEnv] = useState(true);
  const [assetItems, setAssetItems] = useState([]);

  //
  const [gamePlatforms, setGamePlatforms] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState();
  const [assetStates, setAssetStates] = useState();
  const [releaseModalOpen, setReleaseModalOpen] = useState(false);

  const [deployError, setDeployError] = useState(null);

  const { hasAccess, ACTIONS } = useAuth();
  const [size, setSize] = useState("middle"); // default is 'middle'

  const [deleteLoading, setDeleteLoading] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [retrieveLoading, setRetrieveLoading] = useState(false);
  const [isRetrieveModalVisible, setIsRetrieveModalVisible] = useState(false);

  const [purgeLoading, setPurgeLoading] = useState(false);
  const [isPurgeModalVisible, setIsPurgeModalVisible] = useState(false);

  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);
  const [isFailureModalVisible, setIsFailureModalVisible] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [title, setTitle] = useState("");

  const SuccessModal = () => (
    <Modal
      title={title}
      visible={isSuccessModalVisible}
      footer={[
        <Button key="done" onClick={() => setIsSuccessModalVisible(false)}>
          Done
        </Button>,
        <Button key="goToList" type="primary" onClick={() => navigate(`/assetcollection`)}>
          Go to Asset Collection List Page
        </Button>,
      ]}
      onCancel={() => setIsSuccessModalVisible(false)}
    >
      <p>{successMessage}</p>
    </Modal>
  );

  // Failure Modal
  const FailureModal = ({ onTryAgain }) => (
    <Modal
      title={
        <span>
          <ExclamationCircleOutlined style={{ color: "red", marginRight: 8 }} />
          Error
        </span>
      }
      visible={isFailureModalVisible}
      footer={[
        <Button key="cancel" onClick={() => setIsFailureModalVisible(false)}>
          Cancel
        </Button>,
        errorMessage === `Asset collection ${collectionId} is LIVE. Please make it OFFLINE before deleting.` ? (
          <Button
            key="goToDeployment"
            type="primary"
            onClick={() => {
              setIsFailureModalVisible(false);
              handleGoToDeploymentTable();
            }}
          >
            Go to Deployment Table
          </Button>
        ) : (
          <Button key="tryAgain" type="primary" onClick={onTryAgain}>
            Try Again
          </Button>
        ),
      ]}
      onCancel={() => setIsFailureModalVisible(false)}
    >
      <p>{errorMessage}</p>
    </Modal>
  );

  const handleGoToDeploymentTable = () => {
    setReleaseModalOpen(true);
  };

  const showDeleteModal = () => {
    setIsDeleteModalVisible(true);
  };
  const handleDeleteModalCancel = () => {
    setIsDeleteModalVisible(false);
  };

  const showRetrieveModal = () => {
    setIsRetrieveModalVisible(true);
  };

  const handleRetrieveModalCancel = () => {
    setIsRetrieveModalVisible(false);
  };

  const showPurgeModal = () => {
    setIsPurgeModalVisible(true);
  };
  const handlePurgeModalCancel = () => {
    setIsPurgeModalVisible(false);
  };

  const handleReleaseCancel = () => {
    setReleaseModalOpen(false);
    setDeployError(null);
  };

  const handleDeployError = (error) => {
    setDeployError(error);
  };

  const handleItemClick = (item) => {
    setSelectedCollection(item);
    setReleaseModalOpen(true);
  };

  const fetchCollectionDetails = () => {
    getAssetCollectionById(collectionId).then((response) => {
      setAssetCollection(response.data);
      setAssetStates(response.data.assetStates);
      setAllEnv(response.data.allEnv);
      setGamePlatforms(response.data.gamePlatforms);
    });
  };

  useEffect(() => {
    fetchCollectionDetails();
  }, [collectionId]);
  //

  useEffect(() => {
    const fetchAssetCollectionDetails = async () => {
      if (!collectionId) {
        message.error("No collection ID provided");
        setLoading(false);
        return;
      }

      try {
        const response = await getAssetCollectionById(collectionId);
        if (response.status === 200) {
          setAssetCollection(response.data);
        } else {
          message.error("Failed to fetch asset collection details");
        }
      } catch (error) {
        message.error("An error occurred while fetching asset collection details");
      } finally {
        setLoading(false);
      }
    };

    const fetchAssetTypes = async () => {
      try {
        const response = await getAssetTypes();
        const assetTypeMapping = response.data.reduce((acc, asset) => {
          acc[asset.typeId] = asset.typeName;
          return acc;
        }, {});
        setAssetTypeMap(assetTypeMapping);
      } catch (error) {
        message.error("Failed to fetch asset types");
      }
    };

    const fetchEnvironments = async () => {
      try {
        const response = await getTargetPlatforms();
        if (response.status === 200) {
          setAllEnv(response.data);
        } else {
          message.error("Failed to fetch environments");
        }
      } catch (error) {
        message.error("An error occurred while fetching environments");
      }
    };

    const fetchEnvData = () => {
      getAllEnv()
        .then((res) => {
          if (res?.status === 200 && res?.data?.environments?.length) {
            setAllEnv(res.data?.environments);
          } else {
            message.error("Unable to load Environments");
          }
        })
        .catch((error) => {
          message.error("Unable to load Environments");
        })
        .finally(() => {
          setFetchingAllEnv(false);
        });
    };

    const fetchAssetItems = async () => {
      try {
        const response = await getAllAssetItems(collectionId);
        if (response.status === 200) {
          setAssetItems(response.data.results || []);
        } else {
          message.error("Failed to fetch asset items");
        }
      } catch (error) {
        message.error("An error occurred while fetching asset items");
      }
    };

    fetchAssetItems();
    fetchEnvData();
    fetchEnvironments();
    fetchAssetTypes();
    fetchAssetCollectionDetails();
  }, [collectionId]);

  const handleCollectionDelete = async () => {
    const email = assetCollection?.createdBy;

    const payload = {
      deleteCollection: true,
      deletedBy: email,
    };
    setDeleteLoading(true);
    try {
      const response = await deleteAssetCollectionApi(collectionId, payload);

      if (response?.status === 200) {
        setTitle("Asset Collection Deleted");
        setSuccessMessage("Asset Collection has been deleted successfuly!");
        setIsDeleteModalVisible(false);
        setIsSuccessModalVisible(true);
        setAssetCollection((prev) => ({ ...prev, deleted: true }));
      } else {
        setErrorMessage(response?.data?.message || "An unexpected error occurred. Please try again.");
        setIsFailureModalVisible(true);
        setIsDeleteModalVisible(false);
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message || "An unexpected error occurred. Please try again.");
      setIsFailureModalVisible(true);
      setIsDeleteModalVisible(false);
    } finally {
      setDeleteLoading(false);
    }
  };

  const handleCollectionRetrieve = async () => {
    const email = assetCollection?.createdBy;

    const payload = {
      deleteCollection: false,
      deletedBy: email,
    };
    setRetrieveLoading(true);
    try {
      const response = await retrieveAssetCollectionApi(collectionId, payload);

      if (response?.status === 200) {
        setTitle("Asset Collection Retrieved");
        setSuccessMessage("Asset Collection has been retrieved successfully!");
        setIsRetrieveModalVisible(false);
        setIsSuccessModalVisible(true);
        setAssetCollection((prev) => ({ ...prev, deleted: false }));
        navigate(`/assetcollection/${collectionId}`);
      } else {
        setErrorMessage(response?.data?.message || "An unexpected error occurred. Please try again.");
        setIsFailureModalVisible(true);
        setIsRetrieveModalVisible(false);
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message || "An unexpected error occurred. Please try again.");
      setIsFailureModalVisible(true);
    } finally {
      setRetrieveLoading(false);
    }
  };

  const handlePurgeCollection = async () => {
    const email = assetCollection?.createdBy;

    const payload = {
      deletedBy: email,
    };
    setPurgeLoading(true);

    try {
      const response = await purgeAssetCollectionApi(collectionId, payload);
      if (response?.status === 200) {
        message.success("Asset Collection has been purged successfully!");
        setIsPurgeModalVisible(false);
        navigate(`/assetcollection`);
      } else {
        setErrorMessage(response?.data?.message || "An unexpected error occurred. Please try again.");
        setIsFailureModalVisible(true);
        setIsPurgeModalVisible(false);
      }
    } catch (error) {
      setErrorMessage(error?.response?.data?.message || "An unexpected error occurred. Please try again.");
      setIsFailureModalVisible(true);
      setIsPurgeModalVisible(false);
    } finally {
      setPurgeLoading(false);
    }
  };

  if (isLoading) {
    return <Skeleton active />;
  }

  function getIconAndTooltip(typeId) {
    switch (typeId) {
      case "0001":
        return { icon: <FileImageOutlined style={{ fontSize: "20px" }} />, tooltip: "Image" };
      case "0002":
        return { icon: <SoundOutlined style={{ fontSize: "20px" }} />, tooltip: "Audio" };
      case "0003":
        return { icon: <VideoCameraOutlined style={{ fontSize: "20px" }} />, tooltip: "Video" };
      case "0004":
        return { icon: <FaUnity style={{ fontSize: "20px" }} />, tooltip: "Unity Asset" };
      case "0005":
        return { icon: <SiUnrealengine style={{ fontSize: "20px" }} />, tooltip: "Unreal Asset" };
      case "0006":
        return { icon: <BsFiletypePdf style={{ fontSize: "20px" }} />, tooltip: "PDF" };
      case "0007":
        return { icon: <BsFiletypeXml style={{ fontSize: "20px" }} />, tooltip: "XML" };
      case "0008":
        return { icon: <BsFiletypeJson style={{ fontSize: "20px" }} />, tooltip: "JSON" };
      case "9999":
        return { icon: <CiFileOn style={{ fontSize: "20px" }} />, tooltip: "Other" };
      default:
        return { icon: <CiFileOn style={{ fontSize: "20px" }} />, tooltip: "Other" };
    }
  }

  const getAssetCollectionCardBtns = () => {
    const extras = [];
    if (hasAccess(AppObject.ASSET_ITEM, ACTIONS.POST) && hasAccess(AppObject.ASSET_COLLECTION_EDITABLE, ACTIONS.POST)) {
      extras.push(
        <Button
          style={{ height: "45px", marginRight: "10px" }}
          size={size}
          disabled={assetCollection?.deleted === true || assetCollection?.editable === false}
          onClick={() =>
            navigate(`/assetcollection/${collectionId}/asset-items/add-asset-item`, {
              state: {
                collectionId: collectionId,
                maxAssets: assetCollection?.maxAssets,
                allowedAssetTypes: assetCollection?.allowedAssetTypes,
                deployable: assetCollection?.deployable,
                source: "AssetCollectionDetail",
                alreadyCreatedItems: assetCollection?.assetItems,
              },
            })
          }
        >
          <PlusOutlined />
        </Button>
      );
    }
    return extras;
  };

  const getColorAndDot = (state) => {
    switch (state) {
      case "DEFAULT":
        return {
          color: "green",
          dot: <CheckCircleFilled />,
        };
      case "LIVE":
        return {
          color: "green",
        };
      case "OFFLINE":
        return {
          color: "#ccc",
          dot: <StopOutlined />,
        };
      default:
        return {
          color: "#ccc",
        };
    }
  };

  const goToItemDetails = (item) => {
    navigate(`/assetcollection/${collectionId}/asset-items/${item.id}`, {
      state: { editable: assetCollection?.editable },
    });
  };

  const goToDeployModal = () => {
    navigate(`/assetcollection/${collectionId}/deploy`);
  };

  const handleEdit = () => {
    navigate(`/assetcollection/${collectionId}/updatecollection`);
  };

  const CardIcon = () => {
    return (
      <div style={{ display: "flex", flexDirection: "row", gap: "10px", paddingTop: "10px" }}>
        {!assetCollection?.deleted && (
          <>
            <Tooltip title="Edit Collection Detail">
              <EditButton onClick={handleEdit} />
            </Tooltip>

            <Tooltip title="Deploy Collection">
              <DeployButton
                disabled={!assetCollection?.deployable || assetItems.length === 0}
                onClick={handleItemClick}
              />
            </Tooltip>

            <DeleteButton onClick={showDeleteModal} />
          </>
        )}

        {assetCollection?.deleted === true && (
          <>
            <RetrieveButton onClick={showRetrieveModal} />

            <PurgeButton onClick={showPurgeModal} />
          </>
        )}
      </div>
    );
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={"/assetcollection"}>Asset Collections</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{collectionId}</Breadcrumb.Item>
      </Breadcrumb>

      <div style={{ paddingTop: "15px" }}>
        <Row
          gutter={[
            { xs: 8, sm: 16, md: 24, lg: 24 },
            { xs: 8, sm: 16, md: 24, lg: 24 },
          ]}
        >
          <Col lg={{ span: 16 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Card
              hoverable={false}
              title={
                <>
                  {`Asset Collection - ${assetCollection?.collectionId}`}
                  {assetCollection?.deleted && (
                    <Tag color="red" style={{ marginLeft: 10 }}>
                      Deleted
                    </Tag>
                  )}
                </>
              }
              extra={<CardIcon />}
              className="asset-container"
              bordered={false}
              // style={{ borderBottom: "none" }}
              headStyle={{ borderBottom: "none" }}
            >
              {/* <Skeleton> */}
              <Row>
                <Col
                  lg={{ span: 14 }}
                  md={{ span: 12 }}
                  sm={{ span: 24 }}
                  xs={{ span: 24 }}
                  // style={{ height: "100px", width: "100%" }}
                >
                  <img
                    id="image"
                    src={assetCollection?.thumbnail || process.env.PUBLIC_URL + "/3d.svg"}
                    style={{ width: 200, height: 200, objectFit: "scale-down" }}
                    alt="image"
                  />
                </Col>
                <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                  <div id="card-info">
                    <Title level={2} style={{ fontWeight: "300" }}>
                      {assetCollection?.collectionId}
                    </Title>
                    <Title
                      level={3}
                      style={{
                        fontWeight: "300",
                        marginTop: "0",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {assetCollection?.collectionName}
                    </Title>

                    <Title level={5} style={{ fontWeight: "400", marginTop: "0.5em" }}>
                      Total Asset Items : {assetItems.length}
                    </Title>

                    <Title level={5} style={{ fontWeight: "400", marginTop: "0.5em" }}>
                      Max Asset Items : {assetCollection?.maxAssets}
                    </Title>

                    <Title level={5} style={{ fontWeight: "400", marginTop: "0.5em" }}>
                      Cache: {assetCollection?.localCacheControl ? "Enabled" : "Disabled"}
                    </Title>

                    <div
                      style={{ display: "flex", flexDirection: "row", padding: "5px", gap: "10px", flexWrap: "wrap" }}
                    >
                      <Title level={5} style={{ fontWeight: "400", marginTop: "0" }}>
                        {assetCollection?.deployable ? (
                          <Tooltip title={"Deployable"}>
                            <DeploymentUnitOutlined className="secured" style={{ fontSize: "24px" }} />
                          </Tooltip>
                        ) : (
                          <Tooltip title={"Not Deployable"}>
                            <DeploymentUnitOutlined className="unsecured" style={{ fontSize: "24px" }} />
                          </Tooltip>
                        )}
                      </Title>

                      <Title level={5} style={{ fontWeight: "400", marginTop: "0" }}>
                        {assetCollection?.secured ? (
                          <Tooltip title={"Secured"}>
                            <SafetyCertificateOutlined className="secured" style={{ fontSize: "24px" }} />
                          </Tooltip>
                        ) : (
                          <Tooltip title={"Not Secured"}>
                            <SafetyCertificateOutlined className="unsecured" style={{ fontSize: "24px" }} />
                          </Tooltip>
                        )}
                      </Title>
                      <Title level={5} style={{ fontWeight: "400", marginTop: "0" }}>
                        {assetCollection?.editable ? (
                          <Tooltip title={"Editable"}>
                            <VscRequestChanges className="secured" style={{ fontSize: "24px" }} />
                          </Tooltip>
                        ) : (
                          <Tooltip title={"Not Editable"}>
                            <VscRequestChanges className="unsecured" style={{ fontSize: "24px" }} />
                          </Tooltip>
                        )}
                      </Title>
                    </div>

                    <Title level={5} style={{ fontWeight: "400", marginTop: "0.5em" }}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          border: "1px solid #eee ",
                          padding: "5px",
                          borderRadius: "5px",
                          flexWrap: "wrap",
                          width: "15rem",
                        }}
                      >
                        <div>
                          <p>Allowed Asset Types</p>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            gap: "15px",
                          }}
                        >
                          {assetCollection?.allowedAssetTypes?.map((typeId, index) => {
                            const { icon, tooltip } = getIconAndTooltip(typeId);
                            return (
                              <Tooltip title={tooltip} key={index}>
                                <div>{icon}</div>
                              </Tooltip>
                            );
                          })}
                        </div>
                      </div>
                    </Title>
                  </div>
                </Col>
                {!assetCollection?.deleted && <DefaultURLs environments={allEnv} collectionId={collectionId} />}
              </Row>
              {/* </Skeleton> */}
            </Card>
          </Col>

          <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Card
              hoverable={false}
              title={`Asset Items`}
              bordered={false}
              extra={[!assetCollection?.deleted && getAssetCollectionCardBtns()]}
              headStyle={{ borderBottom: "none" }}
            >
              {selectedCollection && (
                <Modal
                  open={releaseModalOpen}
                  title={
                    <div>
                      <span>Collection Deployment - {assetCollection.collectionId}</span>
                      <span
                        style={{ color: "green", cursor: "pointer" }}
                        onClick={() =>
                          navigate(`/assetcollection/${selectedCollection.collectionId}`, {
                            state: {
                              assetCollection,
                            },
                          })
                        }
                      >
                        {selectedCollection.collectionId}
                      </span>
                      {deployError && <div style={{ color: "red", marginTop: "10px" }}>{deployError}</div>}
                    </div>
                  }
                  destroyOnClose={true} 
                  okText="Deploy"
                  bodyStyle={{ marginTop: 15 }}
                  onOk={() => deployAssetCollection(selectedCollection)}
                  onCancel={handleReleaseCancel}
                  width={600}
                  footer={[
                    <Button style={{ float: "left" }} onClick={handleReleaseCancel}>
                      Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleReleaseCancel}>
                      Done
                    </Button>,
                  ]}
                >
                   <div className="scrollable-container" style={{ maxHeight: '400px', overflowY: 'auto' }}>
                  <CollectionDeploymentTable
                    assetStates={assetStates}
                    allEnv={allEnv}
                    gamePlatforms={gamePlatforms}
                    assetCollection={selectedCollection}
                    collectionId={collectionId}
                    onClose={handleReleaseCancel}
                    onDeployError={handleDeployError}
                  />
                  </div>
                </Modal>
              )}

              <AssetTimeline
                items={assetItems.slice(0, 10).map((item) => ({
                  id: item.assetItemId,
                  state: item.state,
                  label: item.assetItemName,
                  date: convertDateToNestorTime(item.createdAt),
                }))}
                // onItemClick={goToItemDetails}
                // onIconClick={goToItemDetails}
                onIconClick={assetCollection?.deleted ? () => {} : goToItemDetails}
                onItemClick={assetCollection?.deleted ? () => {} : goToItemDetails}
                showAllLink={`/assetcollection/table/${collectionId}`}
                isStateProcessing={(state) => state?.includes("PROCESSING")}
                getColorAndDot={getColorAndDot}
              />
            </Card>
          </Col>
        </Row>
      </div>
      <SuccessModal />

      <FailureModal onTryAgain={handleCollectionDelete} />

      <Modal
        title={`Delete Asset Collection - ${collectionId}`}
        open={isDeleteModalVisible}
        onCancel={handleDeleteModalCancel}
        footer={[
          <Button key="cancel" onClick={handleDeleteModalCancel}>
            Cancel
          </Button>,
          <Button key="delete" type="primary" loading={deleteLoading} onClick={handleCollectionDelete}>
            Delete
          </Button>,
        ]}
      >
        <p>Are you sure you want to delete this asset collection?</p>
        <p>You can retrieve it later if you want.</p>
      </Modal>

      <Modal
        title={`Retrieve Asset Collection - ${collectionId}`}
        open={isRetrieveModalVisible}
        onCancel={handleRetrieveModalCancel}
        footer={[
          <Button key="cancel" onClick={handleRetrieveModalCancel}>
            Cancel
          </Button>,
          <Button key="retrieve" type="primary" loading={retrieveLoading} onClick={handleCollectionRetrieve}>
            Retrieve
          </Button>,
        ]}
      >
        <p>Do you want to retrieve this asset collection?</p>
      </Modal>

      <Modal
        title={`Purge Asset Collection - ${collectionId}`}
        open={isPurgeModalVisible}
        onCancel={handlePurgeModalCancel}
        footer={[
          <Button key="cancel" onClick={handlePurgeModalCancel}>
            Cancel
          </Button>,
          <Button key="purge" type="primary" loading={purgeLoading} onClick={handlePurgeCollection}>
            Purge
          </Button>,
        ]}
      >
        <p>Do you wish to purge this asset collection?</p>
        <p style={{ color: "red" }}>
          This will permanently delete this collection and its items, and it cannot be undone.
        </p>
      </Modal>
    </>
  );
}

export default AssetCollectionDetail;
