import { Dashboard, Users, Environment, VirtualAsset ,AssetCollection, Support} from "./menuItem"

export const menuItems = [
  Dashboard,
  Users,
  Environment,
  VirtualAsset,
  AssetCollection,
  Support
]
