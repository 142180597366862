import React, { useEffect, useState } from "react";
import "./ResetPassword.css";
import { Typography } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import ResetPassSuccess from "./ResetPassSuccess";
import ResetPassForm from "./ResetPassForm";

const { Title } = Typography;

function ResetPassword() {
  let navigate = useNavigate();
  const location = useLocation();
  const [success, setSuccess] = useState(false);
  const [status, setStatus] = useState("open");
  const [expirationTime, setExpirationTime] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const statusParam = params.get("status");
    if (statusParam !== "open") {
      setStatus(statusParam);
    }

    const currentTime = new Date();
    const expiration = new Date(currentTime.getTime() + 10 * 60000); // 10 minutes in milliseconds
    setExpirationTime(expiration);

  }, [location.search]);

  const formatTime = (date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const amPM = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${pad(minutes)} ${amPM}`;
  };
  
  // Function to pad single digit with leading zero
  const pad = (num) => {
    return num < 10 ? "0" + num : num;
  };

 
  return (
    <div className="reset-pass-main">
      <div className="reset-pass-header">
        <div className="reset-logo-div">
          <img src={process.env.PUBLIC_URL + "/nestor_text.png"} alt="Nestor" className="reset-nestor-text-img" />
        </div>
        <div>
          <a className="login-link" onClick={() => navigate("/")}>
            Login
          </a>
        </div>
      </div>

      <div className="rp-div-container">
        <div className="reset-pass-container">
          {success ? (
            <ResetPassSuccess />
          ) : (
            <>
              {status !== "open" ? (
                <div className="link-expired-div">
                  <Title level={2} className="reset-pass-text">
                    Reset Password
                  </Title>
                  <p>Your Link has expired, please request a new link</p>
                  <a onClick={() => navigate("/forgot-password")}>
                    <ArrowLeftOutlined /> Retry
                  </a>
                </div>
              ) : (
                <>
                  <div className="reset-pass-text-div">
                  
                    <Title level={2} className="reset-pass-text">
                      Reset Password
                    </Title>
                    <p>Don't worry it happens, Please enter a new password.</p>

                  </div>
                  <ResetPassForm success={success} setSuccess={setSuccess} />
                  {expirationTime && (
                      <p className="expiration-timer">( This session is valid only for 10 minutes until {formatTime(expirationTime)} )</p>
                    )}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
}
export default ResetPassword;
