import React, { useEffect, useState } from "react";
import { getGameAssetById } from "@services/gameAssetService";
import { useNavigate, useParams } from "react-router-dom";

import { Descriptions, message, Skeleton, Image, Upload, Modal, Button, Form, Spin } from "antd";

import Style from "./css/GameAssetDetails.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CopyOutlined, CopyFilled } from "@ant-design/icons";
import { updateAssetImage } from "../../../../services/gameAssetService";
import { AppObject } from "@context/AppObject";
import { PageHeaderDetailForm } from "@dashboardpages/PageHeader";

const { Dragger } = Upload;

const UploadModal = (props) => {
  return (
    <Modal
      title={"Update Asset Media"}
      open={props.visible}
      {...props}
      destroyOnClose={props?.onClose}
      footer={[
        <Button onClick={props?.onCancel}>Close</Button>,
        <Button onClick={props?.onOk} type={!!!props.disabled && "primary"} disabled={props.disabled}>
          Save
        </Button>,
      ]}
    >
      {props.children}
    </Modal>
  );
};

const GameAssetDetails = (props) => {
  const [asset, setAsset] = useState();
  const [isLoading, setLoading] = useState(true);
  const [isAssetImageLoading, setAssetImageLoading] = useState(false);
  const [isAssetFileLoading, setAssetFileLoading] = useState(false);
  const [modalVisibility, setModalVisibility] = useState(false);
  const [uploadModel, setUploadModel] = useState(false);
  const [formField, setForm] = useState();
  const [image, setImage] = useState();
  const [assetFileModel, setAssetFileModel] = useState(false);
  const [disabledB, setDisabledB] = useState(true);
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const { assetId } = useParams();

  useEffect(() => {
    getGameAssetById(assetId)
      .then((res) => {
        if (res.status === 200 && res.data) {
          setAsset(res.data);
          setImage(res.data.assetImage);
        }
        setLoading(false);
      })
      .catch((err) => {
        message.error("Unable to load Asset.Please try again later");
        setLoading(false);
      });
  }, []);

  const goBack = () => {
    navigate("/gameassets");
  };

  const showModal = () => setModalVisibility(true);
  const hideModal = () => setModalVisibility(false);

  const UploadModelShow = () => {
    setUploadModel(true);
  };
  const UploadModelHide = () => {
    setUploadModel(false);
  };

  const assetFileModelShow = () => {
    setAssetFileModel(true);
  };

  const assetFileModelHide = () => {
    setAssetFileModel(false);
  };
  const uploadFileProps = (fieldName) => {
    return {
      onRemove: (file) => {
        setForm({ [fieldName]: null });
        setDisabledB(true);
      },
      beforeUpload: (file) => {
        setForm({ [fieldName]: file });
        setDisabledB(false);
        return false;
      },
      maxCount: 1,
      listType: "picture",
    };
  };

  const stopAssetLoading = () => {
    setAssetFileLoading(false);
    setAssetImageLoading(false);
  };

  const updateMedia = () => {
    let formData = new FormData();
    formData.append("_id", asset._id);
    if (formField?.assetImage) {
      form.setFieldsValue({ assetImage: formField["assetImage"] });
      formData.append("assetImage", form.getFieldValue("assetImage"), form.getFieldValue("assetImage").name);
      setAssetImageLoading(true);
      UploadModelHide();
    } else if (formField?.assetFile) {
      form.setFieldsValue({ assetFile: formField["assetFile"] });
      formData.append("assetFile", form.getFieldValue("assetFile"), form.getFieldValue("assetFile").name);
      setAssetFileLoading(true);
      assetFileModelHide();
    }
    updateAssetImage(formData)
      .then((res) => {
        if (res.status == 201) {
          setImage(res.data.assetImage);
        }
        stopAssetLoading();
      })
      .catch((err) => {
        stopAssetLoading();
      });
  };

  return (
    <React.Fragment>
      {/* <PageHeader
        className="site-page-header"
        title="Asset Details"
        extra={[
          <RoundButton
            key="0"
            icon={<LineChartOutlined />}
            onClick={() => navigate("/gameassets/analytics/" + assetId)}
          />,
          <CancelButton key="1" onClick={goBack} />,
        ]}
      /> */}
      <PageHeaderDetailForm
        objectId={AppObject.ASSET_DETAIL}
        title="Asset Details"
        onAnalytic={() => navigate("/gameassets/analytics/" + assetId)}
        onCancel={goBack}
      />
      <div className={Style["main-container"]}>
        <Skeleton loading={isLoading}>
          <Descriptions className={Style["description"]} bordered={true} labelStyle={{ textAlign: "right" }}>
            <Descriptions.Item label="Asset Id:" span={3}>
              {asset?.assetId}
            </Descriptions.Item>
            <Descriptions.Item label="Asset Name:" span={3}>
              {asset?.assetName}
            </Descriptions.Item>
            <Descriptions.Item label="Engine:" span={3}>
              {asset?.engineType}
            </Descriptions.Item>
            <Descriptions.Item label="Platform:" span={3}>
              {asset?.platform}
            </Descriptions.Item>
            <Descriptions.Item label="API:" span={3}>
              <Button type="link" style={{ padding: 0, fontWeight: 500 }} onClick={showModal}>
                View API
              </Button>
            </Descriptions.Item>

            <Descriptions.Item labelStyle={{ alignSelf: "center" }} label="thumbnail:" span={3}>
              {
                <Button style={{ height: 200, width: 200 }} onClick={UploadModelShow} type="link">
                  <Spin spinning={isAssetImageLoading}>
                    <Image className={Style["asset-image"]} preview={false} src={`${image}?${Date.now()}`} />
                  </Spin>
                </Button>
              }
            </Descriptions.Item>
            <Descriptions.Item label="Asset File:" span={3}>
              <Spin spinning={isAssetFileLoading}>
                <Button type="link" style={{ padding: 0, fontWeight: 500 }} onClick={assetFileModelShow}>
                  Update Asset File
                </Button>
              </Spin>
            </Descriptions.Item>
          </Descriptions>
          <ViewAPIModel visible={modalVisibility} url={asset?.url} onClose={hideModal} />
          <Form form={form}>
            <UploadModal
              visible={uploadModel}
              onOk={() => updateMedia(this)}
              onCancel={UploadModelHide}
              disabled={disabledB}
              okText="Save"
              onClose={() => {
                setForm();
              }}
            >
              <Form.Item name="assetImage" rules={[{ required: true, message: "Please select thumbnail" }]}>
                <Dragger {...uploadFileProps("assetImage")} style={{ height: "350px" }}>
                  <p className="ant-upload-text">Click or drag file to this area to upload.</p>
                </Dragger>
              </Form.Item>
            </UploadModal>
            <UploadModal
              visible={assetFileModel}
              onOk={updateMedia}
              disabled={disabledB}
              onCancel={assetFileModelHide}
              okText="Save"
              onClose={() => {
                setForm();
              }}
            >
              <Form.Item name="assetFile" rules={[{ required: true, message: "Please select thumbnail" }]}>
                <Dragger {...uploadFileProps("assetFile")} style={{ height: "350px" }}>
                  <p className="ant-upload-text">Click or drag file to this area to upload.</p>
                </Dragger>
              </Form.Item>
            </UploadModal>
          </Form>
        </Skeleton>
      </div>
    </React.Fragment>
  );
};

const ViewAPIModel = ({ visible, url, onClose }) => {
  const [isCopied, setCopied] = useState(false);
  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  return (
    <Modal title="Asset API" open={visible} onCancel={onClose} footer={[<Button onClick={onClose}>Ok</Button>]}>
      <CopyToClipboard onCopy={handleCopy} text={url}>
        <div className={Style["modal-container"]}>
          <p style={{ width: 400 }}>{url}</p>
          <div className={Style["clip-board-container"]}>
            {isCopied ? (
              <CopyFilled className={Style["clip-board"]} />
            ) : (
              <CopyOutlined className={Style["clip-board"]} />
            )}
            <p className={Style["is-copied-text"]}>{isCopied && "Copied!"}</p>
          </div>
        </div>
      </CopyToClipboard>
    </Modal>
  );
};

export default GameAssetDetails;
