import React from "react";
import DashboardLayout from "./DashboardLayout";
import GeneralLayout from "./GeneralLayout";

const MasterLayout = (props) => {
  const layoutType = props.layoutType;
  if (layoutType === "panel") {
    return <DashboardLayout></DashboardLayout>;
  } else {
    return <GeneralLayout></GeneralLayout>;
  }
};

export default MasterLayout;
