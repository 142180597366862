import { Button } from "antd";
import { UndoOutlined } from "@ant-design/icons";

export const RefreshButton = (props) => {
  return (
    <Button
      shape="circle"
      onClick={props?.onClick}
      key={props?.key || 1}
      style={{
        width: 40,
        height: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      icon={<UndoOutlined />}
    ></Button>
  );
};
