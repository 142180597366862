import React, { useEffect, useState } from "react";
import { Breadcrumb, Skeleton, message, Button, Steps } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import AddNewAssetCollection from "./AddNewAssetCollection";
import AddNewAssetItem from "./AddNewAssetItem";
import ReleaseAssetCollection from "./ReleaseAssetCollection";

const AssetCollectionCreation = () => {
  const navigate = useNavigate();

//   const { collectionId } = useParams(); 
  const [current, setCurrent] = useState(0);
  const [collectionId, setCollectionId] = useState("");
  const [allowedAssetTypes, setAllowedAssetTypes] = useState([]);
  const [maxAssets, setMaxAssets] = useState(1);
  const [deployable,setDeployable] = useState()

  const handleCollectionCreated = (id,types,value,deployable) => {
    setCollectionId(id);
    setAllowedAssetTypes(types);
    setMaxAssets(value);
    setDeployable(deployable);
    setCurrent(1);

  };


  const steps = [
    {
      title: "Create Asset Collection",
      content: <AddNewAssetCollection 
    //   setCurrent={(val) => setCurrent(val)} 
      onCollectionCreated={handleCollectionCreated}
      />,
    },
    {
      title: "Add Asset Items",
      content: <AddNewAssetItem 
      setCurrent={(val) => setCurrent(val)} 
      collectionId={collectionId} 
      allowedAssetTypes={allowedAssetTypes} 
      maxAssets={maxAssets}
      deployable={deployable}
      />,
    },
    {
      title:"Deploy Collection",
      content:<ReleaseAssetCollection
      setCurrent ={(val) => setCurrent(val)}
      collectionId={collectionId}
      
      />
      
    }
  ];
  const contentStyle = {
    lineHeight: "260px",
    textAlign: "center",
    marginTop: 16,
  };
  return (
    <React.Fragment>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={"/assetcollection"}>Asset Collections</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>New Asset Collection</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          background: "white",
          padding: 10,
          marginBottom: 5,
          borderRadius: 12,
          boxShadow: "0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%)",
          marginTop: 15,
        }}
      >
        <Steps current={current} items={steps} />
      </div>
      <div style={contentStyle}>{steps[current].content}</div>
    </React.Fragment>
  );
};

export default AssetCollectionCreation;
