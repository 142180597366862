import React, { useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { Card } from "antd";
import { Button, message, Form, Input,Col, Select, Upload, Skeleton } from "antd";
import Style from "./AssetVersion.module.css";
import { useNavigate } from "react-router-dom";
import { RefreshButton } from "@dashboard/buttons/RefreshButton";
import { CancelButton } from "@dashboard/buttons/CancelButton";
import { getTargetPlatforms } from "@services/systemService";
import { createAssetVersion } from "@services/assetContainer";
import { InboxOutlined } from "@ant-design/icons";
import SkipModal from "@dashboardmodals/Skip";
import SuccessModel from "@dashboardmodals/Success";
import FailureModel from "@dashboardmodals/Failure";

const { Dragger } = Upload;
const { Option } = Select;

//PROPS
// setCurrent={(val) => setCurrent(val)}
//           assetContainer={assetContainer}
//           setAssetVersion={setAssetVersion}

const AddVersion = (props) => {
    const [form] = Form.useForm();
    const [formField, setFormField] = useState();
    const [gamePlatforms, setGamePlatform] = useState([]);
  
    const [isFormValid, setIsFormValid] = useState(false);
    const [isEngineLoading, setEngineLoaded] = useState(true);
  
    const [isLoading, setLoading] = useState(false);
    const [successModalVisiblilty, setSuccessModalVisiblilty] = useState(false);
    const [failureModalVisiblilty, setFailureModalVisiblilty] = useState(false);
    const [skipModal, setSkipModal] = useState(false);
    const navigate = useNavigate();
  
    useEffect(() => {
      fetchFunc(getTargetPlatforms, setGamePlatform, setEngineLoaded, "targetPlatform");
      form.setFieldsValue({ active: true });
    }, []);
  
    const uploadFileProps = (fieldName) => {
      return {
        onRemove: (file) => {
          setFormField({ ...formField, [fieldName]: null });
          setIsFormValid(false)
        },
        beforeUpload: (file) => {
          setFormField({ ...formField, [fieldName]: file });
          return false;
        },
        maxCount: 1,
        listType: "picture",
      };
    };
  
    const checkFormValidity = () => {
      const hasError = form.getFieldsError().some(({ errors }) => errors.length > 0);
      const { versionName, targetPlatform, assetFile } = form.getFieldsValue();
      const isFormCompleted = assetFile && versionName?.trim() && targetPlatform;
      const isValid = !hasError && isFormCompleted;
      setFormField({ ...formField, versionName, targetPlatform });
      setIsFormValid(isValid);
    };
  
    const fetchFunc = (getFunc, setFunc, setLoadOff, type) => {
      getFunc()
        .then((res) => {
          if (res.status == 200 && res?.data?.dataList?.length > 0) {
            setFunc(res?.data?.dataList);
          } else {
            message.error(`Enable to find game ${type}. Please try again later"`);
          }
          setLoadOff(false);
        })
        .catch((err) => {
          message.error(`Enable to find game ${type}. Please try again later"`);
          setLoadOff(false);
        });
    };
  
    const AddAssetVersion = () => {
      setLoading(true);
      const formData = new FormData();
      for (let key in formField) {
        formData.append(key, formField[key]);
      }
      formData.append("assetContainerId", props?.assetContainer?._id);
  
      createAssetVersion(formData)
        .then((res) => {
          if (res?.status == 201) {
            props?.setAssetVersion(res.data);
            setSuccessModalVisiblilty(true);
          } else {
            // message.error("Sorry! We are unable to create your asset right now.")
            setFailureModalVisiblilty(true);
          }
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          setFailureModalVisiblilty(true);
        });
    };
  
    const onSubmit = () => {
      AddAssetVersion();
    };

    const navigateToAssetDetail = () => {
      setFailureModalVisiblilty(false);
      setSuccessModalVisiblilty(false);
      navigate("/assetscontainer/" + props?.assetContainer?.assetId);
    };
  
    const navigateToAssetList = () => {
      setFailureModalVisiblilty(false);
      setSuccessModalVisiblilty(false);
      navigate("/assetscontainer");
    };
  
    const createMoreAsset = () => {
      props.setCurrent(2);
    };
    const tryAgain = () => {
      setFailureModalVisiblilty(false);
    };
  
    const [size, setSize] = useState("large"); // default is 'middle'
  
    return (
      <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }}>
        <Card className="asset-container asset-card" headStyle={{ borderBottom: "0px" }} bordered={false}>
          <SkipModal onLeftClick={navigateToAssetDetail} visible={skipModal} onRightClick={() => setSkipModal(false)} />
          <PageHeader
            className="site-page-header"
            title={"Asset Versions - " + props?.assetContainer?.assetId}
            extra={[
              
              <CancelButton key="2" onClick={()=>navigate("/assetscontainer/"+props?.assetContainer?.assetId)}/>
              ,              <RefreshButton key="1" onClick={() => form.resetFields()} />,
            ]}
          />
          <div className={Style["main-container"]}>
            <Skeleton loading={isEngineLoading} active>
              <Form
                form={form}
                labelCol={{ span: 5 }}
                onFieldsChange={() => {
                  checkFormValidity();
                }}
                wrapperCol={{ span: 14 }}
                className={Style["form"]}
              >
                <Form.Item
                  label="Version Name"
                  name="versionName"
                  rules={[{ required: true, message: "Please enter Asset Name" }, {max:64, message: "Version name must be less than 64 characters!"}]}
                >
                  <Input className="inputAssets" />
                </Form.Item>
                <Form.Item
                  label="Target Platform"
                  style={{ textAlign: "left" }}
                  name="targetPlatform"
                  rules={[{ required: true, message: "Please select Target Platform" }]}
                >
                  <Select placeholder="Target Platform">
                    {gamePlatforms?.map((platform) => (
                      <Option value={platform?._id}>{platform?.platformName}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Asset File"
                  name="assetFile"
                  rules={[{ required: true, message: "Please select asset file" }]}
                >
                  <Dragger {...uploadFileProps("assetFile")} style={{ width: "100" }}>
                    <UploadMsg subMsg="Upload Asset File." />
                  </Dragger>
                </Form.Item>
  
                {
                /* <Form.Item label="Thumbnail" name="thumbnail">
                  <Dragger {...uploadFileProps("thumbnail")} style={{ width: "100" }}>
                    <UploadMsg subMsg="Only jpeg or png files are supported." />
                  </Dragger>
                </Form.Item> */
                }
                <Form.Item>
                  <Button
                    loading={isLoading}
                    onClick={onSubmit}
                    type="primary"
                    htmlType="submit"
                    className={Style["button"]}
                    disabled={!isFormValid}
                  >
                    Add Asset Version
                  </Button>
                </Form.Item>
              </Form>
              <SuccessModel
                visible={successModalVisiblilty}
                onRightClick={navigateToAssetDetail}
                onLeftClick={props?.gotToNextStep}
                leftTitle="Deploy for environments"
                rightTitle="Not Now"
                title="Asset Version Added Successfully"
                msg={"Do you want to release  this new version for environments?"}
              />
              <FailureModel visible={failureModalVisiblilty} onRightClick={navigateToAssetList} onLeftClick={tryAgain} />
            </Skeleton>
          </div>
        </Card>
      </Col>
    );
  };

  const UploadMsg = (props) => {
    return (
      <div>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">{props?.subMsg || ""}</p>
      </div>
    );
  };

  export default AddVersion;