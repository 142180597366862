import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PageHeader } from "@ant-design/pro-layout";
import { Table, message } from "antd";
import { getAllSubscriptions } from "@services/subscriptionService";
import moment from "moment";

const columns = [
  {
    title: "Subscription Id",
    dataIndex: "subscriptionId",
    key: "subscriptionId",
    render: (text, record) => <Link to={record.subscriptionId}>{text}</Link>,
  },
  {
    title: "ProductId",
    dataIndex: "productId",
    key: "productId",
  },
  {
    title: "Product Name",
    dataIndex: "productName",
    key: "productName",
  },
  {
    title: "Brand",
    dataIndex: "brandDomain",
    key: "brandDomain",
  },
  {
    title: "Start Date",
    dataIndex: "subscriptionStartedDate",
    key: "subscriptionStartedDate",
    render: (subscriptionStartedDate) => {
      return moment(subscriptionStartedDate).format("DD-MM-YYYY HH:MM:SS");
    },
  },
];

const Subscriptions = () => {
  const [dataSource, setDataSource] = useState([]);
  const navigate = useNavigate();

  const fetchSubscriptions = () => {
    getAllSubscriptions()
      .then((response) => {
        setDataSource(response.data.subscriptions);
      })
      .catch((error) => message.error("Unable to fetcht the subscriptions " + error));
  };

  useEffect(() => {
    fetchSubscriptions();
    return () => {};
  }, []);

  return (
    <React.Fragment>
      <PageHeader
        className="site-page-header"
        // onBack={() => null}
        backIcon={null}
        title={String(window.location.pathname.split("/")[1])}
      />
      <Table
        style={{ margin: 20 }}
        columns={columns}
        dataSource={dataSource}
        pagination={{ pageSizeOptions: ["10", "40"], showPageSizeOptions: true, hideOnSinglePage: true }}
      />
    </React.Fragment>
  );
};

export default Subscriptions;
