import { Dashboard, Users, Organization, Environment, VirtualAsset,AssetCollection,Support } from "./menuItem"

export const menuItems = [
  Dashboard,
  Users,
  Organization,
  Environment,
  VirtualAsset,
  AssetCollection,
  Support
]
