import React from "react";
import "./ForgotPassSuccess.css";
import { Typography } from "antd";
import { MailOutlined } from "@ant-design/icons"; //<MailOutlined />
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;

function ForgotPassSuccess() {
  let navigate = useNavigate();

  return (
    <div>
      <div className="check-email-div">
        <div className="mail-div">
          <MailOutlined className="mail-icon" />
        </div>
        <Title level={2} className="forgot-pass-text">
          Please Check Your Email!
        </Title>
        <p>We've sent you a link to reset your password.</p>
        <a className="back-link" onClick={() => navigate("/")}>
          <ArrowLeftOutlined /> Back
        </a>
      </div>
    </div>
  );
}

export default ForgotPassSuccess;
