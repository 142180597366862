import React, { useEffect, useState } from "react";
import { Table, Breadcrumb, message, Skeleton, DatePicker, Tooltip, Tag, Pagination } from "antd";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { CancelButton } from "../../buttons/CancelButton";
import { PageHeader } from "@ant-design/pro-layout";
import { getAllAssetItems } from "@services/assetCollection";
import { convertDateToNestorTime } from "@utilities/datehelper";
import { getTargetPlatforms, getAssetTypes } from "@services/systemService";
import { FileOutlined, GlobalOutlined, AndroidOutlined, AppleOutlined } from '@ant-design/icons';

const pageSize = 10;

function AssetCollectionTable() {
  const navigate = useNavigate();
  const { collectionId } = useParams();
  const [isLoading, setLoading] = useState(true);
  const [filters, setFilters] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalAssets, setTotalAssets] = useState();
  const [targetPlatform, setTargetPlatform] = useState();
  const [assets, setAssets] = useState([]);
  const [assetTypeMap, setAssetTypeMap] = useState({}); // Asset type states
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [showTotal, setShowTotal] = useState();

  const setDateFilter = (dates) => {
    if (dates !== null) {
      setStartDate(new Date(dates[0]?.$d).toISOString());
      setEndDate(new Date(dates[1]?.$d).toISOString());
    } else {
      setEndDate("");
      setStartDate("");
    }
  };

  const fetchAssets = () => {
    const query = `pageNumber=${pageNumber}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`;
    getAllAssetItems(collectionId, query)
      .then((res) => {
        if (res.status === 200 && res.data && Array.isArray(res.data.results)) {
          const formattedData = res.data.results.map((item, index) => ({
            key: index + 1,
            assetId: item.assetItemId,
            name: item.assetItemName,
            targetPlatform: item.targetPlatform,
            size: item.size,
            assetType: item.assetType,
            createdAt: item.createdAt,
            mimeType: item.mimetype,
            item,
          }));
          setAssets(formattedData);
          setTotalAssets(res?.data?.totalResults);
          setLoading(false);
        } else {
          console.error("Unexpected API response format:", res);
        }
      })
      .catch((err) => {
        message.error("Unable to load asset versions.");
        setLoading(false);
      });
  };

  useEffect(() => {
    const fetchAssetTypes = async () => {
      try {
        const response = await getAssetTypes();
        const assetTypeMapping = response.data.reduce((acc, asset) => {
          acc[asset.typeId] = asset.typeName; 
          return acc;
        }, {});
        setAssetTypeMap(assetTypeMapping);
      } catch (error) {
        console.error("Failed to fetch asset types", error);
      }
    };

    fetchAssetTypes();
  }, []);

  const getTargetPlatformFilters = () => {
    getTargetPlatforms()
      .then((res) => {
        if (res.status === 200 && res.data && res.data.dataList) {
          const formattedFilters = res.data.dataList.map((item) => ({
            text: item.platformName,
            value: item.platformName.toLowerCase(),
          }));
          setFilters(formattedFilters);
        } else {
          console.error("Unexpected API response format:", res);
        }
      })
      .catch((err) => {
        message.error("Unable to load target platforms.");
      });
  };

  useEffect(() => {
    getTargetPlatformFilters();
  }, []);

  useEffect(() => {
    fetchAssets();
  }, [collectionId, startDate, endDate, pageNumber, targetPlatform]);

  const convertBytesToMB = (bytes) => {
    return (bytes / (1024 * 1024)).toFixed(2); 
  };

  const TotalComponent = () => {
    return (
      <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "end", marginBlock: "10px" }}>
        {showTotal}
        <Pagination
          {...paginationConfig}
          style={{
            textAlign: "left",
          }}
        />
      </div>
    );
  };

  const paginationConfig = {
    total: parseInt(totalAssets),
    responsive: true,
    pageSize: pageSize,
    current: pageNumber,
    onChange: setPageNumber,
    showSizeChanger: false,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} Assets`,
  };
  // /assetcollection/:collectionId/asset-items/:assetItemId
  const columns = [
    {
      title: "Id",
      dataIndex: "assetId",
      key: "assetId",
      className: "center-align",
      onHeaderCell: () => ({
        style: { textAlign: "center" },
      }),
      render: (assetId,item) => {
        if (assetId.length > 10) {
          return (
            <Link
            to={`/assetcollection/${collectionId}/asset-items/${item.assetId}`}
            state={{
              collectionId : collectionId,
              assetItemId : item.assetId,
              assetItem: item
            }}
            >
            <Tooltip title={assetId}>
              <span style={{color:"#008000"}}>{`${assetId.slice(0, 9)}...`}</span>
            </Tooltip>
            </Link>
          );
        }
        return collectionId;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "center-align",
      onHeaderCell: () => ({
        style: { textAlign: "center" },
      }),
    },
    // {
    //   title: "Target Platform",
    //   dataIndex: "targetPlatform",
    //   key: "targetPlatform",
    //   filterMode: "tree",
    //   filters: filters,
    //   filterSearch: true,
    //   onFilter: (value, record) => record.targetPlatform && record.targetPlatform.includes(value),
    //   className: "center-align",
    //   onHeaderCell: () => ({
    //     style: { textAlign: "center" },
    //   }),
    //   render: (targetPlatform) => {
    //     let IconComponent;
    //     let tooltipTitle;
    
    //     switch (targetPlatform) {
    //       case 'generic':
    //         IconComponent = FileOutlined;
    //         tooltipTitle = "Generic";
    //         break;
    //       case 'web':
    //         IconComponent = GlobalOutlined;
    //         tooltipTitle = "Web";
    //         break;
    //       case 'android':
    //         IconComponent = AndroidOutlined;
    //         tooltipTitle = "Android";
    //         break;
    //       case 'ios':
    //         IconComponent = AppleOutlined;
    //         tooltipTitle = "IOS";
    //         break;
    //       default:
    //         IconComponent = FileOutlined; 
    //         tooltipTitle = "Generic";
    //     }
    
    //     return (
    //       <Tooltip title={tooltipTitle}>
    //         <IconComponent style={{ fontSize: '24px' }} />
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      title: "Size",
      dataIndex: "size",
      key: "size",
      className: "center-align",
      onHeaderCell: () => ({
        style: { textAlign: "center" },
      }),
      render:(size)=>convertBytesToMB(size) + "MB"
    },
    {
      title: "Asset Type",
      dataIndex: "assetType",
      key: "assetType",
      className: "center-align",
      onHeaderCell: () => ({
        style: { textAlign: "center" },
      }),
      render: (assetType) => assetTypeMap[assetType] || assetType,
      
      // 
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      filterDropdown: <DatePicker.RangePicker style={{ margin: 15 }} onChange={setDateFilter} />,
      render: (createdAt) => {
        return convertDateToNestorTime(createdAt);
      },
      className: "center-align",
      onHeaderCell: () => ({
        style: { textAlign: "center" },
      }),
    },
  ];

  const onChange = (pagination, filters, sorter, extra) => {
    setTargetPlatform(filters.targetPlatform || []);
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Skeleton loading={isLoading}>
      <div style={{ width: "100%" }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/dashboard">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/assetcollection">Asset Collections</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/assetcollection/${collectionId}`} state={collectionId}>
              {collectionId}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>items</Breadcrumb.Item>
        </Breadcrumb>

        <PageHeader
          title={["Collection Items - " + collectionId]}
          style={{ margin: 0, marginTop: 0, width: "100%" }}
          extra={[<CancelButton key="cancelButton" onClick={goBack} />]}
        />
<TotalComponent/>
        <Table
          sticky={true}
          columns={columns}
          dataSource={assets}
          onChange={onChange}
          pagination={paginationConfig}
          showSorterTooltip={{
            target: "sorter-icon",
          }}
        />
      </div>
    </Skeleton>
  );
}

export default AssetCollectionTable;
