import React from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { RefreshButton } from "../buttons/RefreshButton";
import { CancelButton } from "../buttons/CancelButton";
import { AddNewButton } from "../buttons/AddNewButton";
import { DeleteButton } from "../buttons/DeleteButton";
import { EditButton } from "../buttons/EditButton";
import { LineChartOutlined } from "@ant-design/icons";
import RoundButton from "../buttons/RoundButton";
import useAuth from "@hooks/useAuth";
import { Input } from "antd";

export const GenericPageHeader = (props) => {
  return (
    <PageHeader
      style={props?.style}
      className="site-page-header"
      title={props?.title}
      extra={props?.extra}
      subTitle={props?.subTitle}
    />
  );
};

export const PageHeaderDetailForm = (props) => {
  const { hasAccess, ACTIONS } = useAuth();

  const getHeaderButtons = () => {
    const extras = [];
    if (props?.onAnalytic && hasAccess(props?.objectId, ACTIONS.GET)) {
      extras.push(<RoundButton key="0" icon={<LineChartOutlined />} onClick={props?.onAnalytic} />);
    }
    if (props?.onDelete && hasAccess(props?.objectId, ACTIONS.DELETE)) {
      extras.push(<DeleteButton key={1} onClick={props.onDelete} />);
    }
    if (props?.onEdit && hasAccess(props?.objectId, ACTIONS.PUT)) {
      extras.push(<EditButton key={2} onClick={props.onEdit} />);
    }
    if (props?.onCancel) {
      extras.push(<CancelButton key={3} onClick={props.onCancel} />);
    }
    return extras;
  };

  return (
    <GenericPageHeader
      title={props.title}
      subTitle={props.subTitle}
      style={{ background: "#fff" }}
      extra={getHeaderButtons()}
    />
  );
};

// Pageheader for Create Form
export const PageHeaderUpdateForm = (props) => {
  const getHeaderButtons = () => {
    const extras = [];
    if (props?.onRefresh) {
      extras.push(<RefreshButton key={1} onClick={props.onRefresh} />);
    }
    if (props?.onCancel) {
      extras.push(<CancelButton key={2} onClick={props.navigate} />);
    }
    return extras;
  };

  return (
    <GenericPageHeader
      title={props.title}
      subTitle={props.subTitle}
      style={{ background: "#fff" }}
      extra={[<RefreshButton key={1} onClick={props.onClick} />, <CancelButton key={2} onClick={props.navigate} />]}
    />
  );
};

// Pageheader for Create Form
export const PageHeaderCreateForm = (props) => {
  const getHeaderButtons = () => {
    const extras = [];
    if (props?.onRefresh) {
      extras.push(<RefreshButton key={1} onClick={props.onRefresh} />);
    }
    if (props?.onCancel) {
      extras.push(<CancelButton key={2} onClick={props.navigate} />);
    }
    return extras;
  };
  return (
    <GenericPageHeader
      title={props.title}
      subTitle={props.subTitle}
      style={{ background: "#fff" }}
      extra={[<RefreshButton key={1} onClick={props.onClick} />, <CancelButton key={2} onClick={props.navigate} />]}
    />
  );
};

// Pageheader for List Page
export const PageHeaderListForm = (props) => {
  const { hasAccess, ACTIONS } = useAuth();
  const getHeaderButtons = () => {
    const extras = [];
    if (props?.onSearch || props?.searchPlaceHolderText) {
      extras.push(
        <div className="search">
          <Input.Search onSearch={props?.onSearch} placeholder={props.searchPlaceHolderText} enterButton />
        </div>
      );
    }
    if (props?.onAdd && hasAccess(props?.objectId, ACTIONS.POST)) {
      extras.push(<AddNewButton key={1} onClick={props.onAdd} />);
    }
    return extras;
  };

  return <GenericPageHeader title={props.title} extra={getHeaderButtons()} {...props} />;
};

// border-radius: 20px 0px 0px 20px !important;
// border: 1px solid #cccc;
// width: 100% !important;
// padding-left: 20px !important;
