import { Card, Col, Row, Spin, Tooltip } from "antd";
import Title from "antd/lib/typography/Title";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import "../css/toplinecard.css";

const TopLineCard = (props) => {
  const navigate = useNavigate();
  return (
    <Card
      key={props.title}
      className="top-card"
      bordered={false}
      hoverable={true}
      headStyle={{ borderBottom: "0 none" }}
      onClick={() => navigate(props.targetUrl)}
    >
      <Row>
        {props.image ? (
          <Col span={10} className="top-card__col">
            <img src={props.image} alt="Games" />
          </Col>
        ) : null}

        <Col span={14} className="top-card__col-2">
          <Title level={5} style={{ textAlign: "center" }}>
            {props.title}
          </Title>
          <Title className="top-card__title" level={1}>
            <Tooltip placement="top" title={<span>{props?.tooltip}</span>}>
              {props.data || props?.data === 0 ? props.data : <Spinner />}
            </Tooltip>
          </Title>
        </Col>
      </Row>
    </Card>
  );
};

const Spinner = () => <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />;

export default TopLineCard;
