import { Routes, Route } from "react-router-dom";
//import Login from "@components/auth/Login";
import "antd/dist/reset.css";
import { ConfigProvider } from "antd";
import Login from "@components/auth/LoginNew";
import SignUp from "@pages/SignUpPage";
import Dashboard from "@pages/Dashboard";
import MasterLayout from "@layouts/MasterLayout";
import NewLandingPage from "@pages/LoginLandingPagev2";
import RequireAuth from "@components/auth/RequireAuth";
import GameList from "@dashboard/pages/Games/GameList";
import OrganizationDetails from "@dashboard/pages/Organizations/OrganizationDetails";
import CreateNewGame from "@dashboard/pages/Games/CreateNewGamePage";
import AssetList from "@dashboard/pages/AssetList";
import GameDetails from "@dashboard/pages/Games/GameDetails";
import GameUpdates from "@dashboard/pages/Games/GameUpdates";
import AssetDetails from "@dashboard/pages/AssetDetails";
import Subscriptions from "@dashboard/pages/Subscriptions/Subscriptions";
import GameAssetList from "@dashboard/pages/GameAsset/GameAssetList";
import CreateGameAsset from "@dashboard/pages/GameAsset/CreateGameAsset";
import GameAssetDetails from "@dashboard/pages/GameAsset/GameAssetDetails";
import SubscriptionDetails from "@dashboard/pages/Subscriptions/SubscriptionDetails";
import OrganizationUpdate from "@dashboard/pages/Organizations/OrganizationUpdate";
import AddOrganization from "@dashboard/pages/Organizations/AddOrganization";
import AssetDownloaded from "@dashboardpages/AssetDownloaded";
import Environment from "@dashboardpages/Environment/Environment";
import AddNewEnv from "@dashboardpages/Environment/AddNewEnvironment";
import EnvironmentDetials from "@dashboardpages/Environment/EnvironmentDetails";
import UpdateEnv from "@dashboardpages/Environment/UpdateEnvironment";
import AssetContainer from "@dashboardpages/GameAsset/AssetContainer";
import CreateAssetContainer from "@dashboardpages/GameAsset/CreateAssetContainer";
import AssetContainerDetail from "./components/dashboard/pages/GameAsset/AssetContainerDetail";
import GameEngines from "./components/dashboard/pages/Games/GameEngines";
import GamePlatform from "./components/dashboard/pages/Games/GamePlatforms";
import SystemData from "./components/dashboard/pages/SystemData";
import AddAssetVersionSteps from "@dashboardpages/AssetVersionSteps";
import AssetVersionList from "@dashboardpages/AssetVersionList";
import UserData from "@dashboardpages/Users/UserList.js";
import AddNewUser from "@dashboardpages/Users/UserForm.js";
import UserDetails from "@dashboardpages/Users/UserDetail.js";
import UpdateUser from "@dashboardpages/Users/UpdateUser.js";
import Support from "@dashboardpages/SupportFeatures/Support.js";
import AssetContainerVersionDetail from "@dashboardpages/GameAsset/AssetContainerVersionDetails";
import BulkUpload from "./components/dashboard/pages/BulkUpload";
import AssetVersionCompare from "./components/comparison/AssetVersionCompare";
import ForgotPasswordPage from "./components/pages/Forgot_Password/ForgotPasswordPage";
import ResetPassword from "./components/pages/Reset_Password/ResetPassword";
import EnvironmentDetailsV2 from "./components/dashboard/pages/Environment/EnvironmentDetailsV2";
import EnvironmentDetailsTable from "./components/dashboard/pages/Environment/EnvironmentDetailsTable";
import AssetVersionTable from "./components/dashboard/pages/GameAsset/assetTableData";
import UpdateUserPassword from "./components/dashboard/pages/Users/UpdatePassword";
import CreateNewTicketPage from "./components/dashboard/pages/SupportFeatures/CreateNewTicket";
import SupportDetails from "./components/dashboard/pages/SupportFeatures/SupportDetails";
import AssetCollection from "./components/dashboard/pages/AssetCollection/AssetCollection";
import AddNewAssetCollection from "./components/dashboard/pages/AssetCollection/AddNewAssetCollection";
import AssetCollectionDetail from "./components/dashboard/pages/AssetCollection/AssetCollectionDetail";
import AddNewAssetItem from "./components/dashboard/pages/AssetCollection/AddNewAssetItem";
import AssetCollectionItemDetail from './components/dashboard/pages/AssetCollection/AssetCollectionItemDetail'
import AssetCollectionTable from "./components/dashboard/pages/AssetCollection/AssetCollectionTable";
import CollectionDeploymentTable from "./components/dashboard/GenericComponenets/CollectionDeploymentTable";
import AssetCollectionCreation from "./components/dashboard/pages/AssetCollection/AssetCollectionCreation";
import AssetCollectionEnvTable from "./components/dashboard/pages/Environment/AssetCollectionEnvTable";
import UpdateAssetCollection from "./components/dashboard/pages/AssetCollection/UpdateAssetCollection";

const GreenApp = () => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#00b96b",
          menuItemActiveBg: "#bcf4c8",
        },
      }}
    >
      <div className="App">
        <Routes>
          <Route element={<MasterLayout layoutType="generic" />}>
            <Route path="/" element={<NewLandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPasswordPage />} />
            <Route path="/reset-password" element={<ResetPassword />} />
          </Route>
          <Route element={<RequireAuth />}>
            <Route element={<MasterLayout layoutType="panel" />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/organization" element={<OrganizationDetails />} />
              <Route path="/organization/update" element={<OrganizationUpdate />} />
              <Route path="/organization/add" element={<AddOrganization />} />
              <Route path="/games/create" element={<CreateNewGame />} />
              <Route path="/games" element={<GameList />} />
              <Route path="/games/:gameId" element={<GameDetails />} />
              <Route path="/games/update/:gameId" element={<GameUpdates />} />
              <Route path="/assets" element={<AssetList />} />
              <Route path="/subscriptions" element={<Subscriptions />} />
              <Route path="/subscriptions/:subscriptionId" element={<SubscriptionDetails />} />
              <Route path="/assets/:assetsName" element={<AssetDetails />} />
              <Route path="/environments" element={<Environment />} />
              <Route path="/environments/add" element={<AddNewEnv />} />
              <Route path="/environments/:envId" element={<EnvironmentDetailsV2 />} />
              <Route path="/environments/table" element={<EnvironmentDetailsTable />} />
              <Route path ="/environments/:envId/assetColTable" element={<AssetCollectionEnvTable/>}/>
              <Route path="/environments/update/:envId" element={<UpdateEnv />} />
              <Route path="/gameassets" element={<GameAssetList />} />
              <Route path="/gameassets/create" element={<CreateGameAsset />} />
              <Route path="/gameassets/:assetId" element={<GameAssetDetails />} />
              <Route path="/assetscontainer/table/:assetId" element={<AssetVersionTable />} />
              <Route path="/support" element={<Support />} />
              <Route path="/support/create" element={<CreateNewTicketPage />} />
              <Route path="/support/details/:ticketId" element={<SupportDetails />} />
              <Route path="/gameassets/analytics/:assetId" element={<AssetDownloaded />} />
              <Route path="/assetscontainer" element={<AssetContainer />} />
              <Route
                path="/assetscontainer/:assetId/assetversion/:versionId"
                element={<AssetContainerVersionDetail />}
              />
              <Route path="/assetscontainer/:id" element={<AssetContainerDetail />} />
              <Route
                path="/assetscontainer/:assetId/assetversion/:versionId"
                element={<AssetContainerVersionDetail />}
              />
              <Route
                path="/assetscontainer/:assetId/assetversion/:versionId/versioncompare"
                element={<AssetVersionCompare />}
              />
              <Route path="/assetscontainer/create" element={<CreateAssetContainer />} />
              <Route path="/systemdata" element={<SystemData />} />
              <Route path="/gameengines" element={<GameEngines />} />
              <Route path="/gameplatforms" element={<GamePlatform />} />
              <Route path="/assetVersions" element={<AssetVersionList />} />
              <Route path="/assetVersions/addVersion" element={<AddAssetVersionSteps />} />
              <Route path="/users" element={<UserData />} />
              <Route path="/users/add" element={<AddNewUser />} />
              <Route path="/users/:email" element={<UserDetails />} />
              <Route path="/users/update/:email" element={<UpdateUser />} />
              <Route path="/users/updatepassword" element={<UpdateUserPassword />} />
              <Route path="/bulk" element={<BulkUpload />} />
              <Route path="/assetcollection" element={<AssetCollection/>}/>
              <Route path="/assetcollection/createnewasset" element={<AssetCollectionCreation/>} />
              <Route path="/assetcollection/:collectionId" element={<AssetCollectionDetail/>}/>
              <Route path = "/assetcollection/:collectionId/updatecollection" element={<UpdateAssetCollection/>}/>
              <Route path="/assetcollection/:collectionId/asset-items/add-asset-item" element={<AddNewAssetItem/>}/>
              <Route path="/assetcollection/:collectionId/asset-items/:assetItemId" element={<AssetCollectionItemDetail/>}/>
              <Route path="/assetcollection/table/:collectionId" element={<AssetCollectionTable/>}/>
              <Route path="/assetcollection/:collectionId/deploy" element={<CollectionDeploymentTable/>}/>
            </Route>
          </Route>
        </Routes>
      </div>
    </ConfigProvider>
    );
};

export default GreenApp;
