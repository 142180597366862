import { useNavigate, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Col, Row, Skeleton, Breadcrumb } from "antd";
import { EmptyView } from "@vyomr/nestor_components";
import { getAllEnv } from "../../../../services/EnvService";
import EnvList from "../../cards/EnvList";
import { AppObject } from "@context/AppObject";
import { PageHeaderListForm } from "@dashboardpages/PageHeader";
import useAuth from "@hooks/useAuth";
const Environment = () => {
  const navigate = useNavigate();
  const { hasAccess, ACTIONS } = useAuth();
  const [envData, setEnvData] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchEnvironments = () => {
    setLoading(true);
    getAllEnv().then((data) => {
      setEnvData(data.data.environments);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetchEnvironments();
  }, []);

  return (
    <React.Fragment>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Environments</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeaderListForm
        objectId={AppObject.ENVIRONMENT}
        title="Environments"
        onAdd={() => {
          navigate("add");
        }}
      />
      <Row gutter={[32, 32]} style={{ padding: 10 }}>
      <Skeleton loading={loading}>
        {envData.length > 0 ? (
          envData.map((item, index) => {
            return (
              <EnvList
                key={index}
                envName={item.envName}
                style={{ margin: 10,height:"250px" }}
                description={item.description}
                envId={item?.envId}
                image={item?.envImageURL}
              />
            );
          })
        ) : (
          <Col span={24}>
          <EmptyView onButtonClick={() => navigate("add", { replace: true })}  disabled={!hasAccess(AppObject.ENVIRONMENT, ACTIONS.POST)} msg="No Environment Data available. Please create new one." buttonText="Add Environment" />
          </Col>
        )}

      </Skeleton>
      </Row>
    </React.Fragment>
  );
};

export default Environment;
