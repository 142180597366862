import React from "react";
import "./AssetVersionImagePreview.css";
import {  Image } from "antd";

const ImagePreview = ({ file }) => {
  return (
    <div className="comp">
        <Image crossOrigin="use-credentials" style={{width:'20vw',height:'20vw', objectFit:'contain'}} src={file} alt="" />
    </div>
  );
};

export default ImagePreview;
