import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

export const CancelButton = (props) => {
  return (
    <Button
      key={props?.key || 1}
      shape="circle"
      onClick={props?.onClick}
      style={{
        width: 40,
        height: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      icon={<CloseOutlined />}
    ></Button>
  );
};
