import axios from "@auth/localaxios";
import { ASSETS_API_PATH, ASSETS_LIST_API_PATH } from "@localconfig/envconfig";

export const getAssetBySubscriptionId = async (subscriptionId) => {
  try {
    const response = await axios.get(ASSETS_API_PATH + "/" + subscriptionId, {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getPrivateAssetDownloadedData = async (filter) => {
  try {
    const response = await axios.get("http://localhost:9900/api/downloaded?filterBy=" + filter, {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getAssetListByProductId = async (productId) => {
  try {
    const response = await axios.get(ASSETS_LIST_API_PATH + "/" + productId, {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};