import React from 'react';
import GenericPreview from '@components/preview/GenericPreview/AssetVersionGenericPreview';
import { assetTypeMappings } from '@components/preview/AssetTypingMapping';

const AssetVersionPreviewComponent = ({ type, file }) => {
  if(type && file){
    const PreviewComponent=assetTypeMappings[type] || GenericPreview;
    return <PreviewComponent file={file} /> 
  }
  else if(!file){
    // incase of no file
    return <GenericPreview file={file}/>;
  }
  return false
};

export default AssetVersionPreviewComponent
