import React, { useEffect, useState } from "react";
import {
  Card,
  Empty,
  Breadcrumb,
  Row,
  Col,
  Image,
  Button,
  Timeline,
  Skeleton,
  Modal,
  Dropdown,
  Space,
  Table,
  Tag,
  Radio,
  Avatar,
  List,
  message,
  Tooltip,
} from "antd";
import moment from "moment";
import { DatePicker } from "antd";
import { Form, Select } from "antd";
import Title from "antd/lib/typography/Title";
import Style from "./css/GameAssetDetails.css";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CopyOutlined, CopyFilled, SafetyCertificateOutlined } from "@ant-design/icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import { RefreshButton } from "../../buttons/RefreshButton";
import {
  PlusOutlined,
  ExportOutlined,
  FilterOutlined,
  AppleFilled,
  AndroidFilled,
  LaptopOutlined,
  FileDoneOutlined,
  StopOutlined,
  CheckCircleFilled,
  EditOutlined,
  LoadingOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";
import {
  addAssetVersionToEnv,
  getAssetAssetContainerById,
  getAssetVersions,
  getEnvironmentsAssets,
  getEnvironmentsAssetsByQuery,
  getAssetVersionsEnvIfno,
  publishAssetVersion,
  getAssetsDownloadUrls,
  getFilterDates,
} from "@services/assetContainer";
import { getAssetTypes } from "@services/systemService";
import "./css/assetcontainer.css";
import { getAllEnv } from "@services/EnvService";
import { convertDateToNestorTime } from "@utilities/datehelper";
import { getAssetState, getTargetPlatforms } from "@services/systemService";
import { AppObject } from "@context/AppObject";
import useAuth from "@hooks/useAuth";

const { Option } = Select;
const filterOption = {
  startDate: "startDate",
  endDate: "endDate",
  environment: "environment",
  targetPlatform: "targetPlatform",
};
const STATES = {
  DEFAULT: "DEFAULT",
  OFFLINE: "OFFLINE",
  LIVE: "LIVE",
  NOT_DEPLOYED: "Not-Deployed",
  PROCESSING: "PROCESSING",
};
const REFRESH_THRESHOLD = 3;
const AssetContainerDetail = (props) => {
  const [size, setSize] = useState("large"); // default is 'middle'
  const [isLoading, setLoading] = useState(true);
  const [skipModal, setSkipModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fetchingAssetContainer, setFetchingAssetContainer] = useState(true);
  const [fetchingAllEnv, setFetchingAllEnv] = useState(true);
  const [fetchingAllPlatform, setFetchingAllPlatform] = useState(true);
  const [fetchingAllEngines, setFetchingAllEngines] = useState(true);
  const [fetchingVersions, setfetchingVersions] = useState(true);
  const [assetContainer, setAssetContainer] = useState();
  const [allEnv, setAllEnv] = useState();
  const [gamePlatforms, setGamePlatforms] = useState();
  const [gameEngines, setAssetType] = useState();
  const [assetVersions, setAssetVersion] = useState();
  const [totalAssetVersion, setTotalAssetVersion] = useState();
  const [assetStates, setAssetStates] = useState();
  const [envAssetVersion, setEnvAssetVersion] = useState();
  const [visible, setVisible] = useState(false);
  const [envDataFields, setEnvDataFields] = useState([]);
  const [envItem, setEnvItem] = useState();
  const [selectedState, setSelectedState] = useState();
  const [filter, setFilter] = useState({});
  const [filterValues, setFilterValues] = useState({});
  const [successModalVisiblilty, setSuccessModalVisiblilty] = useState(false);
  const [failureModalVisiblilty, setFailureModalVisiblilty] = useState(false);
  const [releaseModalOpen, setReleaseModalOpen] = useState(false);
  const [selectedVersion, setSelectedVersion] = useState();
  const [attempt, setAttempt] = useState(0);
  const navigate = useNavigate();
  const params = useParams();
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [dateRange, setDateRange] = useState();
  const { hasAccess, ACTIONS } = useAuth();

  useEffect(() => {
    fetchAssetContainer();
    fetchEnvData();
    fetchAllPlatform();
    fetchAssetStates();
  }, []);

  useEffect(() => {
    if (assetContainer) {
      searchAssetVersion();
    }
  }, [filterValues]);

  const fetchEnvData = () => {
    getAllEnv()
      .then((res) => {
        if (res?.status === 200 && res?.data?.environments?.length) {
          setAllEnv(res.data?.environments);
        } else {
          message.error("Unable to load Environments");
        }
      })
      .catch((error) => {
        message.error("Unable to load Environments");
      })
      .finally(() => {
        setFetchingAllEnv(false);
      });
  };

  const fetchAssetContainer = () => {
    getAssetAssetContainerById(params?.id)
      .then((res) => {
        if (res?.status === 200) {
          setAssetContainer(res.data);
          fetchAssetVersions(res.data?.assetId);
          fetchGameEngines(res.data);
        } else {
          message.error("Unable to load Asset Details");
        }
      })
      .catch((error) => {
        message.error("Unable to Asset Details");
      })
      .finally(() => {
        setFetchingAssetContainer(false);
      });
  };

  const fetchAllPlatform = () => {
    getTargetPlatforms()
      .then((res) => {
        if (res?.status === 200) {
          setGamePlatforms(res?.data?.dataList);
        } else {
          message.error("Unable to load platforms");
        }
      })
      .catch((error) => {
        message.error("Unable to load platforms");
      })
      .finally(() => {
        setFetchingAllPlatform(false);
      });
  };

  const fetchGameEngines = (assetContainer) => {
    getAssetTypes()
      .then((res) => {
        if (res.status === 200) {
          const assetTypes = res.data;
          setAssetType(res.data);
          const assetType = assetTypes.find((assetType) => {
            return assetType._id === assetContainer?.assetType;
          });
          assetContainer.typeName = assetType?.typeName;
          setAssetContainer(assetContainer);
        } else {
          message.error("Unable to load assetTypes");
        }
      })
      .catch((error) => {
        message.error("Unable to load assetTypes");
      })
      .finally(() => {
        setFetchingAllEngines(false);
      });
  };
  const today = moment().endOf("day");
  const disabledStartDate = (current) => {
    // Disable all dates before today
    if (current && current > today) {
      return true;
    }

    return false;
  };

  const disabledEndDate = (current) => {
    // Disable all dates before todaycurrent) {current) {Of

    if (dateRange?.startDate && current > moment(dateRange?.startDate).endOf("day")) {
      return false;
    } else if (dateRange?.startDate && current < moment(dateRange?.startDate).startOf("day")) {
      return true;
    } else if (current && current > today) {
      return true;
    }

    return false;
  };
  const generateQuery = (assetId, filter = {}) => {
    const searchFilter = { ...filter };
    let envId;

    if (searchFilter.environment) {
      envId = searchFilter.environment;
      delete searchFilter.environment;
    }

    let query = Object.keys(searchFilter).map((filterKey) => {
      return `${filterKey}=${searchFilter[filterKey]}`;
    });
    query = query.join("&");

    query += `&assetId=${assetId}&envId=${envId || ""}&targetPlatform=${searchFilter?.platform || ""}`;
    return query;
  };

  const fetchAssetVersions = (assetId, filter) => {
    let query = generateQuery(assetId, filter);
    query += "&pageSize=10";
    getAssetVersions(query)
      .then((res) => {
        if (res.status === 200) {
          setAssetVersion([...res?.data?.results]);
          if (!filter?.targetPlatform || filter?.targetPlatform === "") {
            setTotalAssetVersion(res?.data?.results?.length);
          }
        } else {
          message.error("Unable to load asset versions");
        }
      })
      .catch((error) => {
        message.error("Unable to load asset versions");
      })
      .finally(() => {
        setfetchingVersions(false);
      });
  };

  const fetchEnvironmentAsset = (assetId, filter) => {
    getEnvironmentsAssets(generateQuery(assetId, filter))
      .then((res) => {
        if (res.status === 200) {
          setAssetVersion([...res?.data?.assetVersions]);
          refreshVersionsIfProcessing(res?.data?.assetVersions);
        } else {
          message.error("Unable to load asset versions");
        }
      })
      .catch((error) => {
        message.error("Unable to load asset versions");
      })
      .finally(() => {
        setfetchingVersions(false);
      });
  };

  const fetchAssetStates = () => {
    getAssetState("INTENT-STATE")
      .then((res) => {
        if (res.status === 200) {
          setAssetStates(res?.data?.states);
        } else {
          message.error("Unable to fetch Asset States");
        }
      })
      .catch((error) => {
        message.error("Unable to fetch Asset States");
      });
  };

  const getPlatormId = (systemPlatformId) =>
    gamePlatforms?.find((platform) => platform?.platformId === systemPlatformId);

  const deployAsset = (envItem, intent) => {
    try {
      const { assetContainerId, version, targetPlatform } = props?.assetVersion;
      const releaseAssetBody = {
        assetContainerId,
        version,
        targetPlatform,
        envId: envItem.data.envId,
        intent,
      };

      addAssetVersionToEnv(releaseAssetBody)
        .then((res) => {
          if (res.status === 201) {
            const envIndex = envItem.key;
            const envData = envDataFields[envIndex];
            envData.badge = selectedState;
            setEnvDataFields([...envDataFields]);
            setVisible(false);
            setSuccessModalVisiblilty(true);
          } else {
            setFailureModalVisiblilty(true);
          }
        })
        .catch((err) => {
          setFailureModalVisiblilty(true);
        });
    } catch (err) {
      setFailureModalVisiblilty(true);
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const onFilterApply = () => {
    setIsModalOpen(false);
    setReleaseModalOpen(false);
    // filterAssetsUsingDates();
    setFilterValues(getFilterValues());
  };

  const searchAssetVersion = () => {
    if (filter?.environment) {
      // get environment asset version
      fetchEnvironmentAsset(assetContainer?.assetId, filter);
    } else {
      //get all asset version
      fetchAssetVersions(assetContainer?.assetId, filter);
    }
  };

  const refreshVersionsIfProcessing = (assetVersions) => {
    const isProcessing = assetVersions?.some((version) => version?.state?.includes(STATES.PROCESSING));
    if (assetVersions?.length > 0 && isProcessing && attempt < REFRESH_THRESHOLD) {
      setTimeout(() => {
        handleVersionRefreshing(assetVersions);
      }, 3000);
    } else {
      setAttempt(0);
    }
  };

  const handleVersionRefreshing = (assetVersions) => {
    if (filter?.environment) {
      fetchEnvironmentAsset(assetContainer?.assetId, filter);
      setAttempt(1 + attempt);
    }
  };

  const handleCancel = () => {
    setFilter({});
    setIsModalOpen(false);
    setReleaseModalOpen(false);
    form.resetFields();
    setSelectedVersion();
  };
  const handleReleaseCancel = () => {
    setReleaseModalOpen(false);
    setSelectedVersion();
  };

  const releaseAssetsModal = (assetVersion) => {
    setReleaseModalOpen(true);
    setSelectedVersion(assetVersion);
  };

  const getFilterValues = () => {
    const keyValues = Object.entries(filter);
    if (keyValues.length) {
      return keyValues.map(([key, value]) => value);
    }
  };

  const layout = {
    labelCol: {
      span: 6,
    },
    wrapperCol: {
      span: 17,
    },
  };

  const onFilterSelect = (filterType, value) => {
    if (value) {
      setFilter({ ...filter, [filterType]: value });
    } else {
      //if value cleared
      delete filter[filterType];
      setFilter({ ...filter });
    }
  };

  const onItemClear = (filterValue) => {
    //find and delete filter by value
    const keyValues = Object.entries(filter);
    const indexOfFilter = keyValues.findIndex(([key, value]) => value === filterValue);
    if (indexOfFilter >= 0) {
      const [key, value] = keyValues[indexOfFilter];
      delete filter[key];
      setFilter({ ...filter });
      //delete filteredValues value
      filterValues.splice(filterValues.indexOf(value), 1);
      setFilterValues([...filterValues]);

      //clear filter field
      form.setFieldValue(key);
    }
  };
  // console.log(assetVersions);

  // Callback for date range change
  const handleStartDate = (startDate) => {
    onFilterSelect(filterOption.startDate, new Date(startDate).toISOString());
    setDateRange({ ...dateRange, startDate: new Date(startDate).toISOString() });
  };
  const handleEndDate = (endDate) => {
    onFilterSelect(filterOption.endDate, new Date(endDate).toISOString());
    setDateRange({ ...dateRange, endDate: new Date(endDate).toISOString() });
  };

  // const filterAssetsUsingDates = () => {
  //   if (filter.environment) {
  //     getEnvironmentsAssets(
  //       filter.environment,
  //       "assetId=" + assetContainer.assetId + "&startDate=" + dateRange.startDate + "&endDate=" + dateRange.endDate
  //     )
  //       .then((res) => {
  //         console.log(res);
  //         setLoading(false);
  //         setAssetVersion(res?.data?.assetVersions);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   } else {
  //     getAssetVersions(
  //       "assetId=" + assetContainer.assetId + "&startDate=" + dateRange.startDate + "&endDate=" + dateRange.endDate
  //     )
  //       .then((res) => {
  //         console.log(res);
  //         setLoading(false);
  //         setAssetVersion(res?.data?.assetVersions);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // };

  // Apply filter when date range changes

  const onAllFilterClear = () => {
    setFilter({});
    setFilterValues([]);
    form.resetFields();
    fetchAssetVersions(assetContainer?.assetId);
    fetchAssetStates();
  };
  const getAssetVersionCardButtons = () => {
    const extras = [];
    if (hasAccess(AppObject.ASSET_DETAIL, ACTIONS.POST)) {
      extras.push(
        <Button
          style={{ height: "47px", marginRight: "10px" }}
          size={size}
          onClick={() => navigate("/assetVersions/addVersion", { state: { assetContainer } })}
        >
          <PlusOutlined />
        </Button>
      );
    }
    extras.push(
      <Button disabled={!!!assetVersions?.length} style={{ height: "47px" }} size={size} onClick={showModal}>
        <FilterOutlined />
      </Button>
    );
    return extras;
  };

  return (
    <React.Fragment>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/assetscontainer">Virtual Assets</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{assetContainer?.assetId}</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ paddingTop: "15px" }}>
        <Row
          gutter={[
            { xs: 8, sm: 16, md: 24, lg: 24 },
            { xs: 8, sm: 16, md: 24, lg: 24 },
          ]}
        >
          <Col lg={{ span: 16 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Card
              hoverable={false}
              className="asset-container"
              headStyle={{ borderBottom: "0px" }}
              bordered={false}
              title="Asset Details"
            >
              <Skeleton loading={!assetContainer} active>
                <Row>
                  <Col
                    lg={{ span: 14 }}
                    md={{ span: 12 }}
                    sm={{ span: 24 }}
                    xs={{ span: 24 }}
                    style={{ height: "100px", width: "100%", }}
                  >
                    <img
                      id="image"
                      src={assetContainer?.thumbnail || process.env.PUBLIC_URL + "/3d.svg"}
                      style={{ width: 200, height: 200, objectFit: "scale-down" }}
                      alt=""
                    />
                  </Col>
                  <Col lg={{ span: 10 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }} >
                    <div id="card-info">
                      <Title level={2} style={{ fontWeight: "300" }}>
                        {assetContainer?.assetId}
                        {assetContainer?.secured ? (
                          <Tooltip title={"Secured"}>
                            <SafetyCertificateOutlined className="secured" />
                          </Tooltip>
                        ) : (
                          <Tooltip title={"Not Secured"}>
                            <SafetyCertificateOutlined className="unsecured" />
                          </Tooltip>
                        )}
                      </Title>
                      <Title level={3} style={{ fontWeight: "300", marginTop: "0" }}>
                        {assetContainer?.assetName}{" "}
                      </Title>
                      <Title level={5} style={{ fontWeight: "400", marginTop: "0" }}>
                        {/* This is a gray ship for Space shooter galaxy game. */}
                        {assetContainer?.description}
                      </Title>
                      <Title level={5} style={{ fontWeight: "400", marginTop: "1em" }}>
                        Asset Type - {assetContainer?.typeName}
                      </Title>
                      <Title level={5} style={{ fontWeight: "400", marginTop: "1em" }}>
                        Total Versions - {totalAssetVersion}
                      </Title>
                      <Title level={5} style={{ fontWeight: "400", marginTop: "1em" }}>
                        {/* Local Cache - {assetContainer?.localCacheControl ? "Enabled" : "Disbaled"} */}
                        Local Cache - {assetContainer?.localCacheControl ? (
                          <span>
                            Enabled ({assetContainer.localCacheMaxAge} Min) <InfoCircleOutlined title="This is browser based local cache" />
                          </span>
                        ) : (
                          "Disbaled"
                        )}
                      </Title>

                    </div>
                  </Col>
                  {assetContainer && allEnv && <DefaultUrls environments={allEnv} assetContainer={assetContainer} />}
                </Row>
              </Skeleton>
            </Card>
          </Col>
          <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            <Card
              hoverable={false}
              headStyle={{ borderBottom: "0px", paddingTop: "10px" }}
              bordered={false}
              id="card-timeline"
              title={`Asset Versions`}
              subTitle={"Recent 10 versions"}
              loading={!(releaseAssetsModal && assetVersions)}
              extra={getAssetVersionCardButtons()}
            >
              {filterValues?.length > 0 && (
                <Space style={{ marginBottom: "30px", width: "100%" }}>
                  <FilterItems
                    filterValues={filterValues}
                    onItemClear={onItemClear}
                    onAllFilterClear={onAllFilterClear}
                  />
                </Space>
              )}

              <Modal
                title="Filters - Select Any filters"
                open={isModalOpen}
                onOk={onFilterApply}
                okText="Apply"
                onCancel={handleCancel}
                style={{ marginTop: 20 }}
              >
                <Form form={form} {...layout}>
                  <Form.Item
                    name="environment"
                    label="Environment"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Select
                      placeholder="Environment"
                      allowClear
                      onChange={(value) => onFilterSelect(filterOption.environment, value)}
                    >
                      {allEnv && allEnv.map((item) => <Option value={item?.envId}>{item?.envId}</Option>)}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="platform"
                    label="Target Platform"
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                  >
                    <Select
                      placeholder="Platform"
                      allowClear
                      onChange={(value) => onFilterSelect(filterOption.targetPlatform, value)}
                    >
                      {gamePlatforms &&
                        gamePlatforms.map((item) => <Option value={item?.platformId}>{item?.platformName}</Option>)}
                    </Select>
                  </Form.Item>
                  <Row style={{ width: "100%" }}>
                    <Col span={24}>
                      <Form.Item
                        name="startDate"
                        label="Start Date"
                        rules={[{ required: true, message: "Please select Start Date" }]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          placement="left"
                          placeholder="Start Date"
                          onChange={handleStartDate}
                          disabledDate={disabledStartDate}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item label="End Date" name="endDate">
                        <DatePicker
                          style={{ width: "100%" }}
                          disabledDate={disabledEndDate}
                          placeholder="End Date"
                          onChange={handleEndDate}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Modal>
              {selectedVersion && (
                <Modal
                  open={releaseModalOpen}
                  title={
                    <div>
                      <span>Version Deployment - </span>
                      <span
                        style={{ color: "green", cursor: "pointer" }}
                        onClick={() =>
                          navigate("assetversion/" + selectedVersion?.versionId, {
                            state: {
                              totalAssetVersion,
                              assetContainer,
                              assetVersion: selectedVersion,
                              platform: getPlatormId(selectedVersion?.targetPlatform),
                            },
                          })
                        }
                      >
                        {selectedVersion?.versionId}
                      </span>
                    </div>
                  }
                  okText={"Deploy"}
                  style={{ marginTop: 15 }}
                  onOk={() => {
                    deployAsset(envItem, selectedState);
                  }}
                  onCancel={handleReleaseCancel}
                  width={600}
                  footer={[
                    <Button style={{ float: "left" }} onClick={handleReleaseCancel}>
                      Cancel
                    </Button>,
                    <Button key="submit" type="primary" onClick={handleReleaseCancel}>
                      Done
                    </Button>,
                  ]}
                >
                  <VersionDeploymentTable
                    assetStates={assetStates}
                    allEnv={allEnv}
                    gamePlatforms={gamePlatforms}
                    assetVersion={selectedVersion}
                    assetContainer={assetContainer}
                    searchAssetVersion={searchAssetVersion}
                    filter={filter}
                  />
                </Modal>
              )}
              <AssetVersionTimeLine
                totalAssetVersion={totalAssetVersion}
                assetContainer={assetContainer}
                assetVersions={assetVersions}
                releaseAssetsModal={releaseAssetsModal}
                getPlatormId={getPlatormId}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

const FilterItems = (props) => {
  return (
    <div style={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
      {props?.filterValues?.map((value, index) => (
        <Tag
          style={{ display: "inline-block", fontSize: "14px", padding: "5px 10px", margin: 5 }}
          closable
          onClose={() => props?.onItemClear(value)}
        >
          {value}
        </Tag>
      ))}
      {props?.filterValues?.length ? (
        <a
          style={{ float: "right", color: "#00b96b", height: "30px", lineHeight: "30px" }}
          onClick={props?.onAllFilterClear}
        >
          Clear Filter
        </a>
      ) : null}
    </div>
  );
};

const AssetVersionTimeLine = (props) => {
  const isAssetVersionAvailable = props?.assetVersions?.length > 0;
  const navigate = useNavigate();
  const getIconInfo = (state) => {
    if (state?.includes(STATES.PROCESSING)) {
      return {
        color: "#ccc",
        dot: <LoadingOutlined />,
      };
    }
    switch (state) {
      case STATES.DEFAULT:
        return {
          color: "green",
          dot: <CheckCircleFilled />,
        };
      case STATES.LIVE:
        return {
          color: "green",
        };
      case STATES.OFFLINE:
        return {
          color: "#ccc",
          dot: <StopOutlined />,
        };
      default:
        return {
          color: "#ccc",
        };
    }
  };
  return (
    <React.Fragment>
      {isAssetVersionAvailable ? (
        <Timeline>
          {props?.assetVersions.map((assetVersion) => {
            const iconInfo = getIconInfo(assetVersion?.state);
            return (
              <Timeline.Item className="ant-timeline-item-list" color={iconInfo?.color} dot={iconInfo?.dot}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    props?.releaseAssetsModal(assetVersion);
                  }}
                >
                  {assetVersion?.versionId}
                </span>
                <span className="timeline-text" style={{ marginLeft: 5 }}>
                  {convertDateToNestorTime(assetVersion?.createdAt)}
                </span>
                <span
                  onClick={() =>
                    navigate("assetversion/" + assetVersion.versionId, {
                      state: {
                        totalAssetVersion: props.totalAssetVersion,
                        assetContainer: props.assetContainer,
                        assetVersion,
                        platform: props.getPlatormId(assetVersion?.targetPlatform),
                      },
                    })
                  }
                  style={{
                    color: "#ccc",
                    position: "absolute",
                    left: "90%",
                    top: "-1.8px",
                    cursor: "pointer",
                  }}
                >
                  <ExportOutlined />
                </span>
              </Timeline.Item>
            );
          })}
        </Timeline>
      ) : (
        <Empty
          style={{ marginTop: 50 }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No Asset Version Available"
        />
      )}
      {!(props?.assetVersions?.length < 10) ? (
        <Button
          style={{ width: "100%" }}
          onClick={() => navigate("/assetscontainer/table/" + props?.assetContainer?.assetId)}
        >
          Show All
        </Button>
      ) : null}
    </React.Fragment>
  );
};

const VersionDeploymentTable = (props) => {
  const [isVersionInfoLoaded, setVersionInfoLoaded] = useState(false);
  const [envAssetVersions, setEnvAssetVersions] = useState();
  const [dataSource, setDataSource] = useState();
  const [items, setItems] = useState();
  const [itemsWithOutDefault, setItemsWithOutDefault] = useState();
  const [warningMsg, setWarningMsg] = useState();
  const [currentVersionPlatform, setCurrentVersionPlatform] = useState();
  const { hasAccess, ACTIONS } = useAuth();

  useEffect(() => {
    prepareItems();
    const assetContainerId = props?.assetContainer?.assetId;
    const assetVersion = props?.assetVersion;
    const platform = props?.gamePlatforms?.find((platform) => platform.platformId === assetVersion?.targetPlatform);
    setCurrentVersionPlatform(platform);
    getAssetVersionsEnvIfno(assetContainerId, platform?.platformId, assetVersion?.version)
      .then((res) => {
        if (res?.status === 200) {
          setEnvAssetVersions(res?.data?.assetVersions);
          setDataSource(generateDataSource(res?.data?.assetVersions));
        } else {
          message.error("Unable to load version status");
        }
      })
      .catch((error) => {
        message.error("Unable to load version status");
      })
      .finally(() => {
        setVersionInfoLoaded(true);
      });
  }, []);

  const columns = [
    {
      title: "Environment",
      dataIndex: "environment",
      key: "environment",
      render: (text) => <span>{text}</span>,
      width: "30%",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      width: "20%",
      render: (state, record, index) => (
        <div style={{ width: "80px" }}>
          {record.newState || state}
          {hasAccess(AppObject.ASSET_VERSION_STATE, ACTIONS.PUT) && state != STATES.DEFAULT && (
            <Dropdown
              menu={{
                items: getCorrectItems(state),
                style: { width: "125px" },
                onClick: (e) => {
                  onStateChange(index, record, e);
                },
              }}
              trigger={["click"]}
              placement="bottomLeft"
              arrow={{ pointAtCenter: true }}
            >
              <a style={{ color: "#000", float: "right" }} href="#">
                <EditOutlined />
              </a>
            </Dropdown>
          )}
        </div>
      ),
    },
    {
      width: "45%",
      render: (state, record, index) => {
        return <MsgArea state={state} record={record} index={index} />;
      },
    },
  ];

  const OldDefaultStateDropDown = ({ record = {}, index }) => {
    return (
      <Dropdown
        menu={{
          items: itemsWithOutDefault,
          selectable: true,
          onClick: (e) => {
            onOldVersionStateChange(index, record, e);
          },
        }}
        trigger={["click"]}
      >
        <Space className="versionDeployment" style={{ width: 100 }}>
          {record?.oldDefaultVersion?.versionId + " : " + (record?.oldVersionState ? record?.oldVersionState : "")}
          <EditOutlined />
        </Space>
      </Dropdown>
    );
  };

  const generateDataSource = (assetVersions) => {
    return props?.allEnv.map((env) => {
      return {
        key: env._id,
        environment: env.envId,
        state: getStateByEnv(assetVersions, env.envId),
        env,
      };
    });
  };

  const showSuccessMsg = (msg, dataSource, index) => {
    dataSource[index].successMsg = msg;
    setDataSource([...dataSource]);
    setTimeout(() => {
      dataSource[index].successMsg = "";
      setDataSource([...dataSource]);
    }, 5000);
  };

  const getStateByEnv = (assetVersions, envId) => {
    const assetVersionEnvSpecific = assetVersions.find((assetEnvVersion) => assetEnvVersion.envId === envId);
    return assetVersionEnvSpecific?.state || STATES.NOT_DEPLOYED;
  };

  const onStateChange = (dataSourceIndex, record, e) => {
    if (props?.assetStates[e?.key] === STATES.DEFAULT) {
      if (dataSource[dataSourceIndex].state === STATES.OFFLINE) {
        setWarningMsg("OFFLINE asset cannot be marked as DEFAULT");
      } else {
        handleDefaultLiveRequest(dataSourceIndex, record, e);
      }
      return;
    }
    setWarningMsg();
    dataSource[dataSourceIndex].newState = props?.assetStates[e?.key];
    setDataSource([...dataSource]);
  };

  const onOldVersionStateChange = (dataSourceIndex, record, e) => {
    setWarningMsg();
    dataSource[dataSourceIndex].oldVersionState = props?.assetStates[e?.key];
    setDataSource([...dataSource]);
  };

  const handleDefaultLiveRequest = (dataSourceIndex, record, e) => {
    dataSource[dataSourceIndex].newState = props?.assetStates[e?.key];
    const query = `assetId=${props?.assetContainer?.assetId}&targetPlatform=${currentVersionPlatform._id}&state=${STATES.DEFAULT}&envId=${record?.env?.envId}`;
    getEnvironmentsAssets(query)
      .then((res) => {
        if (res.status === 200) {
          if (res.data?.assetVersions?.length > 0) {
            //default assetPresent
            setWarningMsg("Please select state for asset version which was marked as DEFAULT earlier");
            dataSource[dataSourceIndex].isOldDefaultVersionStateRequired = true;
            dataSource[dataSourceIndex].oldDefaultVersion = res.data?.assetVersions[0];
            setDataSource([...dataSource]);
          } else {
            //no default asset. safe for release
            dataSource[dataSourceIndex].isOldDefaultVersionStateRequired = false;
            setDataSource([...dataSource]);
          }
        } else {
          message.error("Unable to load asset versions");
        }
      })
      .catch((error) => {
        message.error("Unable to load asset versions");
      })
      .finally(() => { });
  };

  const prepareItems = () => {
    const items = props?.assetStates?.map((state, index) => {
      return {
        label: state,
        key: index,
      };
    });
    setItems(items);
    prepareWithOutDefault(items);
  };

  const prepareWithOutDefault = (items) => {
    const index = items?.indexOf(STATES.DEFAULT);
    const newItems = [...items];
    newItems.splice(index, 1);

    setItemsWithOutDefault(newItems);
  };

  const getCorrectItems = (state) => {
    if (state === STATES.OFFLINE || state === STATES.NOT_DEPLOYED) {
      return itemsWithOutDefault;
    }
    return items;
  };

  const deployAsset = (state, record, index) => {
    if (record.state === STATES.NOT_DEPLOYED) {
      depoyAssetInEnvironment(state, record, index, addAssetVersionToEnv);
    } else {
      depoyAssetInEnvironment(state, record, index, publishAssetVersion);
    }
  };

  const depoyAssetInEnvironment = (state, record, index, releaseAssetInEnv) => {
    const { assetContainerId, version } = props?.assetVersion;
    const releaseAssetBody = {
      assetContainerId,
      version,
      targetPlatform: currentVersionPlatform._id,
      envId: record.environment,
      intent: record?.newState,
      oldVersion: record?.oldDefaultVersion?.version || "",
      oldAssetIntent: record?.oldVersionState || "",
    };
    dataSource[index].isLoading = true;
    setDataSource([...dataSource]);
    releaseAssetInEnv(releaseAssetBody)
      .then((res) => {
        if (res?.status === 201) {
          const env = dataSource[index].env;
          dataSource[index] = {
            key: env?._id,
            environment: env?.envId,
            state: record?.newState,
            env,
          };
          setWarningMsg("");
          showSuccessMsg("Succefully Deployed!", dataSource, index);
          props?.searchAssetVersion();
        } else {
          setWarningMsg("Unable to deploy asset.");
          dataSource[index].isLoading = false;
          setDataSource([...dataSource]);
        }
      })
      .catch((error) => {
        setWarningMsg("Unable to deploy asset.");
        dataSource[index].isLoading = false;
        setDataSource([...dataSource]);
      });
  };

  const MsgArea = ({ record = {}, state = "", index }) => {
    // console.log(`env:${record?.environment}:record:${JSON.stringify(record)}`)

    if (
      record.isOldDefaultVersionStateRequired &&
      record?.newState === STATES.DEFAULT &&
      record?.state != STATES.DEFAULT
    ) {
      return (
        <div>
          {
            <Space>
              <OldDefaultStateDropDown record={record} state={state} index={index} />
              {record?.oldVersionState && (
                <Button
                  loading={record?.isLoading}
                  type="primary"
                  onClick={() => {
                    deployAsset(state, record, index);
                  }}
                >
                  Deploy
                </Button>
              )}
            </Space>
          }
        </div>
      );
    } else if (record?.newState && record?.newState != record?.state) {
      return (
        <Button
          loading={record?.isLoading}
          type="primary"
          onClick={() => {
            deployAsset(state, record, index);
          }}
        >
          Deploy
        </Button>
      );
    } else if (record?.successMsg) {
      return (
        <div>
          <p style={{ color: "green", margin: "0px" }}>{record?.successMsg}</p>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <React.Fragment>
      <p style={{ color: "red" }}>{warningMsg}</p>
      <Table columns={columns} dataSource={dataSource} pagination={false} />
    </React.Fragment>
  );
};

const DefaultUrls = (props) => {
  const [selectedEnv, setSelectedEnv] = useState(props?.environments[0]);
  const [isCopied, setCopied] = useState();
  const [isLoading, setLoading] = useState(false);
  const [rotate, setRotate] = useState(false);
  const [urlInfo, setUrlInfo] = useState();
  const [data, setData] = useState();

  useEffect(() => {
    fetchDownloadUrls(selectedEnv);
  }, [selectedEnv]);

  const rotateBtn = () => {
    setRotate(true);
    setData(null);
    setTimeout(() => {
      setRotate(false);
    }, 400);
  };

  const fetchDownloadUrls = (environment) => {
    rotateBtn();
    setLoading(true);
    getAssetsDownloadUrls(props?.assetContainer?.assetId, `envId=${environment?.envId}&state=DEFAULT`)
      .then((res) => {
        if (res.status === 200) {
          setUrlInfo(res.data);
          prepareData(res.data);
        }
      })
      .catch((err) => {
        return (err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCopy = (index) => {
    setCopied(index);
    setTimeout(() => {
      setCopied();
    }, 1000);
  };

  const prepareData = (urlInfo) => {
    let dataList = [];
    setSingleDownloadUrlData(urlInfo, dataList, "android", <AndroidFilled />);
    setSingleDownloadUrlData(urlInfo, dataList, "ios", <AppleFilled />);
    setSingleDownloadUrlData(urlInfo, dataList, "web", <LaptopOutlined />);
    setSingleDownloadUrlData(urlInfo, dataList, "generic", <FileDoneOutlined />);
    setData(dataList);
  };

  const setSingleDownloadUrlData = (urlInfo, dataList, fieldName, icon) => {
    if (urlInfo[fieldName]?.length) {
      const urlData = urlInfo[fieldName][0];
      dataList.push({
        title: urlData?.versionId,
        description: urlData?.downloadUrl,
        avatar: (
          <Avatar
            size={64}
            style={{ color: "black", height: "44px", background: "transparent", fontSize: "42px", lineHeight: "35px" }}
            icon={icon}
          />
        ),
      });
    }
  };
  const onEnvironmentSelection = (value) => {
    let envIdFiltered = props.environments.filter((item) => {
      return item.envId === value;
    });
    setSelectedEnv(envIdFiltered[0]);
  };
  return (
    <React.Fragment>
      <Col
        lg={{ span: 24 }}
        md={{ span: 24 }}
        sm={{ span: 24 }}
        xs={{ span: 24 }}
        style={{ height: "auto", borderTop: "1px solid #eee", marginTop: "20px", marginLeft: "4px" }}
      >
        <div
          style={{
            fontSize: "16px",
            marginBottom: "10px",
            marginTop: "20px",
            marginRight: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <strong>Default URLs</strong>
          <div className={rotate ? "rotate-on-click" : ""}>
            <RefreshButton onClick={() => fetchDownloadUrls(selectedEnv)} />
          </div>
        </div>
        <div
          style={{
            fontSize: "16px",
            marginBottom: "20px",
            marginTop: "10px",
            marginRight: "30px",
            display: "inline-block",
            fontWeight: "500",
          }}
        >
          Select an Environment
        </div>
        <Select
          placeholder="Environment"
          onChange={onEnvironmentSelection}
          value={selectedEnv.envId}
          defaultValue={selectedEnv.envId}
          // animation=
          style={{ width: 200 }}
        >
          {props?.environments?.map((env, index) => {
            return (
              <Option key={index} value={env?.envId}>
                {env?.envId}
              </Option>
            );
          })}
        </Select>
      </Col>
      <Skeleton loading={!data} active>
        <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ height: "auto" }}>
          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item, index) => (
              <List.Item style={{ padding: "15px 0px" }}>
                <List.Item.Meta
                  avatar={<a href="#">{item.avatar}</a>}
                  title={<a href="#">{item.title}</a>}
                  description={<span style={{ overflowWrap: "anywhere" }}>{item.description}</span>}
                />
                <CopyToClipboard onCopy={() => handleCopy(index)} key={index} text={item.description}>
                  <div className={Style["modal-container"]}>
                    <div className={Style["clip-board-container"]}>
                      {isCopied == index ? (
                        <Tooltip title="Copied">
                          <CopyFilled className={Style["clip-board"]} />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Copy Url">
                          <CopyOutlined className={Style["clip-board"]} />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </CopyToClipboard>
              </List.Item>
            )}
          />
        </Col>
      </Skeleton>
    </React.Fragment>
  );
};

export default AssetContainerDetail;
