import React, { useState, useEffect } from "react";
import { PageHeader } from '@ant-design/pro-layout';
import { Button, Table, Tag, message, Modal, Descriptions } from "antd";
import { getAssetBySubscriptionId } from "@services/assetService";
import { Link } from "react-router-dom";


const AssetDetails = (props) => {

  const [assetsURLData, setAssetsURLData] = useState([]);

  const fetchAssetBySubscriptionId = (subscriptionId) => {
    getAssetBySubscriptionId(subscriptionId).then((res) => {
     if(res.status === 200  && res.data?.assetsURLData) 
      {
        setAssetsURLData(res.data.assetsURLData);
      }
    }).catch(error => message.error("Unable to fetch the assets api "+error)) 
  };

  useEffect(() => {
    fetchAssetBySubscriptionId(props.subscriptionId);
  }, []);

  return (
    (<React.Fragment>
      <Modal title="Download Asset API" open={props.isPopupVisible} onOk={props.handleOk} onCancel={props.handleCancel} width={650}>
      <Descriptions
      bordered={true}
      style={{
        width: 500,
        margin: "auto",
        marginTop: 10,
        marginBottom: 40,
        borderColor: "#fff"
      }}>
      {assetsURLData.filter(asset => asset.assetId.includes(props.assetId)).map(filterAsset => (
        <Descriptions.Item label={filterAsset.assetId} span={3}> 
        {filterAsset.url}  
      </Descriptions.Item>
      ))}
      </Descriptions>
    </Modal>
    </React.Fragment>)
  );
};

export default AssetDetails