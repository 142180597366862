import React from "react";
import { Modal } from "antd";

const GenericConfirmModal = props => {
  return (
    (<React.Fragment>
      <Modal
        title={props.title || "Confirm"}
        draggable={props.draggable}
        open={props.open}
        onOk={() => props.onOk()}
        onCancel={() => props.onClose()}
        {...props}
      >
        {props.children}
      </Modal>
    </React.Fragment>)
  );
};

export default GenericConfirmModal;
