import React, { useState, createElement, useEffect } from "react";

import { Button, Layout, Menu, Tag } from "antd";
import GenericConfirmModal from "@dashboardmodals/GenericConfirmModal";
import { useNavigate, useLocation } from "react-router-dom";
import { signout } from "@auth";
import Icon from "@ant-design/icons";
import { StringHelper } from "@utilities";
import { PRODUCTION } from "@localconfig/envconfig";
import { getUserRoleById } from "@services/systemService";
import { MenuUnfoldOutlined, MenuFoldOutlined, LogoutOutlined } from "@ant-design/icons";
import { getMenuItem } from "./sidebarItems";
import useAuth from "@hooks/useAuth";

const Sidebar = (props) => {

  const { auth } = useAuth();
  let location = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [currentPath, setCurrentPath] = useState("dashboard");
  const { Header, Content, Sider } = Layout;
  const [role, setRole] = useState({});

  let navigate = useNavigate();
  const toggle = () => {
    setCollapsed(!collapsed);
  };
  const onLogout = () => {
    signout().then(() => {
      navigate("/");
    });
  };

  const onClick = (e) => {
    setCurrentPath(e.key);
  };

  const getRole = () => {
    getUserRoleById(auth.user.roles[0])
      .then((response) => {
        if (response.data?.role) {
          setRole(response.data.role);
        } else {
          console.error("Role was not found!");
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getRole();
  }, []);

  useEffect(() => {
    const activeRoute = location?.pathname.split("/")[1];
    if (currentPath !== activeRoute) {
      setCurrentPath(activeRoute);
    }
  }, [currentPath, location]);

  return (
    <Layout>
      <Sider
        trigger={null}
        breakpoint="md"
        collapsedWidth="80"
        collapsible
        onBreakpoint={(broken) => {
          if (broken) {
            setCollapsed(true);
          }
        }}
        collapsed={collapsed}
        style={{
          overflow: "auto",
          height: "100vh",
          position: "fixed",
          left: 0,
          top: 0,
          bottom: 0,
          background: "#fff",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
          <div>
            <div className="brand" style={{ textAlign: "center" }}>
              {collapsed ? (
                <img src="/N-logo.png" alt="" style={{ height: 50, paddingTop: 20 }} />
              ) : (
                <img src="/nestor_text.png" alt="" style={{ height: 50, paddingTop: 20 }} />
              )}
            </div>

            <Menu
              onClick={onClick}
              style={{ marginTop: 50 }}
              selectedKeys={[currentPath]}
              mode="inline"
              items={getMenuItem(auth.user.roles[0])}
            />
          </div>
          <div hidden={collapsed} style={{ display: "flex", flexDirection: "column", gap: 10, marginBottom: 20 }}>
            <Tag style={{ width: "min-content", margin: "0 auto" }} color="blue">
              v({process.env.REACT_APP_VERSION_NO})
            </Tag>
            
          </div>
        </div>
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: collapsed ? 80 : 200, paddingBottom: 24 }}>
        <Header
          className="site-layout-background"
          style={{
            zIndex: 1,
            width: "100%",
            boxShadow: "3px 0px 6px rgb(0 0 0 / 20%)",
            height: 50,
          }}
        >
          {createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
            className: "trigger",
            onClick: toggle,
          })}
          <div style={{ width: 235, float: "right" }}>
            <Icon
              style={{
                float: "right",
                // marginRight: collapsed ? "70px" : "0px"
                fontSize: "20px",
                marginTop: 15,
              }}
              onClick={() => setOpenConfirmModal(true)}
              component={LogoutOutlined}
              className="logout-icon"
            />
            <GenericConfirmModal
              open={openConfirmModal}
              title={"Are you sure you want to Logout?"}
              onClose={() => setOpenConfirmModal(false)}
              footer={[
                <Button onClick={() => setOpenConfirmModal(false)}>Cancel</Button>,
                <Button onClick={onLogout} type="primary">
                  Yes
                </Button>,
              ]}
            />
            <Tag style={{ float: "right", marginLeft: "30%", marginTop: 15 }} color="default">
              {StringHelper.toFirstLetterUpperCase(role.roleName)}
            </Tag>
          </div>
        </Header>
        <Content style={{ minHeight: "100vh" }}>
          <div style={{ padding: "32px 32px 0" }}>{props.children}</div>
        </Content>
      </Layout>
    </Layout>
  );
};

export default Sidebar;
