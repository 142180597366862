import "../css/form.css";

import { PageHeader } from "@ant-design/pro-layout";
import { Form, message, Modal, Button, Breadcrumb } from "antd";
import { RefreshButton } from "@dashboard/buttons/RefreshButton";
import { CancelButton } from "@dashboard/buttons/CancelButton";
import React, { useEffect, useState } from "react";
import { getOrganization, updateOrganization } from "@services/organizationService";
import FormInputs from "./formInputs";
import { useNavigate, Link } from "react-router-dom";
const ModalShow = (props) => {
  return (
    <Modal
      title={"Message"}
      open={props.visible}
      footer={
        <Button type="primary" onClick={props.onOk}>
          Close
        </Button>
      }
      afterClose={props.afterClose}
      onCancel={props.onOk}
    >
      <p>{props.msg}</p>
    </Modal>
  );
};

const OrganizationUpdate = () => {
  let navigate = useNavigate();
  const [orgData, setOrgData] = useState({});
  const [form] = Form.useForm();

  const [formData, setFormData] = useState();
  const [loading, setLoading] = useState(false);
  const [modalVisible, setIsModalVisible] = useState(false);
  const [msg, setMsg] = useState("");

  const fetchOrgData = () => {
    getOrganization().then((res) => {
      let orgData = res?.data?.organization;
      setOrgData(orgData);
      setFormData(orgData);
      form.setFieldsValue({
        ...orgData,
      });
    });
  };

  const formInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const updatOrg = () => {
    setLoading(true);
    const data = {};
    for (const key in formData) {
      data[key] = formData[key];
    }
    updateOrganization(data)
      .then((res) => {
        if (res.status === 201) {
          setIsModalVisible(true);
          setMsg("Organization updated successfully");
          setLoading(false);
        } else {
          setIsModalVisible(true);
          setMsg("Error while updating organization");
          setLoading(false);
        }
      })
      .catch((error) => {
        return(error);
      });
  };
  const resetIt = () => {
    fetchOrgData();
  };

  useEffect(() => {
    fetchOrgData();
  }, []);
  let props = {
    formInputChange,

    formData: formData,
    form,
    updatOrg,
    loading,
  };
  return (
    <React.Fragment>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{orgData?.organizationName}</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ paddingTop: 15 }}>
        <ModalShow
          visible={modalVisible}
          msg={msg}
          onOk={() => setIsModalVisible(false)}
          afterClose={() => {
            navigate("/organization");
          }}
        />
        <PageHeader
          style={{ alignSelf: "flex-start", background: "#fff" }}
          title="Organization Details Update"
          extra={[
            <RefreshButton key={"1"} onClick={() => resetIt()} />,
            <CancelButton onClick={() => navigate("/organization")} key={"2"} />,
          ]}
        />
        <div className={"main-container"}>
          <FormInputs {...props} />
        </div>
      </div>
    </React.Fragment>
  );
};

export default OrganizationUpdate;
