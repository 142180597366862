import React, { useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { Button, message, Form, Input, Upload, Skeleton, Modal, Breadcrumb, Switch } from "antd";
import { useNavigate, useParams, Link } from "react-router-dom";
import { CancelButton } from "@dashboard/buttons/CancelButton";
import { RefreshButton } from "@dashboard/buttons/RefreshButton";
import { InboxOutlined } from "@ant-design/icons";
import { UpdateEnv as updateEnvironment, getEnvById, UpdateImage } from "../../../../services/EnvService";
import GenericFormContainer from "../GenericFormContainer";
import "./css/EnvForm.css";
const { Dragger } = Upload;

const UpdateEnv = () => {
  const [formData] = Form.useForm();
  const [formField, setForm] = useState();
  const [envData, setEnvData] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  // const [env, setEnv] = useState();
  const [isLoading, setLoading] = useState(false);
  const [successModalVisiblilty, setSuccessModalVisiblilty] = useState(false);
  const [failureModalVisiblilty, setFailureModalVisiblilty] = useState(false);
  const [thumbnail, setThumbnail] = useState();
  const [status, setStatus] = useState("");
  const [statusText, setStatusText] = useState("");
  const [uploadModel, setUploadModel] = useState(false);
  const [disabledB, setDisabledB] = useState(true);
  const [buttonLoading, setButtonLoad] = useState(false);
  const [cacheAge, setCacheAge] = useState();
  const [cacheError, setCacheError] = useState("");
  const [cacheEnabled, setCacheEnabled] = useState(false);
  const navigate = useNavigate();
  const { envId } = useParams();
  useEffect(() => {
    formData.setFieldsValue({ active: true });
  }, []);

  const uploadFileProps = (fieldName) => {
    return {
      onRemove: (file) => {
        setForm({ ...formField, [fieldName]: null });
        setDisabledB(true);
      },
      beforeUpload: (file) => {
        const fsize = file.size;
        const img = Math.round(fsize / 1024);
        if (img > 1024) {
          setStatus("error");
          setForm({ ...formField, [fieldName]: null });
          setStatusText("The file size must be 1mb");
          setIsFormValid(false);
          setDisabledB(false);
          return true;
        } else {
          setStatus("success");
          setForm({ ...formField, [fieldName]: file });
          setDisabledB(true);

          setStatusText("");

          setIsFormValid(true);
        }
        return false;
      },

      maxCount: 1,
      listType: "picture",
      status: status,
    };
  };

  const handlePrivateMaxAge = (e) => {
    const inputValue = e.target.value;

    if (inputValue === "" || isNaN(inputValue)) {
      setCacheError("Please enter a valid number");
    } else if (Number(inputValue) > 525600 || Number(inputValue) < 30) {
      setCacheError("Cache Age cannot be more than 525,600 min (365 days) or less than 30 min");
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
      setCacheAge(inputValue);
      setCacheError("");
    }
  };

  const updateThumnail = () => {
    const data = new FormData();
    for (let key in formField) {
      data.append(key, formField[key]);
    }
    UpdateImage(envId, data).then((res) => {
      if (res?.status === 201) {
        setSuccessModalVisiblilty(true);
        setThumbnail(res.data?.envImageURL);
      } else {
        // message.error("Sorry! We are unable to create your asset right now.")
        setFailureModalVisiblilty(true);
      }
    });
  };

  const checkFormValidity = () => {
    const hasError = formData.getFieldsError().some(({ errors }) => errors.length > 3);
    if (cacheEnabled) {
      const { envId, envName, description, localCacheControl, localCacheMaxAge } = formData.getFieldsValue();
      const isFormCompleted = envId?.trim() && envName?.trim();
      const isValid = !hasError && isFormCompleted;
      setForm({ ...formField, envId, envName, description, localCacheControl, localCacheMaxAge });
      setIsFormValid(isValid);
    } else {
      const { envId, envName, description } = formData.getFieldsValue();
      const isFormCompleted = envId?.trim() && envName?.trim();
      const isValid = !hasError && isFormCompleted;
      setForm({ ...formField, envId, envName, description });
      setIsFormValid(isValid);
    }
  };

  const update = () => {
    setButtonLoad(true);
    const data = new FormData();
    for (let key in formField) {
      data.append(key, formField[key]);
    }
    updateEnvironment(envId, data)
      .then((res) => {
        if (res?.status === 201) {
          setSuccessModalVisiblilty(true);
        } else {
          // message.error("Sorry! We are unable to create your asset right now.")
          setFailureModalVisiblilty(true);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setFailureModalVisiblilty(true);
      });
  };

  const onSubmit = () => {
    update();
  };

  const navigateToAssetList = () => {
    navigate(-1);
    setFailureModalVisiblilty(false);
    setSuccessModalVisiblilty(false);
  };

  const createMoreEnvironment = () => {
    setSuccessModalVisiblilty(false);
    formData.resetFields();
  };
  const tryAgain = () => {
    setFailureModalVisiblilty(false);
  };
  useEffect(() => {
    getEnvById(envId)
      .then((res) => {
        if (res.status === 200 && res.data) {
          setEnvData(res?.data);
          formData.setFieldsValue({
            envId,
            envName: res.data?.envName,
            description: res.data?.description || "",
            localCacheControl: res.data?.localCacheControl,
            localCacheMaxAge: res.data?.localCacheMaxAge,
          });
          setCacheAge(res.data?.cacheAge);
          setCacheEnabled(res.data?.localCacheControl);

          setThumbnail(res.data?.envImageURL);
        }
        setLoading(false);
      })
      .catch((err) => {
        message.error("Unable to load Environment.Please try again later");
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <GenericFormContainer
        formData={formData}
        path="environments"
        formName={"Update Environment"}
        breadcrumbPath={"envUpdatePage"}
        navigate={() => navigate("/environments")}
      >
        <Skeleton loading={false}>
          <Form
            form={formData}
            labelCol={{ span: 5 }}
            onFieldsChange={() => {
              checkFormValidity();
            }}
            wrapperCol={{ span: 14 }}
            className={"form"}
          >
            <Form.Item label="Environment Id" name="envId">
              <Input style={{ height: 40, borderRadius: "10%" }} disabled />
            </Form.Item>
            <Form.Item
              label="Environment Name"
              name="envName"
              rules={[
                { required: true, message: "Please enter Environment Name" },
                { max: 64, min: 3, message: "character length must be between 3 to 64" },
                { pattern: `^[a-zA-Z]`, message: "Environment Id can only start  with alphabet " },
                { pattern: `[\\w]$`, message: "Environment name can have only alphanumeric and '_' characters" },
              ]}
            >
              <Input style={{ height: 40, borderRadius: "10%" }} />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ max: 4000, min: 0, message: "character length must be between 0 to 4000" }]}
            >
              <Input.TextArea name="description" style={{ height: 100 }} />
            </Form.Item>

            {thumbnail ? (
              <Form.Item label="Thumbnail">
              <img
                src={thumbnail}
                alt=""
                style={{ width: "100%", objectFit: "scale-down",borderRadius:"10px"}}
                onClick={() => setUploadModel(true)}
              />
              </Form.Item>
            ) : (
              <Form.Item label="Thumbnail" name="envImageURL">
                <Dragger {...uploadFileProps("envImageURL")}>
                  <UploadMsg subMsg="Only jpeg or png files are supported." />
                </Dragger>
              </Form.Item>
            )}

            <Modal
              open={uploadModel}
              title="Upload New Thumbnail"
              onOk={() => updateThumnail()}
              onCancel={() => setUploadModel(false)}
              okText="Upload"
              disabled={disabledB}
            >
              <Form.Item name="envImageURL" hasFeedback validateStatus={status} extra={statusText}>
                <Dragger
                  {...uploadFileProps("envImageURL")}
                  style={{ width: "100%", marginTop: 40, }}
                >
                  <UploadMsg subMsg="Only jpeg or png files are supported." />
                </Dragger>
              </Form.Item>
            </Modal>
            <Form.Item label="Local Cache" name="localCacheControl">
              <Switch
                style={{ alignSelf: "start" }}
                name="localCacheControl"
                value={cacheEnabled}
                onChange={setCacheEnabled}
              />
            </Form.Item>
            {cacheEnabled && (
              <Form.Item
                help={cacheError ? cacheError : "Default 30 minutes and Max 525,600 minutes (365 days)"}
                label="Local Cache Max Age"
                name="localCacheMaxAge"
                required
                style={{ marginBottom: 35 }}
                validateStatus={cacheError === "" ? "success" : "error"}
                // help={}
              >
                <Input
                  type="number"
                  style={{ height: 40, borderRadius: "100px", width: "100%" }}
                  name="localCacheMaxAge"
                  onChange={handlePrivateMaxAge}
                />
              </Form.Item>
            )}
            <Form.Item className="update-button">
              <Button
                shouldUpdate
                loading={buttonLoading}
                onClick={onSubmit}
                type="primary"
                htmlType="submit"
                className={"button"}
                disabled={!isFormValid}
              >
                Update
              </Button>
            </Form.Item>
          </Form>

          <SuccessModel
            visible={successModalVisiblilty}
            onRightClick={navigateToAssetList}
            onLeftClick={createMoreEnvironment}
          />
          <FailureModel visible={failureModalVisiblilty} onRightClick={navigateToAssetList} onLeftClick={tryAgain} />
        </Skeleton>
      </GenericFormContainer>
    </div>
  );
};

const UploadMsg = (props) => {
  return (
    <div>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">{props?.subMsg || ""}</p>
    </div>
  );
};

const SuccessModel = ({ visible, msg, onLeftClick, onRightClick }) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <Modal
      onCancel={goBack}
      open={visible}
      footer={[
        <Button type="primary" onClick={onRightClick} onOk={goBack}>
          Close
        </Button>,
      ]}
    >
      Environment has been updated successfully
    </Modal>
  );
};

const FailureModel = ({ visible, onLeftClick, onRightClick }) => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  return (
    <Modal
      onCancel={goBack}
      open={visible}
      footer={[
        <Button type="primary" onClick={onLeftClick}>
          Try Again
        </Button>,
        <Button type="primary" onClick={onRightClick}>
          Close
        </Button>,
      ]}
    >
      <p>Sorry! We are unable to update your environment right now.</p>
    </Modal>
  );
};

export default UpdateEnv;
