import React, { useEffect, useState } from "react";
import { Breadcrumb, Skeleton } from "antd";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { PageHeader } from "@ant-design/pro-layout";
import { CancelButton } from "../../buttons/CancelButton";
import GenericFormContainer from "../GenericFormContainer";
import { Button, message, Form, Input, Select, Switch, Tooltip, Modal, InputNumber } from "antd";
import { getAssetState, getAssetTypes, getTargetPlatforms } from "@services/systemService";
import { createAssetCollection } from "@services/assetCollection";
import { RefreshButton } from "../../buttons/RefreshButton";
import { InfoCircleOutlined } from "@ant-design/icons";
import "./css/AssetCollection.css";

function AddNewAssetCollection({ setCurrent, onCollectionCreated }) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(true);
  const [assetTypes, setAssetTypes] = useState([]);
  const { Option } = Select;

  const [collectionId, setCollectionId] = useState("");
  const [collectionName, setCollectionName] = useState("");
  const [allowedAssetTypes, setAllowedAssetTypes] = useState([]);
  const [maxAssets, setMaxAssets] = useState(null);
  const [editable, setEditable] = useState(true);
  const [deployable, setDeployable] = useState(true);
  const [secured, setSecured] = useState(false);
  const [description, setDescription] = useState("");
  const [targetPlatform, setTargetPlatform] = useState("");
  const [filters, setFilters] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [localCacheControl, setLocalCacheControl] = useState(false);
  const [localCacheMaxAge, setLocalCacheMaxAge] = useState(30);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  const handleFormSubmit = async () => {
    const payload = {
      collectionId,
      collectionName,
      allowedAssetTypes,
      maxAssets,
      editable,
      deployable,
      secured,
      description,
      targetPlatform,
      localCacheControl,
      localCacheMaxAge: localCacheControl ? localCacheMaxAge : undefined,
    };

    setButtonLoading(true);
      try {
        const response = await createAssetCollection(payload);

        if (response.status === 201 && editable === true) {
          const createdCollectionId = response.data.collectionId;
          message.success("Asset Collection created successfully");

          setCollectionId(createdCollectionId);
          onCollectionCreated(createdCollectionId, allowedAssetTypes, maxAssets, deployable);
        } else {
          message.error("Failed to create Asset Collection");
        }
      } catch (error) {
        message.error("An error occurred while creating Asset Collection");
      } finally {
        setButtonLoading(false);
      }

    if (!editable) {
      setModalVisible(true);
    }
  };

  useEffect(() => {
    const getAssetList = () => {
      getAssetTypes().then((res) => {
        if (res.status === 200 && res.data) {
          setAssetTypes(res.data);
        }
      });
    };

    const fetchTargetPlatformFilters = () => {
      getTargetPlatforms()
        .then((res) => {
          if (res.status === 200 && res.data && res.data.dataList) {
            const formattedFilters = res.data.dataList.map((item) => ({
              text: item.platformName,
              value: item.platformName.toLowerCase(),
            }));
            setFilters(formattedFilters);
          } else {
            console.error("Unexpected API response format:", res);
          }
        })
        .catch((err) => {
          message.error("Unable to load target platforms.");
        });
    };

    fetchTargetPlatformFilters();
    getAssetList();
  }, []);

  const goBack = () => {
    navigate(-1);
  };

  const checkFormValidity = () => {
    const hasError = form.getFieldsError().some(({ errors }) => errors.length > 0);
    const isFormCompleted =
      form.isFieldTouched("collectionName") &&
      form.isFieldTouched("collectionId") &&
      form.isFieldTouched("allowedAssetTypes") &&
      form.isFieldTouched("maxAssets") &&
      form.isFieldTouched("targetPlatform");
    const isValid = !hasError && isFormCompleted;
    setIsFormValid(isValid);
  };

  const resetForm = () => {
    form.resetFields(); // Clear form fields
    setEditable(true);
    setDeployable(true);
    setSecured(false);
    setLocalCacheControl(false);
  };

  const handleOk = async () => {
    setModalVisible(false);
    navigate("/assetcollection");
  };

  const handleGoToDetailPage = (collectionId) => {
    setModalVisible(false);
    navigate(`/assetcollection/${collectionId}`);
  };

  return (
    <>
      <div
        style={{
          background: "white",
          padding: 10,
          marginBottom: 5,
          borderRadius: 12,
          boxShadow: "0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%)",
          marginTop: 15,
        }}
      >
        <PageHeader
          title={["Create New Asset Collection"]}
          extra={[<RefreshButton onClick={resetForm} />, <CancelButton onClick={goBack} />]}
        />

        <div style={{ padding: "25px 180px" }}>
          <Form form={form} onFieldsChange={checkFormValidity} labelCol={{ span: 5 }}>
            <Form.Item
              label="Collection Id"
              name="collectionId"
              rules={[
                { required: true, message: "Please enter Collection Id" },
                {
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.resolve();
                    }
                    if (/\s/.test(value)) {
                      return Promise.reject(new Error("Collection Id should not contain spaces"));
                    }
                    if (value.length < 3) {
                      return Promise.reject(new Error("Collection Id must be at least 3 characters long"));
                    }
                    if (!/^[A-Za-z0-9]+$/.test(value)) {
                      return Promise.reject(new Error("Collection Id should not contain special characters"));
                    }
                    if (/^[A-Za-z]/.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("First character must be an alphabet"));
                  },
                },
              ]}
              style={{ textAlign: "left" }}
            >
              <Input
                value={collectionId}
                onChange={(e) => setCollectionId(e.target.value)}
                className="asset-c-inputs"
              />
            </Form.Item>
            <Form.Item
              label="Collection Name"
              name="collectionName"
              rules={[
                { required: true, message: "Please enter Collection Name" },
                {
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.resolve();
                    }
                    if (value.length < 3) {
                      return Promise.reject(new Error("Collection Name must be at least 3 characters long"));
                    }
                    if (!/^[A-Za-z0-9 _-]+$/.test(value)) {
                      return Promise.reject(
                        new Error(
                          "Collection Name should only contain alphanumeric characters, spaces, hyphens, and underscores"
                        )
                      );
                    }
                    if (/^[A-Za-z]/.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("First character must be an alphabet"));
                  },
                },
              ]}
              style={{ textAlign: "left" }}
            >
              <Input
                min={3}
                className="asset-c-inputs"
                value={collectionName}
                onChange={(e) => setCollectionName(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Allowed Asset Types"
              name="allowedAssetTypes"
              rules={[{ required: true }]}
              style={{ textAlign: "left" }}
            >
              <Select
                mode="multiple"
                placeholder="Select Asset Type"
                value={allowedAssetTypes}
                onChange={(value) => setAllowedAssetTypes(value)}
                maxTagCount={5}
                maxTagPlaceholder={(omittedValues) => `+${omittedValues.length} more`}
              >
                {assetTypes?.map((assetType) => (
                  <Option value={assetType?.typeId}>{assetType?.typeName}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Max Assets"
              name="maxAssets"
              rules={[
                { required: true, message: "Minimum value should be 1" },
                {
                  validator: (_, value) => {
                    if (value && !Number.isInteger(value)) {
                      return Promise.reject(new Error("Max Assets must be an integer"));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              style={{ textAlign: "left" }}
            >
              <InputNumber
                className="max-inputs"
                min={1}
                max={1000}
                changeOnWheel
                value={maxAssets}
                onChange={(value) => setMaxAssets(value)}
              />
            </Form.Item>

            <Form.Item
              label="Target Platform"
              name="targetPlatform"
              rules={[{ required: true, message: "Please select a target platform" }]}
              style={{ textAlign: "left" }}
            >
              <Select
                placeholder="Select a platform"
                value={targetPlatform}
                onChange={(value) => setTargetPlatform(value)}
              >
                {filters.map((filter) => (
                  <Option key={filter.value} value={filter.value}>
                    {filter.text}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {/* <Form.Item label="Editable" name="editable" style={{ textAlign: "left" }}>
              <Switch
                defaultChecked={true}
                style={{ alignSelf: "flex-start" }}
                checked={editable}
                onChange={(checked) => setEditable(checked)}
              />
            </Form.Item> */}
            <Form.Item label="Deployable" name="deployable" style={{ textAlign: "left" }}>
              <Switch
                defaultChecked={true}
                style={{ alignSelf: "flex-start" }}
                checked={deployable}
                onChange={(checked) => setDeployable(checked)}
              />
            </Form.Item>
            <Form.Item label="Secured" name="secured" style={{ textAlign: "left" }}>
              <Switch
                defaultChecked={false}
                style={{ alignSelf: "flex-start" }}
                checked={secured}
                onChange={(checked) => setSecured(checked)}
              />
            </Form.Item>

            <Form.Item label="Local Cache" name="localCacheControl" style={{ textAlign: "left" }}>
              <Switch
                defaultChecked={false}
                style={{ alignSelf: "flex-start" }}
                checked={localCacheControl}
                onChange={(checked) => setLocalCacheControl(checked)}
              />
            </Form.Item>

            {localCacheControl && (
              <Form.Item
                label={
                  <span>
                    Max Cache Age &nbsp;
                    <Tooltip title="Default 30 minutes and Max 525,600 minutes (365 days)">
                      <InfoCircleOutlined
                      // style={{ color: '#008000' }}
                      />
                    </Tooltip>{" "}
                    &nbsp;
                  </span>
                }
                name="localCacheMaxAge"
                rules={[
                  { required: true, message: "Please enter Cache Age" },
                  { type: "number", min: 30, max: 525600 },
                  {
                    validator: (_, value) => {
                      if (value && !Number.isInteger(value)) {
                        return Promise.reject(new Error("Max Cache Age must be an integer"));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                style={{ textAlign: "left" }}
              >
                <InputNumber
                  className="max-inputs"
                  min={30}
                  max={525600}
                  defaultValue={30}
                  value={localCacheMaxAge}
                  onChange={(value) => setLocalCacheMaxAge(value)}
                />
              </Form.Item>
            )}

            <Form.Item label="Description" name="description" style={{ textAlign: "left" }}>
              <Input.TextArea
                name="description"
                style={{ minHeight: "100px" }}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Item>
            <Form.Item style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={handleFormSubmit}
                loading={buttonLoading}
                disabled={!isFormValid}
              >
                Create Collection
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
      <Modal
        title="Collection Not Editable"
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="ok" onClick={handleOk}>
            Go to Asset Collection
          </Button>,
          <Button key="details" type="primary" onClick={() => handleGoToDetailPage(collectionId)}>
            Go to Asset Collection Detail Page
          </Button>,
        ]}
      >
        <p>You have selected editable as 'False', so you cannot add any Asset Items to this collection.</p>
      </Modal>
    </>
  );
}

export default AddNewAssetCollection;
