import React from "react";
// import { Form } from "antd";
import { Button } from "antd";
import FormItem from "antd/es/form/FormItem";

const SubmitButton = (props) => {
  return (
    <FormItem>
      <Button className="button" type="primary" disabled={props?.isDisabled} onClick={props.onFinish} loading={props?.loading}>
        {props.title}
      </Button>
    </FormItem>
  );
};

export default SubmitButton;
