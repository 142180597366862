import axios from "@auth/localaxios";
import { GET_ORGANIZATION_PATH } from "@localconfig/envconfig";

export const getOrganization = async () => {
  const response = await axios.get(GET_ORGANIZATION_PATH, {
    withCredentials: true
  });
  return response;
};

export const CreateOrganization = async formdata => {
  const response = await axios.post(GET_ORGANIZATION_PATH, formdata, {
    withCredentials: true
  });
  return response;
};

export const updateOrganization = async formData => {
  const response = await axios.put(GET_ORGANIZATION_PATH, formData, {
    withCredentials: true
  });
  return response;
};

export const updateLogoURL = async logoURL => {
  const response = await axios.put(GET_ORGANIZATION_PATH + "/logo", logoURL, {
    withCredentials: true,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return response;
};
