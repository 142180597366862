import axios from "@auth/localaxios";
import { LIVE_ASSET_PATH, ENV_ASSET_PATH, ASSET_CONTAINER_PATH, ASSET_VERSION_PATH, TOTAL_ASSET_SIZE_PATH, COUNT_PATH, DOWNLOADURL_PATH, SEARCH_PATH } from "@localconfig/envconfig";
export const getAssetAssetContainers = async () => {
  try {
    const response = await axios.get(ASSET_CONTAINER_PATH, {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getAssetAssetContainerById = async (assetId) => await axios.get(ASSET_CONTAINER_PATH + `/${assetId}`);

export const createAssetVersion = async (form) => {
  try {
    const response = await axios.post(ASSET_VERSION_PATH, form, {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getTotalAssetSize = async () => await axios.get(TOTAL_ASSET_SIZE_PATH);

export const getAssetVersions = async (query) => await axios.get(`${ASSET_VERSION_PATH}?${query}`);
export const deleteAssetVersion = async (concatenatedId) => await axios.delete(`${ASSET_VERSION_PATH}/${encodeURIComponent(concatenatedId)}`);
export const getAssetVersionsEnvIfno = async (assetContainerId, platform, version) => await axios.get(`${ASSET_VERSION_PATH}/${assetContainerId}/${platform}/${version}/environments`);

export const createAssetContainers = async (form) => {
  try {
    const response = await axios.post(ASSET_CONTAINER_PATH, form, {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const addAssetVersionToEnv = async (body) => {
  try {
    const response = await axios.post(ENV_ASSET_PATH, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const getEnvironmentsAssets = async (query) => await axios.get(`${ENV_ASSET_PATH}/?${query}`)
export const getEnvironmentsAssetsByQuery = async (query) => await axios.get(`${ENV_ASSET_PATH}?${query}`)
export const getAssetsDownloadUrls = async (assetId, query) => await axios.get(`${DOWNLOADURL_PATH}/${assetId}?${query}`)
export const getAssetsVersionDownloadUrl = async (assetId, envId, versionId) => await axios.get(`${DOWNLOADURL_PATH}/${assetId}/${envId}/${versionId}`)

export const publishAssetVersion = async (body) => {
  try {
    const response = await axios.put(LIVE_ASSET_PATH, body);
    return response;
  } catch (error) {
    return error;
  }
};

export const getFilterDates = async (body) => {
  try {
    const response = await axios.get(ENV_ASSET_PATH + "?&startDate=" + body.startDate + "&endDate=" + body.endDate);
    return response;
  } catch (error) {
    return error;
  }
}

export const search = async (text = '', page = 1) => await axios.get(`${ASSET_CONTAINER_PATH}${SEARCH_PATH}?text=${text}&page=${page}`);

export const getTotalAssetCount = async () => await axios.get(ASSET_CONTAINER_PATH + COUNT_PATH);