import React, { useState, useEffect } from "react";
import { Table, Menu, Dropdown, Button, notification, Spin } from "antd";
import { getEnvDeployedInfo, deployAssetCollection } from "@services/assetCollection";
import { EditOutlined } from "@ant-design/icons";
import { getAllEnv } from "@services/EnvService";
import { AppObject } from "@context/AppObject";
import useAuth from "@hooks/useAuth";


const CollectionDeploymentTable = ({ collectionId, onDeployError }) => {
  const [dataSource, setDataSource] = useState([]);
  const [selectedStates, setSelectedStates] = useState({});
  const [deployStatus, setDeployStatus] = useState({});
  const [loading, setLoading] = useState({});
  const [successfulDeployments, setSuccessfulDeployments] = useState({});
  const { hasAccess, ACTIONS } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const envResponse = await getAllEnv();
        const deploymentResponse = await getEnvDeployedInfo(collectionId);

        if (envResponse?.status === 200 && deploymentResponse?.status === 200) {
          const environments = envResponse.data.environments || [];
          const deploymentInfo = deploymentResponse.data.results.reduce((acc, curr) => {
            acc[curr.envId] = curr.state;
            return acc;
          }, {});

          const formattedData = environments.map((environment) => ({
            key: environment._id,
            environment: environment.envId,
            state: deploymentInfo[environment.envId] || "Not Deployed",
          }));

          setDataSource(formattedData);
        } else {
          console.error("Failed to load environments or deployment info");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [collectionId]);

  const handleMenuClick = (envId, newState) => {
    setSelectedStates((prev) => ({
      ...prev, // Spreads the previous state, maintaining existing entries
      [envId]: newState, // Updates the state for the specific envId with newState
    }));
    // to clear deployment state
    setSuccessfulDeployments((prev) => ({
      ...prev,
      [envId]: false,
    }));
  };

  const handleDeployClick = async (envId) => {
    setLoading((prev) => ({
      ...prev,
      [envId]: true,
    }));
    const state = selectedStates[envId];
    try {
      const response = await deployAssetCollection(collectionId, { state, envId });
      if (response?.status === 201) {
        setDeployStatus((prev) => ({
          ...prev,
          [envId]: "Deployed Successfully",
        }));
        setSuccessfulDeployments((prev) => ({
          ...prev,
          [envId]: true,
        }));
        setDataSource((prev) => prev.map((item) => (item.environment === envId ? { ...item, state } : item)));

        setSelectedStates((prev) => ({
          ...prev,
          [envId]: state,
        }));

        onDeployError(null);
      } else {
        const errorMsg = "Unable to deploy asset collection";
        setDeployStatus((prev) => ({
          ...prev,
          [envId]: errorMsg,
        }));
        onDeployError(errorMsg);
      }
    } catch (error) {
      const errorMsg = "Unable to deploy asset collection";
      setDeployStatus((prev) => ({
        ...prev,
        [envId]: errorMsg,
      }));
      onDeployError(errorMsg);
    }
    setLoading((prev) => ({
      ...prev,
      [envId]: false,
    }));

    // Clear the deployment message after 5 seconds
    setTimeout(() => {
      setDeployStatus((prev) => ({
        ...prev,
        [envId]: undefined,
      }));
    }, 5000);
  };

  const getStateMenu = (envId, state) => {
    const currentState = selectedStates[envId] || state;
    return (
      <Menu onClick={({ key }) => handleMenuClick(envId, key)}>
        {currentState === "LIVE" ? (
          <Menu.Item key="OFFLINE">OFFLINE</Menu.Item>
        ) : currentState === "OFFLINE" ? (
          <Menu.Item key="LIVE">LIVE</Menu.Item>
        ) : (
          <Menu.Item key="LIVE">LIVE</Menu.Item>
        )}
      </Menu>
    );
  };

  const columns = [
    {
      title: "Environment",
      dataIndex: "environment",
      key: "environment",
      width: "30%",
      align: "center",
      onHeaderCell: () => ({
        style: { textAlign: "center" },
      }),
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      width: "20%",
      align: "center",
      onHeaderCell: () => ({
        style: { textAlign: "center" },
      }),
      render: (_, record) => (
        <div style={{ width: "120px" }}>
          {selectedStates[record.environment] || record.state}
          {hasAccess(AppObject.ASSET_COLLECTION_DEPLOYMENT, ACTIONS.PUT) && (
            <Dropdown
              overlay={getStateMenu(record.environment, record.state)}
              trigger={["click"]}
              placement="bottomLeft"
              arrow={{ pointAtCenter: true }}
            >
              {/* <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  textAlign: "center",
                }}
              > */}
                
                <EditOutlined style={{ marginLeft: "8px" }} />
              {/* </div> */}
            </Dropdown>
          )}
        </div>
      ),
    },

    {
      key: "action",
      width: "45%",
      align: "center",
      onHeaderCell: () => ({
        style: { textAlign: "center" },
      }),
      render: (_, record) =>
        successfulDeployments[record.environment] ? (
          <span style={{ color: deployStatus[record.environment] === "Deployed Successfully" ? "#008000" : "red" }}>
            {deployStatus[record.environment]}
          </span>
        ) : selectedStates[record.environment] && selectedStates[record.environment] !== record.state ? (
          <Button
            type="primary"
            onClick={() => handleDeployClick(record.environment)}
            loading={loading[record.environment]}
          >
            Deploy
          </Button>
        ) : null,
    },
  ];

  return <Table columns={columns} dataSource={dataSource} pagination={false} />;
};

export default CollectionDeploymentTable;
