import React, { useEffect, useState } from "react";
import { Breadcrumb } from "antd";
import CardList from "@dashboardcards/CardList";

const dummyItems = [
    {
        title: "ASSET001",
        description: "SpaceShip",
        url: "./assets/images/broken-link-2367103.svg"
    },
    {
        title: "ASSET001",
        description: "SpaceShip",
        url: "./assets/images/broken-link-2367103.svg"
    },
    {
        title: "ASSET001",
        description: "SpaceShip",
        url: "./assets/images/broken-link-2367103.svg"
    },
    {
        title: "ASSET001",
        description: "SpaceShip",
        url: "./assets/images/broken-link-2367103.svg"
    },
    {
        title: "ASSET001",
        description: "SpaceShip",
        url: "./assets/images/broken-link-2367103.svg"
    }
]
const AssetVersionList = (props) => {

    return (
        <React.Fragment>
            <Breadcrumb>
                <Breadcrumb.Item>
                    <a href="/assetscontainer">AssetContainer</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <a href="/assetVersions">AssetsVersions</a>
                </Breadcrumb.Item>
            </Breadcrumb>
            <CardList items={dummyItems} ></CardList>
        </React.Fragment>
    )
}

export default AssetVersionList;