import React, { useState, useEffect } from "react";
import { PageHeader } from '@ant-design/pro-layout';
import { Button, Table, Tag, message, Modal, Descriptions, Card, Row, Col, Select } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { CancelButton } from "@dashboard/buttons/CancelButton";
import { getPrivateAssetDownloadedData } from "../../../services/assetService";
import Chart from "@dashboardpages/chart/Chart";
// import { Select } from "@i";
const AssetDownloaded = (props) => {
  const navigate = useNavigate();
  const { assetId } = useParams();
  //   const [assetsURLData, setAssetsURLData] = useState([]);

  //   const fetchAssetBySubscriptionId = (subscriptionId) => {
  //     getAssetBySubscriptionId(subscriptionId).then((res) => {
  //      if(res.status === 200  && res.data?.assetsURLData)
  //       {
  //         setAssetsURLData(res.data.assetsURLData);
  //       }
  //     }).catch(error => message.error("Unable to fetch the assets api "+error))
  //   };

  //   useEffect(() => {
  //     fetchAssetBySubscriptionId(props.subscriptionId);
  //   }, []);

  const getData = (data) => {
    getPrivateAssetDownloadedData(data).then((res) => {
      return(res);
    });
  };
  const goBack = () => {
    navigate("/gameassets/" + assetId);
  };

  return (
    <React.Fragment>
      <PageHeader
        className="site-page-header"
        title="Asset Analytics"
        extra={[<CancelButton key="1" onClick={goBack} />]}
      />

      <div style={{ width: "100%", height: "100%", padding: 20 }}>
        <Row gutter={[32, 16]}>
          <Col span={24}>
            <Card
              title="Total No. of Downloads"
              //   extra={<a href="#">More</a>}
              className="no-shadow"
              style={{
                width: 300,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <h1>3000</h1>
            </Card>
          </Col>

          <Col span={16}>
            <Card className="no-shadow" bordered={true} style={{ height: 335 }}>
              <Select style={{ width: 150, margin: 10, marginLeft: "80%" }} onChange={getData} defaultValue={"week"}>
                <Select.Option value="week">Last Week</Select.Option>
                <Select.Option value="month">Last Month</Select.Option>
                <Select.Option value="year">Last year</Select.Option>
              </Select>
              <Chart />
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default AssetDownloaded;
