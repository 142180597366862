import React, { useEffect, useState } from "react";
import { Form, Upload, message } from "antd";
import { useNavigate } from "react-router-dom";
import "../css/form.css";
import GenericFormContainer from "../GenericFormContainer";
import { getUserRoles } from "@services/systemService";
import { createUser } from "@services/userService";
import { StringHelper } from "@utilities";
import { InboxOutlined } from "@ant-design/icons";
import SuccessModel from "@dashboardmodals/Success";
import FailureModel from "@dashboardmodals/Failure";
import useAuth from "@hooks/useAuth";
import SubmitButton from "@dashboard/buttons/SubmitButton";
import Email from "@dashboard/formFields/Email";
import Password from "@dashboard/formFields/Password";
import ConfirmPassword from "@dashboard/formFields/ConfirmPassword";
import SelectField from "@dashboard/formFields/SelectField";
import FullName from "@dashboard/formFields/FullName";
import ActiveSwitch from "@dashboard/formFields/ActiveSwitch";

const AddNewUser = () => {
  const [form] = Form.useForm();
  const [userRoles, setUserRole] = useState([]);
  const [userImageFile, setUserImageFile] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [successModalVisiblilty, setSuccessModalVisiblilty] = useState(false);
  const [failureModalVisiblilty, setFailureModalVisiblilty] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();
  const authData = useAuth();

  const labelColSpan = 5;
  const wrapperColSpan = 14;

  const uploadProps = {
    onRemove: (file) => {
      setUserImageFile([]);
    },
    beforeUpload: (file) => {
      setUserImageFile(file);
      return false;
    },
    userImageFile,
    maxCount: 1,
    listType: "picture",
  };

  useEffect(() => {
    getUserRoles().then((response) => {
      const roleOptions = response.data?.roles?.map(({ roleName, roleId }) => {
        return { label: StringHelper.toFirstLetterUpperCase(roleName), value: roleId };
      });
      setUserRole(roleOptions);
    });
  }, []);

  useEffect(() => {
    form.setFieldsValue({ active: true });
  }, [form]);

  const navigateToUserList = () => {
    setFailureModalVisiblilty(false);
    setSuccessModalVisiblilty(false);
    navigate("/users");
  };

  const createMoreUsers = () => {
    setSuccessModalVisiblilty(false);
    form.resetFields();
  };
  const tryAgain = () => {
    setFailureModalVisiblilty(false);
  };

  const resetFunc = () => {
    form.resetFields();
    checkFormValidity();
  };

  const createUserRole = () => {
    setButtonLoading(true);
    form.setFieldsValue({ userImage: userImageFile });
    const formData = new FormData();
    formData.append("email", form.getFieldValue("email"));
    formData.append("password", form.getFieldValue("password"));
    formData.append("confirmPassword", form.getFieldValue("confirmPass"));
    formData.append("name", form.getFieldValue("name"));
    formData.append("active", form.getFieldValue("active"));
    formData.append("roles[0]", form.getFieldValue("role"));
    formData.append("thumbnail", form.getFieldValue("userImage") || null);
    formData.append("createdBy", authData?.auth?.user?.email);

    createUser(formData)
      .then((response) => {
        setSuccessModalVisiblilty(true);
        setButtonLoading(false);
      })
      .catch((err) => {
        message.error("Unable to create user");
        setButtonLoading(false);
      });
  };

  const checkFormValidity = () => {
    const hasError = form.getFieldsError().some(({ errors }) => errors.length > 0);
    const isFormCompleted = form.isFieldTouched("email" && "password" && "confirmPass" && "name" && "role");
    const isValid = !hasError && isFormCompleted;
    setIsFormValid(isValid);
  };

  return (
    <React.Fragment>
      <GenericFormContainer
        formData={form}
        resetFunc={resetFunc}
        path="users"
        formName={"Create User"}
        breadcrumbPath={"usersAddPath"}
        navigate={() => navigate("/users")}
      >
        <Form
          form={form}
          initialValues={{ active: true }}
          labelCol={{ span: labelColSpan }}
          wrapperCol={{ span: wrapperColSpan }}
          className={"form"}
          onFieldsChange={checkFormValidity}
        >
          <Email />
          <Password />
          <ConfirmPassword />
          <FullName />
          <SelectField options={userRoles} labelColSpan={labelColSpan} wrapperColSpan={wrapperColSpan} />
          <ActiveSwitch />

          <Form.Item label="User Image" name="userImage" valuePropName="userImageFile">
            <Upload.Dragger name="files" {...uploadProps} accept="image/jpeg, image/png,image/jpg">
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">Only jpeg or png files are supported.</p>
            </Upload.Dragger>
          </Form.Item>

          <SubmitButton
            title="Create User"
            onFinish={createUserRole}
            loading={buttonLoading}
            isDisabled={!isFormValid}
          />
        </Form>
      </GenericFormContainer>

      <SuccessModel
        visible={successModalVisiblilty}
        onRightClick={navigateToUserList}
        onLeftClick={createMoreUsers}
        leftTitle="Create more Users"
        rightTitle="Done"
        title="Successful"
        msg="User has been created successfully"
      />
      <FailureModel
        visible={failureModalVisiblilty}
        loading={buttonLoading}
        onRightClick={navigateToUserList}
        onLeftClick={tryAgain}
      />
    </React.Fragment>
  );
};

export default AddNewUser;
