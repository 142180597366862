import { Breadcrumb, Card, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../css/SystemData.css";
import { getGameEngines } from "../../../../services/gamesService";
const GameEngines = () => {
  const [engines, setEngines] = useState([]);

  useEffect(() => {
    getGameEngines().then((res) => {
      setEngines(res.data.dataList);
    });
  }, []);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/systemdata">System Data</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Game Engines</Breadcrumb.Item>
      </Breadcrumb>
      <Row gutter={32} style={{ marginTop: 20 }}>
        {engines.map((engine, index) => (
          <Col span={4} key={index}>
            <Card
             className="card-data"
            >
              <Card.Meta title={engine.engineName} description={engine.gameEngineId} />
            </Card>
          </Col>
        ))}
      </Row>
    </>
  );
};

export default GameEngines;
