import { createContext, useState } from "react";
import { AppObject } from "./AppObject";
const AuthContext = createContext({});

export const ACTIONS = {
  POST: "POST",
  GET: "GET",
  PUT: "PUT",
  DELETE: "DELETE",
};

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({});

  const hasAccess = (objectId, actionType) => {
    if (objectId === AppObject.FULL_ACCESS) {
      return true;
    }
    if (objectId) {
      const action = ACTIONS[actionType];
      if (action) {
        const romObj = auth?.rom[objectId];
        return !!romObj && romObj[actionType];
      }
    }
    // no access incase no mapping found
    return false;
  };

  return <AuthContext.Provider value={{ auth, setAuth, hasAccess, ACTIONS }}>{children}</AuthContext.Provider>;
};

export default AuthContext;
