import { Breadcrumb, DatePicker, Tag, Tooltip, message } from "antd";
import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { PageHeaderListForm } from "@dashboardpages/PageHeader";
import { Table } from "antd";
import { AppObject } from "@context/AppObject";
import { useState } from "react";
import { convertDateToNestorTime } from "../../../../utilities/datehelper";

import { getAssetAssetContainerById, getAssetVersions } from "@services/assetContainer";

import { getAllEnv } from "@services/EnvService";
import { getTargetPlatforms } from "@services/systemService";

import { Typography } from "antd";
const { Text } = Typography;

const pageSize = process.env.REACT_APP_PAGE_SIZE || 20;
const AssetVersionTable = () => {
  const navigate = useNavigate();
  const [assetVersions, setAssetVersion] = useState([]);
  const [totalVersions, setTotalVersions] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [targetPlatform, setTargetPlatform] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [gamePlatforms, setGamePlatforms] = useState();
  const [assetContainer, setAssetContainer] = useState();
  const [allEnv, setAllEnv] = useState();
  const { assetId } = useParams();

  const fetchEnvData = () => {
    getAllEnv()
      .then((res) => {
        if (res?.status === 200 && res?.data?.environments?.length) {
          setAllEnv(res.data?.environments);
        } else {
          message.error("Unable to load Environments");
        }
      })
      .catch((error) => {
        message.error("Unable to load Environments");
      });
  };

  const fetchAssetContainer = () => {
    getAssetAssetContainerById(assetId)
      .then((res) => {
        if (res?.status === 200) {
          setAssetContainer(res.data);
        } else {
          message.error("Unable to load Asset Details");
        }
      })
      .catch((error) => {
        message.error("Unable to Asset Details");
      });
  };

  const setDateFilter = (dates) => {
    if (dates !== null) {
      setStartDate(new Date(dates[0]?.$d).toISOString());
      setEndDate(new Date(dates[1]?.$d).toISOString());
    } else {
      setEndDate("");
      setStartDate("");
    }
  };

  const getColumns = (pageNumber, pageSize) => {
    return [
      {
        title: "SNO",
        key: "sno",
        render: (text, record, index) => {
          return (pageNumber - 1) * pageSize + index + 1;
        },
      },
      {
        title: "Id",
        dataIndex: "versionId",
        key: "versionId",
      },
      {
        title: "Name",
        dataIndex: "versionName",
        key: "versionName",
        render: (text) => (
          <Tooltip title={text}>
            <Text style={{ width: 80, textAlign: "center" }} ellipsis>
              {text}
            </Text>
          </Tooltip>
        ),
      },

      {
        title: "Target Platform",
        key: "targetPlatform",
        dataIndex: "targetPlatform",
        filters: [
          {
            value: "ios",
            text: "IOS",
          },
          {
            value: "android",
            text: "Android",
          },
          {
            value: "web",
            text: "Web",
          },
          {
            value: "generic",
            text: "Generic",
          },
        ],
        // filterDropdown: renderTargetPlatformFilter(),
        render: (text) => <Tag>{text}</Tag>,
      },
      {
        title: "Size",
        key: "size",
        dataIndex: "size",
        // sorting:
      },
      {
        title: "Created At",
        key: "createdAt",
        dataIndex: "createdAt",
        filterDropdown: <DatePicker.RangePicker style={{ margin: 15 }} onChange={setDateFilter} />,
        render: (createdAt) => {
          return convertDateToNestorTime(createdAt);
        },
      },
    ];
  };

  const fetchAssetVersions = (assetId) => {
    const targetPlatformQueryTrimmed =
      targetPlatform.length !== 0
        ? targetPlatform.map((item) => {
            let query = "";
            query += `targetPlatform=${item.targetPlatform}`;
            return query;
          })
        : [];

    const query = `assetId=${assetId}&${targetPlatformQueryTrimmed.join(
      "&"
    )}&pageNumber=${pageNumber}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`;

    getAssetVersions(query)
      .then((res) => {
        if (res.status === 200) {
          setAssetVersion([...res?.data?.results]);
          setTotalVersions(res?.data?.totalResults);
        } else {
          message.error("Unable to load asset versions");
        }
      })
      .catch((error) => {
        message.error("Unable to load asset versions");
      });
  };

  const fetchAllPlatform = () => {
    getTargetPlatforms()
      .then((res) => {
        if (res?.status === 200) {
          setGamePlatforms(res?.data?.dataList);
        } else {
          message.error("Unable to load platforms");
        }
      })
      .catch((error) => {
        message.error("Unable to load platforms");
      });
  };
  const getPlatormId = (platformId) => gamePlatforms?.find((platform) => platform?.platformId === platformId);
  const getAssetVersionById = (versionId) => {
    return assetVersions?.find((version) => version?.versionId === versionId);
  };
  useEffect(() => {
    fetchAssetVersions(assetId);
  }, [pageNumber, targetPlatform, startDate, endDate, assetId]);
  useEffect(() => {
    fetchAssetContainer();
    fetchEnvData();
    fetchAllPlatform();
  }, []);
  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/assetscontainer`}>Virtual Assets</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/assetscontainer/${assetId}`}>{assetId}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Asset Versions </Breadcrumb.Item>
      </Breadcrumb>
      <PageHeaderListForm
        objectId={AppObject.ENVIRONMENT}
        // style={{textTransform:"lowercase"}}

        title={`Asset Versions - ${assetId}`}
        onAdd={() => {
          navigate("/assetVersions/addVersion");
        }}
        // onSearch={(f) => getSearchResults(f)}
        // searchPlaceHolderText="Search Asset Versions"
      />
      <Table
        style={{ textAlign: "center" }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              navigate("/assetscontainer/" + assetId + "/assetversion/" + record.versionId, {
                state: {
                  assetContainer: assetContainer,
                  assetVersion: getAssetVersionById(record.versionId),
                  platform: getPlatormId(record?.targetPlatform),
                },
              });
            }, // click row
          };
        }}
        onChange={(pagination, filters, sorter, extra) => {
          const targetPlatformsFilter = [];
          if (filters.targetPlatform) {
            filters.targetPlatform.forEach((value) => {
              targetPlatformsFilter.push({ targetPlatform: value });
            });
          }
          setTargetPlatform(targetPlatformsFilter || []);
        }}
        responsive
        columns={getColumns(pageNumber, pageSize)}
        dataSource={[...assetVersions]}
        size="middle"
        pagination={{
          total: parseInt(totalVersions) /* total number of items */,
          pageSize /* items per page */,
          current: pageNumber /* current page number */,
          onChange: setPageNumber,
          showSizeChanger: false,
          // position: ["topRight", "bottomRight"],
        }}
      />
    </>
  );
};

export default AssetVersionTable;
