import axios from "./localaxios";
import { SIGNIN_PATH,SIGNUP_PATH, SIGNOUT_PATH } from "@localconfig/envconfig";

export const signin = async (user) => {
  try {
    const response = await axios.post(SIGNIN_PATH, user);
    return response;
  } catch (error) {
    return error;
  }
};


export const signup = async (user) => {
  try {
    const response = await axios.post(SIGNUP_PATH, user);
    return response;
  } catch (error) {
    return error;
  }
};

export const signout = async () => {
  try {
    const response = await axios.post(SIGNOUT_PATH, { withCredentials: true });
    return response;
  } catch (error) {
    return error;
  }
};
