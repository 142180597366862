//assetitem :
import React, { useEffect, useState } from "react";
import { Skeleton } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { PageHeader } from "@ant-design/pro-layout";
import { CancelButton } from "../../buttons/CancelButton";
import { RefreshButton } from "@dashboard/buttons/RefreshButton";
import { Button, message, Form, Input, Select, Card, Upload, Modal } from "antd";
import { InboxOutlined, FileOutlined, DeleteFilled, DeleteOutlined } from "@ant-design/icons";
import { BsFiletypePdf,BsFiletypeXml ,BsFiletypeJson } from "react-icons/bs";
import { FaUnity } from "react-icons/fa6";
import { SiUnrealengine } from "react-icons/si";
import { CiFileOn } from "react-icons/ci";
import { createAssetItem, deleteAssetItem } from "@services/assetCollection";
import { getAssetTypes } from "@services/systemService";
import "./css/AssetCollection.css";

function AddNewAssetItem({ setCurrent, collectionId, allowedAssetTypes, maxAssets, deployable }) {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [isFormValid, setIsFormValid] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [assetTypes, setAssetTypes] = useState([]);
  const [assetFile, setAssetFile] = useState(null);
  const [createdItems, setCreatedItems] = useState([]);
  const [doneButtonLoading, setDoneButtonLoading] = useState(false);
  const [nonDeployModalVisiblility, setNonDeployModalVisibility] = useState(false);
  const [assetItemId, setAssetItemId] = useState(null);
  const [deleteLoading, setDeleteLoading] = useState({});
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [requiredProps, setRequiredProps] = useState({
    setCurrent,
    collectionId,
    allowedAssetTypes,
    maxAssets,
    deployable,
  });
  const [alreadyCreatedItems, setAlreadyCreatedItems] = useState([]);
  const [doneModalVisibility, setDoneModalVisibility] = useState(false);
  const [deployModalVisibility, setDeployModalVisibility] = useState(false);

  const { Dragger } = Upload;
  const [form] = Form.useForm();

  const location = useLocation();

  const getRequiredProps = () => {
    const state = location.state;
    setAlreadyCreatedItems(state?.alreadyCreatedItems || []);
    setRequiredProps({
      ...requiredProps,
      collectionId: collectionId || state.collectionId,
      maxAssets: maxAssets || state.maxAssets,
      allowedAssetTypes: allowedAssetTypes || state.allowedAssetTypes,
      source: state?.source,
    });
  };

  const goBack = () => {
    navigate(-1);
  };

  const refreshForm = () => {
    form.resetFields();
    setAssetFile(null)
    setIsFormValid(false)
  };

  const UploadMsg = (props) => {
    return (
      <div>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">{props?.subMsg || ""}</p>
      </div>
    );
  };

  useEffect(() => {
    getRequiredProps();
    const getAssetList = () => {
      getAssetTypes().then((res) => {
        if (res.status === 200 && res.data) {
          setAssetTypes(res.data);
        }
      });
    };
    getAssetList();
  }, []);

  const getAcceptedFileTypes = (typeId) => {
    switch (typeId) {
      case "0001": // Image
        return "image/*";
      case "0002": // Audio
        return "audio/*";
      case "0003": // Video
        return "video/*";
      case "0004": // Unity Asset
      case "0005": // Unreal Asset
        return ".unity,.unreal";
      case "0006": // PDF
        return "application/pdf";
      case "0007": // XML
        return "application/xml";
      case "0008": // JSON
        return "application/json";
      default: // Other or Unknown
        return "*/*"; // Allow all file types
    }
  };


  const uploadFileProps = () => ({
    onRemove: () => {
        setAssetFile(null); 
    },
    beforeUpload: (file) => {
        const selectedAssetType = form.getFieldValue("assetType"); 

        if (!selectedAssetType) {
            message.error("Please select an asset type before uploading a file.");
            return Upload.LIST_IGNORE; 
        }

        const acceptedTypes = getAcceptedFileTypes(selectedAssetType); 
        if (!file.type.startsWith(acceptedTypes.split("/")[0])) {
            message.error("Selected file type does not match the selected asset type.");
            return Upload.LIST_IGNORE;
        }

        setAssetFile(file);
        return false; 
    },
    maxCount: 1,
    listType: "picture"
});
  

  const checkFormValidity = () => {
    const hasError = form.getFieldsError().some(({ errors }) => errors.length > 0);
    const isFormCompleted =
      form.isFieldTouched("assetItemName") && form.isFieldTouched("assetType") && assetFile !== null;
    const isValid = !hasError && isFormCompleted;
    setIsFormValid(isValid);
  };

  const handleFormSubmit = async () => {
    setButtonLoading(true);
    form.setFieldsValue({ assetFile });

    const formData = new FormData();
    formData.append("assetItemName", form.getFieldValue("assetItemName"));
    formData.append("assetType", form.getFieldValue("assetType"));
    formData.append("assetFile", form.getFieldValue("assetFile"));

    try {
      const response = await createAssetItem(requiredProps?.collectionId, formData);
      if (response.status === 201) {
        const newAssetItemId = response.data.assetItemId;
        setAssetItemId(newAssetItemId);
        message.success("Asset Item created successfully");

        const newItem = {
          assetItemId: newAssetItemId,
          assetItemName: form.getFieldValue("assetItemName"),
          assetType: form.getFieldValue("assetType"),
          assetFile: URL.createObjectURL(assetFile),
        };
        const newCreatedItemsList = [newItem, ...createdItems];
        setCreatedItems(newCreatedItemsList);
        setAlreadyCreatedItems([...alreadyCreatedItems, newItem]);

        form.resetFields();
        setAssetFile(null);
        setIsFormValid(false);

        // navigate("/assetcollection");
        // navigate(-1);
      } else {
        message.error("Failed to create Asset Item");
      }
    } catch (error) {
      message.error("An error occurred while creating Asset Item");
    } finally {
      setButtonLoading(false);
    }
  };

  const handleDoneClick = () => {
    if (requiredProps?.source === "AssetCollectionDetail") {
      // If coming from AssetCollectionDetail, just show the modal without deployable check
      setDoneModalVisibility(true);
    } else {
      if (deployable) {
        setDeployModalVisibility(true);
      } else {
        setNonDeployModalVisibility(true);
      }
    }
  };

  const handleDeploy = () => {
    setDeployModalVisibility(false);
    setCurrent(2);
  };

  const handleLater = (collectionId) => {
    setDeployModalVisibility(false);
    navigate(`/assetcollection/${collectionId}`);
  };

  const handleDeployLater = (collectionId) => {
    navigate(`/assetcollection/${collectionId}`);
  };


  const filteredAssetTypes = assetTypes.filter((assetType) =>
    requiredProps?.allowedAssetTypes?.includes(assetType.typeId)
  );

  const renderMedia = (item) => {
    if (item.assetType === "0001") {
      return (
        <img
          alt={item.assetItemName}
          src={item.assetFile}
          style={{ width: "100%", height: "140px", objectFit: "contain" ,background:"#f2f2f2"}}
        />
      );
    } else if (item.assetType === "0003") {
      return <video controls src={item.assetFile} style={{ width: "100%", height: "140px", objectFit: "cover" ,background:"#f2f2f2" }} />;
    } else if (item.assetType === "0002") {
      return <audio controls src={item.assetFile} style={{ width: "100%", height: "140px", objectFit: "cover" ,background:"#f2f2f2" }} />;
    } 
    else if (item.assetType === "0004") {
      return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "140px" ,background:"#f2f2f2" }}>
          <FaUnity style={{ fontSize: "48px" }} />
        </div>
      );
    } else if (item.assetType === "0005") {
      return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "140px" ,background:"#f2f2f2" }}>
          <SiUnrealengine style={{ fontSize: "48px" }} />
        </div>
      );
    } else if (item.assetType === "0006") {
      return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "140px" ,background:"#f2f2f2" }}>
          <BsFiletypePdf style={{ fontSize: "48px" }} />
        </div>
      );
    } else if (item.assetType === "0007") {
      return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "140px",background:"#f2f2f2"  }}>
          <BsFiletypeXml style={{ fontSize: "48px" }} />
        </div>
      );
    } else if (item.assetType === "0008") {
      return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "140px" ,background:"#f2f2f2" }}>
          <BsFiletypeJson style={{ fontSize: "48px" }} />
        </div>
      );
    } else if (item.assetType === "9999") {
      return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "140px",background:"#f2f2f2"  }}>
          <CiFileOn style={{ fontSize: "48px" }} />
        </div>
      );
    } 
    else {
      return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "140px",background:"#f2f2f2"  }}>
          <CiFileOn style={{ fontSize: "48px" }} />
        </div>
      );
    }
  };

  const handleNavigateClick = () => {
    navigate("/assetcollection");
  };

  const handleDelete = async (collectionId, assetItemId) => {
    setDeleteLoading((prev) => ({ ...prev, [assetItemId]: true }));

    try {
      const response = await deleteAssetItem(collectionId, assetItemId);
      if (response.status === 200) {
        message.success("Asset Item deleted successfully");

        // Filter out the deleted item from the createdItems array
        const updatedCreatedItems = createdItems.filter((item) => item.assetItemId !== assetItemId);
        const updatedAlreadyCreatedItems = alreadyCreatedItems.filter((item) => item.assetItemId !== assetItemId);

        setCreatedItems(updatedCreatedItems);
        setAlreadyCreatedItems(updatedAlreadyCreatedItems);
      } else {
        message.error("Failed to delete Asset Item");
      }
    } catch (error) {
      message.error("An error occurred while deleting Asset Item");
    } finally {
      setDeleteLoading((prev) => ({ ...prev, [assetItemId]: false }));
      setDeleteModalVisible(false);
    }
  };

  const handleDeleteModal = () => {
    setDeleteModalVisible(true);
  };

  const handleCancel = () => {
    setDeleteModalVisible(false);
  };

  return (
    <>
      {/* <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={"/assetcollection"}>Asset Collection</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Create</Breadcrumb.Item>
      </Breadcrumb> */}
      <div style={{ display: "flex", flexDirection: "row", flex: "1", gap: "20px", width: "100%" }}>
        <div
          style={{
            width: "70%",
            background: "white",
            padding: 10,
            marginBottom: 5,
            borderRadius: 12,
            boxShadow: "0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%)",
            marginTop: 15,
          }}
        >
          <PageHeader
            className=""
            title={["Create New Asset Item"]}
            extra={[ <RefreshButton onClick={refreshForm} />,<CancelButton onClick={goBack} />]}
          />
          {alreadyCreatedItems?.length < requiredProps?.maxAssets ? (
            <div style={{ padding: "25px 180px" }}>
              <Form labelCol={{ span: 5 }} onFieldsChange={checkFormValidity} form={form}>
                <Form.Item
                  label="Asset Name"
                  name="assetItemName"
                  rules={[
                    { required: true, message: "Please enter Asset Name" },
                    {
                      validator: (_, value) => {
                        if (!value) {
                          return Promise.resolve();
                        }
                        if (!/^[A-Za-z0-9]+$/.test(value)) {
                          return Promise.reject(new Error("Asset Name should not contain special characters"));
                        }
                        if (!/^[A-Za-z0-9 _-]+$/.test(value)) {
                          return Promise.reject(new Error("Asset Name should only contain alphanumeric characters, spaces, hyphens, and underscores"));
                        }
                        if (value.length < 3) {
                          return Promise.reject(new Error("Asset Name must be at least 3 characters long"));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                  style={{ textAlign: "left" }}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="Asset Type"
                  name="assetType"
                  rules={[{ required: true, message: "Please enter Asset Type" }]}
                  style={{ textAlign: "left" }}
                >
                  <Select placeholder="Select Asset Type">
                    {filteredAssetTypes.map((assetType) => (
                      <Select.Option key={assetType.typeId} value={assetType.typeId}>
                        {assetType.typeName}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <Form.Item
                  label="Asset File"
                  name="assetFile"
                  rules={[{ required: true, message: "Please select asset file" }]}
                  style={{ textAlign: "left" }}
                >
                  <Dragger {...uploadFileProps("assetFile")} style={{ width: "100" }}>
                    <UploadMsg subMsg="Upload Asset File." />
                  </Dragger>
                </Form.Item>

                <Form.Item style={{ display: "flex", justifyContent: "center", marginLeft: 70 }}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={handleFormSubmit}
                    loading={buttonLoading}
                    disabled={!isFormValid}
                  >
                    Add Asset Item
                  </Button>
                </Form.Item>
              </Form>
            </div>
          ) : (
            <div
              style={{
                width: "100%",
                height: "80%",
                padding: 10,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                lineHeight: "10px",
                gap: "20px",
              }}
            >
              <h3> You have Added Maximum number of Asset Items.</h3>
              <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "20px" }}>
                <Button onClick={handleNavigateClick}>Go to Asset Collection List Page</Button>
                {requiredProps?.source != "AssetCollectionDetail" && (
                  <Button type="primary" onClick={handleDeploy} disabled={!deployable}>
                    Next Step (Deploy)
                  </Button>
                )}
              </div>
            </div>
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "90vh",
            width: "30%",
            background: "white",
            padding: 10,
            marginBottom: 5,
            borderRadius: 12,
            boxShadow: "0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%)",
            marginTop: 15,
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              flex: "0 0 auto", // Prevents shrinking or growing; fixed height
              display: "flex",
              flexDirection: "row",
              gap: "10px",
              justifyContent: "center",
              lineHeight: "10px",
              padding: "10px",
            }}
          >
            <h4>Max Assets: {requiredProps?.maxAssets}</h4>
            <h4>Added: {alreadyCreatedItems?.length}</h4>
          </div>

          <div className="cardContainer">
            {createdItems.map((item, index) => (
              <Card
                key={index}
                style={{
                  marginBottom: 16,
                  height: "200px",
                  position: "relative",
                }}
                cover={renderMedia(item)}
              >
                <Button
                  type="text"
                  danger
                  icon={<DeleteOutlined />}
                  style={{
                    position: "absolute",
                    top: 2,
                    right: 10,
                    zIndex: 1,
                    background: "white",
                  }}
                  onClick={() => handleDeleteModal(requiredProps?.collectionId, item.assetItemId)}
                />
                <div style={{ display: "flex", justifyContent: "center", fontWeight: "500" }}>{item.assetItemName}</div>
                {/* <Card.Meta style={{ display: "flex", justifyContent: "center" ,flexDirection:"" }} title={item.assetItemName} /> */}
              </Card>
            ))}
          </div>

          <div
            style={{
              flex: "0 0 auto",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "10px",
            }}
          >
            <Button
              type="primary"
              htmlType="submit"
              onClick={handleDoneClick}
              loading={doneButtonLoading}
              disabled={createdItems.length === 0}
            >
              Done
            </Button>
          </div>
        </div>
      </div>

      <Modal
        title=" Are you done with adding Asset Items?"
        open={deployModalVisibility}
        onCancel={() => setDeployModalVisibility(false)}
        footer={[
          <Button key="later" onClick={() => handleLater(requiredProps?.collectionId)}>
            Go to Collection Detail Page
          </Button>,
          <Button key="deploy" type="primary" onClick={handleDeploy}>
            Next Step ( Deploy )
          </Button>,
        ]}
      >
        <p>What do you want to do next?</p>
      </Modal>

      <Modal
        title=" Are you sure you want to delete this item?"
        open={deleteModalVisible}
        onCancel={() => setDeleteModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button
            key="delete"
            type="primary"
            loading={deleteLoading[assetItemId]}
            onClick={() => handleDelete(requiredProps?.collectionId, assetItemId)}
          >
            Delete
          </Button>,
        ]}
      >
        <p>This action cannot be undone.</p>
      </Modal>

      {/* Modal for Done Button when deployable is false */}

      <Modal
        title="Deployment Restricted"
        open={nonDeployModalVisiblility}
        onCancel={() => setNonDeployModalVisibility(false)}
        footer={[
          <Button key="cancel" onClick={() => setNonDeployModalVisibility(false)}>
            Cancel
          </Button>,
          <Button key="goToCollection" type="primary" onClick={() => handleDeployLater(requiredProps?.collectionId)}>
            Yes, Go to Asset Collection Page
          </Button>,
        ]}
      >
        <p style={{ paddingBottom: "10px" }}>
          You have marked this asset collection 'Deployable - False' , hence you can't deploy to any environment. You
          can change this at any time.
          <br />
          <br />
          Would you like to go to the asset collection detail page? <br />
        </p>
      </Modal>

      <Modal
        title=" Your Asset items were added! "
        open={doneModalVisibility}
        onCancel={() => setDoneModalVisibility(false)}
        footer={[
          <Button key="cancel" onClick={() => setDoneModalVisibility(false)}>
            Cancel
          </Button>,
          <Button key="goToCollection" type="primary" onClick={() => handleDeployLater(requiredProps?.collectionId)}>
            Yes, Go to Asset Collection Detail Page
          </Button>,
        ]}
      >
        <p style={{ paddingBottom: "10px" }}>
          You can view the collection details by navigating to the Asset Collection Detail Page.
        </p>
      </Modal>
    </>
  );
}

export default AddNewAssetItem;
