import { Form, Input, Select, Upload, Modal,Button, Switch, message } from "antd";
import useAuth from "@hooks/useAuth";
import { InboxOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { createNewGame, verifyGameId } from "@services/gamesService";
import {getTargetPlatforms, getAssetTypes} from "@services/systemService";
import { useNavigate } from "react-router-dom";
import "../css/form.css";
import GenericFormContainer from "../GenericFormContainer";
import SubmitButton from "../../buttons/SubmitButton";
import { CancelButton } from "../../buttons/CancelButton";
const CreateNewGamePage = () => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [gameEngineList, setGameEngineList] = useState([]);
  const [gamePlatformList, setGamePlatformList] = useState([]);
  const [gameImageFile, setGameImageFile] = useState([]);
  const [gameEngineLoading, setGameEngineLoading] = useState(true);
  const [gamePlatformLoading, setGamePlatformLoading] = useState(true);
  const [isGameCreated, setIsGameCreated] = useState(false);
  const [modal, setIsModalVisible] = useState(false);
  const authData = useAuth();
  const [status, setStatus] = useState("");
  const [helpText, setHelpText] = useState("");
  const navigate = useNavigate();
  // const { uploading, fileList } = this.state;
  const uploadProps = {
    onRemove: (file) => {
      setGameImageFile([]);
    },
    beforeUpload: (file) => {
      setGameImageFile(file);
      return false;
    },
    gameImageFile,
    maxCount: 1,
    listType: "picture",
  };
  const fetchGameEngineList = () => {
    getAssetTypes().then((response) => {
      setGameEngineLoading(false);
      setGameEngineList(response.data.dataList);
    });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const fetchGamePlatformList = () => {
    getTargetPlatforms().then((response) => {
      setGamePlatformLoading(false);
      setGamePlatformList(response.data.dataList);
    });
  };
  const OnOkayConfirmation = () => {
    setIsGameCreated(true);
    form.setFieldsValue({ gameImage: gameImageFile });
    const gameData = new FormData();
    gameData.append("gameId", form.getFieldValue("gameId"));
    gameData.append("gameName", form.getFieldValue("gameName"));
    gameData.append("gameEngineId", form.getFieldValue("gameEngine"));
    gameData.append("gamePlatformId", form.getFieldValue("gamePlatform"));
    gameData.append("active", form.getFieldValue("gameActive"));
    gameData.append("websiteURL", form.getFieldValue("gameWebsite"));
    gameData.append("description", form.getFieldValue("gameDescription"));
    gameData.append("gameImage", form.getFieldValue("gameImage"), form.getFieldValue("gameImage").name);
    gameData.append("createdBy", authData?.auth?.user?.email);
    createNewGame(gameData).then((response) => {
     
        setIsGameCreated(false);
        setIsModalVisible(false);
        message.success("The Game has been created!");
        navigate("/games");
     
    });
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  // const handleCancel = () => {
  //   setIsModalVisible(false);
  // };

  const onFinish = () => {
    showModal();
  };
  const verifyGameExist = () => {
    if (form.getFieldValue("gameId") === "") {
      setHelpText("The Game Id is required!");
      setStatus("error");
    } else if (form.getFieldValue("gameId") !== "") {
      setHelpText("");
      setStatus("success");
      verifyGameId(form.getFieldValue("gameId"))
        .then((response) => {
          if (response.status === 200 && response.data.isIdPresent) {
            setHelpText("The gameId is already taken!");
            setStatus("error");
          } else {
            setHelpText("");
            setStatus("success");
          }
        })
        .catch((err) => {
          setHelpText("Unable to verify the gameId, Please try again later!");
          setStatus("error");
        });
    }
  };

  const isDisabled = () => {
    return (
      !(
        form.isFieldTouched("gameId") &&
        form.isFieldTouched("gameName") &&
        form.isFieldTouched("gameEngine") &&
        form.isFieldTouched("gamePlatform")
      ) || !!form.getFieldsError().filter(({ errors }) => errors.length).length
    );
  };
  useEffect(() => {
    forceUpdate({});
    fetchGameEngineList();
    fetchGamePlatformList();
  }, []);

  return (
    <React.Fragment>
      <Modal
        open={modal}
        footer={[
          <CancelButton />,
          <Button loading={isGameCreated} type="primary" onClick={OnOkayConfirmation}>
            Create
          </Button>,
        ]}
      >
        <p
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100px",
            margin: 0,
          }}
        >
          Are you sure you want to create this game?
        </p>
      </Modal>

      <GenericFormContainer formData={form} path="Games" formName={"Create New Game"} breadcrumbPath={"gameCreateForm"} navigate={()=>navigate("/games")}>
        <Form
          form={form}
          name="createNewGame"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          onFinish={showModal}
          className="form"
        >
          <Form.Item
            label="Game Id"
            name="gameId"
            validateStatus={status}
            help={helpText}
            rules={[{ required: true, message: "Game Id is required!" }]}
          >
            <Input style={{ height: 40 }} onBlur={verifyGameExist} />
          </Form.Item>
          <Form.Item label="Game Name" name="gameName" rules={[{ required: true, message: "Game Name is required!" }]}>
            <Input style={{ height: 40 }} />
          </Form.Item>
          <Form.Item
            label="Engine"
            name="gameEngine"
            style={{ height: 40 }}
            rules={[{ required: true, message: "Game Engine is required!" }]}
          >
            <Select style={{ height: 40, borderRadius: 40 }} placeholder="Select Engine">
              {gameEngineList.map((item) => (
                <Select.Option style={{ height: 40 }} value={item.id} key={item.id}>
                  {item.engineName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Platform"
            name="gamePlatform"
            rules={[{ required: true, message: "Game platform is required!" }]}
          >
            <Select style={{ height: 40 }} placeholder="Select Platform">
              {gamePlatformList.map((gamePlatform) => (
                <Select.Option style={{ height: 40 }} value={gamePlatform?.id} key={gamePlatform.id}>
                  {gamePlatform.platformName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Active"
            name="gameActive"
            style={{ alignItems: "flex-start" }}
            className="switch-game-active"
            valuePropName="checked"
          >
            <Switch defaultChecked={false} />
          </Form.Item>
          <Form.Item label="Website" name="gameWebsite">
            <Input style={{ height: 40 }} />
          </Form.Item>
          <Form.Item label="Description" name="gameDescription">
            <Input.TextArea style={{ height: 100 }} />
          </Form.Item>
          <Form.Item label="Game Image">
            <Form.Item name="gameImage" valuePropName="gameImageFile" getValueFromEvent={normFile} noStyle>
              <Upload.Dragger name="files" {...uploadProps}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">Only jpeg or png files are supported.</p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
          <SubmitButton isDisabled={isDisabled} onFinish={onFinish} title="Submit" />
        </Form>
      </GenericFormContainer>
    </React.Fragment>
  );
};

export default CreateNewGamePage;
