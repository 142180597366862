import { Typography, Button, Tag } from "antd";
import SignUpForm from "./forms/SignUpForm";
import { useNavigate } from "react-router-dom";
import "./css/SignUpPage.css";

const { Title } = Typography;
const SignUp = () => {
  const navigate = useNavigate();
  return (
    <div className="signup-page">
      <div className="signup-form-container">
        <div className="signup-logo-div">
          <img
            src={process.env.PUBLIC_URL + "/nestor_text.png"}
            alt="Nestor"
            className="signup-nestor-text-img"
          />
        </div>
        <Title level={2}>Create an Account!</Title>
        <SignUpForm />
        <Button
          type="standard"
          htmltype="submit"
          className="alt-button"
          onClick={() => navigate("/")}
        >
          Already have an Account?
        </Button>
      </div>
    </div>
  );
};

export default SignUp;
