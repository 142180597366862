import axios from "@auth/localaxios";
import { ASSET_STATE_PATH, USER_ROLES_API_PATH, ROMS_API_PATH, ASSET_TYPE_PATH, TARGETPLATFORMS_API_PATH } from "@localconfig/envconfig";

export const getAssetState = async (type) => {
  try {
    const response = await axios.get(ASSET_STATE_PATH + "/" + type);
    return response;
  } catch (error) {
    return error;
  }
};

export const getUserRoles = async () => {
  try {
    const response = await axios.get(USER_ROLES_API_PATH, { withCredentials: true });
    return response;
  } catch (error) {
    return error;
  }
};

export const getUserRoleById = async (roleId) => {
  try {
    const fullPath = `${USER_ROLES_API_PATH}/${roleId}`
    return await axios.get(fullPath, { withCredentials: true });
  } catch (error) {
    return error;
  }
};

export const getUserROMByRoleId = async (roleId) => {
  const fullPath = `${ROMS_API_PATH}/${roleId}`
  return await axios.get(fullPath, { withCredentials: true });
};

export const getAssetTypes = async () => {
  return await axios.get(ASSET_TYPE_PATH);
}
export const getAssetType = async (assetTypeId) => {
  return await axios.get(`${ASSET_TYPE_PATH}/${assetTypeId}`);
}

export const getTargetPlatforms = async () => {
  return await axios.get(TARGETPLATFORMS_API_PATH); //
}

export const getTargetPlatform = async (platformId) => {
  return await axios.get(`${TARGETPLATFORMS_API_PATH}/${platformId}`);
}
