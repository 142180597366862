import React from "react";
import { useState } from "react";
import { Breadcrumb, Button, Col, Image, Row, Tag, Typography } from "antd";
import { AppObject } from "@context/AppObject";
import { PageHeaderDetailForm } from "../PageHeader";
import { Link, useNavigate, useParams } from "react-router-dom";

import { convertDateToNestorTime } from "../../../../utilities/datehelper";

import { useEffect } from "react";
import { getTicketById } from "../../../../services/SupportFeature";

const { Text, Title } = Typography;
const SupportDetails = () => {
  const navigate = useNavigate();
  const [readMore, setReadMore] = useState(false);
  const { ticketId } = useParams();
  const [ticket, setTicket] = useState();
  const [span, setSpan] = useState({ col1: 12, col2: 12 });
  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    getTicketById(ticketId).then((res) => {
      setTicket(res?.data?.supportTicket);
      setReadMore(res?.data?.supportTicket?.description?.length >= 400 ? true : false);
      if (res?.data?.supportTicket?.description?.length >= 100) {
        setSpan({ col1: 10, col2: 9 });
      }
    });
    return () => {};
  }, [ticketId]);;

  return (
    <React.Fragment>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/support">Tickets</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{ticketId?.split("-")[0]}</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ paddingTop: "15px" }}>
        <PageHeaderDetailForm
          objectId={AppObject.SUPPORT}
          title={`Ticket Details - ${ticketId?.split("-")[0]}`}
          onCancel={goBack}
        />

        <div className="details">
          <div>
            <Row gutter={10}>
              <Col span={span.col1}>
                <Title level={5} style={{ textAlign: "right" }}>
                  Subject:
                </Title>
              </Col>
              <Col span={span.col2}>
                <Text style={{ textAlign: "left", display: "block", margin: "1px 0px" }}>{ticket?.subject}</Text>
              </Col>
            </Row>
            <Row gutter={10} className="nestor_details_content">
              <Col span={span.col1}>
                <Title level={5} style={{ textAlign: "right" }}>
                  Description:
                </Title>
              </Col>
              <Col span={span.col2}>
                <Text style={{ textAlign: "left", display: "block", margin: "1px 0px" }}>
                  {readMore ? ticket?.description.slice(0, 600) : ticket?.description}
                </Text>
                {ticket?.description.length >= 600 && (
                  <Button style={{ margin: 0, padding: 0 }} type={"link"} onClick={() => setReadMore(!readMore)}>
                    {readMore === true ? "Read More" : "Read Less"}
                  </Button>
                )}
              </Col>
            </Row>
            {ticket?.attachment && (
              <Row gutter={10} className="nestor_details_content">
                <Col span={span.col1}>
                  <Title level={5} style={{ textAlign: "right" }}>
                    Ticket Image:
                  </Title>
                </Col>
                <Col span={span.col2}>
                  <Image style={{ height: "auto", width: 200 }} src={ticket?.attachment} />
                </Col>
              </Row>
            )}
            <Row gutter={10} className="nestor_details_content">
              <Col span={span.col1}>
                <Title level={5} style={{ textAlign: "right" }}>
                  Priority:
                </Title>
              </Col>
              <Col span={span.col2}>
                <Tag style={{ textAlign: "left", margin: "1px 0px" }}>{ticket?.priority}</Tag>
              </Col>
            </Row>
            <Row gutter={10} className="nestor_details_content">
              <Col span={span.col1}>
                <Title level={5} style={{ textAlign: "right" }}>
                  Category:
                </Title>
              </Col>
              <Col span={span.col2}>
                <Tag style={{ textAlign: "left", margin: "1px 0px" }}>{ticket?.category}</Tag>
              </Col>
            </Row>
            {ticket?.subCategory && (
              <Row gutter={10} className="nestor_details_content">
                <Col span={span.col1}>
                  <Title level={5} style={{ textAlign: "right" }}>
                    Sub Category:
                  </Title>
                </Col>
                <Col span={span.col2}>
                  <Tag style={{ textAlign: "left", margin: "1px 0px" }}>{ticket?.subCategory}</Tag>
                </Col>
              </Row>
            )}
            <Row gutter={10} className="nestor_details_content">
              <Col span={span.col1}>
                <Title level={5} style={{ textAlign: "right" }}>
                  Status:
                </Title>
              </Col>
              <Col span={span.col2}>
                <Tag color={ticket?.status === "OPEN" ? "green" : "red"} style={{ textAlign: "left" }}>
                  {ticket?.status}
                </Tag>
              </Col>
            </Row>
            {ticket?.alternateEmail && (
              <Row gutter={10} className="nestor_details_content">
                <Col span={span.col1}>
                  <Title level={5} style={{ textAlign: "right" }}>
                    Alternate Email:
                  </Title>
                </Col>
                <Col span={span.col2}>
                  <Text style={{ textAlign: "left", display: "block", margin: "1px 0px" }}>
                    {ticket?.alternateEmail}
                  </Text>
                </Col>
              </Row>
            )}
            {ticket?.phoneNumber && (
              <Row gutter={10} className="nestor_details_content">
                <Col span={span.col1}>
                  <Title level={5} style={{ textAlign: "right" }}>
                    Phone No:
                  </Title>
                </Col>
                <Col span={span.col2}>
                  <Text style={{ textAlign: "left", display: "block", margin: "1px 0px" }}>{ticket?.phoneNumber}</Text>
                </Col>
              </Row>
            )}
            <Row gutter={10} className="nestor_details_content">
              <Col span={span.col1}>
                <Title level={5} style={{ textAlign: "right" }}>
                  Created By:
                </Title>
              </Col>
              <Col span={span.col2}>
                <Text style={{ textAlign: "left", display: "block", margin: "1px 0px" }}>{ticket?.createdBy}</Text>
              </Col>
            </Row>
            <Row gutter={10} className="nestor_details_content">
              <Col span={span.col1}>
                <Title level={5} style={{ textAlign: "right" }}>
                  Created At:
                </Title>
              </Col>
              <Col span={span.col2}>
                <Text style={{ textAlign: "left", display: "block", margin: "1px 0px" }}>
                  {convertDateToNestorTime(ticket?.createdAt)}
                </Text>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SupportDetails;
