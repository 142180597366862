import React from "react";
import { Result } from "antd";
import { useNavigate } from "react-router-dom";

function ResetPassSuccess() {
  let navigate = useNavigate();

  return (
      <div className="success-message">
        <Result
          status="success"
          title="Your Password has been reset successfully!"
          extra={[
            <>
              <p style={{ fontSize: 16 }}>Enjoy your Nestor Journey again.</p>
              <a style={{textDecoration:"none"}} onClick={() => navigate("/")}>
                Login
              </a>
            </>
          ]}
        />
      </div>
  );
}

export default ResetPassSuccess;
