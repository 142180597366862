import { PageHeader } from "@ant-design/pro-layout";
import { Form, Input, Select, Button, Upload, Skeleton, Modal, Switch, message, Breadcrumb } from "antd";
import { UndoOutlined, CloseOutlined, InboxOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { getGameEngines, updateGameImage, getGameByGameId, updateGame } from "@services/gamesService";
import { getTargetPlatforms } from "@services/systemService";
import GenericFormContainer from "../pages/GenericFormContainer";
import { useNavigate, useParams, Link } from "react-router-dom";
import useAuth from "@hooks/useAuth";
import "../pages/css/form.css";
import SubmitButton from "../buttons/SubmitButton";
const GameUpdateForm = () => {
  const [form] = Form.useForm();
  const authData = useAuth();
  const inputRef = React.useRef(null);
  const [, forceUpdate] = useState({});
  const [gameEngineList, setGameEngineList] = useState([]);
  const [gamePlatformList, setGamePlatformList] = useState([]);
  const [gameImageFile, setGameImageFile] = useState([]);
  const [gameEngineLoading, setGameEngineLoading] = useState(true);
  const [gamePlatformLoading, setGamePlatformLoading] = useState(true);
  const [gameLoading, setGameLoading] = useState(true);
  const [isGameCreated, setIsGameCreated] = useState(false);
  const [modal, setIsModalVisible] = useState(false);
  const [gameData, setGameData] = useState();
  const [newlogo, setNewLogo] = useState();
  const [uploadModel, setuploadModel] = useState(false);
  const navigate = useNavigate();

  const { gameId } = useParams();
  // const { uploading, fileList } = this.state;

  const showuploadmodel = () => {
    setuploadModel(true);
  };

  const fetchGameEngineList = () => {
    getGameEngines().then((response) => {
      setGameEngineLoading(false);
      setGameEngineList(response.data.dataList);
    });
  };

  const fetchGameById = (gameId) => {
    getGameByGameId(gameId).then((response) => {
      let data = response.data.dataList;
      setGameLoading(false);
      setGameData(data);
      form.setFieldsValue({
        gameName: data.gameName,
        gameId: data.gameId,
        gameEngine: data.gameEngineId.engineName,
        gamePlatform: data.gamePlatformId.platformName,
        active: data.active,
        website: data.website,
        gameDescription: data.description || "",
      });
      setGameImageFile(data.gameImageURL);
    });
  };

  const gameImageUpdate = () => {
    const gameData = new FormData();
    form.setFieldsValue({ gameImage: gameImageFile });
    gameData.append("gameId", form.getFieldValue("gameId"));
    gameData.append("gameImage", form.getFieldValue("gameImage"), form.getFieldValue("gameImage").name);
    updateGameImage(gameData)
      .then((response) => {
        setNewLogo(response.data.updatelogo.gameImageURL);
        setuploadModel(false);
        message.success("Successfully update the game image!");
      })
      .catch((err) => {
        return err;
      });
  };

  const fetchGamePlatformList = () => {
    getTargetPlatforms().then((response) => {
      setGamePlatformLoading(false);
      setGamePlatformList(response.data.dataList);
    });
  };
  const OnOkayConfirmation = () => {
    setIsGameCreated(true);
    const gameData = new FormData();
    let updatedEngine = gameEngineList.filter((item) => item.engineName === form.getFieldValue("gameEngine"));
    let updatedPlatform = gamePlatformList.filter((item) => item.platformName === form.getFieldValue("gamePlatform"));
    gameData.append("gameId", form.getFieldValue("gameId"));
    gameData.append("gameName", form.getFieldValue("gameName"));
    gameData.append("gameEngineId", updatedEngine[0].id);
    gameData.append("gamePlatformId", updatedPlatform[0].id);
    gameData.append("active", form.getFieldValue("active"));
    gameData.append("lastUpdatedBy", authData?.auth?.user?.email);
    updateGame(gameData)
      .then((response) => {
        if (response.status === 201) {
          setIsGameCreated(false);
          setIsModalVisible(false);
          message.success("Successfully update the game!");
        } else if (!response.response) {
          setIsGameCreated(false);
          setIsModalVisible(false);
          message.error("Error While Updating Game!");
        }
      })
      .catch((err) => {
        return err.message;
      });
  };
  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    showModal();
  };
  const uploadProps = {
    onRemove: (file) => {
      setGameImageFile([]);
    },
    beforeUpload: (file) => {
      setGameImageFile(file);
      return false;
    },
    gameImageFile,
    maxCount: 1,
    listType: "picture",
  };
  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  function onChange(checked) {
    // console.log(`switch to ${checked}`);
    return
  }
  const isDisabled = () => {
    return (
      !(form.isFieldTouched("gameName") && form.isFieldTouched("gameEngine") && form.isFieldTouched("gamePlatform")) ||
      !!form.getFieldsError().filter(({ errors }) => errors.length).length
    );
  };
  useEffect(() => {
    fetchGameById(gameId);
    forceUpdate({});
    fetchGameEngineList();
    fetchGamePlatformList();
  }, []);

  return (
    <div>
      <Modal
        open={modal}
        footer={[
          <Button onClick={handleCancel}>Cancel</Button>,
          <Button loading={isGameCreated} type="primary" onClick={OnOkayConfirmation}>
            Yes, Update
          </Button>,
        ]}
      >
        <p
          style={{
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100px",
            margin: 0,
          }}
        >
          Are you sure you want to update this game?
        </p>
      </Modal>
      <GenericFormContainer
        formData={form}
        path="Games"
        formName={"Update Game"}
        breadcrumbPath={"gameUpdateForm"}
        navigate={() => navigate("/games")}
      >
        <Skeleton loading={gameEngineLoading && gamePlatformLoading && gameLoading}>
          <Form
            form={form}
            name="updateGame"
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 14 }}
            onFinish={showModal}
            className="form"
            encType="multipart/form-data"
          >
            <Form.Item label="Game Id" name="gameId" rules={[{ required: true, message: "Game Id is required!" }]}>
              <Input style={{ height: 40 }} ref={inputRef} disabled />
            </Form.Item>
            <Form.Item
              label="Game Name"
              name="gameName"
              rules={[{ required: true, message: "Game Name is required!" }]}
            >
              <Input style={{ height: 40 }} />
            </Form.Item>
            <Form.Item
              label="Engine"
              name="gameEngine"
              style={{ height: 40 }}
              rules={[{ required: true, message: "Game Engine is required!" }]}
            >
              <Select style={{ height: 40, borderRadius: 40 }} placeholder="Select Engine">
                {gameEngineList.map((item) => (
                  <Select.Option style={{ height: 40 }} value={item.engineName} key={item.id}>
                    {item.engineName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Platform"
              name="gamePlatform"
              rules={[{ required: true, message: "Game platform is required!" }]}
            >
              <Select style={{ height: 40 }} placeholder="Select Platform">
                {gamePlatformList.map((gamePlatform) => (
                  <Select.Option style={{ height: 40 }} value={gamePlatform.platformName} key={gamePlatform.id}>
                    {gamePlatform.platformName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Active"
              name="active"
              style={{ alignItems: "flex-start" }}
              className="switch-game-active"
              valuePropName="checked"
            >
              <Switch defaultChecked={gameData?.active} onChange={onChange} />
            </Form.Item>
            <Form.Item label="Website" name="gameWebsite">
              <Input style={{ height: 40 }} name="website" htmltype="url" />
            </Form.Item>
            <Form.Item label="Description" name="gameDescription">
              <Input.TextArea style={{ height: 100 }} name="gameDescription" />
            </Form.Item>
            <Modal
              open={uploadModel}
              title="Upload new Logo"
              onOk={() => gameImageUpdate()}
              onCancel={() => setuploadModel(false)}
              okText="Upload"
            >
              <Form.Item label="Game Image">
                <Form.Item name="gameImage" valuePropName="gameImageFile" getValueFromEvent={normFile} noStyle>
                  <Upload.Dragger name="files" {...uploadProps}>
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">Only jpeg or png files are supported.</p>
                  </Upload.Dragger>
                </Form.Item>
              </Form.Item>
            </Modal>

            <Form.Item label="Game Image">
              <div onClick={() => showuploadmodel()}>
                {newlogo ? (
                  <img src={newlogo} alt="logo" width="200px" height="200px" />
                ) : (
                  <img src={gameData?.gameImageURL} alt="logo" width="200px" height="200px" />
                )}
              </div>
            </Form.Item>
            <SubmitButton isDisabled={isDisabled()} onFinish={onFinish} title="save" />
          </Form>
        </Skeleton>
      </GenericFormContainer>
      {/* </div> */}
      {/* </div> */}
    </div>
  );
};

export default GameUpdateForm;
