import React, { useState, useEffect } from "react";
import { Table,message } from "antd";
import { getAssetListByProductId } from "@services/assetService";

const AssetList = (props) => {
  
  const [assets, setAssests] = useState([]);

  useEffect(() => {
    fetchAssetsByProductId(props.product._id);
  }, []);

  const fetchAssetsByProductId = (productId) => {
    getAssetListByProductId(productId).then((res) => {
     if(res.status === 200  && res.data?.assetsDetails) 
      {
        if(res.data?.assetsDetails && res.data?.assetsDetails?.length > 0)
        {
          setAssests(res.data?.assetsDetails)
        }
      }
    }).catch(error =>{
       message.error("Unable to fetch the Assets "+error)
      }) 
  };

  return (
    <React.Fragment>
       <Table
          style={{ margin: 20 }}
          title={() => "Assets Details"}
          columns={props.columns}
          pagination={{ position: ["none", "none"] }}
          dataSource={assets}
          onRow={(record, rowIndex) => {
              if (props.clickable) {
              return {
                onClick: (event) => {
                props.setAssetId(assets[rowIndex].assetId)
                props.showPopup()
              }
              };
            }
            else{
            return {}
          }}}
        />
    </React.Fragment>
  );
};

export default AssetList;
