import axios from "@auth/localaxios";
import { ENVIRONMENT_PATH, COUNT_PATH } from "@localconfig/envconfig";

export const getAllEnv = async () => {
  try {
    const response = await axios.get(ENVIRONMENT_PATH, {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getEnvById = async (id) => {
  try {
    const response = await axios.get(ENVIRONMENT_PATH + "/" + id, {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const createEnv = async (form) => {
  try {
    const response = await axios.post(ENVIRONMENT_PATH, form, {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const UpdateEnv = async (envId, form) => {
  try {
    const response = await axios.put(ENVIRONMENT_PATH + "/" + envId, form, {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const UpdateImage = async (envId, form) => {
  try {
    const response = await axios.put(ENVIRONMENT_PATH + "/image/" + envId, form, {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const verifyEnvId = async (envId) => {
  try {
    const response = await axios.get(ENVIRONMENT_PATH + "/verify/" + envId, {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

// export const getPrivateAssetDownloadedData = async (filter) => {
//   try {
//     const response = await axios.get("http://localhost:9900/api/downloaded?filterBy=" + filter, {
//       withCredentials: true,
//     });
//     return response;
//   } catch (error) {
//     return error;
//   }
// };

// export const getAssetListByProductId = async (productId) => {
//   try {
//     const response = await axios.get(ASSETS_LIST_API_PATH + "/" + productId, {
//       withCredentials: true,
//     });
//     return response;
//   } catch (error) {
//     return error;
//   }
// };

export const getTotalEnvironmentCount = async () => await axios.get(ENVIRONMENT_PATH + COUNT_PATH);

export const getTotalAssetVersionService = async (envId)=> await axios.get(`/environments/${envId}/deployed-asset-counts` )

export const getTotalAssetCollectionCount = async (envId) => await axios.get(`/environments/${envId}/deployed-asset-collection-counts`)

export const getDeployedAssetVersions = async (envId , query) => await axios.get(`/environments/${envId}/deployed-assset-versions?${query}`)

export const getEnvDeployedAssetCollection = async(envId,query) => await axios.get(`/environments/${envId}/deployed-asset-collection?${query}`)