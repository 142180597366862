import React from "react";
import { Timeline, Empty, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined, CheckCircleFilled, StopOutlined, ExportOutlined } from "@ant-design/icons";
import { convertDateToNestorTime } from "../../../utilities/datehelper";

const DEFAULT_STATES = {
  PROCESSING: "PROCESSING",
  DEFAULT: "DEFAULT",
  LIVE: "LIVE",
  OFFLINE: "OFFLINE",
};

const AssetTimeline = ({
  items = [],
  onItemClick,
  onIconClick,
  showAllLink,
  showAllButtonLabel = "Show All",
//   convertDateToNestorTime,
  isStateProcessing,
  states = DEFAULT_STATES,
  getColorAndDot,
}) => {
  const isItemAvailable = items.length > 0;
  const navigate = useNavigate();

  const getIconInfo = (state) => {
    if (isStateProcessing(state)) {
      return {
        color: "#ccc",
        dot: <LoadingOutlined />,
      };
    }
    const { color, dot } = getColorAndDot(state);
    return { color, dot };
  };

  return (
    <React.Fragment>
      {isItemAvailable ? (
        <Timeline>
          {items.map((item) => {
            const iconInfo = getIconInfo(item.state);
            return (
              <Timeline.Item key={item.id} className="ant-timeline-item-list" color={iconInfo.color} dot={iconInfo.dot}>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => onItemClick(item)}
                >
                  {item.label}
                </span>
                <span className="timeline-text" style={{ marginLeft: 5 }}>
                  {item.date}
                </span>
                <span
                  onClick={() => onIconClick(item)}
                  style={{
                    color: "#ccc",
                    position: "absolute",
                    left: "90%",
                    top: "-1.8px",
                    cursor: "pointer",
                  }}
                >
                  <ExportOutlined />
                </span>
              </Timeline.Item>
            );
          })}
        </Timeline>
      ) : (
        <Empty
          style={{ marginTop: 50 }}
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="No Items Available"
        />
      )}
      {!(items.length < 10) ? (
        <Button
          style={{ width: "100%" }}
          onClick={() => navigate(showAllLink)}
        >
          {showAllButtonLabel}
        </Button>
      ) : null}
    </React.Fragment>
  );
};

export default AssetTimeline;
