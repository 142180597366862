import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Column } from '@ant-design/plots';

 const Chart = () => {
  const data = [
    {
      type: '21/05',
      sales: 38,
    },
    {
      type: '22/05',
      sales: 52,
    },
    {
      type: '23/05',
      sales: 61,
    },
    {
      type: '24/05',
      sales: 145,
    },
    {
      type: '25/05',
      sales: 48,
    },
    {
      type: '26/05',
      sales: 38,
    },
    {
      type: '27/05',
      sales: 38,
    },
    {
      type: '28/05',
      sales: 38,
    },
  ];

  const config = {
    data,
    xField: 'type',
    yField: 'sales',
    label: {
      position: 'middle',
      style: {
        fill: 'white',
        opacity: 0.9,
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
      },
     
    },
    colorField: 'type',
    meta: {
      type: {
        alias: 'Date',
      },
      sales: {
        alias: 'Download',
      },
    },
    height:300,
    width:900,
    autoFit:true,
    theme: {
      styleSheet: {
        brandColor: '#4cbb17'
      }
    }
    
  };
  return <Column {...config} />;
};

export default Chart;