import { Breadcrumb, DatePicker, Pagination, Tag, Tooltip, Typography } from "antd";
import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PageHeaderListForm } from "@dashboardpages/PageHeader";
import { Table } from "antd";
import { AppObject } from "@context/AppObject";
import { useState } from "react";
import { convertDateToNestorTime } from "../../../../utilities/datehelper";
import { getCategoriesData, getPriortiesData, getTickets } from "../../../../services/SupportFeature";
import { PAGE_SIZE } from "@localconfig/envconfig";
const pageSize = PAGE_SIZE;
const { Text } = Typography;

const TotalComponent = ({ paginationConfg }) => {
  return (
    <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "end", marginBlock: "10px" }}>
      <Pagination
        {...paginationConfg}
        className="nestor_pagination"
        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} Tickets`}
        style={{
          textAlign: "left",
        }}
      />
    </div>
  );
};

const Support = () => {
  const navigate = useNavigate();
  const [tickets, setTickets] = useState([]);
  // const [pageNumber, setPageNumber] = useState(1);
  const [total, setTotal] = useState();
  const [categories, setCategories] = useState([]);
  const [priorities, setPriorites] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [tablefilters, setTableFilters] = useState({ pageNumber: 1 });

  const setDateFilter = (dates) => {
    if (dates !== null) {
      // setStartDate(new Date(dates[0]?.$d).toISOString());
      // setEndDate(new Date(dates[1]?.$d).toISOString());
      setTableFilters({
        ...tablefilters,
        startDate: new Date(dates[0]?.$d).toISOString(),
        endDate: new Date(dates[1]?.$d).toISOString(),
      });
    } else {
      setTableFilters({
        ...tablefilters,
        startDate: "",
        endDate: "",
      });
      // setEndDate("");
      // setStartDate("");
    }
  };

  const getColumns = () => {
    return [
      {
        title: "Ticket Id",
        dataIndex: "ticketId",
        key: "ticketId",
        render: (text, record) => record.ticketId?.split("-")[0],
      },
      {
        title: "Subject",
        dataIndex: "subject",
        key: "subject",
        render: (text) => (
          <Tooltip title={text}>
            <Text style={{ width: 80, textAlign: "center" }} ellipsis>
              {text}
            </Text>
          </Tooltip>
        ),
      },

      {
        title: "Category",
        key: "category",
        dataIndex: "category",
        render: (text) => <Tag>{text}</Tag>,
        filters: [...categories],
      },
      {
        title: "Priority",
        key: "priority",
        dataIndex: "priority",
        filters: [...priorities],
        render: (text) => <Tag>{text}</Tag>,
      },

      {
        title: "Status",
        key: "status",
        dataIndex: "status",
        render: (text) => (
          <Tag color={text === "OPEN" || text === "CLOSED" ? "#32a852" : "red"}>
            <b>{text}</b>
          </Tag>
        ),
        // filterSearch: true,
        filters: [
          {
            text: "Open",
            value: "OPEN",
          },
          {
            text: "Closed",
            value: "CLOSED",
          },
        ],
      },
      {
        title: "Created At",
        key: "createdAt",
        dataIndex: "createdAt",
        filterDropdown: <DatePicker.RangePicker onChange={setDateFilter} />,
        render: (createdAt) => {
          return convertDateToNestorTime(createdAt);
        },
      },
    ];
  };
  const generatefilterQuery = () => {
    delete tablefilters?.createdAt;
    // let query = `pageNumber=${pageNumber}&pageSize=${pageSize}&startDate=${startDate || ""}&endDate=${endDate || ""}`;
    let query = `pageSize=${pageSize}`;
    for (let filter in tablefilters) {
      if (tablefilters[filter] != null) {
        if (Array.isArray(tablefilters[filter]) && tablefilters[filter]?.length > 0) {
          for (let element in tablefilters[filter]) {
            query += `&${filter}=${tablefilters[filter][element]}`;
          }
        } else {
          query += `&${filter}=${tablefilters[filter]}`;
        }
      }
    }
    return query;
  };

  const fetchTickets = () => {
    getTickets(generatefilterQuery()).then((res) => {
      setTotal(res?.data?.totalResults);
      // setPageNumber(res?.data?.pageNumber);
      setTickets(res?.data?.results);
    });
  };

  const fetchPriorities = () => {
    getPriortiesData().then((res) => {
      let prioritesData = [];
      res?.data?.priorities?.forEach((data) => {
        prioritesData.push({
          value: data.priority,
          text: data.priority,
        });
      });
      setPriorites(prioritesData);
    });
  };

  const fetchCategories = () => {
    getCategoriesData().then((res) => {
      let categoriesData = [];
      res?.data?.categories?.forEach((data) => {
        categoriesData.push({
          value: data.category,
          text: data.category,
        });
      });
      setCategories(categoriesData);
    });
  };

  const onSearch = (filter) => {
    setTableFilters({ ...tablefilters, ...filter, pageNumber: 1 });
  };

  useEffect(() => {
    fetchTickets();
    fetchPriorities();
    fetchCategories();
    return () => {};
  }, [tablefilters]);

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Tickets</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeaderListForm
        objectId={AppObject.SUPPORT}
        title="Tickets"
        onAdd={() => navigate("create")}
        onSearch={(val) => onSearch({ search: val })}
        searchPlaceHolderText="Search Tickets"
      />

      <TotalComponent
        paginationConfg={{
          total: total /* total number of items */,
          pageSize: pageSize /* items per page */,
          current: tablefilters.pageNumber /* current page number */,
          onChange: (pageNumber) => setTableFilters({ ...tablefilters, pageNumber }),
          showSizeChanger: false,
        }}
      />
      <Table
        sticky
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              navigate("details/" + record.ticketId.replace("#", ""));
            }, // click row
          };
        }}
        onChange={(pagination, filters, sorter, extra) => onSearch(filters)}
        columns={getColumns()}
        dataSource={tickets}
        size="large"
        // title={() => showTotal}
        className={"nestor_table"}
        pagination={false}
      />
      <TotalComponent
        paginationConfg={{
          total: total /* total number of items */,
          pageSize: pageSize /* items per page */,
          current: tablefilters.pageNumber /* current page number */,
          onChange: (pageNumber) => setTableFilters({ ...tablefilters, pageNumber }),
          showSizeChanger: false,
        }}
      />
    </>
  );
};

export default Support;
