import React from "react";
import { Button, Modal } from "antd";

const FailureModel = ({ visible, onLeftClick, onRightClick, msg = "" }) => {
    return (
        <Modal
            open={visible}
            title="Failure"
        
            footer={[
                <Button type="primary" onClick={onLeftClick}>
                    Try Again
                </Button>,
                <Button type="primary" onClick={onRightClick}>
                    Close
                </Button>,
            ]}
        >
            <p>{msg || "Sorry! We are unable to process your request right now."}</p>
        </Modal>
    );
};

export default FailureModel;