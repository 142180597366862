export const HOST = process.env.REACT_APP_HOST;
export const PORT = process.env.REACT_APP_PORT;
export const GREEN_BACKEND_API_URL = process.env.REACT_APP_GREEN_BACKEND_API_URL;
export const NESTOR_ANALYTICS_API_URL = process.env.REACT_APP_NESTOR_ANALYTICS_API_URL;
export const SIGNIN_PATH = process.env.REACT_APP_SIGN_IN_PATH;
export const SIGNOUT_PATH = process.env.REACT_APP_SIGN_OUT_PATH;
export const SIGNUP_PATH = process.env.REACT_APP_SIGN_UP_PATH;
export const PRODUCTS_PATH = process.env.REACT_APP_PRODUCTS_PATH;
export const PRODUCTTYPE_API_PATH = process.env.REACT_APP_PRODUCTTYPE_API_PATH;
export const GAMEENGINES_API_PATH = process.env.REACT_APP_GAMEENGINE_API_PATH;
export const ASSET_TYPE_PATH = process.env.REACT_APP_ASSETTYPE_API_PATH;
export const GAMEPLATFORMS_API_PATH = process.env.REACT_APP_GAMEPLATFORMS_API_PATH;
export const TARGETPLATFORMS_API_PATH = process.env.REACT_APP_TARGETPLATFORMS_API_PATH;
export const GAMES_API_PATH = process.env.REACT_APP_GAMES_API_PATH;
export const USERS_API_PATH = process.env.REACT_APP_USERS_API_PATH;
export const USER_ROLES_API_PATH = process.env.REACT_APP_USER_ROLES_API_PATH;
export const ROMS_API_PATH = process.env.REACT_APP_ROMS_API_PATH;
export const AZURE_BASE_GREEN = process.env.REACT_APP_AZURE_BASE_GREEN;
export const AZURE_BASE_BLUE = process.env.REACT_APP_AZURE_BASE_BLUE;
export const SUBSCRIPTIONS_API_PATH = process.env.REACT_APP_SUBSCRIPTIONS_API_PATH;
export const ALLPRODUCTS_API_PATH = process.env.REACT_APP_ALLPRODUCTS_API_PATH;
export const ALLASSETS_API_PATH = process.env.REACT_APP_ALLASSETS_API_PATH;
export const ASSETS_API_PATH = process.env.REACT_APP_ASSETS_API_PATH;
export const ASSETS_LIST_API_PATH = process.env.REACT_APP_ASSETS_LIST_API_PATH;
export const GAME_ASSETS_API_PATH = process.env.REACT_APP_GAME_ASSETS_API_PATH;
export const GET_ORGANIZATION_PATH = process.env.REACT_APP_GET_ORGANIZATION_PATH;
export const GET_COUNTRY_API_PATH = process.env.REACT_APP_GET_COUNTRY_API_PATH;
export const GET_BRANDS_API_PATH = process.env.REACT_APP_GET_BRANDS_API;
export const ASSET_STATE_PATH = process.env.REACT_APP_ASSET_STATE_PATH;
export const LIVE_ASSET_PATH = process.env.REACT_APP_LIVE_ASSET_PATH;
export const ENV_ASSET_PATH = process.env.REACT_APP_ENV_ASSET_PATH;
export const ASSET_CONTAINER_PATH = process.env.REACT_APP_ASSET_CONTAINER_PATH;
export const ASSET_VERSION_PATH = process.env.REACT_APP_ASSET_VERSION_PATH;
export const TOTAL_ASSET_SIZE_PATH = process.env.REACT_APP_TOTAL_ASSET_SIZE_PATH;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const ENVIRONMENT_PATH = process.env.REACT_APP_ENVIRONMENT_PATH;
export const PRODUCTION = process.env.REACT_APP_PRODUCTION;
export const COUNT_PATH = process.env.REACT_APP_COUNT_PATH;
export const PRIVATE_ASSET_API_KEY_PATH = process.env.REACT_APP_PRIVATE_ASSET_API_KEY_PATH;
export const DOWNLOADURL_PATH = process.env.REACT_APP_DOWNLOADURL_PATH;
export const SEARCH_PATH = process.env.REACT_APP_SEARCH;
export const PRIVATE_ASSET_DOWNLOADS_PATH = process.env.REACT_APP_PRIVATE_ASSET_DOWNLOADS_PATH;
export const PRIVACY_POLICY_PATH = process.env.REACT_APP_PRIVACY_POLICY_PATH
export const TERMS_OF_USE_PATH = process.env.REACT_APP_TERMS_OF_USE_PATH
export const USERS_THUMBNAIL_UPDATE = process.env.REACT_APP_USERS_THUMBNAIL_UPDATE
export const SUPPORT_TICKET = process.env.REACT_APP_SUPPORT_TICKET
export const SUPPORT_CATEGORIES = process.env.REACT_APP_SUPPORT_CATEGORIES
export const SUPPORT_PRIORITIES = process.env.REACT_APP_SUPPORT_PRIORITIES
export const RESET_PASSWORD_REQUEST = process.env.REACT_APP_RESET_PASSWORD_REQUEST
export const RESET_PASSWORD = process.env.REACT_APP_RESET_PASSWORD
export const GET_TOTAL_ASSET_VERSION = process.env.REACT_APP_GET_TOTAL_ASSET_VERSION
export const PAGE_SIZE = process.env.REACT_APP_PAGE_SIZE
export const CREATE_ASSET_COLLECTION = process.env.REACT_APP_CREATE_ASSET_COLLECTION
export const GET_ALL_ASSET_COLLECTIONS = process.env.REACT_APP_GET_ALL_ASSET_COLLECTIONS
export const GET_ASSET_COLLECTIONS_DOWNLOAD_URLS = process.env.REACT_APP_GET_ASSET_COLLECTIONS_DOWNLOAD_URLS
export const DSN = process.env.REACT_APP_DSN
