import axios from "@auth/localaxios";
import { GAME_ASSETS_API_PATH, COUNT_PATH } from "@localconfig/envconfig";

export const getGameAssets = async () => {
  return await axios.get(GAME_ASSETS_API_PATH);
};

export const getGameAssetById = async assetId => {
  return await axios.get(GAME_ASSETS_API_PATH + "/" + assetId);
};

export const createPrivateGameAsset = async formData => {
  return await axios.post(GAME_ASSETS_API_PATH, formData, {
    headers: {
      "Content-Type": "multipart/form-data;"
    },
    withCredentials: true
  });
};
export const updateAssetImage = async formData => {
  const response = await axios.put(GAME_ASSETS_API_PATH, formData, {
    withCredentials: true,
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
  return response;
};

export const getTotalGameAssetCount = async () => await axios.get(GAME_ASSETS_API_PATH + COUNT_PATH);

