import React, { useEffect, useState } from "react";
import { Row, Col, Skeleton, Empty, Breadcrumb } from "antd";
import { useNavigate, Link } from "react-router-dom";
import GameCard from "@dashboardcards/GameCard";
import { getActiveGames } from "@services/gamesService";
import {AppObject} from "@context/AppObject";
import { PageHeaderListForm } from "@dashboardpages/PageHeader";

const GameList = () => {
  const [gameList, setGameList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [emptyData, setEmptyData] = useState(false);

  const navigate = useNavigate();
  const fetchActiveGameList = () => {
    getActiveGames().then((response) => {
      setLoading(false);
      if (response.data?.dataList && response.data?.dataList?.length > 0) setGameList(response.data.dataList);
      else setEmptyData(true);
    });
  };

  useEffect(() => {
    fetchActiveGameList();
  },[]);

  return (
    <React.Fragment>
      <Breadcrumb>
        <Breadcrumb.Item>
        <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Games</Breadcrumb.Item>
      </Breadcrumb>
    <div>
      {/* <PageHeader
        className="site-page-header"
        // onBack={() => null}
        backIcon={null}
        title={String(window.location.pathname.split("/")[1])}
        extra={
          <AddNewButton onClick={() => navigate("create")} />
        }
      /> */}
      <PageHeaderListForm
          objectId={AppObject.GAMES}
          title={String(window.location.pathname.split("/")[1])}
          onAdd={() => navigate("create")}
        />
      <Row gutter={[32, 32]} style={{ padding: 10 }}>
        <Skeleton loading={loading} active />
        {emptyData ? (
          <Col span={24}>
            <Empty description={<span>No Games in the system</span>} />
          </Col>
        ) : (
          gameList.map((game, i) => <GameCard onClick={() => navigate(game.gameId)} key={i} game={game} />)
        )}
      </Row>
    </div>
    </React.Fragment>
  );
};

export default GameList;
