import React from "react";
import { Col, Card, Row, Badge } from "antd";
import { EmptyView } from "@vyomr/nestor_components";
import { StringHelper } from "@utilities";
const { Meta } = Card;

//props needs emptyText,items<{title,url,description}>
const CardList = (props) => {
  const ListView = () => {
    return (
      <Row
        gutter={[
          { xs: 8, sm: 16, md: 24, lg: 24 },
          { xs: 8, sm: 16, md: 24, lg: 24 },
        ]}
        style={props.style}
      >
        {props?.items.map((item, index) => (
          <Col lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
            {item?.badge ? (
              <Badge.Ribbon text={item.badge} color={item?.badgeColor || "green-inverse"}>
                <Card
                  onClick={() => {
                    props?.onClick(item, index);
                  }}
                  hoverable
                  key={item.key || index}
                  bodyStyle={{ display: "flex", justifyContent: "center", padding: 10 }}
                  cover={
                    item?.url || item?.thumbnail ? (
                      <div style={{ display: "flex", paddingTop: 10, justifyContent: "center" }}>
                        <img alt="" style={{ objectFit: "scale-down", height: 150 }} src={item.url || item.thumbnail} />
                      </div>
                    ) : (
                      <h1
                        style={{
                          background: "#eee",
                          height: "150px",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "64px",
                          textAlign: "center",
                          margin: "auto",
                        }}
                      >
                        {StringHelper.getInitials(item?.title, true)}
                      </h1>
                    )
                  }
                >
                  <Meta title={item.title} description={item?.description || item?.data.envId} />
                </Card>
              </Badge.Ribbon>
            ) : (
              <Card
                onClick={() => {
                  props?.onClick(item, index);
                }}
                hoverable
                key={item.key || index}
                bodyStyle={{ display: "flex", justifyContent: "center", padding: 10 }}
                cover={
                  item?.url || item?.thumbnail ? (
                    <div style={{ display: "flex", paddingTop: 10, justifyContent: "center" }}>
                      <img alt="" style={{ objectFit: "scale-down", height: 150 }} src={item.url || item.thumbnail} />
                    </div>
                  ) : (
                    <div style={{
                      display: "flex", paddingTop: 10, justifyContent: "center", background: "#eee",
                    }}>

                      <h1
                        style={{
                          height: 150,
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          fontSize: "64px",
                          textAlign: "center",
                          margin: "auto",
                        }}
                      >
                        {StringHelper.getInitials(item?.title, true)}
                      </h1>
                    </div>
                  )
                }
              >
                <Meta
                  style={{ textAlign: "center" }}
                  title={item.title}
                  description={item?.description || item?.data.envId}
                />
              </Card>
            )}
          </Col>
        ))}
      </Row>
    );
  };

  return props?.items?.length ? (
    <ListView />
  ) : (
    props?.hideEmptyView ?null: <EmptyView
      onButtonClick={props.onButtonClick}
      buttonText={props.buttonText}
      disabled={props.disabled}
      msg={props.msg}
    /> 
  )
};

export default CardList;
