import React from "react";
import { Modal } from "antd";

const InformationModal = (props) => {
  return (
    <Modal maskClosable={false} title={props.title} open={props.open} footer={null} onCancel={props.onCancel}>
      <ol>
        {props.content.map((obj, id) => (
          <li key={id}>{obj}</li>
        ))}
      </ol>
    </Modal>
  );
};

export default InformationModal;
