import React from 'react';
import { Card, Empty } from "antd";

export const EmptyDataCard = (props) => {
    return (
        <React.Fragment>
            <Card
                style={{ height: "400px" }}
                bordered={false}
                hoverable
                headStyle={{ borderBottom: "0 none" }}
                title={props.title}
            >
                <div style={{ marginTop: '70px', }}>
                    <Empty description="Not Enough Data Available" />
                </div>
            </Card>
        </React.Fragment>
    )
}