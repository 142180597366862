import { PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";

export const AddNewButton = (props) => {
  return (
    <Button
      key={props.key || 1}
      style={{
        width: 40,
        height: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      onClick={props?.onClick}
    >
      <PlusOutlined />
    </Button>
  );
};
