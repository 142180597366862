import { Form, Input, Button, Alert, Checkbox } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { signin } from "@auth";
import useAuth from "@hooks/useAuth";
import { useEffect, useState } from "react";
import { getUserROMByRoleId } from "@services/systemService";

const FormItem = Form.Item;

const LoginForm = () => {
  let navigate = useNavigate();
  const { setAuth } = useAuth();
  let [form] = Form.useForm();
  const [values, setValues] = useState({
    email: "",
    password: "",
    error: "",
    loading: false,
    redirectToReferrer: false,
  });

  const handleChange = (name) => (event) => {
    setValues({ ...values, error: false, [name]: event.target.value });
  };

  // const { email, password, loading, error } = values;

  const showError = () => (
    <Alert
      message={values?.error}
      type="error"
      showIcon
      style={{
        marginBottom: "10px",
      }}
    />
  );

  const getRomMap = (roms) => {
    const rom = {};
    for (let index = 0; index < roms.length; index++) {
      const singleRom = roms[index];
      rom[singleRom?.object] = singleRom;
    }
    return rom;
  };

  const clickSubmit = (event) => {
    setValues({ ...values, error: false, loading: true });
    signin({ email: values?.email, password: values?.password }).then((responseData) => {
      if (responseData?.status === 200) {
        const roles = responseData?.data?.user?.roles;
        const roleId = roles?.length > 0 && roles[0];
        getUserROMByRoleId(roleId)
          .then((romResponse) => {
            const roms = romResponse?.data?.roms;
            const rom = getRomMap(roms);

            setAuth({
              token: responseData?.data?.token,
              user: responseData?.data?.user,
              rom,
            });
            setValues({
              ...values,
              email: "",
              password: "",
              loading: false,
              error: "",
              redirectToReferrer: true,
            });
            form.resetFields();
            navigate("/dashboard", { replace: true });
          })
          .catch((err) => {
            setValues({
              ...values,
              loading: false,
              error: "Unable to load user data. Please try after sometime",
              redirectToReferrer: false,
            });
          });
      } else if (!responseData.response) {
        setValues({
          ...values,
          loading: false,
          error: "No Response From Server. Please try after sometime",
          redirectToReferrer: false,
        });
      } else if (responseData?.response?.status === 401) {
        setValues({
          ...values,
          // email: "",
          // password: "",
          loading: false,
          error: responseData?.response?.data?.message,
          redirectToReferrer: false,
        });
      } else if (responseData?.response?.status === 404) {
        setValues({
          ...values,
          // email: "",
          // password: "",
          loading: false,
          error: "Please try after sometime",
          redirectToReferrer: false,
        });
      } else if (responseData?.response?.status === 400) {
        setValues({
          ...values,
          loading: false,
          error: "Please provide valid Email & Password",
          redirectToReferrer: false,
        });
      } else {
        setValues({
          ...values,
          loading: false,
          error: "Please try after sometime",
          redirectToReferrer: false,
        });
      }
    });
  };

  return (
    <>
      <Form className="login-form" required form={form}>
        {values?.error && showError()}
        <FormItem
          name="email"
          rules={[{ required: true, message: "Please input your email!" }]}
          wrapperCol={{ span: 24 }}
        >
          <Input
            prefix={<MailOutlined style={{ color: "#589636", fontSize: 16 }} />}
            type="email"
            placeholder="Email"
            onChange={handleChange("email")}
            value={values?.email}
            autoFocus
          />
        </FormItem>
        <FormItem
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
          wrapperCol={{ span: 24 }}
        >
          <Input
            prefix={<LockOutlined style={{ color: "#589636", fontSize: 16 }} />}
            type="password"
            placeholder="Password"
            onChange={handleChange("password")}
            value={values?.password}
          />
        </FormItem>
        <FormItem className="form-button">
          <div className="login-form-forgot">
            <Checkbox>Remember me</Checkbox>
            <a onClick={()=> navigate("/forgot-password")}>Forgot password</a>
          </div>
          <Button
            type="primary"
            htmltype="submit"
            className="login-form-button"
            onClick={clickSubmit}
            loading={values?.loading}
          >
            Log in
          </Button>
        </FormItem>
      </Form>
    </>
  );
};

export default LoginForm;
