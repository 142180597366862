import axios from "@auth/localaxios";
import { SUBSCRIPTIONS_API_PATH ,ALLPRODUCTS_API_PATH} from "@localconfig/envconfig";

export const createNewSubscription = async (data) => {
  try {
    const response = await axios.post(SUBSCRIPTIONS_API_PATH, data, { withCredentials: true });
    return response;
  } catch (error) {
    return error;
  }
};

export const getAllSubscriptions = async () => {
  try {
    const response = await axios.get(SUBSCRIPTIONS_API_PATH, { withCredentials: true });
    return response;
  } catch (error) {
    return error;
  }
};

export const getSubscriptionById = async (subscriptionId) => {
  try {
    const response = await axios.get(SUBSCRIPTIONS_API_PATH+"/"+subscriptionId, { withCredentials: true });
    return response;
  } catch (error) {
    return error;
  }
};


export const getSubscriptionByProductId = async productId => {
  try {
    const response = await axios.get(
      ALLPRODUCTS_API_PATH +"/"+ productId+"/subscriptions",
      {
        withCredentials: true
      }
    );
    return response;
  } catch (error) {
    return error;
  }
};




// export const getGameByGameId = async gameId => {
//   try {
//     const fullPath = GAMES_API_PATH + "/" + gameId;
//     console.log("🚀 ~ file: gamesService.js ~ line 55 ~ fullPath", fullPath)

//     const response = await axios.get(fullPath, { withCredentials: true });
//     return response;
//   } catch (error) {
//     return error;
//   }
// };

// export const deleteGameByGameId = async gameId => {
//   try {
//     const fullPath = GAMES_API_PATH.concat("/", gameId);
//     const response = await axios.delete(fullPath, { withCredentials: true });
//     return response;
//   } catch (error) {
//     return error;
//   }
// };
