import React from "react";
import "./AssetVersionGenericPreview.css";

const GenericPreview = ({ file }) => {
  // thumbnail is not required on asset version 
  return (
    <div>
      <div className="gen-comp">
        <div className="gen-img">
          <img crossOrigin="anonymous" src={process.env.PUBLIC_URL + "/3d.svg"} className="img" alt="" />
        </div>
      </div>
    </div>
  );
};

export default GenericPreview;
