import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import React, { useState } from "react";
import RolesModal from "../modals/RolesModal";

const IButton = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <RolesModal role={props.role} options={props.options} value={props.value} open={open} setOpen={setOpen} />
      <Tooltip title="Click to view Access Control List" trigger="hover">
        <InfoCircleOutlined
          onClick={() => setOpen(true)}
          style={{
            color: "#00b96b",
            fontSize: "1rem",
            cursor: "pointer",
            ...props.positionStyle,
          }}
        />
      </Tooltip>
    </>
  );
};

export default IButton;
