import initials from "initials";

export const toFirstLetterUpperCase = (stringValue) => {
  const trimmedValue = stringValue?.trim();
  const firstChar = trimmedValue?.charAt(0);
  return trimmedValue?.replace(firstChar, firstChar?.toUpperCase());
};

export const getInitials = (value, existing) => {
  if (!value) return "";
  let lcValue = value.toLowerCase();
  if (lcValue.split(" ").length > 2) {
    lcValue = lcValue.split(" ").slice(0, 2).join(" ");
  }
  return initials(lcValue, { existing })?.toUpperCase();
};
