import axios from "axios";
import { message } from 'antd'
const envconfig = require("@localconfig/envconfig");

const customAxios = axios.create({
    baseURL: envconfig.GREEN_BACKEND_API_URL,
    withCredentials: true,
})

export const analyticsAxios = axios.create({
    baseURL: envconfig.NESTOR_ANALYTICS_API_URL,
})

// customAxios.interceptors.response.use(response => {
//     return response;
// }, error => {
//     const status = error.response.status;
//     if (ErrorMessage[status]) {
//         message.error(ErrorMessage[status]);
//     }
//     return Promise.reject(error)
// })

const responseInterceptor = (response) => {
    return response;
}

const errorInterceptor = (error) => {
    const status = error.response.status;
    console.log("Error: ", error.response);
    if (ErrorMessage[status]) {
        message.error(ErrorMessage[status]);
    }
    return Promise.reject(error)
}


// customAxios.interceptors.response.use(responseInterceptor, errorInterceptor);
// analyticsAxios.interceptors.response.use(responseInterceptor, errorInterceptor);

const ErrorMessage = {
    401: 'Please login and try again',
    500: 'Something went wrong!, Please try again later.',
    generic: 'Something went wrong!, Please try again later.'
}

export default customAxios

