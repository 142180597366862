import { ResponsivePieCanvas } from "@nivo/pie";
const CustomDoughnut = (props) => (
  <ResponsivePieCanvas
    data={[
      {
        id: "default",
        label: "Default",
        value: props.default,
        color: "#108ee9",
      },
      {
        id: "offline",
        label: "Offline",
        value: props.offline,
        color: "#C0C0C0",
      },
      {
        id: "live",
        label: "Live",
        value: props.live,
        color: "#87d068",
      },
    ]}
    margin={{ top: 25, right: 20, bottom: 30, left: 20 }}
    align="center"
    innerRadius={0.5}
    padAngle={0.7}
    cornerRadius={3}
    activeOuterRadiusOffset={8}
    colors={{ datum: 'data.color' }}
    // borderColor={{
    //   from: "color",
    //   modifiers: [["darker", 0.6]],
    // }}
    arcLinkLabelsSkipAngle={10}
    arcLinkLabelsTextColor="#333333"
    arcLinkLabelsThickness={2}
    arcLinkLabelsColor={{ from: "color" }}
    arcLabelsSkipAngle={10}
    arcLabelsTextColor="#fff"
    //  height={250}
    defs={[
      {
        id: "dots",
        type: "patternDots",
        background: "inherit",
        color: "rgba(255, 255, 255, 0.3)",
        size: 4,
        padding: 1,
        stagger: true,
      },
      {
        id: "lines",
        type: "patternLines",
        background: "inherit",
        color: "rgba(255, 255, 255, 0.3)",
        // rotation: -45,
        lineWidth: 6,
        spacing: 10,
      },
    ]}
    fill={[
      {
        match: {
          id: "default",
        },
        id: "dots",
      },
      {
        match: {
          id: "live",
        },
        id: "dots",
      },
      {
        match: {
          id: "offline",
        },
        id: "dots",
      },
    ]}
    // legends={[
    //   {
    //     // anchor: "right",
    //     // direction: "column",
    //     justify: false,
    //     translateX: 140,
    //     translateY: 0,
    //     itemsSpacing: 2,
    //     itemWidth: 60,
    //     itemHeight: 14,
    //     itemTextColor: "#999",
    //     itemDirection: "left-to-right",
    //     itemOpacity: 1,
    //     symbolSize: 14,
    //     symbolShape: "circle",
    //   },
    // ]}
    // legends={true}
  />
);

export default CustomDoughnut;
