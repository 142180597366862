import React, { useEffect, useState } from "react";
import { message, Skeleton } from "antd";
import Style from "./css/GameAssetList.css";
import { getGameAssets } from "@services/gameAssetService";
import CardList from "@dashboardcards/CardList";
import { AppObject } from "@context/AppObject";
import { PageHeaderListForm } from "@dashboardpages/PageHeader";
import { useNavigate } from "react-router-dom";

const GameAssetList = (props) => {
  const [gameAssets, setGameAssets] = useState([]);
  const [isLoadingAsset, setLoadingAsset] = useState(true);
  const [listItem, setListItem] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    fetchAssets();
  }, []);

  const prepareListItem = (gameAssetList) => {
    if (gameAssetList?.length) {
      const gameAssetListItems = gameAssetList.map((gameAssets) => {
        return {
          title: gameAssets?.assetName,
          description: gameAssets?.assetId,
          url: gameAssets?.assetImage,
          data: gameAssets,
        };
      });
      setListItem(gameAssetListItems);
    }
  };

  const fetchAssets = () => {
    getGameAssets()
      .then((res) => {
        if (res?.status === 200 && res?.data?.assets?.length) {
          setGameAssets(res.data.assets);
          prepareListItem(res.data.assets);
        }
        setLoadingAsset(false);
      })
      .catch((err) => {
        setLoadingAsset(false);
        err?.response?.status !== 404 && message.error("Unable to load private assets.");
      });
  };

  const onListItemClick = (item, index) => {
    navigate("/gameassets/" + item?.data?.assetId);
  };

  return (
    <React.Fragment>
      {/* <PageHeader
        className="site-page-header"
        title="Private Assets"
        extra={[
          <AddNewButton
            onClick={() => {
              navigate("/gameassets/create");
            }}
          />,
        ]}
      /> */}
        <PageHeaderListForm
          objectId={AppObject.ASSET_DETAIL}
          title="Private Assets"
          onAdd={() => {navigate("/gameassets/create");}}
        />
      <div className={Style["main-container"]}>
        <Skeleton loading={isLoadingAsset} active>
          <CardList emptyText="No Asset available." items={listItem} onClick={onListItemClick} />
        </Skeleton>
      </div>
    </React.Fragment>
  );
};

export default GameAssetList;
