import React from "react";
import { Form, Switch } from "antd";
const ActiveSwitch = (props) => {
  return (
    <Form.Item
      label={props.label || "Active"}
      name={props.name || "active"}
      valuePropName="checked"
      rules={[{ required: false }]}
      style={{ alignItems: "flex-start" }}
    >
      <Switch style={{ alignSelf: "flex-start" }} defaultChecked={true} />
    </Form.Item>
  );
};

export default ActiveSwitch;
