import React from "react";
import { Form, Input } from "antd";

const Password = (props) => {
  return (
    <Form.Item
      label={props.label || "Password"}
      name={props.name || "password"}
      style={{ marginTop: "15px !important" }}
      rules={[
        { required: true, message: "Please enter password" },
        { max: 15, min: 8, message: "Password length must be between 8 to 15" },
      ]}
    >
      <Input autoComplete="new-password" type="password" style={{ height: 40, borderRadius: "10%" }} />
    </Form.Item>
  );
};

export default Password;
