import React from "react";
import { Form, Input } from "antd";

const emailValidationRegex = /^(?![0-9\s])[\w\.-]+@\w+\.\w+$/;

const Email = (props) => {
  const defaultName = "email"
  return (
    <Form.Item
      label={props?.label || "Email"}
      name={props?.name || defaultName}
      rules={[
        { required: true, message: `Please enter ${props?.label || defaultName} address` },
        { max: 64, min: 3, message: "character length must be between 3 to 64" },
        { pattern: emailValidationRegex, message: `${props?.label || defaultName} address only start with alphabet` },
      ]}
    >
      <Input type="email" defaultValue={props?.value} disabled={props?.disabled} placeholder="This will be the login Id" style={{ height: 40, borderRadius: "10%" }} />
    </Form.Item>
  );
};

export default Email;
