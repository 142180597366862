import { EditOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";

export const EditButton = (props) => {
  return (
    <>
      {/* <Tooltip title={props.title}> */}
      <Button
        key={props?.key || 1}
        style={{
          width: 40,
          height: 40,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        {...props}
      >
        <EditOutlined />
      </Button>
      {/* </Tooltip> */}
    </>
  );
};
