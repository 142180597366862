import React, { useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { Button, Form, Input, InputNumber, Switch, Tooltip, Skeleton, Modal, Breadcrumb, message } from "antd";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CancelButton } from "@dashboard/buttons/CancelButton";
import { RefreshButton } from "@dashboard/buttons/RefreshButton";
import { InboxOutlined } from "@ant-design/icons";
import { InfoCircleOutlined } from "@ant-design/icons";

import { getAssetCollectionById, updateAssetCollectionApi } from "@services/assetCollection";
import "./css/AssetCollection.css";

const UpdateAssetCollection = () => {
  const { collectionId } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [initialValues, setInitialValues] = useState({});
  const [collectionName, setCollectionName] = useState("");
  const [maxAssets, setMaxAssets] = useState(null);
  const [editable, setEditable] = useState();
  const [deployable, setDeployable] = useState();
  const [description, setDescription] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [localCacheControl, setLocalCacheControl] = useState(false);
  const [localCacheMaxAge, setLocalCacheMaxAge] = useState(30);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [collectionData, setCollectionData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const goBack = () => {
    navigate(-1);
  };

  const resetForm = () => {
    form.setFieldsValue(initialValues);
    setIsFormValid(false);
  };

  useEffect(() => {
    form.setFieldsValue({ editable: true });
    getAssetCollectionById(collectionId)
      .then((res) => {
        if (res.status === 200 && res.data) {
          const data = {
            collectionId: collectionId,
            collectionName: res.data?.collectionName,
            description: res.data?.description || "",
            maxAssets: res.data?.maxAssets,
            editable: res.data?.editable,
            deployable: res.data?.deployable,
            localCacheControl: res.data?.localCacheControl,
            localCacheMaxAge: res.data?.localCacheMaxAge,
          };
          setInitialValues(data); 
          form.setFieldsValue(data);
       
        }
        setLoading(false);
      })
      .catch((err) => {
        message.error("Unable to load Asset Collection. Please try again later.");
        setLoading(false);
      });
  }, [collectionId, form]);

  const checkFormValidity = () => {
    const hasError = form.getFieldsError().some(({ errors }) => errors.length > 0);
    const isFormCompleted =
      form.isFieldTouched("collectionName") &&
      form.isFieldTouched("maxAssets") &&
      form.isFieldTouched("editable") &&
      form.isFieldTouched("deployable") &&
      form.isFieldTouched("localCacheControl");
    const isValid = !hasError && isFormCompleted;
    setIsFormValid(isValid);
  };


  const handleFormSubmit = async () => {
    try {
      const values = await form.validateFields();

      setButtonLoading(true);

      const formData = {
        collectionName: values.collectionName,
        maxAssets: values.maxAssets,
        editable: values.editable,
        deployable: values.deployable,
        description: values.description,
        localCacheControl: values.localCacheControl,
      };
      if (localCacheControl === true){
        formData.localCacheMaxAge = values.localCacheMaxAge
      }

      const response = await updateAssetCollectionApi(collectionId, formData);

      if (response.status === 200) {
        setShowSuccessModal(true);
      } else {
        setErrorMessage(response.data?.message || "Failed to update the Asset Collection. Please try again.");
        setShowFailureModal(true);
      }
    } catch (error) {
      setErrorMessage(error?.response.data?.message || "Failed to update the Asset Collection. Please try again.");
      setShowFailureModal(true);
    } finally {
      setButtonLoading(false);
    }
  };
  const handleRetry = async () => {
    setShowFailureModal(false); // Close the failure modal before retrying
    handleFormSubmit(); // Retry the form submission
  };

  const handleCancel = () => {
    setShowFailureModal(false); // Close the failure modal
  };

  const handleSuccessDone = () => {
    navigate(`/assetcollection/${collectionId}`); // Navigate to the collection detail page
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/assetcollection">Asset Collection</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/assetcollection/${collectionId}`}>{collectionId}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Update</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          background: "white",
          padding: 10,
          marginBottom: 5,
          borderRadius: 12,
          boxShadow: "0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%)",
          marginTop: 15,
        }}
      >
        <PageHeader
          title={["Update Asset Collection"]}
          extra={[<RefreshButton onClick={resetForm} />, <CancelButton onClick={goBack} />]}
        />
        <div style={{ padding: "25px 180px" }}>
          <Form form={form} onFieldsChange={checkFormValidity} labelCol={{ span: 5 }}>
            <Form.Item label="Collection Id" name="collectionId">
              <Input disabled />
            </Form.Item>

            <Form.Item
              label="Collection Name"
              name="collectionName"
              rules={[
                { required: true, message: "Please enter Collection Name" },
                {
                  validator: (_, value) => {
                    if (!value) {
                      return Promise.resolve();
                    }
                    if (value.length < 3) {
                      return Promise.reject(new Error("Collection Name must be at least 3 characters long"));
                    }
                    if (!/^[A-Za-z0-9 _-]+$/.test(value)) {
                      return Promise.reject(
                        new Error(
                          "Collection Name should only contain alphanumeric characters, spaces, hyphens, and underscores"
                        )
                      );
                    }
                    if (/^[A-Za-z]/.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("First character must be an alphabet"));
                  },
                },
              ]}
              style={{ textAlign: "left" }}
            >
              <Input
                min={3}
                className="asset-c-inputs"
                value={collectionName}
                onChange={(e) => setCollectionName(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              label="Max Assets"
              name="maxAssets"
              rules={[
                { required: true, message: "Minimum value should be 1" },
                {
                  validator: (_, value) => {
                    if (value && !Number.isInteger(value)) {
                      return Promise.reject(new Error("Max Assets must be an integer"));
                    }
                    return Promise.resolve();
                  },
                },
              ]}
              style={{ textAlign: "left" }}
            >
              <InputNumber
                className="max-inputs"
                min={1}
                max={1000}
                changeOnWheel
                value={maxAssets}
                onChange={(value) => setMaxAssets(value)}
              />
            </Form.Item>

            <Form.Item 
             label={
              <span>
                Editable &nbsp;
                <Tooltip title="If this is turned on , then you can add or delete asset items from the collection , if turned off , you cannot add or delete asset items ">
                  <InfoCircleOutlined
                  />
                </Tooltip>{" "}
                &nbsp;
              </span>
            } 
            name="editable" 
            style={{ textAlign: "left" }}>
              <Switch
                defaultChecked={true}
                style={{ alignSelf: "flex-start" }}
                checked={editable}
                onChange={(checked) => setEditable(checked)}
              />
            </Form.Item>

            <Form.Item label="Deployable" name="deployable" style={{ textAlign: "left" }}>
              <Switch
                defaultChecked={true}
                style={{ alignSelf: "flex-start" }}
                checked={deployable}
                onChange={(checked) => setDeployable(checked)}
              />
            </Form.Item>

            <Form.Item label="Local Cache" name="localCacheControl" style={{ textAlign: "left" }}>
              <Switch
                defaultChecked={false}
                style={{ alignSelf: "flex-start" }}
                checked={localCacheControl}
                onChange={(checked) => setLocalCacheControl(checked)}
              />
            </Form.Item>

            {localCacheControl && (
              <Form.Item
                label={
                  <span>
                    Max Cache Age &nbsp;
                    <Tooltip title="Default 30 minutes and Max 525,600 minutes (365 days)">
                      <InfoCircleOutlined
                      // style={{ color: '#008000' }}
                      />
                    </Tooltip>{" "}
                    &nbsp;
                  </span>
                }
                name="localCacheMaxAge"
                rules={[
                  { required: true, message: "Please enter Cache Age" },
                  { type: "number", min: 30, max: 525600 },
                  {
                    validator: (_, value) => {
                      if (value && !Number.isInteger(value)) {
                        return Promise.reject(new Error("Max Cache Age must be an integer"));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                style={{ textAlign: "left" }}
              >
                <InputNumber
                  className="max-inputs"
                  min={30}
                  max={525600}
                  defaultValue={30}
                  value={localCacheMaxAge}
                  onChange={(value) => setLocalCacheMaxAge(value)}
                />
              </Form.Item>
            )}

            <Form.Item label="Description" name="description" style={{ textAlign: "left" }}>
              <Input.TextArea
                name="description"
                style={{ minHeight: "100px" }}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Item>
            <Form.Item style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                onClick={handleFormSubmit}
                loading={buttonLoading}
                disabled={!isFormValid}
              >
                Update Collection
              </Button>
            </Form.Item>
          </Form>

          <Modal
            open={showSuccessModal}
            title="Successfully Updated"
            onCancel={handleSuccessDone}
            footer={[
              <Button key="done" type="primary" onClick={handleSuccessDone}>
                Done
              </Button>,
            ]}
          >
            <p>Asset collection has been updated successfully!</p>
          </Modal>

          {/* Failure Modal */}
          <Modal
            open={showFailureModal}
            title="Error Updating Asset Collection"
            onCancel={handleCancel}
            footer={[
              <Button key="cancel" onClick={handleCancel}>
                Cancel
              </Button>,
              <Button key="retry" type="primary" onClick={handleRetry}>
                Try Again
              </Button>,
            ]}
          >
            <p>{errorMessage}</p>
          </Modal>
        </div>
      </div>
    </>
  );
};
export default UpdateAssetCollection;
