import axios from "@auth/localaxios";
import {
  GAMEENGINES_API_PATH,
  GAMEPLATFORMS_API_PATH,
  GAMES_API_PATH,
  COUNT_PATH
} from "@localconfig/envconfig";
import { AZURE_BASE_GREEN } from "@localconfig/envconfig";

export const getGameEngines = async () => {
  try {
    const response = await axios.get(GAMEENGINES_API_PATH);
    return response;
  } catch (error) {
    return error;
  }
};

export const getGamePlatforms = async () => {
  try {
    const response = await axios.get(GAMEPLATFORMS_API_PATH);
    return response;
  } catch (error) {
    return error;
  }
};

export const createNewGame = async formData => {
  try {
    const response = await axios.post(GAMES_API_PATH, formData, {
      headers: {
        "Content-Type": "multipart/form-data;"
      },
      withCredentials: true
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const updateGame = async formData => {
  try {
    const response = await axios.put(GAMES_API_PATH, formData, {
      headers: {
        "Content-Type":
          "multipart/form-data;"
      },
      withCredentials: true
    });
    return response;
  } catch (error) {
    return error;
  }
};


export const updateGameImage = async formData => {
  try {
    const response = await axios.put(GAMES_API_PATH+"/gameImage", formData, {
      headers: {
        "Content-Type":
          "multipart/form-data"
      },
      withCredentials: true
    });
    return response;
  } catch (error) {
    return error;
  }
}

export const getActiveGames = async () => {
  try {
    const response = await axios.get(GAMES_API_PATH+"?active=true", { withCredentials: true });
    return response;
  } catch (error) {
    return error;
  }
};


export const verifyGameId = async (gameId)=>{
  try {
    const response = await axios.get(GAMES_API_PATH+"/verify/"+gameId, { withCredentials: true });
    return response;
  } catch (error) {
    return error;
  }
}

export const getGameImageURL = async filePath => {
  return AZURE_BASE_GREEN.concat(filePath);
};

export const getGameByGameId = async gameId => {
  try {
    const fullPath = GAMES_API_PATH + "/" + gameId;
    const response = await axios.get(fullPath, { withCredentials: true });
    return response;
  } catch (error) {
    return error;
  }
};

export const deleteGameByGameId = async gameId => {
  try {
    const fullPath = GAMES_API_PATH.concat("/", gameId);
    const response = await axios.delete(fullPath, { withCredentials: true });
    return response;
  } catch (error) {
    return error;
  }
};

export const getTotalGameCount = async () => await axios.get(GAMES_API_PATH + COUNT_PATH);
