import axios from "@auth/localaxios";
import { USERS_API_PATH ,USERS_THUMBNAIL_UPDATE} from "@localconfig/envconfig";

export const getUsers = async () => {
  try {
    const response = await axios.get(USERS_API_PATH, { withCredentials: true });
    return response;
  } catch (error) {
    return error;
  }
};

export const getUserByEmail = async (email) => {
  try {
    const fullPath = `${USERS_API_PATH}/${email}`;
    return await axios.get(fullPath, { withCredentials: true });
  } catch (error) {
    return error;
  }
};

export const createUser = async (formData) => {
  return await axios.post(USERS_API_PATH, formData, {
    headers: {
      "Content-Type": "multipart/form-data;",
    },
    withCredentials: true,
  });
};

export const updateUser = async (email, formData) => {
  return await axios.put(`${USERS_API_PATH}/${email}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data;",
    },
    withCredentials: true,
  });
};

export const updateLogo = async (email, formData) => {
  return await axios.put(`${USERS_API_PATH}/${email}${USERS_THUMBNAIL_UPDATE}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data;",
    },
    withCredentials: true,
  });
};

export const deleteUser = async (email) => {
  return await axios.delete(`${USERS_API_PATH}/${email}`, {
    headers: {
      "Content-Type": "multipart/form-data;",
    },
    withCredentials: true,
  });
};
