import { Form, Input, Select, Upload, Modal, Button, Breadcrumb } from "antd";
import useAuth from "@hooks/useAuth";
import { InboxOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import "../css/form.css";

import SubmitButton from "../../buttons/SubmitButton";
import { PageHeaderCreateForm } from "../PageHeader";
import { AppObject } from "@context/AppObject";
import SuccessModel from "../../modals/Success";
import {
  createNewTicket,
  getCategoriesData,
  getPriortiesData,
  getSubCategoriesData,
} from "../../../../services/SupportFeature";
import FailureModel from "../../modals/Failure";

const CreateNewTicketPage = () => {
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const [attachment, setAttachment] = useState();
  // const [bugSelected, setbugSelected] = useState("none");
  const [isGameCreated, setIsGameCreated] = useState(false);
  const [modal, setIsModalVisible] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [priorities, setPriorities] = useState([]);
  const [errorModal, setErrorModal] = useState(false);
  const authData = useAuth();
  const navigate = useNavigate();
  const [ticketId, setTicketId] = useState();
  const [categorySelection, setCategorySelection] = useState(false);

  const uploadProps = {
    onRemove: (file) => {
      setAttachment(null);
    },
    beforeUpload: (file) => {
      setAttachment(file);
      return false;
    },
    attachment,
    maxCount: 1,
    listType: "picture",
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const OnOkayConfirmation = () => {
    setIsGameCreated(true);
    form.setFieldsValue({ attachment: attachment });
    const ticketData = new FormData();
    appendPayLoad(ticketData, "subject");
    appendPayLoad(ticketData, "category");
    appendPayLoad(ticketData, "priority");
    appendPayLoad(ticketData, "description");
    appendPayLoad(ticketData, "subCategory");
    appendPayLoad(ticketData, "attachment");
    appendPayLoad(ticketData, "alternateEmail");
    appendPayLoad(ticketData, "phoneNumber");

    createNewTicket(ticketData).then((response) => {
      if (response.status === 201) {
        setIsGameCreated(false);
        setIsModalVisible(false);
        setTicketId(response?.data?.supportTicket?.ticketId)
        setSuccessModal(true);
      } else {
        setIsGameCreated(false);
        setIsModalVisible(false);
        setErrorModal(true);
      }
    });
    return;
  };

  const appendPayLoad = (ticketData, fieldName) => {
    const fieldValue = form.getFieldValue(fieldName);
    if (fieldValue) {
      ticketData.append(fieldName, fieldValue);
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinish = () => {
    showModal();
  };

  const isDisabled = () => {
    let isInvalid =
      !(
        form.isFieldTouched("subject") &&
        form.isFieldTouched("category") &&
        isValidSubCategory() &&
        form.isFieldTouched("priority") &&
        form.isFieldTouched("description")
      ) || !!form.getFieldsError().filter(({ errors }) => errors.length).length;

    setDisabled(isInvalid);
  };

  const isValidSubCategory = () => {
    if (!subCategories || subCategories.length === 0) {
      return true;
    }
    let selectedSubCategory = form.getFieldValue("subCategory");
    const index = subCategories.some((subCategory) => subCategory.category === selectedSubCategory);

    return index !== -1;
  };

  useEffect(() => {
    getCategoriesData().then((res) => {
      if (res?.status === 200) {
        setCategories(res?.data?.categories);
      }
    });

    getPriortiesData().then((res) => {
      if (res?.status === 200) {
        setPriorities(res?.data?.priorities);
      }
    });
  }, []);

  const renderErrorModal = () => {
    return (
      <FailureModel
        title={"Error"}
        msg={`Something went wrong while creating ticket, please try again later!`}
        visible={errorModal}
        rightTitle={"Try Again"}
        leftTitle={"Go to Support List Page"}
        onLeftClick={() => navigate("/support")}
        onRightClick={() => setErrorModal(false)}
        onClose={() => setErrorModal(!errorModal)}
      />
    );
  };
  useEffect(() => {
    // OnOkayConfirmation()
    getSubCategoriesData(categorySelection).then((res) => {
      if (res?.data?.categories?.length > 0 && res?.status === 200) {
        setSubCategories(res?.data?.categories);
      } else {
        setSubCategories([]);
      }
    });
  }, [categorySelection]);

  return (
    <React.Fragment>
      <Modal
        open={modal}
        title="Confirmation"
        onCancel={handleCancel}
        footer={[
          <Button onClick={handleCancel}>Cancel</Button>,
          <Button loading={isGameCreated} type="primary" onClick={OnOkayConfirmation}>
            Create
          </Button>,
        ]}
      >
        <p>Are you sure you want to create this Ticket?</p>
      </Modal>

      <Breadcrumb style={{ marginBottom: 10 }}>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Tickets</Breadcrumb.Item>
      </Breadcrumb>
      <PageHeaderCreateForm
        onCancel
        navigate={() => {
          navigate("/support");
        }}
        objectId={AppObject.SUPPORT}
        title="Create New Ticket"
      />
      <Form
        form={form}
        name="createNewTicket"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
        onFinish={showModal}
        className="form"
        onFieldsChange={isDisabled}
        initialValues={{
          email: authData.auth.user.email,
          name: authData.auth.user.name,
        }}
      >
        <Form.Item label="Email" name="email">
          <Input style={{ height: 40 }} readOnly disabled />
        </Form.Item>
        <Form.Item label="Name" name="name">
          <Input style={{ height: 40 }} readOnly disabled />
        </Form.Item>
        <Form.Item
          label="Subject"
          name="subject"
          rules={[
            { required: true, message: "Please enter the Subject!" },
            { max: 64, message: "Subject must be less than 64 characters!" },
          ]}
        >
          <Input style={{ height: 40 }} placeholder="Subject" />
        </Form.Item>

        <Form.Item label="Category" name="category" rules={[{ required: true, message: "Please select Category!" }]}>
          <Select
            style={{ height: 40 }}
            placeholder="Select a Category"
            onChange={(e) => {
              setCategorySelection(e);
              setSubCategories([]);
              form.setFieldValue("subCategory");
            }}
          >
            {categories?.map((item, index) => (
              <Select.Option style={{ height: 40 }} value={item?.category} key={index}>
                {item?.category}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {subCategories?.length !== 0 ? (
          <Form.Item
            label="Sub Category"
            name="subCategory"
            rules={[{ required: true, message: "Please select sub category!" }]}
          >
            <Select style={{ height: 40 }} placeholder="Select a Sub Category">
              {subCategories?.map((item, index) => (
                <Select.Option style={{ height: 40 }} value={item?.category} key={index}>
                  {item?.category}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ) : null}
        <Form.Item label="Priority" name="priority" rules={[{ required: true, message: "Please select priority!" }]}>
          <Select style={{ height: 40 }} placeholder="Select Priority">
            {priorities?.map((item, index) => (
              <Select.Option style={{ height: 40 }} value={item?.priority} key={index}>
                {item?.priority}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
          rules={[
            { required: true, message: "Please enter description!" },
            {
              max: 4000,
              message: "Description should be less than or euqal 4000 characters",
            },
          ]}
        >
          <Input.TextArea style={{ height: 200 }} />
        </Form.Item>

        <Form.Item label="Attachment">
          <Form.Item
            name="attachment"
            className="image_uploader"
            valuePropName="attachment"
            getValueFromEvent={normFile}
            noStyle
          >
            <Upload.Dragger className="image_uploader" name="files" {...uploadProps}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or drag file to this area to upload</p>
              <p className="ant-upload-hint">Only jpeg or png files are supported.</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>
        <Form.Item
          label="Alternate Email"
          name="alternateEmail"
          rules={[{ max: 64, message: "Alernate email must be less than 64 characters!" }]}
        >
          <Input style={{ height: 40 }} />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="phoneNumber"
          rules={[{ max: 12, message: "Please enter valid phone number!" }]}
        >
          <Input style={{ height: 40 }} />
        </Form.Item>
        <SubmitButton isDisabled={disabled} onFinish={onFinish} title="Create Ticket" />
      </Form>
      {renderErrorModal()}
      <SuccessModel
        title={"Success"}
        msg={"Your ticket is created successfully! Your Ticket Id - " + ticketId}
        visible={successModal}
        rightTitle={"Go to Support List Page"}
        onClose={() => setSuccessModal(!successModal)}
        onRightClick={() => navigate("/support")}
      />
    </React.Fragment>
  );
};

export default CreateNewTicketPage;
