import React from "react";
import { Button, Modal } from "antd";

const ConfirmWarningModal = (props) => {
    const { visible, rightButtonText, leftButtonText, onLeftClick, onRightClick, msg = "", warningMsg }=props;
    return (
        <Modal
            open={visible}
            {...props}
            footer={[]}
        >
            <div>
                <p>{msg || "Sorry! We are unable to process your request right now."}</p>
                <p style={{ color: 'red' }}>{warningMsg}</p>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <Button onClick={onLeftClick}>
                        {leftButtonText}
                    </Button>
                    <Button type="primary" onClick={onRightClick}>
                        {rightButtonText}
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmWarningModal;