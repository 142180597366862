import React, { useState } from "react";
import "./ForgotPasswordPage.css";
import { useNavigate } from "react-router-dom";
import ForgotPassSuccess from "./ForgotPassSuccess";
import ForgotPassForm from "./ForgotPassForm";

function ForgotPasswordPage() {
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  return (
    <div className="forgot-main">
      <div className="forgot-pass-header">
        <div className="forgot-logo-div">
          <img src={process.env.PUBLIC_URL + "/nestor_text.png"} alt="Nestor" className="forgot-nestor-text-img" />
        </div>
        <div>
          <a className="login-link" onClick={() => navigate("/")}>
            Login
          </a>
        </div>
      </div>

      <div className="fp-div-container">
        <div className="forgot-pass-container">
          {success ? (
            <ForgotPassSuccess />
          ) : (
            <ForgotPassForm success={success} setSuccess={setSuccess} navigate={navigate} />
          )}
        </div>
      </div>
    </div>
  );
}

export default ForgotPasswordPage;
