import { Breadcrumb, Col, Row, Skeleton, Empty } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../css/UserCard.css";
import { getUsers, } from "@services/userService";
import { getUserRoleById } from "@services/systemService";
import UserCard from "@dashboardcards/UserCard";
import { AppObject } from "@context/AppObject";
import { PageHeaderListForm } from "@dashboardpages/PageHeader";
const UserData = () => {
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const getUsersList = () => {
    getUsers().then(response => {
      const users = response?.data?.users || [];
      setUserList(users);
      setLoading(false)
      fetchUserRoles(users)
    })
  };

  const fetchUserRoles = async (users) => {
    if (!!!users?.length) {
      return
    }
    for (let index = 0; index < users.length; index++) {
      const user = users[index];
      if (user?.roles?.length === 0) {
        continue;
      }

      const response = await getUserRoleById(user.roles[0]);
      setUserList((previousList) => {
        const list = previousList.length > 0 ? previousList : users
        list[index] = {
          ...user,
          ribbon: response?.data?.role?.roleName
        }
        return [...list]
      })
    }
  }

  useEffect(() => {
    getUsersList();
  }, [])

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Users</Breadcrumb.Item>
      </Breadcrumb>
      {/* <PageHeader
        ghost={false}
        title={"Users"}
        extra={getHeaderButtons()}
      /> */}
      <PageHeaderListForm
        objectId={AppObject.USER}
        title="Users"
        onAdd={() => { navigate("add"); }}
      />
      <Row gutter={[32, 16]} style={{ marginTop: 20 }}>
        <Skeleton loading={loading} active>
        {userList?.length ? (
          userList.map((user, i) => <UserCard key={i} title={user.email} thumbnail={user.thumbnail} initials={user.name} link={user.email} ribbon={user.ribbon} />)
        ) : (
          <Col span={24}>
            <Empty description={<span>No Users in the system</span>} />
          </Col>
        )}
        </Skeleton>
      </Row>
    </>
  );
};

export default UserData;
