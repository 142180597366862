import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Select, Upload } from "antd";
import { useNavigate } from "react-router-dom";
import "./css/form.css";
import GenericFormContainer from "./GenericFormContainer";
import { InboxOutlined } from "@ant-design/icons";

const BulkUpload = () => {
  const [formData] = Form.useForm();
  const [formField, setForm] = useState();
  const [isFormValid, setIsFormValid] = useState(false);
  const [successModalVisiblilty, setSuccessModalVisiblilty] = useState(false);
  const [failureModalVisiblilty, setFailureModalVisiblilty] = useState(false);
  const [gameImageFile, setGameImageFile] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    formData.setFieldsValue({ active: true });
  }, [formData]);

  const checkFormValidity = () => {
    const hasError = formData.getFieldsError().some(({ errors }) => errors.length > 0);
    const { creds, source } = formData.getFieldsValue();
    const isFormCompleted = creds?.trim() && source?.trim();

    const isValid = !hasError && isFormCompleted;
    setForm({ ...formField, creds, source });
    setIsFormValid(isValid);

  };

  const createEnvironment = () => {
    return;
  };

  const onSubmit = () => {
    createEnvironment();
  };

  const navigateToEnvironmentsList = () => {
    setFailureModalVisiblilty(false);
    setSuccessModalVisiblilty(false);
    navigate("/users");
  };

  const createMoreEnvironment = () => {
    setSuccessModalVisiblilty(false);
    formData.resetFields();
  };
  const tryAgain = () => {
    setFailureModalVisiblilty(false);
  };

  const sourceOptions = [
    {
      value: "gdrive",
      label: "Google Drive",
    },
  ];

  const uploadProps = {
    onRemove: (file) => {
      setGameImageFile([]);
    },
    beforeUpload: (file) => {
      setGameImageFile(file);
      return false;
    },
    gameImageFile,
    maxCount: 1,
    listType: "picture",
  };

  return (
    <>
      <GenericFormContainer
        formData={formData}
        path="bulk"
        formName={"Bulk Upload"}
        breadcrumbPath={"bulkUploadPath"}
        navigate={() => navigate("/dashboard")}
      >
        <Form
          form={formData}
          labelCol={{ span: 5 }}
          onFieldsChange={() => {
            checkFormValidity();
          }}
          wrapperCol={{ span: 14 }}
          initialValues={{ source: "gdrive" }}
          className={"form"}
        >
          <Form.Item label="Source" name="source">
            <Select options={sourceOptions} />
          </Form.Item>

          <Form.Item label="Key file" name="creds">
            <Input.TextArea style={{ height: 100 }} />
          </Form.Item>
          <Form.Item label="Json File Structure:">
            <Form.Item name="JSON file Structure" valuePropName="structure" noStyle>
              <Upload.Dragger name="files" {...uploadProps}>
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">Only Json file is supported.</p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <Button
              loading={false}
              onClick={onSubmit}
              type="primary"
              htmlType="submit"
              className={"button"}
              disabled={!isFormValid}
            >
              Start
            </Button>
          </Form.Item>
        </Form>
      </GenericFormContainer>

      <SuccessModel
        visible={successModalVisiblilty}
        onRightClick={navigateToEnvironmentsList}
        onLeftClick={createMoreEnvironment}
      />
      <FailureModel visible={failureModalVisiblilty} onRightClick={navigateToEnvironmentsList} onLeftClick={tryAgain} />
    </>
  );
};

const SuccessModel = ({ visible, msg, onLeftClick, onRightClick }) => {
  return (
    <Modal
      open={visible}
      footer={[
        <Button type="primary" onClick={onLeftClick}>
          Create More Environment
        </Button>,
        <Button type="primary" onClick={onRightClick}>
          Close
        </Button>,
      ]}
    >
      Environment has been created successfully
    </Modal>
  );
};

const FailureModel = ({ visible, onLeftClick, onRightClick }) => {
  return (
    <Modal
      open={visible}
      footer={[
        <Button type="primary" onClick={onLeftClick}>
          Try Again
        </Button>,
        <Button type="primary" onClick={onRightClick}>
          Close
        </Button>,
      ]}
    >
      <p>Sorry! We are unable to create your environment right now.</p>
    </Modal>
  );
};

const UploadMsg = (props) => {
  return (
    <div>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">{props?.subMsg || ""}</p>
    </div>
  );
};

export default BulkUpload;
