const Users = {
  usersAddPath: [
    { name: "Home", path: "/dashboard" },
    {
      name: "Users",
      path: "/users",
    },
    {
      name: "Add",
    },
  ],
  usersUpdatePath: [
    { name: "Home", path: "/dashboard" },
    {
      name: "Users",
      path: "/users",
    },
    {
      name: "Update",
    },
  ],
  usersUpdatePasswordPath: [
    { name: "Home", path: "/dashboard" },
    {
      name: "Users",
      path: "/users",
    },
    {
      name: "password",
    },
  ],
};

export default Users;
