import React, { useEffect, useState } from "react";
import { PageHeader } from '@ant-design/pro-layout';
import { Button, message, Form, Input, Select, Switch, Upload, Skeleton, Modal } from 'antd';
import Style from './css/CreateGameAsset.css';
import { useNavigate } from "react-router-dom";
import { CancelButton } from "@dashboard/buttons/CancelButton";
import { RefreshButton } from "@dashboard/buttons/RefreshButton";
import { InboxOutlined } from "@ant-design/icons";
import { getTargetPlatforms, getAssetTypes } from "@services/systemService";
import { createPrivateGameAsset } from "@services/gameAssetService";

const { Dragger } = Upload;
const { Option } = Select;
const CreateGameAsset = () => {
    const [form] = Form.useForm();
    const [formField, setForm] = useState();
    const [gameEngines, setGameEngines] = useState([]);
    const [gamePlatforms, setGamePlatform] = useState([]);
    const [isFormValid, setIsFormValid] = useState(false);
    const [isEngineLoading, setEngineLoaded] = useState(true);
    const [isPlatformLoading, setPlatformLoaded] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [successModalVisiblilty, setSuccessModalVisiblilty] = useState(false);
    const [failureModalVisiblilty, setFailureModalVisiblilty] = useState(false);
    const [createdAsset, setCreatedAsset] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        fetchFunc(getAssetTypes, setGameEngines, setEngineLoaded, "assetType");
        fetchFunc(getTargetPlatforms, setGamePlatform, setPlatformLoaded, "targetPlatform");
        form.setFieldsValue({ active: true });
    }, [])

    const uploadFileProps = (fieldName) => {
        return {
            onRemove: file => {
                setForm({ ...formField, [fieldName]: null })
                setIsFormValid(false);
            },
            beforeUpload: file => {
                setForm({ ...formField, [fieldName]: file })
                return false;
            },
            maxCount: 1,
            listType: "picture",
        };
    }

    const checkFormValidity = () => {
        const hasError = form.getFieldsError().some(({ errors }) => errors.length > 0);
        const { assetFile, assetId, assetImage, assetName, platform, engineType, active } = (form.getFieldsValue())
        const isFormCompleted = (assetFile && assetImage && assetId?.trim() && assetName?.trim() && engineType && platform)
        const isValid = (!hasError && isFormCompleted);
        setForm({ ...formField, assetId, assetName, platform, engineType, active })
        setIsFormValid(isValid);
    }

    const fetchFunc = (getFunc, setFunc, setLoadOff, type) => {
        getFunc().then(res => {
            if (res.status === 200 && res?.data?.dataList?.length > 0) {
                setFunc(res?.data?.dataList);
            }
            else {
                message.error(`Enable to find game ${type}. Please try again later"`)
            }
            setLoadOff(false);
        }).catch(err => {
            message.error(`Enable to find game ${type}. Please try again later"`)
            setLoadOff(false);
        })
    }

    const onSwitchChange = (isChecked) => {
        form.setFieldsValue({ active: isChecked });
    }

    const createAsset = () => {
        setLoading(true)
        const formData = new FormData();
        for (let key in formField) {
            formData.append(key, formField[key]);
        }

        createPrivateGameAsset(formData).then(res => {
            if (res?.status === 201) {
                setCreatedAsset(res.data)
                setSuccessModalVisiblilty(true);
            }
            else {
                // message.error("Sorry! We are unable to create your asset right now.")
                setFailureModalVisiblilty(true);
            }
            setLoading(false);
        }).catch(err => {
            setLoading(false);
            setFailureModalVisiblilty(true);
        })
    }

    const onSubmit = () => {
        createAsset();
    }

    const navigateToAssetList = () => {
        setFailureModalVisiblilty(false);
        setSuccessModalVisiblilty(false)
        navigate("/gameassets")
    }

    const createMoreAsset=()=>{
        setSuccessModalVisiblilty(false)
        form.resetFields();
    }
    const tryAgain=()=>{
        setFailureModalVisiblilty(false)
    }

    return (
        <React.Fragment>
            <PageHeader
                className="site-page-header"
                title="Create Asset"
                extra={[
                    <RefreshButton key="1" onClick={() => form.resetFields()} />,
                    <CancelButton key="2" onClick={navigateToAssetList} />
                ]}
            />
            <div className={Style["main-container"]}>
                <Skeleton loading={isEngineLoading || isPlatformLoading} active>
                    <Form form={form} labelCol={{ span: 5 }} onFieldsChange={() => {
                        checkFormValidity();
                    }}
                        wrapperCol={{ span: 14 }} className={Style["form"]} >
                        <Form.Item label="Asset Id" name="assetId" rules={[{ required: true, message: "Please enter assetId" }]}  >
                            <Input style={{ height: 40, borderRadius: "10%" }} />
                        </Form.Item>
                        <Form.Item label="Asset Name" name="assetName" rules={[{ required: true, message: "Please enter Asset Name" }]}  >
                            <Input style={{ height: 40, borderRadius: "10%" }} />
                        </Form.Item>
                        <Form.Item label="Engine" name="engineType" rules={[{ required: true, message: "Please select Engine" }]}>
                            <Select placeholder="Select Engine">
                                {
                                    gameEngines?.map(engine => <Option value={engine?.gameEngineId}>{engine?.engineName}</Option>)
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Platform" name="platform" rules={[{ required: true, message: "Please select Platform" }]}>
                            <Select placeholder="Select Platform">
                                {
                                    gamePlatforms?.map(gamePlatform => <Option value={gamePlatform?.platformId}>{gamePlatform?.platformName}</Option>)
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item label="Active" name="active" valuePropName="checked" rules={[{ required: false }]} style={{ alignItems: "flex-start" }}>
                            <Switch style={{ alignSelf: "flex-start" }} defaultChecked={true} onChange={onSwitchChange} />
                        </Form.Item>
                        <Form.Item label="Thumbnail" name="assetImage" rules={[{ required: true, message: "Please select thumbnail" }]}>
                            <Dragger {...uploadFileProps("assetImage")}>
                                <UploadMsg subMsg="Only jpeg or png files are supported." />
                            </Dragger>
                        </Form.Item>
                        <Form.Item label="Asset File" name="assetFile" rules={[{ required: true, message: "Please select asset file" }]}>
                            <Dragger {...uploadFileProps("assetFile")} >
                                <UploadMsg />
                            </Dragger>
                        </Form.Item>
                        <Form.Item >
                            <Button loading={isLoading} onClick={onSubmit} type="primary" htmlType="submit" className={Style["button"]} disabled={!isFormValid}>Create Asset</Button>
                        </Form.Item>
                    </Form>
                    <SuccessModel visible={successModalVisiblilty} onRightClick={navigateToAssetList} onLeftClick={createMoreAsset} msg={createdAsset?.url} />
                    <FailureModel visible={failureModalVisiblilty} onRightClick={navigateToAssetList} onLeftClick={tryAgain}/>
                </Skeleton>
            </div>
        </React.Fragment>
    )
}

const UploadMsg = (props) => {
    return (
        <div>
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">
                Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
                {props?.subMsg || ''}
            </p>
        </div>
    )
}

const SuccessModel = ({ visible, msg, onLeftClick, onRightClick }) => {
    return (
        (<Modal
            open={visible}
            title="Asset has been created successfully"
            footer={[
                <Button type="primary" onClick={onLeftClick}>Create More Assets</Button>,
                <Button type="primary" onClick={onRightClick}>Close</Button>,
            ]} >
            {msg}
        </Modal>)
    );
}

const FailureModel = ({ visible, onLeftClick, onRightClick }) => {
    return (
        (<Modal
            open={visible}
            footer={[
                <Button type="primary" onClick={onLeftClick}>Try Again</Button>,
                <Button type="primary" onClick={onRightClick}>Close</Button>,
            ]} >
            <p>Sorry! We are unable to create your asset right now.</p>
        </Modal>)
    );
}

export default CreateGameAsset;