import { Form, Input, Button, Checkbox, Alert, Modal } from "antd";
import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import PrivacyPolicy from "../Terms_and_Conditions/PrivacyPolicy";
import TermsOfServices from "../Terms_and_Conditions/TermsOfServices";
import { useEffect, useState } from "react";
import Link from "antd/es/typography/Link";
import { signup } from "@auth";
import OtpModal from "../modals/OPTModal";
import { PRIVACY_POLICY_PATH, TERMS_OF_USE_PATH } from "@localconfig/envconfig";

const SignUpForm = () => {
  // const [loading, setLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [openOtpModal, setOpenOtpModal] = useState(false); //Set this to false
  const [ppOpen, setPpOpen] = useState(false);
  const [tosOpen, setTosOpen] = useState(false);
  const [policyTermsCheck, setPolicyTermsCheck] = useState(false);
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    error: "",
    loading: false,
    redirectToReferrer: false,
  });

  const { email, name, password, loading, error, redirectToReferrer } = values;
  const PrivacyPolicyText = () => {
    // return <Link onClick={() => setPpOpen(true)}>Privacy Policy</Link>;
    return (
      <Link href={PRIVACY_POLICY_PATH} target="_blank">
        Privacy Policy
      </Link>
    );
  };

  const ModalShow = (props) => {
    return (
      <Modal
        title={props.title}
        open={props.visible}
        footer={[
          <Button type="primary" onClick={props.onOk}>
            {props.okText}
          </Button>,
          <Button onClick={() => setVisible(false)}>Cancel</Button>,
        ]}
      >
        <p>{props.message}</p>
      </Modal>
    );
  };

  const TermsOfServicesText = () => {
    return (
      <Link href={TERMS_OF_USE_PATH} target="_blank">
        Terms of Services
      </Link>
    );
  };
  const handleChange = (name) => (event) => {
    setValues({ ...values, error: false, [name]: event.target.value });
  };

  const onValueChanges = (_, allValues) => {
    setValues({ ...allValues, error: false });
  };
  const checkFormValidity = () => {
    const hasError = form.getFieldsError().some(({ errors }) => errors.length > 0);
    const { name, email, password, confirmPass } = form.getFieldsValue();
    const isFormCompleted = name && email && password && confirmPass && policyTermsCheck;

    const isValid = !hasError && isFormCompleted;
    setIsFormValid(isValid);
  };
  const clickSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, error: false, loading: true });
    signup({ email, password, name, tncAccepted: policyTermsCheck, privacyPolicyAccepted: policyTermsCheck }).then((responseData) => {
      if (responseData?.status === 201) {
        setValues({
          ...values,
          loading: false,
          error: "",
          redirectToReferrer: false,
        });
        setVisible(true);
      } else if (!responseData.response) {
        setValues({
          ...values,
          loading: false,
          error: "No Response From Server. Please try after sometime",
          redirectToReferrer: false,
        });
      } else if (responseData?.response?.status === 401) {
        setValues({
          ...values,
          loading: false,
          error: responseData?.response?.data?.message,
          redirectToReferrer: false,
        });
      } else if (responseData?.response?.status === 409) {
        setValues({
          ...values,
          loading: false,
          error: "User Already Exist!",
          redirectToReferrer: false,
        });
      } else {
        setValues({
          ...values,
          loading: false,
          error: "Please try after sometime",
          redirectToReferrer: false,
        });
      }
    });
  };
  const showError = () => (
    <Alert message={error} type="error" showIcon style={{ display: error ? "" : "none", marginBottom: "10px" }} />
  );

  const handleVerify = (otp) => {
    // console.log(otp);
    //Verification Logic
    return
  };

  useEffect(() => {
    checkFormValidity();
  }, [policyTermsCheck]);

  return (
    <Form
      className="signup-form"
      onValuesChange={onValueChanges}
      form={form}
      onFieldsChange={() => checkFormValidity()}
    >
      {showError()}
      <Form.Item
        name="name"
        rules={[
          { required: true, message: "Name Required" },
          {
            pattern: /^[A-Za-z ]+$/,
            message: "Invalid name format",
          },
        ]}
        wrapperCol={{ span: 24 }}
      >
        <Input
          prefix={<UserOutlined style={{ color: "#589636", fontSize: 16 }} />}
          onChange={handleChange}
          placeholder="Name"
          autoFocus

        />
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          { required: true, message: "Email Required" },
          { type: "email", message: "Incorrect Email format" },
        ]}
        onChange={handleChange}
        wrapperCol={{ span: 24 }}
        autocomplete="off"
      >
        <Input
          prefix={<MailOutlined style={{ color: "#589636", fontSize: 16 }} />}
          onChange={handleChange}
          type="email"
          autocomplete="off"
          placeholder="Email"
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[
          { required: true, message: "Please enter password" },
          { max: 15, min: 8, message: "Password length must be between 8 to 15" },
        ]}
        wrapperCol={{ span: 24 }}
      >
        <Input
          prefix={<LockOutlined style={{ color: "#589636", fontSize: 16 }} />}
          onChange={handleChange}
          type="password"
          placeholder="Password"
          autoComplete="new-password"
        />
      </Form.Item>
      <Form.Item
        name="confirmPass"
        rules={[
          {
            required: true,
            message: "Please confirm your password",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error("Passwords do not match"));
            },
          }),
        ]}
        wrapperCol={{ span: 24 }}
      >
        <Input
          prefix={<LockOutlined style={{ color: "#589636", fontSize: 16 }} />}
          type="password"
          placeholder="Confirm Password"
          autocomplete="off"
        />
      </Form.Item>

      {/* Terms and Conditions */}
      <div className="check-div">
        <Checkbox
          check={policyTermsCheck}
          className="check-box"
          onChange={(e) => {
            setPolicyTermsCheck(e.target.checked);
          }}
        />

        <div className="TandC">
          I accept the {PrivacyPolicyText()} and the {TermsOfServicesText()}
        </div>
      </div>
      <Form.Item>
        <Button
          disabled={!isFormValid}
          type="primary"
          htmltype="submit"
          onClick={clickSubmit}
          className="signup-form-button"
          loading={loading}
        >
          Sign Up
        </Button>
      </Form.Item>

      {/* Terms and Conditions Modals */}
      <PrivacyPolicy open={ppOpen} onCancel={() => setPpOpen(false)} />
      <TermsOfServices open={tosOpen} onCancel={() => setTosOpen(false)} />
      <OtpModal handleVerify={handleVerify} open={openOtpModal} onCancel={() => setOpenOtpModal(false)} />
      <ModalShow
        title={"User is registered successfully!"}
        message={"You want to go login page now?"}
        okText={"Go to login"}
        onOk={() => navigate("/", { replace: true })}
        visible={visible}
      />
    </Form>
  );
};

export default SignUpForm;
