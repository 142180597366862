import { useNavigate, useParams, Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getGameByGameId } from "@services/gamesService";
import GenericConfirmModal from "@dashboardmodals/GenericConfirmModal";
import { deleteGameByGameId } from "@services/gamesService";
import { Descriptions, Image, message, Skeleton, Breadcrumb } from "antd";
import {AppObject} from "@context/AppObject";
import { PageHeaderDetailForm } from "@dashboardpages/PageHeader";

const GameDetails = () => {
  const navigate = useNavigate();
  const { gameId } = useParams();
  const [gameData, setGameData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const [msg, setMessage] = useState("");
  const [ToBeDeletedgameId, setToBeDeletedgameId] = useState();

  const fetchGameById = (gameId) => {
    setLoading(true);
    getGameByGameId(gameId).then((response) => {
      setGameData(response.data.dataList);
      setLoading(false);
    });
  };
  const toggleModal = () => {
    setVisible(!visible);
  };

  const DeleteGame = () => {
    deleteGameByGameId(ToBeDeletedgameId).then((response) => {
      if (response.status === 200) {
        setMessage("Game by Id " + ToBeDeletedgameId + "Successfully deleted!");
        message.success("Game by Id " + ToBeDeletedgameId + "Successfully deleted!");
        setVisible(false);
        navigate("/games");
      }
      if (response.status === 404) {
        message.error("There no game with id " + ToBeDeletedgameId);
      }
    });
  };

  useEffect(() => {
    fetchGameById(gameId);
  }, []);

  return (
    <React.Fragment>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/games">Games</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{gameData?.gameName}</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ paddingTop: "15px" }}>
        <GenericConfirmModal visible={visible} onCancel={() => toggleModal()} onOk={() => DeleteGame()} okText="Delete">
          {msg === "" ? (
            <div>
              <p>Please put the game id to delete the game!</p>
              <input type="text" placeholder={gameId} onChange={(e) => setToBeDeletedgameId(e.target.value)} />{" "}
            </div>
          ) : (
            <p>{msg}</p>
          )}
        </GenericConfirmModal>
        <PageHeaderDetailForm
          objectId={AppObject.GAMES}
          title="Game Details"
          onDelete={toggleModal}
          onEdit={() => navigate("/games/update/" + gameData.gameId)}
          onCancel={() => navigate("/games")}
        />

        <Skeleton loading={loading} active>
          <div
            style={{
              background: "#fff",
              width: "100%",
              display: "flex",

              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Descriptions
              bordered={true}
              style={{
                width: 500,
                margin: "auto",
                marginTop: 40,
                marginBottom: 40,
                borderColor: "#fff",
              }}
              labelStyle={{ textAlign: "right" }}
            >
              <Descriptions.Item label={"Game Id"} key="1" span={3}>
                {gameData.gameId}
              </Descriptions.Item>
              <Descriptions.Item label={"Game Name"} key="2" span={3}>
                {gameData.gameName}
              </Descriptions.Item>
              <Descriptions.Item label={"Game Engine"} key="3" span={3}>
                {gameData.gameEngineId?.engineName}
              </Descriptions.Item>
              <Descriptions.Item label={"Game Platform"} key="4" span={3}>
                {gameData.gamePlatformId?.platformName}
              </Descriptions.Item>
              <Descriptions.Item label={"Active"} key="5" span={3}>
                <span>{`${gameData.active}`}</span>
              </Descriptions.Item>
              <Descriptions.Item label={"Game Website"} key="6" span={3}>
                {gameData.websiteURL}
              </Descriptions.Item>
              <Descriptions.Item label={"Game Description"} key="7" span={3}>
                {gameData.description}
              </Descriptions.Item>
              <Descriptions.Item label={"Game Icon"} key="8" span={3}>
                <Image style={{ height: 200 }} src={gameData.gameImageURL} />
              </Descriptions.Item>
            </Descriptions>
          </div>
        </Skeleton>
      </div>
    </React.Fragment>
  );
};

export default GameDetails;
