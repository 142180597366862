import React, { useState } from "react";
import { Modal, Button, Input } from "antd";
import OtpInput from "react-otp-input";

const OTPModal = (props) => {
  const [otp, setOtp] = useState("");
  return (
    <Modal
      title={props.title || "We have sent a six-digit code on your email id to verify, Please enter the code."}
      width={"45%"}
      open={props.open}
      maskClosable={false}
      footer={
        props.footer || [
          <Button onClick={props.onCancel}>Cancel</Button>,
          <Button disabled={otp.length !== 6} type="primary" onClick={() => props.handleVerify(otp)}>
            Verify
          </Button>,
        ]
      }
      onCancel={props.onCancel}
    >
      <div style={{ width: "60%", display: "flex", margin: "auto" }}>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          inputType={"number"}
          renderSeparator={"--"}
          shouldAutoFocus={true}
          renderInput={(props) => <Input {...props} />}
        />
      </div>
    </Modal>
  );
};

export default OTPModal;
