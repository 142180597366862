import React from "react";
import "./AssetVersionVideoPreview.css";

const VideoPreview = ({ file }) => {
  return (
    <div className="video-container">
      <video crossOrigin="use-credentials" controls width={300}>
        <source src={file} type="video/mp4" />
      </video>
    </div>
  );
};

export default VideoPreview;
