import React, { useEffect, useState } from "react";
import { Table, Breadcrumb, message, Skeleton, DatePicker, Tooltip, Tag, Pagination } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CancelButton } from "../../buttons/CancelButton";
import "./css/EnvDetailsTable.css";
import { PageHeader } from "@ant-design/pro-layout";
import { getEnvDeployedAssetCollection } from "@services/EnvService";
import { convertDateToNestorTime } from "@utilities/datehelper";
import { getTargetPlatforms } from "@services/systemService";
import { FileOutlined, GlobalOutlined, AndroidOutlined, AppleOutlined } from "@ant-design/icons";

const pageSize = 20;

const AssetCollectionEnvTable = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { env, envId } = location.state || {};
  const [isLoading, setLoading] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalVersions, setTotalVersions] = useState();
  const [showTotal, setShowTotal] = useState();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [deployedAssetCollection, setDeployedAssetCollection] = useState();
  const [filters, setFilters] = useState([]);

  const setDateFilter = (dates) => {
    if (dates !== null) {
      setStartDate(new Date(dates[0]?.$d).toISOString());
      setEndDate(new Date(dates[1]?.$d).toISOString());
    } else {
      setEndDate("");
      setStartDate("");
    }
  };

  const deployedAssetCollectionInfo = (envId) => {
    const query = `pageNumber=${pageNumber}&pageSize=${pageSize}&startDate=${startDate}&endDate=${endDate}`;
    getEnvDeployedAssetCollection(envId, query).then((res) => {
      if (res.status === 200 && res.data && Array.isArray(res.data.results)) {
        console.log("OG DATA :", res.data);
        const formattedData = res.data.results.map((item, index) => ({
          collectionId: item.collectionId,
          name: item.collectionName,
          totalItems: item.assetItems,
          createdAt: item.createdAt,
          state: item.state,
          targetPlatform: item.targetPlatform,
          item,
        }));
        setDeployedAssetCollection(formattedData);
        setTotalVersions(res.data.totalResults);
        console.log(formattedData);
        setLoading(false);
      } else {
        console.error("Unexpected API response format:", res);
      }
    });
  };

  useEffect(() => {
    deployedAssetCollectionInfo(envId);
  }, [envId, pageNumber, startDate, endDate]);

  const getTargetPlatformFilters = () => {
    getTargetPlatforms()
      .then((res) => {
        if (res.status === 200 && res.data && res.data.dataList) {
          const formattedFilters = res.data.dataList.map((item) => ({
            text: item.platformName,
            value: item.platformName.toLowerCase(),
          }));
          setFilters(formattedFilters);
        } else {
          console.error("Unexpected API response format:", res);
        }
      })
      .catch((err) => {
        message.error("Unable to load target platforms.");
      });
  };

  useEffect(() => {
    getTargetPlatformFilters();
  }, []);

  const columns = [
    {
      title: "Collection Id",
      dataIndex: "collectionId",
      key: "collectionId",
      className: "center-align",
      onHeaderCell: () => ({
        style: { textAlign: "center" },
      }),
      render: (collectionId, record) => (
        <Link
          to={`/assetcollection/${record.collectionId}`}
          state={{
            collectionId: record.collectionId,
          }}
          className="custom-link"

        >
          {collectionId}
        </Link>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      className: "center-align",
      onHeaderCell: () => ({
        style: { textAlign: "center" },
      }),
    },
    {
      title: "Total items",
      dataIndex: "totalItems",
      key: "totalItems",
      className: "center-align",
      onHeaderCell: () => ({
        style: { textAlign: "center" },
      }),
      render: (totalItems) => {
        return totalItems.length;
      },
    },
    {
      title: "Target Platform",
      dataIndex: "targetPlatform",
      key: "targetPlatform",
      filterMode: "tree",
      filters: filters,
      filterSearch: true,
      onFilter: (value, record) => record.targetPlatform && record.targetPlatform.includes(value),
      className: "center-align",
      onHeaderCell: () => ({
        style: { textAlign: "center" },
      }),
      render: (targetPlatform) => {
        let IconComponent;
        let tooltipTitle;

        switch (targetPlatform) {
          case "generic":
            IconComponent = FileOutlined;
            tooltipTitle = "Generic";
            break;
          case "web":
            IconComponent = GlobalOutlined;
            tooltipTitle = "Web";
            break;
          case "android":
            IconComponent = AndroidOutlined;
            tooltipTitle = "Android";
            break;
          case "ios":
            IconComponent = AppleOutlined;
            tooltipTitle = "IOS";
            break;
          default:
            IconComponent = FileOutlined;
            tooltipTitle = "Generic";
        }

        return (
          <Tooltip title={tooltipTitle}>
            <IconComponent style={{ fontSize: "24px" }} />
          </Tooltip>
        );
      },
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      className: "center-align",
      onHeaderCell: () => ({
        style: { textAlign: "center" },
      }),

      render: (state) => {
        let color;
        let text;
        switch (state) {
          case "LIVE":
            color = "#87d068";
            text = "LIVE";
            break;
          case "DEFAULT":
            color = "#108ee9";
            text = "DEFAULT";
            break;
          case "OFFLINE":
            color = "#C0C0C0";
            text = "OFFLINE";
            break;
          default:
            color = "black";
            text = state;
        }
        return <Tag color={color}>{text}</Tag>;
      },
    },

    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      filterDropdown: <DatePicker.RangePicker style={{ margin: 15 }} onChange={setDateFilter} />,
      // sorter: (a, b) => a.date - b.date,
      render: (createdAt) => {
        return convertDateToNestorTime(createdAt);
      },
      className: "center-align",
      onHeaderCell: () => ({
        style: { textAlign: "center" },
      }),
    },
  ];

  const paginationConfg = {
    total: parseInt(totalVersions),
    responsive: true,
    pageSize: pageSize,
    current: pageNumber,
    onChange: setPageNumber,
    showSizeChanger: false,
    showTotal: (total, range) => setShowTotal(`${range[0]}-${range[1]} of ${total} Collections`),
  };

  const TotalComponent = () => {
    return (
      <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "end", marginBlock: "10px" }}>
        {showTotal}
        <Pagination
          {...paginationConfg}
          style={{
            textAlign: "left",
          }}
        />
      </div>
    );
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Skeleton loading={isLoading}>
      <div style={{ width: "100%" }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to="/dashboard">Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to="/environments">Environments</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link to={`/environments/${envId}`} state={envId}>
              {env?.envName}
            </Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>Collections</Breadcrumb.Item>
        </Breadcrumb>

        <PageHeader
          title={["Deployed Asset Collections - " + envId]}
          style={{ margin: 0, marginTop: 0, width: "100%" }}
          extra={[<CancelButton key="cancelButton" onClick={goBack} />]}
        />
        <TotalComponent />
        <Table
          sticky={true}
          columns={columns}
          dataSource={deployedAssetCollection}
          pagination={paginationConfg}
          showSorterTooltip={{
            target: "sorter-icon",
          }}
        />
      </div>
    </Skeleton>
  );
};

export default AssetCollectionEnvTable;
