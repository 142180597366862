import React, { useState } from "react";
import './ForgotPassForm.css'
import { Form, Input, Button, Typography } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getResetPasswordRequest } from "@services/forgotPasswordRequest";

const FormItem = Form.Item;
const { Title } = Typography;

function ForgotPassForm({ success, setSuccess, navigate }) {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({ email: "" });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const handleFormSubmit = async () => {
    setLoading(true);
    try {
      if (!values.email.trim()) {
        setError("Please enter your email!");
        return;
      }
      const response = await getResetPasswordRequest(values.email);
      if (response.status === 201) {
        setSubmitted(true);
        setSuccess(true); 
      } else {
        const errorData = await response.json();
        setError(errorData.message || "Something went wrong. Please try again!");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Network error. Please try again later!");
    } finally {
      setLoading(false);
    }
  };

  const checkFormValidity = () => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
    return emailPattern.test(values.email);
  };

  return (
    <>
      <div className="go-back">
        <a onClick={() => navigate("/")}>
          <ArrowLeftOutlined /> Back
        </a>
      </div>
      <div className="forgot-pass-text-div">
        <Title level={2} className="forgot-pass-text">
          Forgot Password?
        </Title>
        <p>Don't worry it happens, Please enter the Email address linked with your account.</p>
      </div>
      <div className="forgot-pass-form">
        <Form>
          <FormItem
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "Incorrect Email format" },
            ]}
          >
            <Input
              prefix={<MailOutlined style={{ color: "#589636", fontSize: 16 }} />}
              className="forgot-pass-input"
              type="email"
              placeholder="Email"
              value={values.email}
              onChange={(e) => setValues({ ...values, email: e.target.value })}
              disabled={submitted}
            />
          </FormItem>
          <FormItem>
            <Button
              type="primary"
              htmltype="submit"
              className="forgot-pass-submit"
              loading={loading}
              onClick={handleFormSubmit}
              disabled={!checkFormValidity() || submitted}
              style={{ opacity: !checkFormValidity() ? 0.3 : 1 }}
            >
              Submit
            </Button>
          </FormItem>
        </Form>
      </div>
      <div style={{ display: error ? "block" : "none", color: "red" }}>{error}</div>
    </>
  );
}

export default ForgotPassForm;
