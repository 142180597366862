import React from "react";
import { PageHeaderCreateForm } from "./PageHeader";
// import BreadCrumbs from "./BreadCrumbs";
import BreadCrumbsPath from "./Breadcrumbs/index";
import { BreadCrumbs } from "@vyomr/nestor_components";
import "./GameAsset/css/CreateGameAsset.css";

const GenericFormContainer = (props) => {
  return (
    <React.Fragment>
      {props.breadcrumbPath ? <BreadCrumbs items={BreadCrumbsPath[props.breadcrumbPath]} /> : ""}
      <div style={{ paddingTop: "15px" }}>
        <PageHeaderCreateForm
          title={props.formName}
          onClick={() => props.resetFunc()}
          navigate={() => props.navigate()}
        />
        {props.children}
      </div>
    </React.Fragment>
  );
};

export default GenericFormContainer;
