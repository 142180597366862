export const AppObject={
    FULL_ACCESS:"FULLACCESS",
    ORGANIZATION:"go0001",
    ENVIRONMENT:"go0002",
    API_KEY:"go0003",
    ASSET_DETAIL:"go0004",
    ASSET_VERSION:"go0005",
    ASSET_VERSION_ENV:"go0006",
    ASSET_VERSION_STATE:"go0007",
    USER:"go0008",
    GAMES:"go0009",
    BULK_UPLOAD:"go0010",
    SUPPORT:"go0011",
    ASSET_COLLECTION: "go0012",          
    ASSET_ITEM: "go0013",                
    ASSET_COLLECTION_DEPLOYMENT: "go0014",
    ASSET_COLLECTION_EDITABLE: "go0015"
}