import React, { useState, useEffect } from "react";
import { Col, Card, Badge } from "antd";
import { getGameImageURL } from "@services/gamesService";

const { Meta } = Card;

const GameCard = props => {
  return (
    <Col span={6} key={props.key}>
      <Badge.Ribbon text="Unity" color="cyan">
        <Card
          cover={
            <img
              alt={props.game.gameName}
              src={props.game.gameImageURL}
              style={{ height: 150 }}
              onClick={props.onClick}
            />
          }
        >
          <Meta title={props.game.gameName} description={props.game.gameId} />
        </Card>
      </Badge.Ribbon>
    </Col>
  );
};

export default GameCard;
