//first
import React, { useEffect, useState } from "react";
import { Breadcrumb, Skeleton, Button, Card, Form, Input, Select, Upload, message, Modal, Col } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { PageHeader } from "@ant-design/pro-layout";
import { CancelButton } from "../../buttons/CancelButton";
import { InboxOutlined } from "@ant-design/icons";
import { getAllEnv } from "@services/EnvService";
import { getEnvDeployedInfo, deployAssetCollection } from "@services/assetCollection";
import SkipModal from "@dashboardmodals/Skip";
import CardList from "../../cards/CardList";

const ReleaseAssetCollection = ({ collectionId }) => {
  const [visible, setVisible] = useState(false);
  const [skipModal, setSkipModal] = useState(false);
  const [envDataFields, setEnvDataFields] = useState([]);
  const [envItem, setEnvItem] = useState();
  const [selectedState, setSelectedState] = useState();
  const [assetStates, setAssetStates] = useState(["LIVE"]);
  const [isDeployed, setIsDeployed] = useState(false); // state to track deploy status
  const [successModalVisibility, setSuccessModalVisibility] = useState(false);
  const [failureModalVisibility, setFailureModalVisibility] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [successModalTitle, setSuccessModalTitle] = useState("");
  const [deployBtnLoading, setDeployBtnLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const getData = () => {
      getAllEnv().then((res) => {
        changeFields(res?.data?.environments);
      });
    };

    getData();
  }, []);

  const navigateTo = (path) => {
    setFailureModalVisibility(false);
    setSuccessModalVisibility(false);
    navigate(path);
  };

  let structure = [];
  const changeFields = (Data) => {
    // eslint-disable-next-line array-callback-return
    Data?.map((item, index) => {
      if (!(structure.length > Data.length)) {
        structure.push({ title: item.envName, url: item.envImageURL, _id: item._id, key: index, data: item });
      }
    });

    setEnvDataFields([...structure]);
  };

  const deployAsset = async () => {
    try {
      setDeployBtnLoading(true);
      const response = await deployAssetCollection(collectionId, { state: selectedState, envId: envItem?.data?.envId });
      if (response.status === 201) {
        const envIndex = envItem.key;
        const envData = envDataFields[envIndex];
        envData.badge = selectedState;
        envData.badgeColor = selectedState === "OFFLINE" ? "gray" : "green";
        setEnvDataFields([...envDataFields]);
        setVisible(false);
        setSelectedState(null); //clear state.
        setSuccessMessage(`You have successfully deployed this asset collection.`);
        setSuccessModalTitle(`Asset Collection is now ${selectedState}!`);
        setSuccessModalVisibility(true);
        setIsDeployed(true); // Update deploy status
        setAssetStates(["LIVE", "OFFLINE"]);
      } else {
        setFailureModalVisibility(true);
      }
    } catch (error) {
      console.error("Failed to deploy asset collection", error);
      setFailureModalVisibility(true);
    }
    finally {
      setDeployBtnLoading(false);
    }
  };

  return (
    <Card
      className="asset-container"
      headStyle={{ borderBottom: "0px" }}
      style={{ paddingBottom: 30 }}
      bordered={false}
    >
      <SkipModal
        onLeftClick={() => navigateTo(`/assetcollection/${collectionId}`)}
        visible={skipModal}
        msg="Are you sure, you are done with this step?"
        onRightClick={() => setSkipModal(false)}
        leftButtonText="Yes"
      />
      <PageHeader
        className="site-page-header"
        title="Deploy Asset Collection"
        extra={[
          <Button type="primary" size={"large"} onClick={() => setSkipModal(true)}>
            Done
          </Button>,
        ]}
      />
      {visible && (
        <Modal
          open={visible}
          title={"Choose State"}
          okText={"Deploy"}
          bodyStyle={{ height: 75, marginTop: 40 }}
          onOk={() => {
            deployAsset(envItem, selectedState);
           
          }}
          okButtonProps={{
            loading: deployBtnLoading,
          }}
          onCancel={() => {
            setVisible(false);
            setSelectedState(null); //clear state.
          }}
        >
          <Form>
            <Form.Item
              name="state"
              label="State"
              rules={[
                {
                  required: true,
                  message: "Missing State",
                },
              ]}
            >
              <Select
                placeholder="Select State"
                // value={selectedState}
                style={{
                  width: "100%",
                  textAlign: "left",
                }}
                onSelect={(val) => {
                  setSelectedState(val);
                }}
              >
                {assetStates?.map((state) => (
                  <Select.Option key={state} value={state}>
                    {state}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      )}
      <Card
        title={"Environments"}
        headStyle={{ borderBottom: "none" }}
        style={{ paddingBottom: 30, textAlign: "left" }}
      >
        <Col
          className="environmentList"
          style={{ margin: "auto", maxWidth: "100%" }}
          lg={{ span: 18 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <CardList
            style={{ textAlign: "Center" }}
            msg={"No Environment Data available. Please create new one."}
            onButtonText="Add Environment"
            items={envDataFields}
            onClick={(item, index) => {
              setEnvItem(item);

              if (item.badge === "LIVE" || item.badge === "OFFLINE") {
                setSelectedState(item.badge); // Set the current state if already deployed
                setAssetStates(["LIVE", "OFFLINE"]); // Show both states if already deployed
              } else {
                setSelectedState(undefined); // No state selected for undeployed environments
                setAssetStates(["LIVE"]); // Only show "LIVE" for undeployed environments
              }

              setVisible(true);
            }}
            onButtonClick={() => {
              navigate("/environments");
            }}
          />
        </Col>
      </Card>
      <SuccessModel
        visible={successModalVisibility}
        onLeftClick={() => setSuccessModalVisibility(false)}
        onRightClick={() => navigateTo(`/assetcollection/${collectionId}`)}
        leftTitle="Close"
        rightTitle="Go to Asset Collection Detail Page"
        title={successModalTitle} // Dynamically set title
        msg={successMessage}
        onClose={() => setSuccessModalVisibility(false)}
      />
      <FailureModel
        visible={failureModalVisibility}
        onRightClick={() => navigateTo("/assetcollection")}
        onLeftClick={() => setFailureModalVisibility(false)}
      />
    </Card>
  );
};

export default ReleaseAssetCollection;

const SuccessModel = ({ visible, msg, onLeftClick, onRightClick, title, leftTitle, rightTitle, onClose, note }) => {
  return (
    <Modal
      open={visible}
      title={title}
      onCancel={onClose}
      footer={[
        <Button type="primary" onClick={onLeftClick}>
          {leftTitle}
        </Button>,
        <Button type="primary" onClick={onRightClick}>
          {rightTitle}
        </Button>,
      ]}
    >
      {note ? (
        <span>
          <span style={{ color: "red" }}>*</span>
          {note}
          <span style={{ color: "red" }}>*</span>
        </span>
      ) : null}
      <div style={{ marginTop: "5px" }}>{msg}</div>
    </Modal>
  );
};

const FailureModel = ({ visible, onLeftClick, onRightClick, onClose }) => {
  return (
    <Modal
      open={visible}
      onCancel={onClose}
      footer={[
        <Button type="primary" onClick={onLeftClick}>
          Try Again
        </Button>,
        <Button type="primary" onClick={onRightClick}>
          Close
        </Button>,
      ]}
    >
      <p>Sorry! We are unable to deploy your asset collection right now.</p>
    </Modal>
  );
};
