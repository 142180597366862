import React, { useState, useEffect } from "react";
import {Skeleton} from "antd";
import {useParams } from "react-router-dom";
import {getSubscriptionById } from "@services/subscriptionService";
import AssetList from "../AssetList";
import AssetDetails from "../AssetDetails";
import SubscriptionInfo from "./SubscriptionInfo";
import { subscriptionAssetColumn } from "../../modals/AssetColumn";

const SubscriptionDetails = () => {
  // create a usestate object
  const [subscription, setSubscription] = useState({});
  const [product, setProduct] = useState({});
  const [skeletonVisible, setSkeletonVisible] = useState(true);
  const { subscriptionId } = useParams();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [assetId, setAssetId] = useState({});
  const showPopup = () => {
    setIsPopupVisible(true);
  };

  const handleOk = () => {
    setIsPopupVisible(false);
  };

  const handleCancel = () => {
    setIsPopupVisible(false);
  };

  const fetchSubscriptionById = (subscriptionId) => {
    getSubscriptionById(subscriptionId).then((res) => {
      //we need to check status code 200
      setSkeletonVisible(false)
      setSubscription(res.data.subscriptions);
      setProduct(res.data.subscriptions._productId)
    });
  };

  useEffect(() => {
    fetchSubscriptionById(subscriptionId);
  }, []);

  return (
    <div style={{ background: "#fff", padding: 30, width: "100%" }}>
    <Skeleton loading={skeletonVisible} active >
      {product?.productId && subscription?.subscriptionId && 
            <SubscriptionInfo product={product} subscription={subscription} />
        }
      {assetId && subscriptionId && 
            <AssetDetails isPopupVisible={isPopupVisible} handleOk={handleOk} 
              handleCancel={handleCancel} assetId={assetId} subscriptionId={subscriptionId} />
        }
     {product?._id && 
            <AssetList product={product} setAssetId={setAssetId} clickable={true} showPopup={showPopup} 
              columns={subscriptionAssetColumn}/>
        } 
      </Skeleton> 
    </div>
  );
};

export default SubscriptionDetails;
