import { Typography, Button, Tag } from "antd";
import LoginForm from "./forms/LoginForm";
import "./css/LoginPage.css";
import { useNavigate } from "react-router-dom";

const { Title } = Typography;
const LoginPage = () => {
  const navigate = useNavigate();
  return (
    <div className="login-page">
      <div className="login-form-container" >
        <div className="login-logo-div">
          <img
            src={process.env.PUBLIC_URL + "/nestor_text.png"}
            alt="Nestor"
            className="login-nestor-text-img"
          />

        </div>
        <Title level={2} className="login-welcome-text">
          Welcome back!
        </Title>
        <LoginForm />
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <div style={{ height: '1px', backgroundColor: '#d3d3d3', width: '40%' }}></div>
          <p className="or" >OR</p>
          <div style={{ height: '1px', width: '40%', backgroundColor: '#d3d3d3' }}></div>
        </div>
        <Button
          type="standard"
          htmlType="submit"
          className="alt-button"
          onClick={() => navigate("/signup")}
        >
          Create an Account!
        </Button>
      </div>
    </div>
  );
};

export default LoginPage;
