import React, { useState } from "react";
import { Form, Input, Button } from "antd";
import "../css/form.css";

const FormInputs = (props) => {
  const [IsFromValid, setIsFormValid] = useState(false);

  // const fetchCountry = () => {
  //   getCountries().then((res) => {
  //     setCountry(res.data.dataList);
  //   });
  // };
  const validateURL = (rule, value, callback) => {
    // if (value && !/^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/.test(value)) {
    if (value && !/^(https:\/\/|http:\/\/|www\.)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}.*$/.test(value)) {
      callback("Please enter a valid URL");
    } else {
      callback();
    }
  };
  const checkFormValidity = () => {
    const hasError = props.form.getFieldsError().some(({ errors }) => errors.length > 0);
    const isFormCompleted = props.form.isFieldTouched("organizationName");
    const isValid = !hasError && isFormCompleted;
    setIsFormValid(isValid);
  };

  return (
    <React.Fragment>
      <Form
        form={props.form}
        name="OrganizationDetails"
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 14 }}
        className="form"
        onFieldsChange={checkFormValidity}
      >
        <Form.Item
          label="Organization Name"
          name="organizationName"
          rules={[{ required: true, message: "Organization Name is required!" }]}
        >
          <Input name="organizationName" onChange={props.formInputChange} style={{ height: 40 }} minLength={3} />
        </Form.Item>

        <Form.Item label="Website" name="website" rules={[{ validator: validateURL }]}>
          <Input name="website" htmltype={"url"} onChange={props.formInputChange} style={{ height: 40 }} />
        </Form.Item>

        <Form.Item shouldUpdate wrapperCol={{ offset: 0, span: 24 }}>
          {() => (
            <Button
              style={{ height: 40, width: "215px" }}
              type="primary"
              onClick={props.updatOrg}
              loading={props.loading}
              disabled={!IsFromValid}
            >
              {props.buttonText || "Update"}
            </Button>
          )}
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

export default FormInputs;
