const Games = {
  gameListPath: [
    { name: "Home", path: "/dashboard" },
    {
      name: "Games",
    },
  ],

  gameUpdateForm: [
    { name: "Home", path: "/dashboard" },
    {
      name: "Games",
      path: "/games",
    },
    {
      name: "Update",
    },
  ],

  gameCreateForm: [
    { name: "Home", path: "/dashboard" },
    {
      name: "Games",
      path: "/games",
    },
    {
      name: "Add New Games",
    },
  ],
};

export default Games;
