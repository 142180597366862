import React from "react";
import ReactDOM from "react-dom";
import "./sentry"
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import "./App.css";
import "./global.css"
import { AuthProvider } from "@context/AuthProvider";
import GreenApp from "./GreenApp";
import * as Sentry from "@sentry/react";
// Sentry.captureException(new Error("Test Sentry error 3"));

ReactDOM.render(
  <BrowserRouter>
    <AuthProvider>
      <GreenApp />
    </AuthProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
