const Env = {
  envListPath: [
    { name: "Home", path: "/dashboard" },
    {
      name: "Environments",
    },
  ],

  envUpdatePage: [
    { name: "Home", path: "/dashboard" },
    {
      name: "Environments",
      path: "/Environments",
    },
    {
      name: "Update",
    },
  ],

  envCreatePage: [
    { name: "Home", path: "/dashboard" },
    {
      name: "Environments",
      path: "/environments",
    },
    {
      name: "Add New Environment",
    },
  ],
};

export default Env;
