import { UndoOutlined } from "@ant-design/icons";
import { Button, Spin, Tooltip } from "antd";
import { LiaTrashRestoreAltSolid } from "react-icons/lia";
import { IconContext } from "react-icons";
import { useState } from "react";

export const RetrieveButton = (props) => {
  const [hovered, setHovered] = useState(false);

  return (
    <>
      {props?.loading ? (
        <Spin
          style={{
            width: 40,
            height: 40,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: "#00b96b",
          }}
        />
      ) : (
        <IconContext.Provider value={{ style: { fontSize: "24px" } }}>
          <Tooltip title="Retrieve Collection">
            <div
              key={props?.key || 1}
              onClick={props?.onClick}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              style={{
                cursor: "pointer",
                border: `1px solid ${hovered ? "#00b96b" : "#d9d9d9"}`,
                borderRadius: "50%",
                padding: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                transition: "border-color 0.3s ease",
              }}
            >
              <LiaTrashRestoreAltSolid />
            </div>
          </Tooltip>
        </IconContext.Provider>
      )}
    </>
  );
};
