import React, { useEffect, useState } from "react";
import "./AssetVersionCompare.css";
import { Card } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import ImageComparison from "../imageComparison/ImageComparison";
import { Breadcrumb } from "antd/lib";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { getAssetVersions } from "../../services/assetContainer";
import { CancelButton } from "@dashboard/buttons/CancelButton";

const envconfig = require("@localconfig/envconfig");

const AssetVersionCompare = () => {
  const location = useLocation();
  const { assetVersion, assetContainer } = location.state;
  const { assetId } = useParams();
  const navigate = useNavigate();

  const [assetVs, setAssetVs] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [compareableVersions, setCompareableVersions] = useState([]);
  const [compareableUIelements, setCompareableUIelement] = useState([]);

  const fetchAssetVersions = async (assetId) => {
    const query = `assetId=${assetId}`;
    const response = await getAssetVersions(query);
    setAssetVs(response?.data?.assetVersions);
    getCompareableVersions(response?.data?.results);
  };

  useEffect(() => {
    fetchAssetVersions(assetId);
  }, []);

  const handleVersionClick = (imageUrl) => {
    setSelectedImage(imageUrl);
  };

  const getPreviewUrl = (assetVersion) => {
    if (assetVersion?.concatenatedId) {
      return `${envconfig.GREEN_BACKEND_API_URL}/assetversionfiles/${encodeURIComponent(assetVersion?.concatenatedId)}`;
    }
  };

  // const otherAssets = assetVs.filter((asset) => asset.versionId !== assetVersion.versionId);

  const setInitialSelectedVersion = (compareableVersions) => {
    if (compareableVersions?.length) {
      setSelectedImage(compareableVersions[0]);
    }
  };

  const getCompareableVersions = (assetVersions) => {
    let compareableVersions = [];

    const compareableUI = assetVersions.map((asset, index) => {
      if (asset.versionId !== assetVersion.versionId) {
        compareableVersions.push(asset);
        return (
          <div className="md-right-box"  key={index} onClick={() => handleVersionClick(asset)}>
            <img src={getPreviewUrl(asset)} crossOrigin="use-credentials" alt={`Version ${asset.versionId}`} />
            <p className="version-name">{asset.versionId}</p>
          </div>
        );
      }
    });
    setCompareableVersions(compareableVersions);
    setCompareableUIelement(compareableUI);
    setInitialSelectedVersion(compareableVersions);
    return compareableUI;
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/assetscontainer">Virtual Assets</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={`/assetscontainer/${assetId}`}>{assetId}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link
            to={`/assetscontainer/${assetId}/assetversion/${assetVersion?.versionId}`}
            state={{ assetContainer, assetVersion }}
          >
            {assetVersion.versionId}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Version Compare</Breadcrumb.Item>
      </Breadcrumb>

      <Card
        className="vc-card"
        style={{ width: "100%", height: "100%", borderRadius: 12, marginTop: 15, paddingTop: 0 }}
      >
        <PageHeader
          title={["Version Compare"]}
          style={{ margin: 0, marginTop: 0 }}
          extra={[<CancelButton key="cancelButton" onClick={goBack} />]}
        />
        <div className="card-main">
          <div className="card-left">
            <div className="left-name">
              <p style={{fontSize:14}}>{assetVersion?.versionId}</p>
              <p style={{fontSize:14}}>{selectedImage?.versionId}</p>
            </div>
            <div className="img-compare-div">
              <ImageComparison leftImage={getPreviewUrl(assetVersion)} rightImage={getPreviewUrl(selectedImage)} />
            </div>
          </div>

          <div className="card-right">
            <div className="right-name">
              <p>Other Versions</p>
            </div>

            <div className="scroll-rb">{compareableUIelements}</div>
          </div>
        </div>
      </Card>
    </>
  );
};

export default AssetVersionCompare;
