import { Card, Col } from "antd";
import { useNavigate } from "react-router-dom";
import { StringHelper } from "@utilities";
const EnvList = (props) => {
  const navigate = useNavigate();
  return (
    <Col span={6} key={props.key}>
      <Card
        onClick={() => navigate(props.envId)}
        bodyStyle={{textAlign:"center"}}
        cover={
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            {props.image === undefined ? (
              <h1
                style={{
                  background: "#eee",
                  height: "150px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "74px",
                  textAlign: "center",
                  margin: "auto",
                }}
              >
                {StringHelper.getInitials(props.envName, false)}
              </h1>
            ) : (
              <img
                alt={props.envName}
                src={props.image}
                style={{ width: props.image ? "100%" : 200, height: 150, objectFit: "scale-down" }}
              />
            )}
          </div>
          // :  StringHelper.getInitials(props.envName?.substring(0, 2), null)
        }
      >
        <Card.Meta title={props.envName} description={props.envId} />
      </Card>
    </Col>
  );
};

export default EnvList;
