import axios from "@auth/localaxios";
import {
  SUPPORT_TICKET,
  SUPPORT_CATEGORIES,
  SUPPORT_PRIORITIES
} from "@localconfig/envconfig";

export const createNewTicket = async (formData) => {
  try {
    const response = await axios.post(SUPPORT_TICKET, formData, {
      headers: {
        "Content-Type": "multipart/form-data;",
      },
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getCategoriesData = async () => {
  try {
    const response = await axios.get(`${SUPPORT_CATEGORIES}?isParent=true`, {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getSubCategoriesData = async (parentId) => {
  try {
    const response = await axios.get(`${SUPPORT_CATEGORIES}?parentId=${parentId}`, {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getPriortiesData = async () => {
  try {
    const response = await axios.get(SUPPORT_PRIORITIES, {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getTicketById = async (ticketId) => {
  try {
    const response = await axios.get(`${SUPPORT_TICKET}/${ticketId}`, {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const getTickets = async (query) => {
  try {
    const response = await axios.get(`${SUPPORT_TICKET}?${query}`, {
      withCredentials: true,
    });
    return response;
  } catch (error) {
    return error;
  }
};
