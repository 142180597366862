import React, { useEffect, useState } from "react";
import { Col, Avatar, Select, Skeleton, List, Tooltip, message } from "antd";
import {
  AndroidFilled,
  AppleFilled,
  LaptopOutlined,
  FileDoneOutlined,
  CopyOutlined,
  CopyFilled,
} from "@ant-design/icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { RefreshButton } from "../buttons/RefreshButton";
// import Style from "./css/GameAssetDetails.css";
import { getCollectionDownloadUrls } from "@services/assetCollection";
import { getAllEnv } from "@services/EnvService";

const { Option } = Select;

const DefaultURLs = ({ collectionId }) => {
  const [environments, setEnvironments] = useState([]);
  const [selectedEnv, setSelectedEnv] = useState(null);
  const [isCopied, setCopied] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [rotate, setRotate] = useState(false);
  const [urlInfo, setUrlInfo] = useState();
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchEnvData();
  }, []);

  useEffect(() => {
    if (selectedEnv) {
      fetchUrls(selectedEnv);
    }
  }, [selectedEnv]);

  const fetchEnvData = () => {
    getAllEnv()
      .then((res) => {
        if (res?.status === 200 && res?.data?.environments?.length) {
          setEnvironments(res.data.environments);
          setSelectedEnv(res.data.environments[0]); // Set the first environment as default
        } else {
          message.error("Unable to load Environments");
        }
      })
      .catch((error) => {
        message.error("Unable to load Environments");
      });
  };

  const rotateBtn = () => {
    setRotate(true);
    setData([]);
    setTimeout(() => {
      setRotate(false);
    }, 400);
  };

  const fetchUrls = async (environment) => {
    rotateBtn();
    setLoading(true);
    try {
      const response = await getCollectionDownloadUrls(collectionId, environment.envId);
      if (response.status === 200) {
        setUrlInfo(response.data.downloadUrl);
        prepareData(response.data);
      } else {
        message.error("Failed to fetch download URLs");
      }
    } catch (error) {
      console.error("Error fetching download URLs:", error);
      // message.error("An error occurred while fetching download URLs");
    } finally {
      setLoading(false);
    }
  };

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 6000);
  };

  const prepareData = (urlInfo) => {
    // const dataList = [];
    // setSingleDownloadUrlData(urlInfo, dataList, "android", <AndroidFilled />);
    // setSingleDownloadUrlData(urlInfo, dataList, "ios", <AppleFilled />);
    // setSingleDownloadUrlData(urlInfo, dataList, "web", <LaptopOutlined />);
    // setSingleDownloadUrlData(urlInfo, dataList, "generic", <FileDoneOutlined />);
    // setData(dataList);
    setData([getCollectionURLInfo(urlInfo.downloadUrl)]);
  };

  const getCollectionURLInfo = (downloadUrl) => {
    return {
      title: collectionId,
      description: downloadUrl,
      avatar: (
        <Avatar
          size={64}
          style={{ color: "black", height: "44px", background: "transparent", fontSize: "42px", lineHeight: "35px" }}
          icon={<FileDoneOutlined />}
        />
      ),
    };
  };

  const setSingleDownloadUrlData = (urlInfo, dataList, fieldName, icon) => {
    if (urlInfo[fieldName]?.length) {
      const urlData = urlInfo[fieldName][0];
      dataList.push({
        title: urlData?.versionId,
        description: urlData?.downloadUrl,
        avatar: (
          <Avatar
            size={64}
            style={{ color: "black", height: "44px", background: "transparent", fontSize: "42px", lineHeight: "35px" }}
            icon={icon}
          />
        ),
      });
    }
  };

  const onEnvironmentSelection = (value) => {
    const selected = environments.find((env) => env.envId === value);
    setSelectedEnv(selected);
  };

  return (
    <React.Fragment>
      <Col
        lg={{ span: 24 }}
        md={{ span: 24 }}
        sm={{ span: 24 }}
        xs={{ span: 24 }}
        style={{ height: "auto", borderTop: "1px solid #eee", marginTop: "20px", marginLeft: "4px" }}
      >
        <div
          style={{
            fontSize: "16px",
            marginBottom: "10px",
            marginTop: "20px",
            marginRight: "30px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <strong>Collection URLs</strong>
          <div className={rotate ? "rotate-on-click" : ""}>
            <RefreshButton onClick={() => fetchUrls(selectedEnv)} />
          </div>
        </div>
        <div
          style={{
            fontSize: "16px",
            marginBottom: "20px",
            marginTop: "10px",
            marginRight: "30px",
            display: "inline-block",
            fontWeight: "500",
          }}
        >
          Select an Environment
        </div>
        <Select
          placeholder="Environment"
          onChange={onEnvironmentSelection}
          value={selectedEnv?.envId}
          defaultValue={selectedEnv?.envId}
          style={{ width: 200 }}
        >
          {environments.map((env, index) => (
            <Option key={index} value={env.envId}>
              {env.envId}
            </Option>
          ))}
        </Select>
      </Col>
      <Skeleton loading={isLoading} active>
        <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }} xs={{ span: 24 }} style={{ height: "auto" }}>
          <List
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item) => (
              <List.Item style={{ padding: "15px 0px" }}>
                <List.Item.Meta
                  avatar={<a href="#">{item.avatar}</a>}
                  title={<a href="#">{item.title}</a>}
                  description={<span style={{ overflowWrap: "anywhere" }}>{item.description}</span>}
                />
                <CopyToClipboard onCopy={handleCopy} text={item.description}>
                  <div>
                    <div>
                      {isCopied ? (
                        <Tooltip title="Copied">
                          <CopyFilled />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Copy Url">
                          <CopyOutlined />
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </CopyToClipboard>
              </List.Item>
            )}
          />
        </Col>
      </Skeleton>
    </React.Fragment>
  );
};

export default DefaultURLs;
