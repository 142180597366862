import axios from "@auth/localaxios";
import {
  CREATE_ASSET_COLLECTION,
  GET_ALL_ASSET_COLLECTIONS,
  GET_ASSET_COLLECTIONS_DOWNLOAD_URLS,
} from "@localconfig/envconfig";

export const createAssetCollection = async (payload) => {
  return axios.post(CREATE_ASSET_COLLECTION, payload);
};

export const getAllAssetCollection = async (query) => {
  return axios.get(`${GET_ALL_ASSET_COLLECTIONS}?${query}`);
};

// export const getAssetCollectionById = async (collectionId) => {
//   return axios.get(GET_ALL_ASSET_COLLECTIONS + collectionId);
// };

export const getAssetCollectionById = async (collectionId) => {
  return axios.get(`/asset-collections/${collectionId}`);
};

export const createAssetItem = async (collectionId, formData) => {
  return axios.post(`/asset-collections/${collectionId}/items`, formData);
};

export const getAllAssetItems = async (collectionId, query) => {
  return axios.get(`/asset-collections/${collectionId}/items?${query}`);
};

export const getAssetItemById = async (collectionId, assetItemId) => {
  return axios.get(`/asset-collections/${collectionId}/items/${assetItemId}`);
};
export const getCollectionDownloadUrls = async (collectionId, envId) =>
  await axios.get(`${GET_ALL_ASSET_COLLECTIONS}/${collectionId}${GET_ASSET_COLLECTIONS_DOWNLOAD_URLS}/${envId}`);

export const deployAssetCollection = async (collectionId, data) =>
  await axios.post(`${GET_ALL_ASSET_COLLECTIONS}/${collectionId}/deploy`, data);

export const getEnvDeployedInfo = async (collectionId) =>
  await axios.get(`${GET_ALL_ASSET_COLLECTIONS}/${collectionId}/environments`);

export const deleteAssetItem = async (collectionId, assetItemId) =>
  await axios.delete(`${GET_ALL_ASSET_COLLECTIONS}/${collectionId}/items/${assetItemId}`);

// api/asset-collections/<collectionId>/items/<assetItemId>
// http://localhost:9090/api/asset-collections/nc1/environments

export const deleteAssetCollectionApi = async (collectionId, payload) =>
  await axios.delete(`${GET_ALL_ASSET_COLLECTIONS}/${collectionId}`, { data: payload });

export const retrieveAssetCollectionApi = async (collectionId, payload) =>
  await axios.delete(`${GET_ALL_ASSET_COLLECTIONS}/${collectionId}`, { data: payload });

export const purgeAssetCollectionApi = async (collectionId, payload) =>
  await axios.delete(`${GET_ALL_ASSET_COLLECTIONS}/${collectionId}/purge`, { data: payload });

export const updateAssetCollectionApi = async (collectionId, formData) =>
  await axios.put(`${GET_ALL_ASSET_COLLECTIONS}/${collectionId}`, formData);
