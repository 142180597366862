import { Form, Select } from "antd";
import IButton from "./../buttons/IButton";

const { Option } = Select;
const SelectField = (props) => {
  return (
    <div style={{ position: "relative" }}>
      <Form.Item label={props.label || "Role"} name={props.label || "role"} rules={[{ required: true }]}>
        <Select disabled={props?.disabled} defaultValue={props?.value}>
          {props.options.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <IButton
        options={props.options}
        value={props.value}
        positionStyle={{
          position: "absolute",
          top: "50%",
          left: `calc((((${props.wrapperColSpan} + ${props.labelColSpan})/24) * 100%) + 5px)`,
          // (((wrapper columns + label columns)/total columns * 100%) + gap)
          transform: "translateY(-50%)",
        }}
      />
    </div>
  );
};

export default SelectField;
