import { Outlet } from "react-router-dom";
import Sidebar from "@layouts/Sidebar";

const DashboardLayout = () => {
  return (
    <Sidebar>
        <Outlet />
    </Sidebar>
  );
};

export default DashboardLayout;
