import React from "react";
import { Form, Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

const UploadMsg = (props) => {
  return (
    <div>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">{props?.subMsg || ""}</p>
    </div>
  );
};

const ImageUpload = (props) => {
  const uploadFileProps = (fieldName) => {
    return {
      onRemove: (_file) => {
        props?.setForm({ ...props.formField, [fieldName]: null });
        props?.setStatusText(``);
      },
      beforeUpload: (file) => {
        const fsize = file.size;
        const img = Math.round(fsize / 1024);
        if (img > 1024) {
          props?.setStatus("error");
          props.setForm({ ...props.formField, [fieldName]: null });
          props?.setStatusText("The file size must be 1mb (" + Math.round(fsize / 1024 / 1024) + "Mb)");
          props?.setIsFormValid(false);
          return true;
        } else {
          props?.setStatus("success");
          props?.setForm({ ...props.formField, [fieldName]: file });
          props?.setStatusText("");
          props?.setStatusText(`${Math.round(fsize / 1024 / 1024)}Mb`);
          props?.setIsFormValid(true);
        }
        return false;
      },

      maxCount: 1,
      listType: "picture",
      status: props?.status,
    };
  };
  return (
    <Form.Item
      label="Thumbnail"
      name={props.fieldName}
      hasFeedback
      validateStatus={props?.status}
      extra={props?.statusText}
    >
      <Dragger {...uploadFileProps(props.fieldName)} accept="image/jpeg, image/png,image/jpg">
        <UploadMsg subMsg="Only jpeg or png files are supported."/>
      </Dragger>
    </Form.Item>
  );
};

export default ImageUpload;
