import React from "react";
import { Button, Modal } from "antd";

const SkipModal = ({ visible, onLeftClick, onRightClick, msg = "", leftButtonText, rightButtonText }) => {
  return (
    <Modal
      closable={false}
      open={visible}
      footer={[
        <Button type="primary" onClick={onLeftClick}>
          {leftButtonText || 'Skip'}
        </Button>,
        <Button type="primary" onClick={onRightClick}>
          {rightButtonText || 'No'}
        </Button>,
      ]}
    >
      {msg || "Do you really want to skip this step?"}
    </Modal>
  );
};

export default SkipModal;