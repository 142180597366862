import React, { useEffect, useState } from "react";
import { PageHeader } from "@ant-design/pro-layout";
import { Card, Col } from "antd";
import { Button, Form, Select, Upload, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { addAssetVersionToEnv, publishAssetVersion } from "@services/assetContainer";
import CardList from "@dashboardcards/CardList";
import { getAllEnv } from "@services/EnvService";
import { getAssetState } from "@services/systemService";
import { InboxOutlined } from "@ant-design/icons";
import SkipModal from "@dashboardmodals/Skip";
import SuccessModel from "@dashboardmodals/Success";
import FailureModel from "@dashboardmodals/Failure";

const ReleaseAssets = (props) => {
  const [envData, setEnvData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [skipModal, setSkipModal] = useState(false);
  const [envDataFields, setEnvDataFields] = useState([]);
  const [envItem, setEnvItem] = useState();
  const [selectedState, setSelectedState] = useState();
  const [assetStates, setAssetsStates] = useState([]);
  const [successModalVisiblilty, setSuccessModalVisiblilty] = useState(false);
  const [failureModalVisiblilty, setFailureModalVisiblilty] = useState(false);
  const [assetContainer, setAssetContainer] = useState();
  const [isDeploying, setIsDeploying] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getData();
    setAssetContainer(props?.assetContainer)
  }, []);

  const getData = () => {
    getAllEnv().then((res) => {
      setEnvData(res?.data?.environments);
      changeFields(res?.data?.environments);
    });
    getAssetState("INTENT-STATE").then((res) => {
      const statesList = res?.data?.states
      const index = statesList.indexOf("DEFAULT");
      statesList.splice(index, 1);
      setAssetsStates(statesList);
    });
  };

  const navigateTo = (path) => {
    setFailureModalVisiblilty(false);
    setSuccessModalVisiblilty(false);
    navigate(path);
  };

  let structure = [];
  const changeFields = (Data) => {
    Data?.map((item, index) => {
      if (!(structure.length > Data.length)) {
        structure.push({ title: item.envId, url: item.envImageURL, _id: item._id, key: index, data: item });
      }
    });

    setEnvDataFields([...structure]);
  };

  const getDeploymentFunc = (envItem) => {
    if (envItem.badge) {
      return publishAssetVersion
    }
    return addAssetVersionToEnv
  }

  const deployAsset = (envItem, intent) => {
    setIsDeploying(true);
    const { assetContainerId, version, targetPlatform } = props?.assetVersion;
    const releaseAssetBody = {
      assetContainerId,
      version,
      targetPlatform,
      envId: envItem.data.envId,
      intent,
    };
    const deployFunc = getDeploymentFunc(envItem);
    deployFunc(releaseAssetBody)
      .then((res) => {
        if (res.status === 201) {
          const envIndex = envItem.key;
          const envData = envDataFields[envIndex];
          envData.badge = selectedState;
          if (selectedState === "OFFLINE") {
            envData.badgeColor = "gray"
          }
          setEnvDataFields([...envDataFields]);
          setVisible(false);
          setSuccessModalVisiblilty(true);
        } else {
          setFailureModalVisiblilty(true);
        }
        setIsDeploying(false);
      })
      .catch((err) => {
        setFailureModalVisiblilty(true);
        setIsDeploying(false);
      });
  };

  const [size, setSize] = useState("large"); // default is 'middle'
  return (
    <Card
      className="asset-container"
      headStyle={{ borderBottom: "0px" }}
      style={{ paddingBottom: 30 }}
      bordered={false}
    >
      <SkipModal
        onLeftClick={() => navigateTo("/assetscontainer/" + assetContainer?.assetId)}
        visible={skipModal}
        onRightClick={() => setSkipModal(false)}
        msg="Are you sure, you are done with this step?"
        leftButtonText="Yes"
      />
      <PageHeader
        className="site-page-header"
        title="Release Assets"
        extra={[
          <Button size={size} onClick={() => setSkipModal(true)}>
            Done
          </Button>,
        ]}
      />
      <Modal
        open={visible}
        title={"Choose State"}
        okText={"Deploy"}
        bodyStyle={{ height: 75, marginTop: 40 }}
        onOk={() => {
          deployAsset(envItem, selectedState);
        }}
        onCancel={() => setVisible(false)}
        confirmLoading={isDeploying}
      >
        <Form.Item
          name="state"
          label="State"
          // className="ant-form-item-section"
          rules={[
            {
              required: true,
              message: "Missing State",
            },
          ]}
        >
          <Select
            placeholder="Select State"
            style={{
              width: "100%",
              textAlign: "left",
            }}
            onChange={(val) => setSelectedState(val)}
          >
            {assetStates?.map((state) => (
              <Select.Option value={state}>{state}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Modal>
      <Card title={"Environments"} style={{ paddingBottom: 30, textAlign: "left" }}>
        <Col
          className="environmentList"
          style={{ margin: "auto", maxWidth: "100%" }}
          lg={{ span: 18 }}
          md={{ span: 12 }}
          sm={{ span: 24 }}
          xs={{ span: 24 }}
        >
          <CardList
            style={{ textAlign: "Center" }}
            items={envDataFields}
            onClick={(item, index) => {
              setEnvItem(item);
              setVisible(true);
            }}
            onButtonClick={() => { navigate('/environments') }}
          />
        </Col>
      </Card>
      <SuccessModel
        visible={successModalVisiblilty}
        onRightClick={() => navigateTo("/assetscontainer/" + assetContainer?.assetId)}
        onLeftClick={() => navigateTo("/assetscontainer")}
        leftTitle="Virtual Assets List"
        rightTitle="Virtual Asset Details"
        title="Asset Version Added Successfully"
        msg={"You have successfully deployed this version for environments."}
        onClose={() => setSuccessModalVisiblilty(false)}
      />
      <FailureModel
        visible={failureModalVisiblilty}
        onRightClick={() => navigateTo("/assetscontainer")}
        onLeftClick={() => setFailureModalVisiblilty(false)}
      />
    </Card>
  );
};

const UploadMsg = (props) => {
  return (
    <div>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag file to this area to upload</p>
      <p className="ant-upload-hint">{props?.subMsg || ""}</p>
    </div>
  );
};

export default ReleaseAssets;