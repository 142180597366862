import React from "react";
import './ResetPassForm.css'
import { Form, Input, Button } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { useState } from "react";
import { handleResetPassword } from "@services/resetPasswordRequest";


const FormItem = Form.Item;

function ResetPassForm({success,setSuccess}) {

  const [password, setPassword] = useState(""); //
  const [confirmPassword, setConfirmPassword] = useState(""); //
  const [loading, setLoading] = useState(false); //
  const [error, setError] = useState(null); //
  const [submitted, setSubmitted] = useState(false); //

  const handleFormSubmit = async () => {
    if (!password.trim() || !confirmPassword.trim()) {
      setError("Please enter both new password and confirm password.");
      return;
    }

    setLoading(true);
    try {
      const response = await handleResetPassword(password);
      if (response.status === 200) {
        setSubmitted(true);
        setSuccess(true);
      } else {
        const errorData = await response.json();
        setError(errorData.message || "Something went wrong. Please try again!");
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Network error. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="reset-pass-form">
      <div>
        <div style={{ color: "red", paddingBottom: "15px" }}>{error}</div>
      </div>
      <Form>
        <FormItem
          name="password"
          rules={[
            { required: true, message: "Please input your new password!" },
            { max: 15, min: 8, message: "Password length must be between 8 to 15" },
          ]}
          wrapperCol={{ span: 24 }}
        >
          <Input
            prefix={<LockOutlined style={{ color: "#589636", fontSize: 16 }} />}
            type="password"
            placeholder="New Password"
            className="reset-pass-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={submitted}
          />
        </FormItem>

        <FormItem
          name="confirm-password"
          rules={[
            {
              required: true,
              message: "Please confirm your password",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Passwords do not match"));
              },
            }),
          ]}
          wrapperCol={{ span: 24 }}
        >
          <Input
            prefix={<LockOutlined style={{ color: "#589636", fontSize: 16 }} />}
            type="password"
            placeholder="Confirm New Password"
            className="reset-pass-input"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            disabled={submitted}
          />
        </FormItem>
        <FormItem>
          <Button
            type="primary"
            htmltype="submit"
            className="reset-pass-submit"
            onClick={handleFormSubmit}
            loading={loading}
            disabled={submitted}
          >
            Confirm
          </Button>
        </FormItem>
      </Form>
    </div>
  );
}

export default ResetPassForm;
