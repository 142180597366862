import React, { useEffect, useState } from "react";
import { Breadcrumb, Steps } from "antd";
import AddVersion from "@dashboardforms/AssetVersion";
import ReleaseAsset from "@dashboardforms/ReleaseAsset";
import { Link, useLocation } from "react-router-dom";

const AddAssetVersionSteps = (props) => {
  const [current, setCurrent] = useState(0);
  const [assetContainer, setAssetContainer] = useState();
  const [assetVersion, setAssetVersion] = useState();

  const location = useLocation();

  useEffect(() => {
    if (location?.state?.assetContainer) {
      setAssetContainer(location?.state?.assetContainer)
    }
  }, [])

  const gotToNextStep = () => {
    setCurrent(current + 1);
  }
  const steps = [
    {
      title: "Add Asset Version",
      content: (
        <AddVersion
          setCurrent={(val) => setCurrent(val)}
          gotToNextStep={gotToNextStep}
          assetContainer={assetContainer}
          setAssetVersion={setAssetVersion}
        />
      ),
    },
    {
      title: "Release Asset",
      content: <ReleaseAsset assetVersion={assetVersion} assetContainer={assetContainer} />,
    },
  ];
  const contentStyle = {
    lineHeight: "260px",
    textAlign: "center",
    marginTop: 16,
  };
  return (
    <React.Fragment>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/assetscontainer">Virtual Assets</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Create</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          background: "white",
          padding: 10,
          marginBottom: 5,
          borderRadius: 12,
          boxShadow: "0 1px 2px 0 rgb(0 0 0 / 3%), 0 1px 6px -1px rgb(0 0 0 / 2%), 0 2px 4px 0 rgb(0 0 0 / 2%)",
          marginTop: 15,
        }}
      >
        <Steps current={current} items={steps} />
      </div>
      <div style={contentStyle}>{steps[current].content}</div>
    </React.Fragment>
  );
};

export default AddAssetVersionSteps;