import { ReactComponent as OrganizationIcon } from "../building.svg";
import { ReactComponent as GamesIcon } from "../gamepad.svg";
import { ReactComponent as EnvironmentsIcon } from "../layers.svg";
import { ReactComponent as PrivateAssetsIcon } from "../diversity.svg";
import { ReactComponent as Folder } from "../folder.svg";
import { ReactComponent as GameEngine } from "../gaming1.svg";
import { ReactComponent as GamePlatform } from "../gaming-platforms.svg";
import { DashboardOutlined, UserOutlined ,FolderOpenOutlined} from "@ant-design/icons";
import { SlSupport } from "react-icons/sl";
import { Link } from "react-router-dom";
import Icon from "@ant-design/icons";


// SINGLE-COMPONENT
const getLabel=(label,path)=>{
    return(
        <Link exact="true" to={path}>
            {label}
        </Link>
    )
}

const getIcon=(icon)=>{
    return  <Icon component={icon} />
}

export const Dashboard = {
    label: getLabel('Dashboard','/dashboard'),
    key: 'dashboard',
    icon:getIcon(DashboardOutlined)
}

export const Support = {
    label: getLabel('Support','/support'),
    key: 'support',
    icon:getIcon(SlSupport)
}

export const Users = {
    label:getLabel('Users','/users'),
    key: "users",
    icon:getIcon(UserOutlined)
}

export const Organization = {
    label:getLabel('Organization','/organization'),
    key: "organization",
    icon:getIcon(OrganizationIcon)
}

export const Games = {
    label:getLabel('Games','/games'),
    key: "games",
    icon: getIcon(GamesIcon)
}

export const Environment = {
    label:getLabel('Environments','/environments'),
    key: "environments",
    icon:getIcon(EnvironmentsIcon)
}

export const VirtualAsset = {
    label:getLabel('Virtual Assets','/assetscontainer'),
    key: "assetscontainer",
    icon:getIcon(PrivateAssetsIcon)
}

export const SystemData = {
    label: "System Data",
    key: 8,
    icon:getIcon(Folder),
    children: [
        {
            label:getLabel('Game Engines','/gameengines'),
            key: "gameengines",
            icon: getIcon(GameEngine)
        },
        {
            label:getLabel('Game Platforms','/gameplatforms'),
            key: "gameplatforms",
            icon: getIcon(GamePlatform)
        }
    ]
}

export const BulkUpload = {
    label:getLabel('Bulk Upload','/bulk'),
    key: "bulk",
    icon: getIcon(PrivateAssetsIcon)
}

export const AssetCollection = {
    label:getLabel('Asset Collection' ,'/assetcollection'),
    key: "assetcollection",
    icon: getIcon(FolderOpenOutlined)
}