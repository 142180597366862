import { Role } from '@context/Role'

export const getMenuItem = roleId => {
  let menuData;
  switch (roleId) {
    case Role.OWNER:
      menuData = require("./owner");
      break;
    case Role.ADMIN:
      menuData = require("./admin");
      break;
    case Role.DEVELOPER:
      menuData = require("./developer");
      break;
    case Role.ARTIST:
      menuData = require("./artist");
      break;
    case Role.VIEWER:
      menuData = require("./viewer");
      break;
    default:
      return;
  }
  return menuData.menuItems
};
