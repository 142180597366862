import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import TopLineCard from "./dashboardCards/TopLineCard";
import ChartCard from "./dashboardCards/ChartCard";
import { EmptyDataCard } from "./dashboardCards/EmptyDataCard";

import MostDownloadedAsset from "./dashboardCards/MostDownloadedCard";
import { getTotalAssetCount } from "@services/assetContainer";
import { getTotalEnvironmentCount } from "@services/EnvService";
import { getTotalGameCount } from "@services/gamesService";
import { getAssetDownloadCount } from "@services/analyticsService";
import { getTotalAssetSize } from "@services/assetContainer";
import { getTotalGameAssetCount } from "@services/gameAssetService";
import TotalSize from "./dashboardCards/TotalSizeCard";
import useAuth from "@hooks/useAuth";

const enviromentTitle = "Environments";
const gameTitle = "Games";
const assetTitle = "Assets";
const download = "Downloads";

const Dashboard = () => {
  const { auth } = useAuth();
  const [topLineInfo, setTopLineInfo] = useState([
    {
      title: "Total Asset Size",
      image: "/game-console.svg",
    },
    {
      title: "Assets",
      targetUrl: "/assetscontainer",
      image: "/puzzle.svg",
    },
    {
      title: "Downloads",
      image: "/download-assets.svg",
    },
    {
      title: "Environments",
      targetUrl: "/environments",
      image: "/web-programming.svg",
    },
  ]);

  const getAllAssetSize = async () => {
    getTotalAssetSize()
      .then((res) => {
        if (res.status === 200) {
          const { size, unit } = res?.data;

          const trimmedSize = Math.ceil(size);
          setTopLineInfoData("Total Asset Size", `${trimmedSize} ${unit}`, `${size} ${unit}`);
        } else {
          setTopLineInfoData("Total Asset Size", 0, 0);
        }
      })
      .catch((err) => {
        setTopLineInfoData("Total Asset Size", 0, 0);
      });
  };

  useEffect(() => {
    fetchCount(getTotalGameCount, gameTitle);
    fetchCount(getTotalAssetCount, assetTitle);
    fetchCount(getTotalEnvironmentCount, enviromentTitle);
    fetchCount(getAssetDownloadCount, download, auth?.user.domainDBId);
    getAllAssetSize();
  }, []);

  const setTopLineInfoData = (title, data, tooltip) => {
    const topLine = topLineInfo.find((value) => value.title == title);
    if (topLine) {
      topLine.data = data;
      topLine.tooltip = tooltip;
    }
    setTopLineInfo([...topLineInfo]);
  };

  const fetchCount = (fetchCountFunc, title, ...params) => {
    fetchCountFunc(...params)
      .then((res) => {
        if (res.status === 200) {
          const denote =
            res?.data?.counts >= 1000 && res?.data?.counts <= 999999
              ? `${res.data.counts / 1000} K`
              : res?.data?.counts > 999999
              ? `${res?.data?.counts / 1000000} M`
              : res?.data?.counts;
          setTopLineInfoData(title, denote, res.data.counts);
        } else {
          setTopLineInfoData(title, 0, 0);
        }
      })
      .catch((err) => {
        setTopLineInfoData(title, 0, 0);
      });
  };

  return (
    <Row
      gutter={[
        { xs: 8, sm: 16, md: 24, lg: 24 },
        { xs: 8, sm: 16, md: 24, lg: 24 },
      ]}
    >
      {/* <Col key={4} xl={{ span: 6 }} lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
        <TotalSize title="Total Asset Size" data={20} />
      </Col> */}
      {topLineInfo.map((item, index) => (
        <Col key={index} xl={{ span: 6 }} lg={{ span: 6 }} md={{ span: 12 }} sm={{ span: 12 }} xs={{ span: 24 }}>
          <TopLineCard title={item.title} data={item?.data} tooltip={item?.tooltip} image={item.image} targetUrl={item.targetUrl} />
        </Col>
      ))}

      <Col lg={{ span: 15 }} span={24}>
        {/* <ChartCard /> */}
        <EmptyDataCard title="Asset Download Data (Weekly)" />
      </Col>
      <Col lg={{ span: 9 }} span={24}>
        {/* <MostDownloadedAsset title="GS2045 (Blue ship)" week="Week (16/01)" /> */}
        <EmptyDataCard title="Most Downloaded Asset" />
      </Col>
    </Row>
  );
};

export default Dashboard;
