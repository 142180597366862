import React from "react";
import { Button, Modal } from "antd";

const SuccessModel = ({ visible, msg, onLeftClick, onRightClick, title, leftTitle, rightTitle, onClose }) => {
  const footers = []
  if (leftTitle) {
    footers.push(
      <Button type="primary" onClick={onLeftClick}>
        {leftTitle}
      </Button>)
  }
  if (rightTitle) {
    footers.push(
      <Button type="primary" onClick={onRightClick}>
        {rightTitle}
      </Button>
    )
  }
  return (
    <Modal
      success
      open={visible}
      title={title}
      onCancel={onClose}
      footer={footers}
    >
      {msg}
    </Modal>
  );
};

export default SuccessModel;

// [
//   <Button type="primary" onClick={onLeftClick}>
//     {leftTitle}
//   </Button>,
//   <Button type="primary" onClick={onRightClick}>
//     {rightTitle}
//   </Button>,
// ]