import React from "react";
import { Form, Input } from "antd";
const FullName = () => {
  return (
    <Form.Item
      label="Full Name"
      name="name"
      rules={[
        { required: true, message: "Please enter Full Name" },
        { max: 64, min: 3, message: "character length must be between 3 to 64" },
        { pattern: `^[a-zA-Z]`, message: "Full Name only start with alphabet " },
      ]}
    >
      <Input style={{ height: 40, borderRadius: "10%" }} />
    </Form.Item>
  );
};

export default FullName;
