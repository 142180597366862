import axios from '@auth/localaxios';
import { PRIVATE_ASSET_API_KEY_PATH } from '@localconfig/envconfig';

export const generatePrivateAssetAPIKEY = async (body) => {
    try {
        return axios.post(PRIVATE_ASSET_API_KEY_PATH, body);
    } catch (error) {
        return error
    }
}
export const getPrivateAssetAPIKEY = async (id) => {
    try {
        return axios.get(PRIVATE_ASSET_API_KEY_PATH + "/" + id);
    } catch (error) {
        return error
    }
}
export const deletePrivateAssetAPIKEY = async (maskedkey, envId) => {
    try {
        return axios.delete(PRIVATE_ASSET_API_KEY_PATH + "/"+envId+"/"+maskedkey);
    } catch (error) {
        return error
    }
}