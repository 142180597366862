import React, { useEffect, useState } from "react";
import { Button, Form, Input, Skeleton, Modal, InputNumber, Switch } from "antd";
import { useNavigate } from "react-router-dom";
import "./css/EnvForm.css";
import { verifyEnvId, createEnv } from "@services/EnvService";
import SubmitButton from "../../buttons/SubmitButton";
// import BreadCrumbs from "../BreadCrumbs";
import ImageUpload from "../ImageUpload";
import GenericFormContainer from "../GenericFormContainer";

const AddNewEnv = () => {
  const [formData] = Form.useForm();
  const [formField, setForm] = useState();
  const [isFormValid, setIsFormValid] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [successModalVisibility, setSuccessModalVisibility] = useState(false);
  const [cacheAge, setCacheAge] = useState();
  const [failureModalVisibility, setFailureModalVisibility] = useState(false);
  const [status, setStatus] = useState();
  const [helpText, setHelpText] = useState();
  const [statusText, setStatusText] = useState("");
  const [cacheError, setCacheError] = useState("");
  const [cacheEnabled, setCacheEnabled] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    formData.setFieldsValue({ active: true });
  }, [formData]);

  const verifyEnvIdExist = () => {
    if (formField.envId === "") {
      setHelpText("Please enter environment Id!");
      setStatus("error");
    } else if (formField.envId !== "") {
      setHelpText("");
      setStatus("success");
      verifyEnvId(formField.envId)
        .then((response) => {
          if (response.status === 200 && response.data.isIdPresent) {
            setHelpText("The envId is already taken!");
            setStatus("error");
          } else {
            setHelpText("");
            setStatus("success");
          }
        })
        .catch((_err) => {
          setHelpText("Unable to verify the envId, Please try again later!");
          setStatus("error");
        });
    }
  };
  const checkFormValidity = () => {
    const hasError = formData.getFieldsError().some(({ errors }) => errors.length > 0);
    if (cacheEnabled) {
      const {
        envId = "",
        envName = "",
        description = "",
        localCacheControl,
        localCacheMaxAge,
      } = formData.getFieldsValue();
      const isFormCompleted = envId?.trim() && envName?.trim();
      const isValid = !hasError && isFormCompleted;
      setForm({ ...formField, envId, envName, description, localCacheControl, localCacheMaxAge });
      setIsFormValid(isValid);
    } else {
      const { envId = "", envName = "", description = "" } = formData.getFieldsValue();
      const isFormCompleted = envId?.trim() && envName?.trim();
      const isValid = !hasError && isFormCompleted;
      setForm({ ...formField, envId, envName, description });
      setIsFormValid(isValid);
    }
  };

  const resetFunc = () => {
    formData.resetFields();
    checkFormValidity();
  };

  const createEnvironment = () => {
    setLoading(true);
    const data = new FormData();
    for (let key in formField) {
      data.append(key, formField[key]);
    }
    createEnv(data)
      .then((res) => {
        if (res?.status === 201) {
          setSuccessModalVisibility(true);
        } else {
          setFailureModalVisibility(true);
        }
        setLoading(false);
      })
      .catch((_err) => {
        setLoading(false);
        setFailureModalVisibility(true);
      });
  };

  const onSubmit = () => {
    createEnvironment();
  };

  const navigateToEnvironmentsList = () => {
    setFailureModalVisibility(false);
    setSuccessModalVisibility(false);
    navigate("/environments");
  };

  const createMoreEnvironment = () => {
    setSuccessModalVisibility(false);
    formData.resetFields();
  };
  const tryAgain = () => {
    setFailureModalVisibility(false);
  };
  const handlePrivateMaxAge = (e) => {
    const inputValue = e.target.value;

    if (inputValue === "" || isNaN(inputValue)) {
      setCacheError("Please enter a valid number");
    } else if (Number(inputValue) >  525600 || Number(inputValue) < 1 || Number(inputValue) === "") {
      setCacheError("Cache Age cannot be more than 525,600 min (365 days) or less than 1 min");
      setIsFormValid(false);
    } else {
      setIsFormValid(true);
      setCacheAge(inputValue);
      setCacheError("");
    }
  };
  return (
    <div>
      <GenericFormContainer
        formData={formData}
        path="Games"
        formName={"Create New Environment"}
        breadcrumbPath={"envCreatePage"}
        resetFunc={resetFunc}
        navigate={() => navigate("/environments")}
      >
        <Skeleton loading={false}>
          <Form
            form={formData}
            labelCol={{ span: 5 }}
            onFieldsChange={() => {
              checkFormValidity();
            }}
            wrapperCol={{ span: 14 }}
            className={"form"}
            // initialValues={{ localCacheMaxAge: 30 }}
          >
            <Form.Item
              label="Environment Id"
              name="envId"
              validateStatus={status}
              help={helpText}
              rules={[
                { required: true, message: "Please enter Environment Id" },
                { max: 32, min: 3, message: "character length must be between 3 to 32" },
                { pattern: `^\\S*$`, message: "Spaces are not allowed in Environment Id" },
                { pattern: `^[a-zA-Z]`, message: "Environment Id can only start with alphabate" },
                { pattern: `[\\w]$`, message: "Environment name can have only alphanumeric and '_' characters" },
              ]}
            >
              <Input style={{ height: 40, borderRadius: "10%" }} onBlur={verifyEnvIdExist} />
            </Form.Item>
            <Form.Item
              label="Environment Name"
              name="envName"
              rules={[
                { required: true, message: "Please enter Environment Name" },
                { max: 64, min: 3, message: "character length must be between 3 to 64" },
                { pattern: `^[a-zA-Z]`, message: "Environment Name only start  with alphabate " },
              ]}
            >
              <Input style={{ height: 40, borderRadius: "10%" }} />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ max: 4000, min: 0, message: "character length must be between 0 to 4000" }]}
            >
              <Input.TextArea name="description" style={{ height: 100 }} />
            </Form.Item>

            <ImageUpload
              setStatus={(val) => setStatus(val)}
              setForm={(val) => setForm(val)}
              setIsFormValid={setIsFormValid}
              setStatusText={setStatusText}
              fieldName="envImageURL"
              status={status}
              statusText={statusText}
            />
            <Form.Item label="Local Cache" name="localCacheControl">
              <Switch
                style={{ alignSelf: "start" }}
                name="localCacheControl"
                value={cacheEnabled}
                onChange={setCacheEnabled}
              />
            </Form.Item>
            {cacheEnabled && (
              <Form.Item
                help={cacheError ? cacheError : "Default 30 minutes and Max 525,600 minutes (365 days)"}
                label="Local Cache Max Age"
                name="localCacheMaxAge"
                required
                style={{ marginBottom: 35 }}
                validateStatus={cacheError === "" ? "success" : "error"}
                // help={}
              >
                <Input  
                  type="number"
                  style={{ height: 40, borderRadius: "100px", width: "100%" }}
                  name="localCacheMaxAge"
                  value={cacheAge}  
                  onChange={handlePrivateMaxAge}
                  min={1}
                  max={525600}
                />
              </Form.Item>
            )}
            <SubmitButton
              loading={isLoading}
              isDisabled={!isFormValid}
              onFinish={onSubmit}
              title="Create Environment"
            />
          </Form>
          <SuccessModel
            visible={successModalVisibility}
            onRightClick={navigateToEnvironmentsList}
            onLeftClick={createMoreEnvironment}
          />
          <FailureModel
            visible={failureModalVisibility}
            onRightClick={navigateToEnvironmentsList}
            onLeftClick={tryAgain}
          />
        </Skeleton>
      </GenericFormContainer>
    </div>
  );
};

const SuccessModel = ({ visible, msg, onLeftClick, onRightClick }) => {
  return (
    <Modal
      open={visible}
      onClose={onRightClick}
      footer={[
        <Button type="primary" onClick={onLeftClick}>
          Create More Environment
        </Button>,
        <Button type="primary" onClick={onRightClick}>
          Close
        </Button>,
      ]}
    >
      Environment has been created successfully
    </Modal>
  );
};

const FailureModel = ({ visible, onLeftClick, onRightClick }) => {
  return (
    <Modal
      open={visible}
      onClose={onRightClick}
      footer={[
        <Button type="primary" onClick={onLeftClick}>
          Try Again
        </Button>,
        <Button type="primary" onClick={onRightClick}>
          Close
        </Button>,
      ]}
    >
      <p>Sorry! We are unable to create your environment right now.</p>
    </Modal>
  );
};

export default AddNewEnv;
