export const convertDateToNestorTime = (dateValue = new Date()) => {
  const options = { month: 'short' };
  const date = new Date(dateValue);
  const day = date.getDate();
  const month = date.toLocaleDateString('en-US', options);
  const year = date.getFullYear()
  const hour = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  return `${day}-${month}-${year} ${hour}:${minutes}:${seconds}`
}


let currentDate = new Date();
let monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

let day = currentDate.getDate();
let monthIndex = currentDate.getMonth(); // Note: Months are zero-based
let  year = currentDate.getFullYear();
const hour = currentDate.getHours();
const minutes = currentDate.getMinutes();
const seconds = currentDate.getSeconds();
export let formattedDate = `${day}-${monthNames[monthIndex]}-${year} ${hour}:${minutes}:${seconds}`
