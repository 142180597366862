import React from "react";
import { PageHeader } from '@ant-design/pro-layout';
import { Descriptions, Card } from "antd";
import { Link } from "react-router-dom";


const SubscriptionInfo = (props) => {
  return (
    <React.Fragment>
        <PageHeader
        className="site-page-header"
        // onBack={() => null}
        backIcon={null}
        title="Subscription Details"
      />
        <Descriptions bordered={true}
          style={{
            width: 500,
            margin: "auto",
            marginTop: 10,
            marginBottom: 40,
            borderColor: "#fff" 
          }}
          labelStyle={{overflow:"hidden", whiteSpace: "nowrap"}}
          >
          <Descriptions.Item label={"Subscription Id"} span={6} >
            {props.subscription.subscriptionId}
          </Descriptions.Item>
          <Descriptions.Item label={"Product Id"} span={3}>
            <Link to={"/products/"+props.product.productTypeName+"/"+props.product.productId}>{props.product.productId}
            </Link>
          </Descriptions.Item>
          <Descriptions.Item label={"Product Name"} span={3}>
          {props.product.name}
          </Descriptions.Item>
          <Descriptions.Item label={"Product Thumbnail"} span={3}>
          <Card bordered={true}>
            <img
              src={props.product.thumbnailImageURL}
              alt=""
            />
          </Card>
        </Descriptions.Item>
          </Descriptions>
      </React.Fragment>
      );
  }

export default SubscriptionInfo;
