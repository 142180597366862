import { CheckOutlined, StopOutlined } from "@ant-design/icons";
import { Modal, Table, Select } from "antd";
import { StringHelper } from "@utilities";
import { useState } from "react";
import "./Modal_i_btn.css";

const TableUI = (props) => {
  const { Option } = Select;
  const { options, value, role } = props

  const tick = <CheckOutlined style={{ color: "#00b96b", scale: "1.2" }} />;
  const cross = <StopOutlined style={{ color: "#CCCCCC", scale: "1.2" }} />;

  const [selectedRole, setSelectedRole] = useState(value ? value : options ? options[0].value : "gr0001");

  const data = {
    gr0001: {
      Organization: {
        update: true,
        read: true,
      },
      Environment: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      "API Keys": {
        create: true,
      },
      "Asset Details": {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      "Asset Version": {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      "Asset Version - Env Mapping": {
        create: true,
        read: true,
      },
      "Asset Version - State": {
        update: true,
      },
      Users: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      // Game: {
      //   create: true,
      //   read: true,
      //   update: true,
      //   delete: true,
      // },
      // "Bulk Upload": {
      //   create: true,
      //   read: true,
      //   update: true,
      //   delete: true,
      // },
      Support:{
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      "Asset Collection":{
        create:true,
        read:true,
        update:false,
        delete:true
      },
      "Asset Collection - Item":{
        create:true,
        read:true,
        update:false,
        delete:true
      },
      "Asset Collection - Deployment":{
        create:true,
        read:true,
        update:false,
        delete:true
      }
    },
    gr0002: {
      Organization: {
        update: false,
        read: true,
      },
      Environment: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      "API Keys": {
        create: true,
      },
      "Asset Details": {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      "Asset Version": {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      "Asset Version - Env Mapping": {
        create: true,
        read: true,
      },
      "Asset Version - State": {
        update: true,
      },
      Users: {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      // Game: {
      //   create: true,
      //   read: true,
      //   update: true,
      //   delete: true,
      // },
      // "Bulk Upload": {
      //   create: true,
      //   read: true,
      //   update: true,
      //   delete: true,
      // },
      Support:{
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      "Asset Collection":{
        create:true,
        read:true,
        update:false,
        delete:true
      },
      "Asset Collection - Item":{
        create:true,
        read:true,
        update:false,
        delete:true
      },
      "Asset Collection - Deployment":{
        create:true,
        read:true,
        update:false,
        delete:true
      }
    },
    gr0003: {
      Organization: {
        update: false,
        read: false,
      },
      Environment: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      "API Keys": {
        create: false,
      },
      "Asset Details": {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      "Asset Version": {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      "Asset Version - Env Mapping": {
        create: true,
        read: true,
      },
      "Asset Version - State": {
        update: true,
      },
      Users: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      // Game: {
      //   create: false,
      //   read: true,
      //   update: false,
      //   delete: false,
      // },
      // "Bulk Upload": {
      //   create: false,
      //   read: true,
      //   update: false,
      //   delete: false,
      // },
      Support:{
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      "Asset Collection":{
        create:false,
        read:true,
        update:false,
        delete:false
      },
      "Asset Collection - Item":{
        create:false,
        read:true,
        update:false,
        delete:true
      },
      "Asset Collection - Deployment":{
        create:true,
        read:true,
        update:false,
        delete:true
      }
    },
    gr0004: {
      Organization: {
        update: false,
        read: false,
      },
      Environment: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      "API Keys": {
        create: false,
      },
      "Asset Details": {
        create: true,
        read: true,
        update: true,
        delete: false,
      },
      "Asset Version": {
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      "Asset Version - Env Mapping": {
        create: true,
        read: true,
      },
      "Asset Version - State": {
        update: true,
      },
      Users: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      // Game: {
      //   create: false,
      //   read: true,
      //   update: false,
      //   delete: false,
      // },
      // "Bulk Upload": {
      //   create: false,
      //   read: true,
      //   update: false,
      //   delete: false,
      // },
      Support:{
        create: true,
        read: true,
        update: true,
        delete: true,
      },
      "Asset Collection":{
        create:true,
        read:true,
        update:false,
        delete:true
      },
      "Asset Collection - Item":{
        create:false,
        read:true,
        update:false,
        delete:false
      },
      "Asset Collection - Deployment":{
        create:false,
        read:true,
        update:false,
        delete:false
      }
    },
    gr0005: {
      Organization: {
        update: false,
        read: false,
      },
      Environment: {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      "API Keys": {
        create: false,
      },
      "Asset Details": {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      "Asset Version": {
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      "Asset Version - Env Mapping": {
        create: false,
        read: true,
      },
      "Asset Version - State": {
        update: false,
      },
      Users: {
        create: false,
        read: false,
        update: false,
        delete: false,
      },
      // Game: {
      //   create: false,
      //   read: true,
      //   update: false,
      //   delete: false,
      // },
      // "Bulk Upload": {
      //   create: false,
      //   read: false,
      //   update: false,
      //   delete: false,
      // },
      Support:{
        create: false,
        read: true,
        update: false,
        delete: false,
      },
      "Asset Collection":{
        create:false,
        read:true,
        update:false,
        delete:false
      },
      "Asset Collection - Item":{
        create:false,
        read:true,
        update:false,
        delete:false
      },
      "Asset Collection - Deployment":{
        create:false,
        read:true,
        update:false,
        delete:false
      }
    },
  };

  const tableData = data[role?.roleId || selectedRole];

  const columns = [
    {
      title: "Feature",
      dataIndex: "feature",
      sticky: "top",
      className: "bold",
    },
    {
      title: options ? (
        <Select style={{ width: "110px" }} value={selectedRole} onChange={setSelectedRole}>
          {options.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      ) : (
        StringHelper.toFirstLetterUpperCase(role?.roleName)
      ),
      dataIndex: "crudOps",
      children: [
        {
          title: "Create",
          dataIndex: "create",
          align: "center",
          className: "sameWidth"
        },
        {
          title: "Read",
          dataIndex: "read",
          align: "center",
          className: "sameWidth"
        },
        {
          title: "Update",
          dataIndex: "update",
          align: "center",
          className: "sameWidth"
        },
        {
          title: "Delete",
          dataIndex: "delete",
          align: "center",
          className: "sameWidth"
        },
      ],
    },
  ];

  columns[0].fixed = "left";

  const rows = tableData
    ? Object.entries(tableData).map(([key, value]) => ({
        feature: key,
        create: value?.create ? tick : cross,
        read: value?.read ? tick : cross,
        update: value?.update ? tick : cross,
        delete: value?.delete ? tick : cross,
      }))
    : null;

  return <Table dataSource={rows} columns={columns} pagination={false} bordered />;
};

const optionsModal = (props) => {
  const { open, setOpen } = props;

  return (
    <>
      <Modal
        title={"Access Control List"}
        maskClosable={false}
        bodyStyle={{
          overflow: "auto",
        }}
        className="roles-modal"
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
      >
        <TableUI {...props} />
      </Modal>
    </>
  );
};

export default optionsModal;
