import React from "react";
import "./ImageComparison.css";
import { ReactCompareSlider, ReactCompareSliderImage } from "react-compare-slider";

const ImageComparison = ({ leftImage, rightImage }) => {
  return (
    <>
      <ReactCompareSlider
        boundsPadding={0}
        itemOne={
          <ReactCompareSliderImage
          crossOrigin="use-credentials"
            alt="Image one"
            src={leftImage}
            style={{ objectFit:'inherit' }}
          />
        }
        itemTwo={
          <ReactCompareSliderImage
          crossOrigin="use-credentials"
            alt="Image two"
            src={rightImage}
            style={{objectFit:'inherit' }}
          />
        }
        keyboardIncrement="5%"
        position={50}
        style={{
          height: "70vh",
          width: "100%",
          transition : '.5s ease-in-out'
        }}
      />
    </>
  );
};

export default ImageComparison;
