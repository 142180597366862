import React, { useEffect, useState } from "react";
import "./css/AssetCollection.css";
import { Breadcrumb, Skeleton, message, Pagination, Button } from "antd";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { PageHeaderListForm } from "@dashboardpages/PageHeader";
import { AddNewButton } from "../../buttons/AddNewButton";
import CardList from "@dashboardcards/CardList";
import { getAllAssetCollection } from "@services/assetCollection";
import { AppObject } from "@context/AppObject";

function AssetCollection() {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(true);
  const [assetCollections, setAssetCollections] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [showTotal, setShowTotal] = useState();

  const pageSize = 50;

  const createAsset = () => {
    navigate("/assetcollection/createnewasset");
  };

  useEffect(() => {
    const fetchAssetCollections = async () => {
      const query = `pageNumber=${currentPage}&pageSize=${pageSize}`;
      try {
        const response = await getAllAssetCollection(query);
        if (response.status === 200) {
          if(response.data.results.length){
          const transformedData = response.data.results.map((item) => ({
            title: item.collectionName,
            description: item.collectionId,
            collectionId: item.collectionId,
            badge: item.deleted ? "Deleted" : null,
            badgeColor: item.deleted ? "red" : null,
          }));
          setAssetCollections(transformedData);
          setTotalItems(response.data.totalResults);
        }
        } else {
          message.error("Failed to fetch asset collections");
        }
      } catch (error) {
        message.error("An error occurred while fetching asset collections");
      } finally {
        setLoading(false);
      }
    };

    fetchAssetCollections();
  }, [currentPage, pageSize]);

  const goToDetails = (collectionId) => {
    navigate(`/assetcollection/${collectionId}`, { state: { collectionId } });
  };

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const TotalComponent = () => {
    return (
      <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "end", marginBlock: "10px" }}>
        {showTotal}
        <Pagination
          {...paginationConfg}
          style={{
            textAlign: "left",
          }}
        />
      </div>
    );
  };
  const paginationConfg = {
    total: parseInt(totalItems),
    responsive: true,
    pageSize: pageSize,
    current: currentPage,
    onChange: setCurrentPage,
    showSizeChanger: false,
    showTotal: (total, range) => setShowTotal(`${range[0]}-${range[1]} of ${total} Collections`),
  };

  return (
    <div className="ac-main">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">Home</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Asset Collections</Breadcrumb.Item>
      </Breadcrumb>

      <PageHeaderListForm
      objectId={AppObject.ASSET_COLLECTION}
        title={["Asset Collections"]}
        onAdd={createAsset}
      />
      <Skeleton loading={isLoading}>
      {totalItems > 0 && <TotalComponent />}
        <div>
          <CardList
            items={assetCollections}
            onClick={(item) => goToDetails(item.collectionId)}
            buttonText="Add Asset Collection"
            onButtonClick={createAsset}
          />
        </div>
      </Skeleton>
      {totalItems > pageSize && (
        <div
          style={{
            marginTop: 15,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Pagination current={currentPage} total={totalItems} pageSize={pageSize} onChange={onPageChange} showSizeChanger={false}/>
        </div>
      )}
    </div>
  );
}

export default AssetCollection;
