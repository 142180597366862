import React, { useState } from "react";
import { Row, Col, Card, Typography, Image } from "antd";
import { StringHelper } from "@utilities";
import "../../dashboard/pages/css/UserCard.css";
import { EyeOutlined, EditOutlined } from "@ant-design/icons";
import useAuth from "@hooks/useAuth";
import IButton from "../buttons/IButton";

const { Title } = Typography;

function GenericDetailsPage(props) {
  const [preview, setPreview] = useState(false);
  const { hasAccess, ACTIONS } = useAuth();

  const getDetailList = () => {
    let data = [];
    for (const key in props.items) {
      data.push(
        <Title level={5} className="versionDetails-row" key={key}>
          <span className="versionDetails-col" style={{ textAlign: "right" }}>
            <strong>{key.toString()}:</strong>
          </span>
          <span style={{ textAlign: "left", marginLeft: 10 }}>
            {" "}
            {props?.items[key]?.toString()} {key === "Role" ? <IButton role={props?.role} /> : null}
          </span>
        </Title>
      );
    }
    return data;
  };
  const InitialImage = ({ onClick }) => {
    if (props.thumbnail) {
      return (
        <Card
          bordered={false}
          style={{
            width: 300,
            height: 300,
            borderRadius: "100%",
            cursor: "pointer",
            display: "flex",
            overflow: "hidden",
          }}
          bodyStyle={{
            display: "flex",
            padding: 0,
            position: "relative",
            overflow: "hidden",
            boxShadow: "none",
            border: 0,
            alignItems: "center",
          }}
          className="card-hovering"
        >
          <Image
            id="image"
            preview={
              preview && {
                visible: preview,
                scaleStep: 1,
                src: props?.thumbnail,
                onVisibleChange: (value) => {
                  setPreview(value);
                },
              }
            }
            width={"300px"}
            height={"300px"}
            style={{ objectFit: "scale-down" }}
            src={props?.thumbnail}
          />
          <br />

          <div className="overlay-save" style={{ borderRadius: "100%" }}>
            <EyeOutlined onClick={() => setPreview(true)} style={{ margin: 10 }} />
            {hasAccess(props?.objectId, ACTIONS.PUT) && !!onClick && (
              <EditOutlined onClick={onClick} style={{ margin: 10 }} />
            )}
          </div>
        </Card>
      );
    }
    return (
      <Card
        bordered={false}
        style={{ width: 300, height: 300, borderRadius: "100%", cursor: "pointer", display: "flex" }}
        bodyStyle={{
          display: "flex",
          padding: 0,
          position: "relative",
          overflow: "hidden",
          alignItems: "center",
        }}
        className="card-hovering"
      >
        <h1
          onClick={onClick}
          style={{
            background: "#eee",
            height: "300px",
            width: "300px",
            borderRadius: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "74px",
            textAlign: "center",
            margin: "auto",
          }}
        >
          {StringHelper.getInitials(props.name, true)}
        </h1>

        <div className="overlay-save" style={{ borderRadius: "100%" }}>
          {hasAccess(props?.objectId, ACTIONS.PUT) && !!onClick && <EditOutlined onClick={onClick} />}
        </div>
      </Card>
    );
  };
  return (
    <React.Fragment>
      <Row
        gutter={[
          { xs: 8, sm: 16, md: 24, lg: 24 },
          { xs: 8, sm: 16, md: 24, lg: 24 },
        ]}
      >
        <Col lg={{ span: 24 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
          <Card hoverable={false} style={{ borderRadius: "0px" }} bordered={false}>
            <Row style={{ paddingTop: "40px" }}>
              <Col
                lg={{ span: 12 }}
                md={{ span: 12 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
                style={{ height: "auto", display: "flex", alignItems: "center", justifyContent: "center" }}
              >
                <InitialImage onClick={props.onUpdateImage} />
              </Col>
              <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                <div id="card-info">{getDetailList()}</div>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default GenericDetailsPage;
