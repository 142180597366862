const BulkUpload = {
  bulkUploadPath: [
    { name: "Home", path: "/dashboard" },
    {
      name: "Bulk Upload",
    },
  ],
};

export default BulkUpload;
