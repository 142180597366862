import React from "react";
import { Modal, Input } from "antd";

const InputModal = (props) => {
  const renderInputs = (nestorOptions) => {
    return (
      <div>
        <p>{nestorOptions?.message}</p>
        {nestorOptions?.inputItems?.map((item) => (
          <Input {...item} />
        ))}
      </div>
    );
  };
  console.debug(props);
  return (
    <React.Fragment>
      <Modal {...props}>{renderInputs(props.nestorOptions)}</Modal>
    </React.Fragment>
  );
};

export default InputModal;
