import React, { useState } from "react";
import { Row, Col, Card, Input } from "antd";
import { Form, Button, Checkbox } from "antd";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Typography, Alert } from "antd";
import { useNavigate } from "react-router-dom";
import { signin } from "@auth";
import useAuth from "@hooks/useAuth";
import { getUserROMByRoleId } from "@services/systemService";
import './css/Login.css';

const { Title } = Typography;
const FormItem = Form.Item;

const Login = () => {
  const { setAuth } = useAuth();

  const [values, setValues] = useState({
    email: "",
    password: "",
    error: "",
    loading: false,
    redirectToReferrer: false,
  });

  const { email, password, loading, error, redirectToReferrer } = values;

  const handleChange = (name) => (event) => {
    setValues({ ...values, error: false, [name]: event.target.value });
  };

  const clickSubmit = (event) => {
    event.preventDefault();
    setValues({ ...values, error: false, loading: true });
    signin({ email, password }).then((responseData) => {
      if (responseData?.status === 200) {
        const roles = responseData?.data?.user?.roles;
        const roleId = roles?.length > 0 && roles[0];
        getUserROMByRoleId(roleId).then(romResponse => {
          const roms = romResponse?.data?.roms;
          setAuth({
            token: responseData?.data?.token,
            user: responseData?.data?.user,
            roms
          });
          setValues({
            ...values,
            email: "",
            password: "",
            loading: false,
            error: "",
            redirectToReferrer: true,
          });
          navigate("/dashboard", { replace: true });
        }).catch(err=>{
          setValues({
            ...values,
            loading: false,
            error: "Unable to load user data. Please try after sometime",
            redirectToReferrer: false,
          });
        })
      } else if (!responseData.response) {

        setValues({
          ...values,
          loading: false,
          error: "No Response From Server. Please try after sometime",
          redirectToReferrer: false,
        });
      } else if (responseData?.response?.status === 401) {
        setValues({
          ...values,
          email: "",
          password: "",
          loading: false,
          error: responseData?.response?.data?.message,
          redirectToReferrer: false,
        });
      } else if (responseData?.response?.status === 404) {
        setValues({
          ...values,
          email: "",
          password: "",
          loading: false,
          error: "Please try after sometime",
          redirectToReferrer: false,
        });
      }
    });
  };

  const showError = () => (
    <Alert message={error} type="error" showIcon style={{ display: error ? "" : "none", marginBottom: "10px" }} />
  );

  let navigate = useNavigate();
  const loginForm = () => (
    <Form className="login-form" required>
      {showError()}
      <FormItem name="email" rules={[{ required: true, message: "Please input your email!" }]}>
        <Input
          prefix={<MailOutlined style={{ color: "#589636", fontSize: 16 }} />}
          type="email"
          placeholder="Email"
          onChange={handleChange("email")}
          value={email}
        />
      </FormItem>
      <FormItem name="password" rules={[{ required: true, message: "Please input your password!" }]}>
        <Input
          prefix={<LockOutlined style={{ color: "#589636", fontSize: 16 }} />}
          type="password"
          placeholder="Password"
          onChange={handleChange("password")}
          value={password}
        />
      </FormItem>
      <FormItem>
        <div className="login-form-forgot">
          <Checkbox>Remember me</Checkbox>
          <a href="/">Forgot password</a>
        </div>
        <Button type="primary" htmltype="submit" className="login-form-button" onClick={clickSubmit} loading={loading}>
          {/* <Button
          type="primary"
          htmltype="submit"
          className="login-form-button"
          onClick={() => navigate("/dashboard")}
        > */}
          Log in
        </Button>
      </FormItem>
    </Form>
  );

  const loginCard = () => (
    <Card style={{ width: "35%", height: "100%" }}>
      <Title>
        <a className="navbar-brand logo-image" href="/">
          <img src="/thumbnail_nestor1.png" style={{ width: "30%" }} alt="Nestor" />
        </a>
      </Title>
      {loginForm()}
    </Card>
  );

  return (
    <Row>
      <Col className="login-banner-col"
        lg={{ span: 16 }} md={{ span: 12 }} sm={{ span: 0 }} xs={{ span: 0 }} >
        <div className="login-nestor-text-div">
          <img src='/N-logo.png' className="login-logo-img" alt="Nestor"></img>
        </div>
        <div className="rightside-banner">
        </div>
      </Col>
      <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }} >
        <Col span={12} offset={6}>
          <div className="login-logo-div">
            <img src={process.env.PUBLIC_URL + '/nestor_text.png'} alt="Nestor" className="login-nestor-text-img"></img>
          </div>
          <Title level={5} className="login-green-title">[Green]</Title>
          <Title level={2} className="login-welcome-text">Welcome back!</Title>
        </Col>
        <Col lg={{ span: 24 }} md={{ span: 18 }} className="login-section">
          {loginForm()}
        </Col>
      </Col>
    </Row>
  );
};
export default Login;
